import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Select,
  Button,
  Typography,
  DatePicker,
  AutoComplete,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  FileDoneOutlined,
  StopOutlined,
  ExceptionOutlined,
  FileExcelOutlined,
} from '@ant-design/icons'

import { format, parseISO, subDays } from 'date-fns'
import moment from 'moment'

import InputMask from 'react-input-mask'
import services from '../subscriptionPlan.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'

import priceMask from '~/utils/price-mask'
import removeMask from '~/utils/remove-mask'

import {
  PlanWrapper,
  FormWrapper,
  ButtonIcon,
  LegendWrapper,
  LegendItem,
} from '../subscriptionPlan.styles'

import GraphicSubscriptionPlan from './subscriptionPlan-graphic.component'

function SubscriptionPlanListComponent() {
  const [filter, setFilter] = useState()
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('')
  const [partnerSchool, setPartnerSchool] = useState([])
  const [partnerSchoolId, setPartnerSchoolId] = useState('')
  const { Text } = Typography
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [formSubscriptionPlan] = Form.useForm()
  const dateNow = new Date()
  const beginDateNow = format(subDays(dateNow, 7), 'yyyy-MM-dd')
  const endDateNow = format(dateNow, 'yyyy-MM-dd')
  const history = useHistory()

  function getFilter() {
    setFilter({
      DateType: 1,
      BeginDate: beginDateNow,
      EndDate: endDateNow,
    })
  }

  useEffect(() => {
    getFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getSubscriptionPlan() {
      const response = await services.subscriptionPlan()

      const subscriptionPlanList = response.data.map((item) => {
        return {
          id: item.id,
          value: item.name,
        }
      })

      setSubscriptionPlan(subscriptionPlanList)
    }

    getSubscriptionPlan()
  }, [])

  useEffect(() => {
    async function getPartnerSchool() {
      const response = await services.partnerSchool()

      const partnerSchoolList = response.data.map((item) => {
        return {
          id: item.id,
          value: item.schoolName,
        }
      })

      setPartnerSchool(partnerSchoolList)
    }

    getPartnerSchool()
  }, [])

  function getDateFormat(dateValue, type) {
    if (dateValue) {
      const date = parseISO(dateValue)
      let newFormatDate = 'dd/MM/yyyy'

      if (type) {
        newFormatDate = 'yyyy-MM-dd'
      }

      // const dateInitial = dateString[0].replace(/\//g, '-')
      // const dateEnd = dateString[1].replace(/\//g, '-')

      return format(date, newFormatDate)
    }

    return ''
  }

  function renderPriceMask(value) {
    return <Text>{priceMask(value)}</Text>
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar Planos e Escolas">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() =>
                history.push(`/aluno/${data.studentId}/plano-e-assinatura/`)
              }
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderPaymentStatus(data) {
    let icon = ''
    let title = ''
    let color = ''

    if (data === 'Confirmada') {
      icon = <FileDoneOutlined />
      title = 'Assinatura confirmada'
      color = 'confirmed'
    }

    if (data === 'Cancelada') {
      icon = <StopOutlined />
      title = 'Assinatura cancelada'
      color = 'canceled'
    }

    if (data === 'Não Confirmada') {
      icon = <ExceptionOutlined />
      title = 'Assinatura não confirmada'
      color = 'notConfirmed'
    }

    return (
      <Button.Group>
        <Tooltip title={title}>
          <ButtonIcon color={color}>{icon}</ButtonIcon>
        </Tooltip>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Nome Aluno',
      dataIndex: 'studentName',
    },
    {
      title: 'CPF Aluno',
      dataIndex: 'studentCPF',
    },
    {
      title: 'E-mail Aluno',
      dataIndex: 'studentEmail',
    },
    {
      title: 'Escola Parceira',
      dataIndex: 'discountCouponPartnerSchool',
    },
    {
      title: 'Plano Assinatura',
      dataIndex: 'subscriptionPlanName',
    },
    {
      title: 'Data Assinatura',
      dataIndex: 'subscriptionDate',
      render: (subscriptionDate) => getDateFormat(subscriptionDate),
    },
    {
      title: 'Forma Pagamento',
      dataIndex: 'paymentMethodDescription',
    },
    {
      title: 'Cupom de Desconto',
      dataIndex: 'discountCouponCode',
      render: (data) => data || 'Sem cupom',
    },
    // {
    //   title: 'Desconto',
    //   dataIndex: 'discountAmount',
    //   render: (data) => renderPriceMask(data),
    // },
    {
      title: 'Total Assinatura',
      dataIndex: 'amountPaid',
      render: (data) => renderPriceMask(data),
    },
    {
      dataIndex: 'paymentStatusDescription',
      render: (data) => renderPaymentStatus(data),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderActions(data),
    },
  ]

  function handleSubscriptionPlanChange(_, option) {
    setSubscriptionPlanId(option.id)
  }
  function handleSubscriptionPlanClearField() {
    setSubscriptionPlanId('')
  }

  function handlePartnerSchoolChange(_, option) {
    setPartnerSchoolId(option.id)
  }

  function resetFilter() {
    formSubscriptionPlan.resetFields()
    handleSubscriptionPlanChange('', '')
    getFilter()
  }

  function handleChangeDate(_, dateString) {
    setBeginDate(getDateFormat(dateString[0], true))
    setEndDate(getDateFormat(dateString[1], true))
  }

  function onFinish(values) {
    const {
      dateType,
      paymentMethodId,
      studentEmail,
      studentCPF,
      paymentStatus,
      discountCouponCode,
    } = values

    let dateTypeValue = dateType

    if (studentEmail || studentCPF) dateTypeValue = ''

    if (dateType === undefined) dateTypeValue = 1

    const data = {
      DateType: dateTypeValue || dateType,
      BeginDate: beginDate || beginDateNow,
      EndDate: endDate || endDateNow,
      SubscriptionPlanId: subscriptionPlanId,
      PaymentMethodId: paymentMethodId,
      StudentEmail: studentEmail,
      StudentCPF: studentCPF ? removeMask(studentCPF) : '',
      PaymentStatus: paymentStatus || null,
      DiscountCouponCode: discountCouponCode || '',
      DiscountCouponPartnerSchoolId: partnerSchoolId || '',
    }

    setFilter(data)
  }

  const handleExportExcel = async () => {
    const getFeildValue = await formSubscriptionPlan.getFieldsValue()
    const dateType = await getFeildValue.dateType
    const paymentMethodId = await getFeildValue.paymentMethodId
    const studentEmail = await getFeildValue.studentEmail
    const studentCPF = await getFeildValue.studentCPF
    const paymentStatus = await getFeildValue.paymentStatus
    const discountCouponCode = await getFeildValue.discountCouponCode

    let dateTypeValue = dateType

    if (studentEmail || studentCPF) dateTypeValue = ''

    if (dateType === undefined) dateTypeValue = 1

    const data = {
      DateType: dateTypeValue || dateType,
      BeginDate: beginDate || beginDateNow,
      EndDate: endDate || endDateNow,
      SubscriptionPlanId: subscriptionPlanId,
      PaymentMethodId: paymentMethodId,
      StudentEmail: studentEmail,
      StudentCPF: studentCPF ? removeMask(studentCPF) : '',
      PaymentStatus: paymentStatus || null,
      DiscountCouponCode: discountCouponCode || '',
      DiscountCouponPartnerSchoolId: partnerSchoolId || '',
    }

    const response = await services.getDashboardExportExcel(data)

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([response.data]))
    link.download = `Assinatura_${new Date().getTime()}.xlsx`
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Space direction="vertical">
      <PlanWrapper className="plan-wrapper">
        <Collapse defaultActiveKey={1} bordered={false}>
          <Collapse.Panel key={1} header="Filtros">
            <FormWrapper>
              <Form form={formSubscriptionPlan} onFinish={onFinish}>
                <Row gutter={16}>
                  <Col xs={24} md={6} className="data-col">
                    <Text>Período</Text>

                    <Form.Item name="date">
                      <RangePicker
                        onChange={handleChangeDate}
                        defaultValue={[
                          moment(beginDateNow),
                          moment(endDateNow),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={6}>
                    <Text>Filtrar por</Text>

                    <Form.Item name="dateType">
                      <Select placeholder="Escolha o tipo" defaultValue="1">
                        <Option value="1">Data Assinatura</Option>
                        <Option value="2">Data Confirmação de Pagamento</Option>
                        <Option value="3">Data de Expiração</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={6}>
                    <Text>Forma Pagamento</Text>

                    <Form.Item name="paymentMethodId">
                      <Select
                        placeholder="Escolha a forma de pagamento"
                        defaultValue="Todos"
                      >
                        <Option value="">Todos</Option>
                        <Option value="1">Cartão Crédito</Option>
                        <Option value="2">Boleto</Option>
                        <Option value="3">Apple Pay</Option>
                        <Option value="4">Google Pay</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={6}>
                    <Text>Status</Text>

                    <Form.Item name="paymentStatus">
                      <Select
                        placeholder="Escolha o status do pagamento"
                        defaultValue=""
                      >
                        <Option value="">Todos</Option>
                        <Option value="1">Confirmado</Option>
                        <Option value="2">Não Confirmado</Option>
                        <Option value="3">Cancelado</Option>
                        <Option value="4">Freemium</Option>
                        <Option value="5">Freemium e Confirmado</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={4}>
                    <Text>Plano Assinatura</Text>

                    {!!subscriptionPlan && (
                      <Form.Item name="subscriptionPlan">
                        <AutoComplete
                          placeholder="Escolha o plano da assinatura"
                          options={subscriptionPlan}
                          onSelect={(val, option) =>
                            handleSubscriptionPlanChange(val, option)
                          }
                          allowClear
                          onClear={handleSubscriptionPlanClearField}
                          value={subscriptionPlan.id}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} md={6}>
                    <Text>E-mail do Aluno</Text>

                    <Form.Item name="studentEmail">
                      <Input placeholder="Digite o e-mail do aluno" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={4}>
                    <Text>CPF do Aluno</Text>

                    <Form.Item name="studentCPF">
                      <InputMask
                        placeholder="Digite o CPF do aluno"
                        mask="999.999.999-99"
                        className="ant-input"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={4}>
                    <Text>Escola Parceira</Text>

                    {!!partnerSchool && (
                      <Form.Item name="partnerSchool">
                        <AutoComplete
                          placeholder="Escolha a escola paceira"
                          options={partnerSchool}
                          onSelect={(val, option) =>
                            handlePartnerSchoolChange(val, option)
                          }
                          value={partnerSchool.id}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} md={6}>
                    <Text>Cupom de Desconto</Text>

                    <Form.Item name="discountCouponCode">
                      <Input
                        placeholder="Digite o código do cupom"
                        className="ant-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} align="end">
                  <Col className="filter-form">
                    <Space>
                      <Tooltip title="Exportar no Excel">
                        <Button
                          type="primary"
                          icon={<FileExcelOutlined />}
                          onClick={handleExportExcel}
                        />
                      </Tooltip>

                      <Tooltip title="Pesquisar">
                        <Button
                          htmlType="submit"
                          type="primary"
                          icon={<SearchOutlined />}
                        />
                      </Tooltip>

                      <Tooltip title="Redefinir Filtros">
                        <Button
                          type="primary"
                          icon={<RedoOutlined />}
                          onClick={resetFilter}
                        />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </FormWrapper>
          </Collapse.Panel>
        </Collapse>

        <GraphicSubscriptionPlan
          baseURL="/subscriptionplan/dashboard/"
          filter={filter}
        />

        {filter && (
          <Table
            baseURL="/subscriptionplan/dashboard/"
            filter={filter}
            columns={columns}
          />
        )}

        <Row gutter={16} align="end">
          <LegendWrapper>
            <LegendItem color="confirmed">
              <FileDoneOutlined />
              Assinatura Confirmada
            </LegendItem>

            <LegendItem color="canceled">
              <StopOutlined />
              Assinatura Cancelada
            </LegendItem>

            <LegendItem color="notConfirmed">
              <ExceptionOutlined />
              Assinatura Não Confirmada
            </LegendItem>
          </LegendWrapper>
        </Row>
      </PlanWrapper>
    </Space>
  )
}

export default SubscriptionPlanListComponent
