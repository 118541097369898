import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Checkbox,
  Tag,
  Select,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons'

import services from '../tests.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function TestListComponent() {
  const [filter, setFilter] = useState({})
  const { Option, OptGroup } = Select
  const history = useHistory()
  const [form] = Form.useForm()

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/simulado/${id}/visualizacao`)
  }

  function edit(id) {
    history.push(`/simulado/${id}/edicao`)
  }

  function handleSendeditFreeCheck(id) {
    services.updateFree(id)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    })
  }

  function renderActions(id) {
    return (
      <Button.Group>
        <PermittedAction tag="TestList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="TestEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="TestDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  // Classe customizada do checkbox
  class CustCheckbox extends Checkbox {
    constructor(props) {
      super(props);
      this.state = {
        isChecked: props.check,
        id: props.id
      };
    }

    toggleChange = () => {
      handleSendeditFreeCheck(this.state.id);
      this.setState({
        isChecked: !this.state.isChecked,
      });
    }

    render() {
      return (
        <Checkbox checked={this.state.isChecked} onChange={this.toggleChange}></Checkbox>
      );
    }

  }

  function renderFreemiumCK(row) {
    return (<CustCheckbox check={row.freemium} id={row.id} />)
  }

  const columns = [
    {
      title: "Free",
      align: 'center',
      render: (row) => renderFreemiumCK(row),
    },
    {
      title: 'Simulado',
      dataIndex: 'name',
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
    },
    {
      title: 'N° Perguntas',
      dataIndex: 'numberOfQuestions',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (id) => renderActions(id),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function GetValueFromStatus(selectedStatus) {
    switch (selectedStatus) {
      case '1':
        return true
      case '0':
        return false
      default:
        return null
    }
  }

  function onFinish(values) {
    const activeResult = GetValueFromStatus(values.status)

    const data = {
      description: values.description,
      name: values.name,
      active: activeResult,
    }

    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item name="description">
                  <Input placeholder="Descrição" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="name">
                  <Input placeholder="Simulado" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={4} md={5}>
                <Form.Item name="status">
                  <Select placeholder="Status" allowClear>
                    <OptGroup label="Status">
                      <Option value="1">Ativo</Option>
                      <Option value="0">Inativo</Option>
                      <Option value="null">Todos</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction tag="TestCreate">
          <Tooltip title="Incluir">
            <Link to="/simulado/inclusao">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      <Table baseURL="/test" filter={filter} columns={columns} />
    </Space>
  )
}

export default TestListComponent
