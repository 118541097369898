import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import StudentManagementPartnerSchoolFormComponent from '../components/studentManagementPartnerSchool-form.component'

import services from '../studentManagement.services'

function StudentManagementPartnerSchoolViewContainer() {
  const [school, setSchool] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [form] = Form.useForm()
  const params = useParams()
  const { idstudent, studentStudyPlanId } = params

  useEffect(() => {
    async function getData() {
      const response = await services.getStudentPlanByPartnerSchoolById(
        idstudent,
        studentStudyPlanId,
      )

      setSchool(response.data)
      setLoadingData(false)
    }

    getData()
  }, [idstudent, studentStudyPlanId])

  if (loadingData) return <Skeleton active />

  return (
    <StudentManagementPartnerSchoolFormComponent
      type="V"
      form={form}
      school={school}
    />
  )
}

export default StudentManagementPartnerSchoolViewContainer
