import api from '~/services/api'

const services = {
  getDiscountCouponById(id) {
    return api.get(`/discount-coupon/${id}`)
  },

  discountCouponAdd(data) {
    const {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    } = data

    return api.post('/discount-coupon', {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    })
  },

  discountCouponUpdate(data) {
    const {
      idDiscountCoupon,
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    } = data

    return api.put(`/discount-coupon/${idDiscountCoupon}`, {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    })
  },

  discountCouponDelete(id) {
    return api.delete(`/discount-coupon/${id}`)
  },

  partnerSchool() {
    return api.get('/partnerschool/active')
  },

  subscriptionPlan(data) {
    return api.get('/subscriptionplan/autocomplete', {
      params: { Active: data },
    })
  },
}

export default services
