import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CreditCardOutlined } from '@ant-design/icons'

import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Space,
  Button,
  AutoComplete,
  Typography,
  Checkbox,
  DatePicker,
  Select,
  InputNumber,
} from 'antd'

import { format, parseISO } from 'date-fns'
import moment from 'moment'

import services from '../studentManagement.services'

import StudentInformationComponent from './studentInformation-component'

import priceMask from '~/utils/price-mask'

import * as S from '../studentManagement.styles'

function StudentManagementPlansFormComponent(props) {
  const [studentId, setStudentId] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  // const [studyPlan, setStudyPlan] = useState([])
  // const [studyPlanId, setStudyPlanId] = useState('')
  const [installments, setInstallments] = useState('')
  const [installmentsCheck, setInstallmentsCheck] = useState(false)
  const [creditCardMask, setCreditCardMask] = useState('')
  const [creditCardInstallments, setCreditCardInstallments] = useState('')
  const [errorAmountPaid, setErrorAmountPaid] = useState(false)
  const [form] = Form.useForm()
  const { type, loadingSubmit, plan, onSubmit } = props
  const { Text } = Typography
  const { Option } = AutoComplete
  const { RangePicker } = DatePicker
  const params = useParams()
  const { idstudent } = params

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && plan) {
      // plan.studyPlanName = `${plan.studyPlanName} (${plan.studyPlanShortDescription})`
      plan.subscriptionName = `${plan.subscriptionName} (${plan.subscriptionShortDescription})`

      if (isView) {
        const installmentValue = priceMask(plan.installmentAmount)
        const installmentFormat = `${plan.installments}x ${installmentValue}`

        // plan.installments = installmentFormat

        setCreditCardInstallments(installmentFormat)
        setCreditCardMask(plan.creditCardMask)
      }

      form.setFieldsValue(plan)

      if (plan.subscriptionDate && plan.studyPlanExpirationDate) {
        setStartDate(moment(plan.subscriptionDate).utc().format('yyyy-MM-DD'))
        setEndDate(
          moment(plan.studyPlanExpirationDate).utc().format('yyyy-MM-DD'),
        )
      }

      if (plan.installments && plan.paymentMethodId === 1)
        setInstallmentsCheck(true)
    }
  }, [isEdit, isView, plan, form])

  useEffect(() => {
    setStudentId(idstudent)
  }, [idstudent])

  useEffect(() => {
    async function getSubscriptionPlans() {
      const response = await services.getSubscriptionPlan()

      const subscriptionPlans = response.data.map((item) => (
        <Option
          id={item.id}
          key={item.id}
          value={`${item.name} (${item.shortDescription})`}
        >
          {item.name} ({item.shortDescription})
        </Option>
      ))

      setSubscriptionPlan(subscriptionPlans)
    }

    getSubscriptionPlans()

    // async function getStudyPlans() {
    //   const response = await services.getStudyPlan()

    //   const studyPlanList = response.data.map((item) => (
    //     <Option
    //       id={item.id}
    //       key={item.id}
    //       value={`${item.name} (${item.shortDescription})`}
    //     >
    //       {item.name} ({item.shortDescription})
    //     </Option>
    //   ))

    //   setStudyPlan(studyPlanList)
    // }

    // getStudyPlans()
  }, [])

  useEffect(() => {
    if (!installmentsCheck && installments === '1') setInstallmentsCheck(true)
  }, [installments, installmentsCheck])

  useEffect(() => {
    if (isNew) form.setFieldsValue({ studyPlanActive: true })
  }, [isNew, form])

  async function onFinish(values) {
    const data = {
      ...values,
      studentId,
      subscriptionPlanId,
      startDate,
      endDate,
      // studyPlanId,
    }

    onSubmit(data)
  }

  function getDateFormat(dateValue) {
    if (dateValue) {
      const date = parseISO(dateValue)

      return format(date, 'yyyy-MM-dd')
    }

    return ''
  }

  function handleSubscriptionPlanChange(_, option) {
    setSubscriptionPlanId(option.key)
  }

  // function handleStudyPlanChange(_, option) {
  //   setStudyPlanId(option.key)
  // }

  function handleChangeDateSend(_, dateString) {
    setStartDate(getDateFormat(dateString[0]))
    setEndDate(getDateFormat(dateString[1]))
  }

  function handleAmount() {
    setErrorAmountPaid(false)

    const amount = parseFloat(form.getFieldValue('amount'))
    const discount = parseFloat(form.getFieldValue('discount'))

    if (discount > amount) {
      setErrorAmountPaid(true)
      form.setFieldsValue({ amountPaid: '' })
    } else {
      const amountPaid = (amount - discount).toFixed(2).replace('.', ',')
      form.setFieldsValue({ amountPaid })
    }
  }

  return (
    <S.FormWrapper>
      {!isNew && plan && (
        <StudentInformationComponent
          studentInformationId={studentId}
          baseURL="study-plan-by-subscription"
          studentInformationPlanId={plan.studentStudyPlanId}
        />
      )}

      <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
        <Row gutter={16} align="middle">
          {!isNew && (
            <Col xs={24} md={6}>
              <Text>Número Assinatura</Text>

              <Form.Item name="subscriptionNumber">
                <Input
                  placeholder="Número Assinatura"
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={12}>
            <Text>Plano Assinatura</Text>

            <Form.Item name="subscriptionName" rules={requiredRule}>
              <AutoComplete
                placeholder="Plano Assinatura"
                dataSource={subscriptionPlan}
                onSelect={(val, option) =>
                  handleSubscriptionPlanChange(val, option)
                }
                value={subscriptionPlan.id}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={isView || isEdit}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col
            xs={(isView || isEdit) && startDate && endDate ? 6 : 24}
            md={(isView || isEdit) && startDate && endDate ? 6 : 12}
          >
            <Text>Período</Text>

            {!!isNew && (
              <Form.Item name="startDates" rules={requiredRule}>
                <RangePicker
                  showtime
                  onChange={handleChangeDateSend}
                  className="row-100"
                />
              </Form.Item>
            )}

            {!isNew && startDate && endDate && (
              <Form.Item name="startDates">
                <RangePicker
                  showtime
                  onChange={handleChangeDateSend}
                  defaultValue={[moment(startDate), moment(endDate)]}
                  disabled={isView}
                  className="row-100"
                  allowClear={false}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={16} align="middle">
          <Col xs={24} md={8}>
            <Text>Valor Assinatura</Text>

            <Form.Item name="amount" rules={requiredRule}>
              <InputNumber
                placeholder="Valor Assinatura"
                onChange={handleAmount}
                disabled={isView || isEdit}
                min={0}
                className="row-100"
                decimalSeparator=","
                stringMode
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Valor Desconto</Text>

            <Form.Item
              name="discount"
              rules={requiredRule}
              className={errorAmountPaid && 'ant-alert-error alert-error'}
            >
              <InputNumber
                placeholder="Valor Desconto"
                onChange={handleAmount}
                disabled={isView || isEdit}
                min={0}
                className="row-100"
                decimalSeparator=","
                stringMode
              />
            </Form.Item>

            {!!errorAmountPaid && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">Desconto não pode ser maior que o valor.</div>
              </div>
            )}
          </Col>

          <Col xs={24} md={8}>
            <Text>Valor Pago</Text>

            <Form.Item name="amountPaid" rules={requiredRule}>
              <Input placeholder="Valor Pago" disabled className="row-100" />
            </Form.Item>
          </Col>

          <Col xs={24} md={installmentsCheck ? 6 : 8}>
            <Text>Forma Pagamento</Text>

            <Form.Item
              name="paymentMethodDescription"
              rules={requiredRule}
              style={creditCardMask && { marginBottom: '6px' }}
            >
              <Select
                placeholder="Forma Pagamento"
                onChange={(value) => setInstallments(value)}
                disabled={isView || isEdit}
              >
                <Option value="1">Cartão Crédito</Option>
                <Option value="2">Boleto</Option>
              </Select>
            </Form.Item>

            {!!installmentsCheck && creditCardMask && (
              <S.CardInfo>
                <CreditCardOutlined />

                <Text>
                  {creditCardMask} {creditCardInstallments}
                </Text>
              </S.CardInfo>
            )}
          </Col>

          {!!installmentsCheck && (
            <Col xs={24} md={4}>
              <Text>Número Parcelas</Text>

              <Form.Item name="installments" rules={requiredRule}>
                {isView ? (
                  <Input
                    placeholder="Número Parcelas"
                    disabled={isView}
                    className="row-100"
                  />
                ) : (
                  <InputNumber
                    type="number"
                    placeholder="Número Parcelas"
                    disabled={isView || isEdit}
                    min={1}
                    className="row-100"
                  />
                )}
              </Form.Item>
            </Col>
          )}

          {!isNew && (
            <Col xs={24} md={installmentsCheck ? 4 : 8}>
              <Text>Status Pagamento</Text>

              <Form.Item name="paymentStatusDescription">
                <Input
                  placeholder="Status Pagamento"
                  disabled={isView || isEdit}
                />
              </Form.Item>
            </Col>
          )}

          {/* <Col xs={24} md={installmentsCheck ? 6 : 8}>
            <Text>Planos Estudos</Text>

            <Form.Item name="studyPlanName" rules={requiredRule}>
              <AutoComplete
                placeholder="Planos Estudos"
                dataSource={studyPlan}
                onSelect={(val, option) => handleStudyPlanChange(val, option)}
                value={studyPlan.id}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={isView || isEdit}
                allowClear
              />
            </Form.Item>
          </Col> */}

          <Col xs={24} md={installmentsCheck ? 4 : 8}>
            <Text>Status</Text>

            <Form.Item
              name="studyPlanActive"
              valuePropName="checked"
              rules={requiredRule}
            >
              <Checkbox disabled={isView} defaultChecked>
                Ativo
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to={`/aluno/${idstudent}/plano-e-assinatura/`}>
                {isEdit ? 'Cancelar' : 'Voltar'}
              </Link>
            </Button>

            {!isView && (
              <Button htmlType="submit" type="primary" loading={loadingSubmit}>
                {isNew ? 'Confirmar' : 'Atualizar'}
              </Button>
            )}
          </Space>
        </Row>
      </Form>
    </S.FormWrapper>
  )
}

export default StudentManagementPlansFormComponent
