import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import notification from '~/shared/Notification'

import DiscountCouponFormComponent from '../components/discountCoupon-form.component'

import services from '../discountCoupon.services'

function DiscountCouponEditContainer() {
  const [discountCoupon, setDiscountCoupon] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const { idDiscountCoupon } = params

  async function getData() {
    const response = await services.getDiscountCouponById(idDiscountCoupon)

    setDiscountCoupon(response.data)
  }

  useEffect(() => {
    getData()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values) {
    const {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    } = values

    const data = {
      idDiscountCoupon,
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    }

    setLoadingSubmit(true)

    try {
      await services.discountCouponUpdate(data)
      notification.success('Cupom atualizado com sucesso.')
      setLoadingSubmit(false)

      history.push(`/cupom-de-desconto/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <DiscountCouponFormComponent
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      discountCoupon={discountCoupon}
    />
  )
}

export default DiscountCouponEditContainer
