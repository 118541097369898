import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'
import { format } from 'prettier'
import DisciplineSubjectFormComponent from '../components/disciplinesubjects-form.component'
import services from '../disciplinesubjects.services'
import disciplineService from '../../disciplines/discipline.services'
import notification from '~/shared/Notification'

function DisciplineSubjectNewContainer() {
  const [form] = Form.useForm()
  const params = useParams()
  const { iddiscipline } = params
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [discipline, setDiscipline] = useState({})
  const [areas, setAreas] = useState([])
  const [sequence, setSequence] = useState([])

  async function loadAreas() {
    const responseAreas = await services.getAreas(iddiscipline)

    const mappedAreas = responseAreas.data.map((area) => {
      return { label: area.name, value: area.id }
    })

    setAreas(mappedAreas)
  }

  async function loadSequence() {
    const responseSequence = await services.getSequence(iddiscipline, true)

    const mappedSequence = responseSequence.data.map((sequence) => {
      return {
        label: sequence,
        value: sequence,
      }
    })
    setSequence(mappedSequence)
  }

  useEffect(() => {
    async function getData() {
      const responseDiscipline = await disciplineService.getById(iddiscipline)
      setLoadingData(false)
      setDiscipline(responseDiscipline.data)
    }

    getData()
    loadAreas()
    loadSequence()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iddiscipline])

  async function onSubmit(values) {
    setLoadingSubmit(true)

    try {
      await services.save(values)
      notification.success('Cadastrado com sucesso.')
      setLoadingSubmit(false)
      form.resetFields()
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <DisciplineSubjectFormComponent
      type="N"
      disciplineId={discipline.id}
      areas={areas}
      loadingSubmit={loadingSubmit}
      displaySequence={sequence}
      onSubmit={onSubmit}
      formContainer={form}
      loadAreas={loadAreas}
      loadSequence={loadSequence}
    />
  )
}

export default DisciplineSubjectNewContainer
