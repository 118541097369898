import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import notification from '~/shared/Notification'

import StudentManagementFormComponent from '../components/studentManagement-form.component'

import services from '../studentManagement.services'

import removeMask from '~/utils/remove-mask'

function StudentManagementPlansEditContainer() {
  const [student, setStudent] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const { idstudent } = params

  async function getData() {
    const response = await services.getStudent(idstudent)

    setStudent(response.data)
  }

  useEffect(() => {
    getData()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values) {
    const {
      firstName,
      lastName,
      cpf,
      email,
      mobile,
      password,
      active,
      cep,
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,
    } = values

    const data = {
      id: idstudent,
      firstName,
      lastName,
      cpf: cpf && removeMask(cpf),
      email,
      mobile: mobile && removeMask(mobile),
      password: password || null,
      active,
      cep: cep && removeMask(cep),
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,
    }

    setLoadingSubmit(true)

    try {
      await services.updateStudent(data)
      notification.success('Aluno atualizado com sucesso.')
      setLoadingSubmit(false)

      history.push(`/aluno/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <StudentManagementFormComponent
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      studentInformation={student}
    />
  )
}

export default StudentManagementPlansEditContainer
