import api from '~/services/api'

const services = {
  getPartnerSchools() {
    return api.get('/partnerschool/active')
  },
  save(data) {
    return api.post('/user', data)
  },
  getById(id) {
    return api.get(`/user/${id}`)
  },
  update(data) {
    return api.put('/user', data)
  },
  delete(id) {
    return api.delete(`/user/${id}`)
  },
  reset(id) {
    return api.post(`/user/login/reset/${id}`)
  },
  disciplineActive() {
    return api.get(`/discipline/active`)
  },
}

export default services
