import GroupListContainer from './containers/group-list.container'
import GroupNewContainer from './containers/group-new.container'
import GroupEditContainer from './containers/group-edit.container'
import GroupViewContainer from './containers/group-view.container'

const routes = [
  {
    path: '/grupo-usuario',
    tag: 'RoleList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        breadcrumbName: 'Grupo de Usuário',
      },
    ],
    authenticated: true,
    component: GroupListContainer,
  },
  {
    path: '/grupo-usuario/inclusao',
    tag: 'RoleCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/grupo-usuario',
        breadcrumbName: 'Grupo de Usuário',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: GroupNewContainer,
  },
  {
    path: '/grupo-usuario/:id/edicao',
    tag: 'RoleEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/grupo-usuario',
        breadcrumbName: 'Grupo de Usuário',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: GroupEditContainer,
  },
  {
    path: '/grupo-usuario/:id/visualizacao',
    tag: 'RoleList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/grupo-usuario',
        breadcrumbName: 'Grupo de Usuário',
      },
      {
        breadcrumbName: 'Visualização ',
      },
    ],
    authenticated: true,
    component: GroupViewContainer,
  },
]

export default routes
