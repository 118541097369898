import React from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'

function PasswordStrenghtComponent(props) {
  const { password } = props

  return (
    <PasswordStrengthBar
      password={password}
      minLength={6}
      shortScoreWord=""
      scoreWords={['muito fraca', 'fraca', 'médio', 'forte', 'muito forte']}
    />
  )
}

export default PasswordStrenghtComponent
