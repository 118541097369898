import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import ForgetPasswordFormComponent from '../components/forgetpassword-form.component'

import services from '../forgetpassword.services'

import notification from '~/shared/Notification'

function ForgetPasswordContainer() {
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    setLoading(true)

    try {
      await services.forgetPassword(values)
      notification.success('Código enviado, confira sua caixa de e-mail.')

      history.push('/nova-senha')
    } catch {
      setLoading(false)
    }
  }

  return <ForgetPasswordFormComponent loading={loading} onSubmit={onSubmit} />
}

export default ForgetPasswordContainer
