import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import ForumPostComponent from '../components/forumPost.component'
import services from '../forumPost.services'

function ForumPostViewContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [forumPost, setForumPost] = useState(null)
  const params = useParams()
  const { id } = params

  useEffect(() => {
    async function getData() {
      const response = await services.forumPostListById(id)

      setLoadingData(false)

      setForumPost(response.data)
    }

    getData()
  }, [id])

  if (loadingData) return <Skeleton active />

  return <ForumPostComponent forumPost={forumPost} />
}

export default ForumPostViewContainer
