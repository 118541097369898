import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Button,
  Select,
  Tooltip,
  Table,
  Modal,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import services from '../disciplinelesson.services'
import notification from '~/shared/Notification'

function ModalQuestionsComponent(props) {
  const {
    visibleModalLesson,
    closeModalQuestions,
    tags,
    idDiscipline,
    setInternalQuestionsDataSource,
    internalQuestionsDataSource,
    selectedQuestionIds,
    setSelectedQuestionIds,
    idLesson,
    formModalQuestion,
    modalDataSourceQuestions,
    setModalDataSourceQuestions,
    selectedQuestionIdsModal,
    setSelectedQuestionIdsModal,
  } = props
  const [paginationQuestions, setPaginationQuestions] = useState({
    pageNumber: 1,
    pageSize: 10,
  })
  const [totalRecords, setTotalRecords] = useState()
  const [firstLoad, setFirstLoad] = useState(true)

  const [filterQuestions, setFilterQuestions] = useState({})

  const [loadingTableQuestions, setLoadingTableQuestions] = useState(false)

  function selectQuestion(id, row) {
    const alreadyExist = selectedQuestionIds.includes(row.key)
    const recordSelected = {
      id: row.key,
      code: row.code,
      idLessonQuestion: null,
    }

    if (alreadyExist) {
      const index = selectedQuestionIds.indexOf(row.key)

      selectedQuestionIds.splice(index, 1)
      internalQuestionsDataSource.splice(index, 1)

      const afterRemoveDataTable = [...internalQuestionsDataSource]
      setInternalQuestionsDataSource(afterRemoveDataTable)
    } else {
      const newDataTable = [...internalQuestionsDataSource, recordSelected]

      selectedQuestionIds.push(row.key)
      setInternalQuestionsDataSource(newDataTable)
      setSelectedQuestionIds(selectedQuestionIds)
    }
  }

  function view(id, row) {
    window.open(`/pergunta/${id}/visualizacao`, '_blank')
  }

  function showInsertOrDelete(id, row) {
    const alreadyExist = selectedQuestionIds.includes(id)
    if (!alreadyExist) {
      return (
        <Tooltip title="Incluir">
          <Button
            type="link"
            icon={<CheckCircleOutlined />}
            onClick={() => selectQuestion(id, row)}
          />
        </Tooltip>
      )
    }
  }

  function renderActions(id, row) {
    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => view(id, row)}
          />
        </Tooltip>
        {showInsertOrDelete(id, row)}
      </Button.Group>
    )
  }

  const columnsQuestions = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      align: 'center',
      render: (id, row) => renderActions(id, row),
    },
  ]

  function saveModalQuestions() {
    closeModalQuestions()
  }

  async function searchQuestions(values) {
    setLoadingTableQuestions(true)

    const data = {
      DisciplineLessonId: idLesson > 0 ? idLesson : null,
      DisciplineId: idDiscipline,
      Statement: values.Statement,
      Tag: values.Tag,
      Code: values.code,
    }

    setFilterQuestions(data)

    try {
      const response = await services.getQuestions(
        data,
        paginationQuestions.pageNumber,
        paginationQuestions.pageSize,
      )
      const dataSource = response.data.content
      const { totalRecords } = response.data

      if (dataSource.length > 0) {
        const dataSourceWithKey = dataSource.map((question) => {
          return {
            key: question.id,
            id: question.id,
            code: question.code,
            idLessonQuestion: question.questionId,
          }
        })

        if (firstLoad) setFirstLoad(false)
        setTotalRecords(totalRecords)

        setTimeout(() => {
          setLoadingTableQuestions(false)
          setModalDataSourceQuestions(dataSourceWithKey)
        }, 1500)
      } else {
        setTimeout(() => {
          notification.warning('Não há dados para serem exibidos.')
          setLoadingTableQuestions(false)
          setModalDataSourceQuestions([])
          setTotalRecords(null)
        }, 1500)
      }
    } catch {
      notification.error('Erro inesperado.')
    }
  }

  useEffect(() => {
    async function execute() {
      await searchQuestions(formModalQuestion.getFieldsValue(true))
    }

    if (firstLoad) return

    execute()
  }, [paginationQuestions])

  const { TextArea } = Input
  const { Option } = Select

  const tagsOptions = tags.map((tag) => (
    <Option id={tag.value} key={tag.value} value={tag.label}>
      {tag.label}
    </Option>
  ))

  function resetFilter() {
    formModalQuestion.resetFields()
    const initialFilter = {
      DisciplineLessonId: idLesson > 0 ? idLesson : null,
      DisciplineId: idDiscipline,
      Statement: '',
      Tag: '',
    }
    setFilterQuestions(initialFilter)
    setLoadingTableQuestions(true)
    setModalDataSourceQuestions([])
    setLoadingTableQuestions(false)
  }
  useEffect(() => {
    if (visibleModalLesson) {
      formModalQuestion.resetFields()
      setFirstLoad(true)
      setTotalRecords(0)
      setPaginationQuestions({
        pageNumber: 1,
        pageSize: 10,
      })
      setModalDataSourceQuestions([])
    }
  }, [visibleModalLesson])

  return (
    <Modal
      visible={visibleModalLesson}
      title="Inclusão de Perguntas"
      width={1000}
      onCancel={() => closeModalQuestions()}
      onOk={() => saveModalQuestions()}
      footer={[
        <Button key="back" onClick={() => closeModalQuestions()}>
          Fechar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => saveModalQuestions()}
        >
          Confirmar
        </Button>,
      ]}
    >
      <Form form={formModalQuestion} onFinish={searchQuestions}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item name="code">
              <Input placeholder="Código da pergunta" />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item name="Statement">
              <TextArea
                placeholder="Enunciado"
                autoSize={{ minRows: 2, maxRows: 2 }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item name="Tag">
              <Select showSearch placeholder="Termos">
                {tagsOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16} align="end">
          <Col xs={24} md={1}>
            <Tooltip title="Pesquisar">
              <Button
                htmlType="submit"
                type="primary"
                icon={<SearchOutlined />}
              />
            </Tooltip>
          </Col>
          <Col xs={24} md={1}>
            <Tooltip title="Redefinir Filtros">
              <Button
                type="primary"
                icon={<RedoOutlined />}
                onClick={resetFilter}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col xs={24} md={24}>
            <Table
              filter={filterQuestions}
              dataSource={modalDataSourceQuestions}
              columns={columnsQuestions}
              loading={loadingTableQuestions}
              pagination={{
                current: paginationQuestions.pageNumber,
                pageSizeOptions: [5, 10],
                onChange: (pageNumber, pageSize) => {                 
                  setPaginationQuestions((pagination) => ({
                    pageNumber:
                      pagination.pageSize !== pageSize ? 1 : pageNumber,
                    pageSize,
                    fromFilter: false,
                  }))
                },
                total: totalRecords,
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalQuestionsComponent
