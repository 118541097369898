import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import StudentManagementPartnerSchoolFormComponent from '../components/studentManagementPartnerSchool-form.component'
import services from '../studentManagement.services'

import notification from '~/shared/Notification'

function StudentManagementPartnerSchoolNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    const {
      studentId,
      partnerSchoolId,
      // studyPlanId,
      expirationDate,
      studyPlanActive,
    } = values

    const data = {
      studentId,
      partnerSchoolId,
      // studyPlanId,
      expirationDate,
      active: studyPlanActive,
    }

    setLoadingSubmit(true)

    try {
      await services.studentPlanByPartnerSchool(data)
      notification.success('Escola cadastrada com sucesso.')

      history.push(`/aluno/${studentId}/plano-e-assinatura/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <StudentManagementPartnerSchoolFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default StudentManagementPartnerSchoolNewContainer
