import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Select,
  Tag,
  Button,
  Typography,
  Modal,
} from 'antd'

import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'
import notification from '~/shared/Notification'

import services from '../partnerSchool.services'

import cnpjMask from '~/utils/cnpjMask'

function PartnerSchoolListComponent() {
  const [filter, setFilter] = useState()
  const { Text } = Typography
  const { Option } = Select
  const [formPartnerSchool] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    function getFilter() {
      setFilter({})
    }

    getFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getPartnerSchoolDeleteId(id) {
    const configModalDelete = {
      title: 'Deseja excluir essa Escola Parceira?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          services
            .partnerSchoolDelete(id)
            .then(() => {
              notification.success('Escola Parceira excluída com sucesso.')
              setFilter({})
              resolve()
            })
            .catch(reject)
        })
      },
      onCancel() {},
    }

    Modal.confirm(configModalDelete)
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() =>
                history.push(`/escola-parceira/${data.id}/visualizacao`)
              }
            />
          </Tooltip>

          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => history.push(`/escola-parceira/${data.id}/edicao`)}
            />
          </Tooltip>

          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => getPartnerSchoolDeleteId(data.id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  const columns = [
    {
      title: 'Escola Parceira',
      dataIndex: 'schoolName',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      render: (data) => cnpjMask(data),
    },
    {
      title: 'Cidade/Estado',
      dataIndex: 'cityState',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderActions(data),
    },
  ]

  function resetFilter() {
    formPartnerSchool.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    const { partnerSchoolName, partnerSchoolStatus } = values

    setFilter({
      partnerSchoolName,
      partnerSchoolStatus,
    })
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={formPartnerSchool} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Text>Escola Parceira</Text>

                <Form.Item name="partnerSchoolName">
                  <Input
                    placeholder="Digite o nome da Escola Parceira"
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Text>Status</Text>

                <Form.Item name="partnerSchoolStatus">
                  <Select
                    placeholder="Escolha o status da Escola Parceira"
                    defaultValue="0"
                  >
                    <Option value="0">Todos</Option>
                    <Option value="1">Ativos</Option>
                    <Option value="2">Inativos</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} align="end">
              <Col className="filter-form">
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction>
          <Tooltip title="Incluir nova Escola Parceira">
            <Link to="/escola-parceira/inclusao/">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      {filter && (
        <Table baseURL="/partnerschool" filter={filter} columns={columns} />
      )}
    </Space>
  )
}

export default PartnerSchoolListComponent
