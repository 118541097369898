import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import DisciplineFormComponent from '../components/discipline-form.component'
import services from '../discipline.services'

function DisciplineViewContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [discipline, setDiscipline] = useState(null)
  const params = useParams()
  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseDiscipline = await services.getById(id)

      setLoadingData(false)

      setDiscipline(responseDiscipline.data)
    }

    getData()
  }, [id])

  if (loadingData) return <Skeleton active />

  return <DisciplineFormComponent type="V" discipline={discipline} />
}

export default DisciplineViewContainer
