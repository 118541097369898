import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
  Card,
  Tooltip,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import services from '../user.services'

function UserFormComponent(props) {
  const [roleIds, setRoleIds] = useState([])
  const [teacherRolesIds, setTeacherRolesIds] = useState([])
  const [disciplinesList, setDisciplinesList] = useState([])
  const [disciplinesOptions, setDisciplinesOptions] = useState([])
  const [teacherId, setTeacherId] = useState(0)

  const [form] = Form.useForm()

  const { type, user, partnerSchools, roles, loadingSubmit, onSubmit } = props

  const { Option } = Select

  const requiredRule = [{ required: true }]
  const emailRule = [{ type: 'email' }]
  const minLengthRule = [{ min: 6 }]

  const [isSchoolPartner, setIsSchoolPartner] = useState(false)

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const teacherRoles = [
    { id: 1, title: 'Aula Gravada' },
    { id: 2, title: 'Aula Particular' },
    { id: 3, title: 'Mentoria' },
  ]

  async function getDisciplineList() {
    const response = await services.disciplineActive()

    const mapDiscipline = response.data.map((status) => {
      return {
        value: status.id,
        label: status.name,
      }
    })

    setDisciplinesList(mapDiscipline)

    const disciplinesOptions = mapDiscipline.map((status) => (
      <Option key={status.value} value={status.label}>
        {status.label}
      </Option>
    ))

    setDisciplinesOptions(disciplinesOptions)
  }

  useEffect(() => {
    getDisciplineList()
  }, [])

  function setValueDefaultSchoolPartner() {
    if (isNew) {
      if (partnerSchools.length === 1) {
        setIsSchoolPartner(true)
        form.setFieldsValue({
          partnerSchoolId: partnerSchools[0].value,
          partnerSchoolName: partnerSchools[0].label,
        })
      }
    }
  }

  useEffect(() => {
    setValueDefaultSchoolPartner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSchools])

  useEffect(() => {
    if ((isEdit || isView) && user) {
      form.setFieldsValue(user)

      const auxArr = []

      if (user.recordedClasses === true) {
        auxArr.push(1)
      }
      if (user.privateClasses === true) {
        auxArr.push(2)
      }
      if (user.mentorShip === true) {
        auxArr.push(3)
      }

      setTeacherRolesIds(auxArr)
    }
  }, [isEdit, isView, user, form])

  useEffect(() => {
    if ((isEdit || isView) && user) {
      const reducedRolesIds = user.roles.reduce((acc, role) => {
        if (role.id === 2) setTeacherId(role.id)
        return [...acc, role.id]
      }, [])

      setRoleIds(reducedRolesIds)
    }
  }, [isEdit, isView, user])

  function addOrRemoveIfContainsSelectedRole(id) {
    if (!roleIds.includes(id)) {
      if (id === 2) setTeacherId(id)
      setRoleIds((ids) => [...ids, id])
    } else {
      if (id === 2) setTeacherId(0)
      setRoleIds((ids) => ids.filter((roleId) => roleId !== id))
    }
  }

  function addOrRemoveIfContainsSelectedTeacherRole(id) {
    if (!teacherRolesIds.includes(id)) setTeacherRolesIds((ids) => [...ids, id])
    else setTeacherRolesIds((ids) => ids.filter((roleId) => roleId !== id))
  }

  async function onFinish(values) {
    const data = {
      ...values,
      partnerSchoolId: !isEdit ? values.partnerSchoolId : null,
      roles: roleIds,
      biography: values.biography ? values.biography : null,
      discipline: values.discipline ? values.discipline : null,
      calendlySlug: values.calendlySlug ? values.calendlySlug : null,
      rating: '5',
      recordedClasses: !!teacherRolesIds.includes(1),
      privateClasses: !!teacherRolesIds.includes(2),
      mentorShip: !!teacherRolesIds.includes(3),
    }

    onSubmit(data)
  }

  return (
    <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
      <Card size="small" title="Dados Cadastrais" bordered={false}>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item name="name" rules={requiredRule}>
              <Input
                placeholder="Nome Completo"
                maxLength={50}
                disabled={isView}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="email" rules={[...requiredRule, ...emailRule]}>
              <Input placeholder="E-mail" maxLength={50} disabled={isView} />
            </Form.Item>
          </Col>

          {isNew && (
            <Col xs={24} md={8}>
              <Form.Item
                name="password"
                rules={[...requiredRule, ...minLengthRule]}
              >
                <Input.Password
                  placeholder="Senha"
                  maxLength={16}
                  disabled={isView}
                  prefix={
                    <Tooltip title="Este usuário poderá usar a senha informada neste campo ou cadastrar uma nova senha utilizando as instruções que receberá por e-mail.">
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={8}>
            {isNew && !isSchoolPartner && (
              <Form.Item name="partnerSchoolId">
                <Select
                  options={partnerSchools}
                  allowClear
                  placeholder="Escola Parceira"
                />
              </Form.Item>
            )}

            {(isEdit || isView || isSchoolPartner) && (
              <Form.Item name="partnerSchoolName">
                <Input placeholder="Escola Parceira" disabled />
              </Form.Item>
            )}
          </Col>

          <Col xs={6} md={3}>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox disabled={isView}>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card size="small" title="Grupos" bordered={false}>
        <Row gutter={16}>
          {roles.map((role) => {
            const defaultChecked = roleIds.includes(role.id)

            return (
              <Col key={role.id} span={24}>
                <Checkbox
                  defaultChecked={defaultChecked}
                  onChange={() => addOrRemoveIfContainsSelectedRole(role.id)}
                  disabled={isView}
                >
                  {role.name}
                </Checkbox>
              </Col>
            )
          })}
        </Row>
      </Card>

      {teacherId === 2 && (
        <>
          <Card size="small" title="Dados Adicionais" bordered={false}>
            <Row gutter={16}>
              {!!disciplinesList && (
                <Col xs={24} md={8}>
                  <Form.Item name="discipline" rules={requiredRule}>
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      }}
                      placeholder="Disciplina"
                      allowClear
                      disabled={isView}
                    >
                      {disciplinesOptions}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={8}>
                <Form.Item name="calendlySlug">
                  <Input
                    placeholder="Slug do calendly"
                    maxLength={50}
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item name="biography" rules={requiredRule}>
                  <Input.TextArea
                    placeholder="Biografia"
                    autoSize
                    disabled={isView}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card size="small" title="Função do Professor" bordered={false}>
            <Row gutter={16}>
              {teacherRoles.map((role) => {
                const defaultChecked = teacherRolesIds.includes(role.id)

                return (
                  <Col key={role.id} span={24}>
                    <Checkbox
                      name=""
                      defaultChecked={defaultChecked}
                      onChange={() =>
                        addOrRemoveIfContainsSelectedTeacherRole(role.id)
                      }
                      disabled={isView}
                    >
                      {role.title}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Card>
        </>
      )}

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/usuario">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default UserFormComponent
