import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Input, Checkbox, Divider, Space, Button } from 'antd'
import {} from '@ant-design/icons'

function DisciplineFormComponent(props) {
  const [form] = Form.useForm()
  const { type, loadingSubmit, discipline, onSubmit } = props

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const routeRule = [
    () => ({
      validator(_, value) {
        const regex = new RegExp('^[A-Za-z0-9_-]*$')

        if (regex.test(value)) {
          return Promise.resolve()
        }

        return Promise.reject(new Error('Texto não permitido.'))
      },
    }),
  ]

  async function onBlurName(value) {
    const valueWithoutAccent = value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    const routePeplaced = valueWithoutAccent.split(' ').join('-')
    const routeCompleted = routePeplaced.toLowerCase()

    form.setFieldsValue({ route: routeCompleted })
  }

  useEffect(() => {
    if ((isEdit || isView) && discipline) form.setFieldsValue(discipline)
  }, [isEdit, isView, discipline, form])

  async function onFinish(values) {
    onSubmit(values)
  }

  return (
    <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
      <Row gutter={16} align="middle">
        <Col xs={24} md={8}>
          <Form.Item name="name" rules={requiredRule}>
            <Input
              placeholder="Nome"
              maxLength={50}
              disabled={isView}
              onBlur={(event) => onBlurName(event.target.value)}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name="route" rules={[...requiredRule, ...routeRule]}>
            <Input placeholder="Rota" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={4}>
          <Form.Item name="shortName" rules={requiredRule}>
            <Input placeholder="Sigla" maxLength={5} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={4}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView}>Ativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/disciplina">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default DisciplineFormComponent
