import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import LiveClassesFormComponent from '../components/liveClasses-form.component'
import services from '../liveClasses.services'

import notification from '~/shared/Notification'

function LiveClassesNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit({
    dateLiveClass,
    videoId,
    disciplineId,
    disciplineSubjectId,
    inactive,
  }) {
    setLoadingSubmit(true)

    try {
      await services.liveClasses({
        dateLiveClass,
        videoId,
        disciplineId,
        disciplineSubjectId,
        inactive: inactive || false,
      })

      notification.success('Aula ao Vivo cadastrada com sucesso.')

      history.push('/aula-ao-vivo')
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <LiveClassesFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default LiveClassesNewContainer
