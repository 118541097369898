import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd'
import StudyPlanListComponent from '../components/studyplan-list.component'
import servicesDisciplines from '../../disciplines/discipline.services'
import servicesSubjects from '../../disciplinesubjects/disciplinesubjects.services'

function StudyPlanListContainer() {
  const [loadingData, setLoadingData] = useState(false)
  const [disciplines, setDisciplines] = useState([])
  const [subjects, setSubjects] = useState([])

  const renderTitle = (title: string) => {
    return <span>{title}</span>
  }

  function renderItemForSubjects(subjectsList) {
    const mapperOptionsSubjects = subjectsList.map((subjectOption) => {
      return {
        value: subjectOption.subjectId,
        label: subjectOption.subjectName,
      }
    })

    return mapperOptionsSubjects
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getSubjects(listIdDisciplines) {
    let disciplineFilters = listIdDisciplines

    if (disciplineFilters === undefined) {
      disciplineFilters = {}
    }

    const responseDiscipline = await servicesSubjects.subjectsActive(
      disciplineFilters,
    )

    const mappedSubjects = responseDiscipline.data.map((subject) => {
      return {
        label: renderTitle(subject.disciplineName),
        options: renderItemForSubjects(subject.subjects),
      }
    })

    setSubjects(mappedSubjects)
  }

  useEffect(() => {
    getDisciplines()
    getSubjects()
    setLoadingData(false)
  }, [])

  if (loadingData) return <Skeleton active />

  return (
    <StudyPlanListComponent
      disciplines={disciplines}
      subjects={subjects}
      getSubjects={getSubjects}
    />
  )
}

export default StudyPlanListContainer
