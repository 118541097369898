import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import GroupFormComponent from '../components/group-form.component'
import services from '../group.services'

import notification from '~/shared/Notification'

function GroupEditContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [group, setGroup] = useState(null)
  const [permissions, setPermissions] = useState([])

  const history = useHistory()
  const params = useParams()

  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseGroup = await services.getById(id)
      const responsePermissions = await services.getPermissions()

      setLoadingData(false)

      setGroup(responseGroup.data)
      setPermissions(responsePermissions.data)
    }

    getData()
  }, [id])

  async function onSubmit(values) {
    const { name, active, permissions } = values

    if (!permissions.length) {
      notification.error('É necessário informar ao menos uma Permissão.')
      return
    }

    const data = {
      id,
      name,
      active,
      permissions,
    }

    setLoadingSubmit(true)

    try {
      await services.update(data)
      notification.success('Atualizado com sucesso.')

      history.push('/grupo-usuario')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <GroupFormComponent
      type="E"
      loading={loadingSubmit}
      group={group}
      permissions={permissions}
      onSubmit={onSubmit}
    />
  )
}

export default GroupEditContainer
