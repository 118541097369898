import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'
import QuestionFormComponent from '../components/question-form.component'
import services from '../question.services'
import servicesDisciplines from '../../disciplines/discipline.services'
import notification from '~/shared/Notification'

function QuestionEditContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const params = useParams()
  const { id } = params
  const history = useHistory()
  const [question, setQuestion] = useState(null)
  const [disciplines, setDisciplines] = useState([])
  const [tags, setTags] = useState([])
  const [typeResolution, setTypeResolution] = useState([])

  async function getData() {
    const responseQuestion = await services.getById(id)

    setLoadingData(false)

    setQuestion(responseQuestion.data)
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setTags(mappedTags)
  }

  async function getTypeResolutionId() {
    const typeResolution = [
      {
        value: 1,
        label: 'Sem Resolução',
      },
      {
        value: 2,
        label: 'Conteúdo Texto',
      },
      {
        value: 3,
        label: 'Vídeo',
      },
    ]

    const mappedTypeResolution = typeResolution.map((type) => {
      return { label: type.label, value: type.value }
    })

    setTypeResolution(mappedTypeResolution)
  }

  async function onSubmit(values, statement, resolution, listTable) {
    const data = {
      id,
      code: values.code,
      disciplineId: values.disciplineId,
      statement,
      allowedInLesson: values.allowedInLesson,
      allowedInTest: values.allowedInTest,
      allowedInExam: values.allowedInExam,
      active: values.active,
      questionSolutionTypeId: values.questionSolutionTypeId,
      solutionContent: resolution,
      solutionVideoId: values.solutionVideoId,
      answers: listTable,
      tags: values.tags,
    }

    setLoadingSubmit(true)

    try {
      await services.update(data)
      notification.success('Atualizado com sucesso.')

      history.push('/pergunta')
    } catch {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    setLoadingData(true)
    getData()
    getDisciplines()
    getTags()
    getTypeResolutionId()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loadingData) return <Skeleton active />

  return (
    <QuestionFormComponent
      type="E"
      question={question}
      disciplines={disciplines}
      tags={tags}
      typeResolutionData={typeResolution}
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
    />
  )
}

export default QuestionEditContainer
