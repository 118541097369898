import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd'

import UserListComponent from '../components/user-list.component'

import services from '../user.services'

function UserListContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [partnerSchools, setPartnerSchools] = useState([])

  useEffect(() => {
    async function getData() {
      const response = await services.getPartnerSchools()

      const mappedPartnerSchools = response.data.map((partnerSchool) => {
        return { label: partnerSchool.schoolName, value: partnerSchool.id }
      })

      setLoadingData(false)
      setPartnerSchools(mappedPartnerSchools)
    }

    getData()
  }, [])

  if (loadingData) return <Skeleton active />

  return <UserListComponent partnerSchools={partnerSchools} />
}

export default UserListContainer
