import DiscountCouponList from './containers/discountCoupon-list.container'
import DiscountCouponNew from './containers/discountCoupon-new.container'
import DiscountCouponView from './containers/discountCoupon-view.container'
import DiscountCouponEdit from './containers/discountCoupon-edit.container'

const routes = [
  {
    path: '/cupom-de-desconto',
    tag: '',
    breadcrumb: [
      {
        breadcrumbName: 'Cupom de Desconto',
      },
    ],
    authenticated: true,
    component: DiscountCouponList,
  },
  {
    path: '/cupom-de-desconto/inclusao',
    tag: '',
    breadcrumb: [
      {
        path: '/cupom-de-desconto',
        breadcrumbName: 'Cupom de Desconto',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: DiscountCouponNew,
  },
  {
    path: '/cupom-de-desconto/:idDiscountCoupon/visualizacao',
    tag: '',
    breadcrumb: [
      {
        path: '/cupom-de-desconto',
        breadcrumbName: 'Cupom de Desconto',
      },
      {
        breadcrumbName: 'Cupom',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: DiscountCouponView,
  },
  {
    path: '/cupom-de-desconto/:idDiscountCoupon/edicao',
    tag: '',
    breadcrumb: [
      {
        path: '/cupom-de-desconto',
        breadcrumbName: 'Cupom de Desconto',
      },
      {
        breadcrumbName: 'Cupom',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: DiscountCouponEdit,
  },
]

export default routes
