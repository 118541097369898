import api from '~/services/api'

const urlBase = 'liveclass'

const services = {
  getLiveClassesById(id) {
    return api.get(`/${urlBase}/${id}`)
  },

  liveClasses(data) {
    return api.post(urlBase, data)
  },

  liveClassesUpdate({
    idLiveClass,
    dateLiveClass,
    videoId,
    disciplineId,
    disciplineSubjectId,
    inactive,
  }) {
    return api.put(`/${urlBase}`, {
      id: idLiveClass,
      dateLiveClass,
      videoId,
      disciplineId,
      disciplineSubjectId,
      inactive,
    })
  },

  liveClassesDelete(id) {
    return api.delete(`/${urlBase}/${id}`)
  },
}

export default services
