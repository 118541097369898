import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Providers from './providers'
import Routes from './routes'
import GlobalStyles from './styles/global'

function App() {
  return (
    <>
      <BrowserRouter>
        <Providers>
          <Routes />
        </Providers>
      </BrowserRouter>

      <GlobalStyles />
    </>
  )
}

export default App
