import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import StudentManagementFormComponent from '../components/studentManagement-form.component'
import services from '../studentManagement.services'

import notification from '~/shared/Notification'

import removeMask from '~/utils/remove-mask'

function StudentManagementNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values, plansAdd) {
    const {
      firstName,
      lastName,
      cpf,
      email,
      mobile,
      password,
      active,
      cep,
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,
    } = values

    const data = {
      firstName,
      lastName,
      cpf: cpf && removeMask(cpf),
      email,
      mobile: mobile && removeMask(mobile),
      password,
      active,
      cep: cep && removeMask(cep),
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,
    }

    setLoadingSubmit(true)

    try {
      const response = await services.studentAdd(data)
      notification.success('Aluno cadastrado com sucesso.')

      if (plansAdd) {
        history.push(`/aluno/${response.data}/plano-e-assinatura/inclusao`)
      } else {
        history.push('/aluno')
      }
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <StudentManagementFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default StudentManagementNewContainer
