import React from 'react'
import { ConfigProvider as ConfigProviderAntd } from 'antd'
import ptBR from 'antd/lib/locale/pt_BR'

import validateMessages from './validateMessages'

function ConfigProvider(props) {
  const { children } = props

  return (
    <ConfigProviderAntd locale={ptBR} form={{ validateMessages }}>
      {children}
    </ConfigProviderAntd>
  )
}

export default ConfigProvider
