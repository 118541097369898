import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col, Typography } from 'antd'
import { Column } from '@ant-design/charts'
import feedbackService from '../feedbacksManagement.services'

export default function FeedbacksManagementGraphicComponent(props) {
  const { filter } = props
  const { Title } = Typography
  const [dataColumns, setDataColumns] = useState([])

  const getFeedbacksData = useCallback(async (filter) => {
    const { data } = await feedbackService.getFeedbacksGraphicData(filter)

    if (data) setDataColumns(data)
  }, [])

  useEffect(() => {
    if (filter) {
      getFeedbacksData(filter)
    } else {
      getFeedbacksData()
    }
  }, [filter, getFeedbacksData])
  const config = {
    data: dataColumns,
    isGroup: true,
    xField: 'disciplineLessonName',
    yField: 'total',
    seriesField: 'score',

    legend: {
      itemName: {
        formatter: (score) => {
          switch (score) {
            case '2':
              return '😤 (2)'
            case '4':
              return '😠 (4)'
            case '6':
              return '😐 (6)'
            case '8':
              return '😀 (8)'
            case '10':
              return '🥰 (10)'
            default:
              return ''
          }
        },
      },
    },
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    /* tooltip: {
      formatter: (data) => {
        const { score, total } = data
        return {
          name: score,
          value: `${total} notas`,
        }
      },
    }, */
  }
  return (
    <Row>
      <Col xs={12} md={24}>
        <Title>Matérias x Notas </Title>
        <Column {...config} />
      </Col>
    </Row>
  )
}
