import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd'
import QuestionListComponent from '../components/question-list.component'
import services from '../question.services'
import servicesDisciplines from '../../disciplines/discipline.services'

function QuestionListContainer() {
  const [loadingData, setLoadingData] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [tags, setTags] = useState([])
  const [tests, setTests] = useState([])

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setTags(mappedTags)
  }

  async function getTests() {
    const responseTests = await services.getTests()

    const mappedTags = responseTests.data.map((test) => {
      return { label: test.testName, value: test.testId }
    })

    setTests(mappedTags)
  }

  useEffect(() => {
    setLoadingData(true)
    getDisciplines()
    getTags()
    getTests()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loadingData) {
    return <Skeleton active />
  }

  return (
    <QuestionListComponent
      disciplines={disciplines}
      tags={tags}
      tests={tests}
    />
  )
}

export default QuestionListContainer
