import React, { useState, useEffect, useCallback, useRef } from 'react'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'

import servicesSignature from '../signature-service'

import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  AutoComplete,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
  Typography,
  DatePicker,
  Tag
} from 'antd'

import { CloseCircleOutlined } from '@ant-design/icons';

import * as S from '../signature.styles'
import removeMask from '~/utils/remove-mask'

import moment from 'moment'
import { format, parseISO, subDays } from 'date-fns'

import SignatureListComponent from '../components/signature-grid'
import { ConsoleSqlOutlined } from '@ant-design/icons'

import notification from '~/shared/Notification'

let arrAux = []
let dataUpdate = {}
let objToHelp = {}
function SignatureForm(props) {
  const [arrayFinal, setArrayFinal] = useState([])
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [valorSelect, setValorSelect] = useState('')
  const [allSignatureList, setAllSignatureList] = useState([])
  const [editFormValues, setEditValuesForm] = useState({})

  const [valorBenefits, setValorBenefits] = useState([])
  const [valorItemEdit, setValorIteEdit] = useState([])

  const inputRef = useRef(null);
  const inputRefFAQ = useRef(null);
  const inputRefFAQ2 = useRef(null);
  const inputRefBlockEssay = useRef(null);
  const inputRefBlockForum = useRef(null);

  const inputRefCupomDesconto = useRef(null);
  const inputRefQtdPercelas = useRef(null);
  const inputRefStatus = useRef(null);
  const inputRefDesconto = useRef(null);

  const inputRefNome = useRef(null);
  const inputRefDescricao = useRef(null);
  const inputRefDuracaoPlano = useRef(null);
  const inputRefValorPlano = useRef(null);
  const inputRefDiasPeriodoTeste = useRef(null);
  const inputRefSelect = useRef(null);



  const [
    subscriptionPlanBenefitsOpt,
    setSubscriptionPlanbenefictsOpt,
  ] = useState([])

  const [
    subscriptionPlanValueDefault,
    setSubscriptionPlanValueDefault,
  ] = useState([])

  const [valorNovo, setValorNovo] = useState([])
  const [aux, setAux] = useState([])


  const [tipo, setTipo] = useState('')

  const [studyPlanValueDefault, setStudyPlanValueDefault] = useState([])
  const [studyPlanOption, setStudyPlanOption] = useState([])

  const [
    subscriptionPlanValueDefaultCheck,
    setSubscriptionPlanValueDefaultCheck,
  ] = useState(false)

  const [objSendUpdate, setObjToUpdate] = useState([])

  const [canUpdate, setCanUpdate] = useState(false)

  const [btnTypeChance, setBtnTypeChange] = useState(false)
  const requiredRule = [{ required: true }]
  const minLengthRule = [{ min: 6 }]
  const { Text } = Typography

  const [form] = Form.useForm()
  const { RangePicker } = DatePicker
  const { Option } = Select

  const dateNow = new Date()
  const beginDateNow = format(subDays(dateNow, 7), 'yyyy-MM-dd')
  const endDateNow = format(dateNow, 'yyyy-MM-dd')

  function getDateFormat(dateValue, type) {
    if (dateValue) {
      const date = parseISO(dateValue)
      let newFormatDate = 'dd/MM/yyyy'

      if (type) {
        newFormatDate = 'yyyy-MM-dd'
      }

      return format(date, newFormatDate)
    }

    return ''
  }

  function handleChangeDate(_, dateString) {
    setBeginDate(getDateFormat(dateString[0], true))
    setEndDate(getDateFormat(dateString[1], true))
  }

  const handleChange = (valor) => {
    setValorSelect(valor)
  }

  // INSERT
  async function onFinish(values) {
    const {
      nome,
      descricao,
      duracao,
      valorPlano,
      desconto,
      qtdParcelas,
      diasPeriodoTeste,
      appleSKU,
      testeComDesconto,
      acessoForum,
      valorStatus,
      aulasAoVivo,
      aulasAoVivo2,
      redacao,
      subscriptionPlanBenefits,
    } = values

    const data = {
      name: nome,
      shortDescription: descricao,
      numberOfMounts: duracao,
      amount: valorPlano,
      discount: desconto,
      displayInstallmentAmount: qtdParcelas,
      ChoosePlanButtonLabel: 'Assine agora',
      SubscriptionExpirationTypeId: valorSelect,
      trialPeriodStart: beginDate || beginDateNow,
      trialPeriodEnd: endDate || endDateNow,
      trialPeriodNumberOfDays: diasPeriodoTeste,
      appleSKU: appleSKU,
      trialPeriodAllowedWithDiscountCoupon: testeComDesconto,
      blockForum: acessoForum,
      active: valorStatus,
      accessLiveClasses: aulasAoVivo2, // Aulas ao vivo?
      allowLiveFAQClasses: aulasAoVivo, // Pantao
      blockEssays: redacao,
      subscriptionPlanBenefits: valorBenefits,
    }

    Insert(data)
  }

  async function Insert(data) {
    const response = await servicesSignature.signatureAdd(data)
    if (response.data !== null || response.data !== '') {
      window.location.reload(true);
      setSubscriptionPlanValueDefault([])
    }
    notification.success('Inserido com sucesso!')
  }

  async function limpaForm() {
    window.location.reload(true);
  }

  async function getAllSubscription() {
    const response = await servicesSignature.getAllSignature()
    if (response.data !== null || response.data !== '') {
      setAllSignatureList(response.data)
      setSubscriptionPlanValueDefault([])
    }
  }

  async function getSubscriptionPlans() {
    const response = await servicesSignature.getSubscriptionPlan()
    const subscriptPlan = response.data.map((item) => {
      return {
        id: item.id,
        value: item.name,
      }
    })
    setSubscriptionPlan(subscriptPlan)
  }

  async function getSubscriptionPlanBenefitsDefault() {
    const response = await servicesSignature?.getSubscriptionPlanBenefits()
    const subscriptionPlanDefault = response?.data?.map((item) => {
      return {
        id: item.id,
        subscriptionPlanId: item.subscriptionPlanId,
        value: item.description,
      }
    })

    setSubscriptionPlanbenefictsOpt(subscriptionPlanDefault)

    const subscriptionPlanValue = await subscriptionPlanDefault?.map((item) => {
      return {
        id: item.id,
        subscriptionPlanId: item.subscriptionPlanId,
        value: item.description,
      }
    })
    setSubscriptionPlanValueDefault(subscriptionPlanValue)
  }

  useEffect(() => {
    getAllSubscription()
    getSubscriptionPlanBenefitsDefault()
  }, [])


  async function handleCallbackState(valor, tipo) {
    
    setBtnTypeChange(true)
    setTipo(tipo)

    const subscriptionHandleCallbackState = await valor.subscriptionPlanBenefits?.map((item) => {
      return {
        key: item.id.toString(),
        value: item.subscriptionPlanId.toString(),
        label: item.description
      }
    })

    setValorNovo(subscriptionHandleCallbackState)

    form.setFieldsValue({
      nome: valor.name,
      descricao: valor.shortDescription,
      duracao: valor.numberOfMounts,
      valorPlano: valor.amount,
      desconto: valor.discount,
      dataRange: [moment(valor.trialPeriodStart), moment(valor.trialPeriodEnd)],
      diasPeriodoTeste: valor.trialPeriodNumberOfDays,
      appleSKU: valor.appleSKU === null ? ' ---- ' : valor.appleSKU,
      valor: valor.subscriptionExpirationTypeId,     
      qtdParcelas: valor.displayInstallmentAmount,
      testeComDesconto: valor.trialPeriodAllowedWithDiscountCoupon
        ? true
        : false,
      acessoForum: valor.blockForum,
      valorStatus: valor.active,
      aulasAoVivo: valor.allowLiveFAQClasses, // Plantao
      aulasAoVivo2: valor.accessLiveClasses, // Live Casses - AULAS AO VIVO
      redacao: valor.blockEssays,      
    })

    dataUpdate = {
      id: valor.id,
    }
  }

  // EDITAR
  async function Editar(e) {
    const valorNovoArrayFinal = valorNovo?.map((item) => {
      return {
        SubscriptionPlanId: item.key,
        Description: item.label
      }
    })

    const valorBenefitsArrayFinal = valorBenefits?.map((item) => {
      return {
        SubscriptionPlanId: item.subscriptionPlanId,
        Description: item.description
      }
    })

    arrAux = valorBenefitsArrayFinal.concat(valorNovoArrayFinal)

    let valorObjFinal = {
      ...dataUpdate,
      subscriptionPlanBenefits: arrAux,
      appleSKU: inputRef.current.props.value,
      discount: inputRefDesconto.current.props.value,
      allowLiveFAQClasses: inputRefFAQ.current.props.checked, // Pantao
      AccessLiveClasses: inputRefFAQ2.current.props.checked, // Aulas ao Vivo?
      blockEssays: inputRefBlockEssay.current.props.checked,
      blockForum: inputRefBlockForum.current.props.checked,
      trialPeriodAllowedWithDiscountCoupon: inputRefCupomDesconto.current.props.checked,
      displayInstallmentAmount: inputRefQtdPercelas.current.props.checked,
      active: inputRefStatus.current.props.checked,
      trialPeriodStart: beginDate,
      trialPeriodEnd: endDate,
      trialPeriodNumberOfDays: inputRefDiasPeriodoTeste.current.props.value,
      name: inputRefNome.current.props.value,
      shortDescription: inputRefDescricao.current.props.value,
      numberOfMounts: inputRefDuracaoPlano.current.props.value,
      amount: inputRefValorPlano.current.props.value,
      SubscriptionExpirationTypeId: inputRefSelect.current.props.value,
      ChoosePlanButtonLabel: 'Assine agora',
    }
    
    if (valorObjFinal.appleSKU === 'undefined' || valorObjFinal.discount === 'undefined',
      valorObjFinal.allowLiveFAQClasses === 'undefined' || valorObjFinal.blockEssays === 'undefined' ||
      valorObjFinal.blockForum === 'undefined' || valorObjFinal.trialPeriodAllowedWithDiscountCoupon === 'undefined' ||
      valorObjFinal.displayInstallmentAmount === 'undefined' || valorObjFinal.active === 'undefined' ||
      valorObjFinal.trialPeriodStart === 'undefined' || valorObjFinal.trialPeriodEnd === 'undefined' ||
      valorObjFinal.trialPeriodNumberOfDays === 'undefined' || valorObjFinal.name === 'undefined' ||
      valorObjFinal.shortDescription === 'undefined' || valorObjFinal.numberOfMounts === 'undefined' ||
      valorObjFinal.amount === 'undefined' || valorObjFinal.SubscriptionExpirationTypeId === undefined ||
      valorObjFinal.ChoosePlanButtonLabel === 'undefined') {

      notification.error('Todos os campos devem estar preenchidos!')
      return
    }

    const response = await servicesSignature.updateSignature(valorObjFinal)
    if (response.data !== null || response.data !== '') {
      setTimeout(window.location.reload(true), 2000);
    }
    notification.success('Editado com sucesso!')
  }

  function mudaArrayBeneficios(id) {
    var retorno = valorNovo.filter(valor => valor.key !== id)
    setValorNovo(retorno)
  }

  return (
    <>
      <S.FormWrapper>
        <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Text style={{ marginBottom: '10px', display: 'block' }}>
                Planos de Assinaturas
              </Text>
            </Col>
            <Col xs={24} md={12}>
              <Text>Nome</Text>
              <Form.Item name="nome" rules={requiredRule} hasFeedback>
                <Input ref={inputRefNome} placeholder="Nome" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Text>Descrição</Text>
              <Form.Item name="descricao" rules={requiredRule} hasFeedback>
                <Input ref={inputRefDescricao} placeholder="Descrição" />
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Duração do plano</Text>
              <Form.Item name="duracao" rules={requiredRule} hasFeedback>
                <Input ref={inputRefDuracaoPlano} placeholder="Duração" />
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Valor do plano</Text>
              <Form.Item name="valorPlano" rules={requiredRule} hasFeedback>
                <Input ref={inputRefValorPlano} placeholder="Valor do plano" />
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Desconto</Text>
              <Form.Item name="desconto" rules={requiredRule} hasFeedback>
                <Input ref={inputRefDesconto} placeholder="Desconto" />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text>Início - Fim do periodo de Teste</Text>
              <Form.Item name="dataRange">
                <RangePicker
                  onChange={handleChangeDate}
                  defaultValue={[]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={3}>
              <Text>Dias do periodo de teste</Text>
              <Form.Item
                name="diasPeriodoTeste"
                rules={requiredRule}
                hasFeedback
              >
                {/* <Input placeholder="Dias do periodo de teste" disabled /> */}
                <Input ref={inputRefDiasPeriodoTeste} placeholder="Dias do periodo de teste" />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text>AppleSKU</Text>
              <Form.Item name="appleSKU" rules={requiredRule} hasFeedback>
                <Input ref={inputRef} placeholder="apple sku" />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text>Tipo de vencimento</Text>
              <Form.Item name="valor">
                <Select
                  ref={inputRefSelect}                 
                  onChange={(e)=>handleChange(e)}                 
                  allowClear
                  placeholder="Tipo de vencimento"
                >                  
                   <Option value={1}>Vencimento por Mês</Option>
                   <Option value={2}>Vencimento por Data</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Text>Beneficio do plano</Text>
              <Form.Item name="subscriptionPlanBenefits">
                <Select
                  mode="multiple"
                  placeholder="Escolha o benefício para o plano"
                  defaultValue={subscriptionPlanValueDefault}
                  allowClear={true}
                  onChange={(e, children) => {
                    setSubscriptionPlanValueDefault(e)

                    let arrayValor = []
                    for (let i = 0; i < children.length; i++) {
                      arrayValor.push({
                        subscriptionPlanId: children[i]?.value,
                        description: children[i]?.label,
                      })
                    }
                    setValorBenefits(arrayValor)
                  }}
                  disabled={false}
                >
                  {subscriptionPlanBenefitsOpt.map((item) => {
                    return (
                      <Option
                        key={item?.id}
                        value={item?.description} // subscriptionPlanId
                        label={item.value} // description
                      >
                        {/* {item?.id} - {item?.value} */}
                        {item?.value}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Text>Exibir quantidade de parcelas</Text>
              <Form.Item
                name="qtdParcelas"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefQtdPercelas}>Sim</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Text>Perido de teste com cupom de desconto?</Text>
              <Form.Item
                name="testeComDesconto"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefCupomDesconto}>Sim</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Acesso ao forum?</Text>
              <Form.Item
                name="acessoForum"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefBlockForum}>Sim</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Status</Text>
              <Form.Item
                name="valorStatus"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefStatus}>Ativo</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={2}>
              <Text>Plantão</Text>
              <Form.Item
                name="aulasAoVivo"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefFAQ}>Sim</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} md={2}>
              <Text>Aulas ao vivo?</Text>
              <Form.Item
                name="aulasAoVivo2"
                valuePropName="checked"
                defaultChecked
              >
                <Checkbox ref={inputRefFAQ2}>Sim</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} md={2}>
              <Text>Redação</Text>
              <Form.Item name="redacao" valuePropName="checked" defaultChecked>
                <Checkbox ref={inputRefBlockEssay}>Sim</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {(btnTypeChance && valorNovo.length > 0) &&
            <div>
              <Col xs={24} md={20}>
                <Form.Item name="txtValorNovo">
                  <Text>Benefícios já contidos nesse tipo de assinatura:</Text>
                  <br />
                  {valorNovo.map((valor) => (
                    <Tag onClick={(e) => { mudaArrayBeneficios(valor.key) }}
                      icon={<CloseCircleOutlined />}
                      color="geekblue"
                      style={{ fontSize: 14 }}
                    >
                      {valor.key} - {valor.label}
                    </Tag>
                  ))}
                </Form.Item>
              </Col>
            </div>
          }

          <Row justify="end">
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                style={{ background: '#FFD300', borderColor: '#FFD300' }}
                onClick={limpaForm}
              >
                Limpar formulário
              </Button>

              {!btnTypeChance ?
                <Button
                  htmlType="submit"
                  type="primary"
                  style={
                    !btnTypeChance
                      ? { background: '#52C419', borderColor: '#52C419' }
                      : { background: '#A1D4FF', borderColor: '#A1D4FF' }
                  }
                >
                  {!btnTypeChance ? 'Gravar' : 'Editar'}
                </Button>
                :
                <Button
                  onClick={(e) => { Editar(e) }}
                  htmlType="button"
                  type="primary"
                  style={{ background: '#197ac4', borderColor: '#197ac4' }}
                >
                  Editar
                </Button>
              }
            </Space>
          </Row>
        </Form>

        <Divider />

        <SignatureListComponent handleCallbackState={handleCallbackState} />
      </S.FormWrapper>
    </>
  )
}

export default SignatureForm
