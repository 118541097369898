import React, { useEffect, useState } from 'react'

import { Form, AutoComplete, Typography } from 'antd'

import services from './disciplines.services'

function DisciplinesList({
  name,
  hasFeedback,
  rules,
  isView,
  setDisciplineId,
}) {
  const [disciplines, setDisciplines] = useState([])

  const { Text } = Typography

  useEffect(() => {
    async function getDisciplinesList() {
      const { data } = await services.getDisciplines()

      const disciplinesList = data?.map(({ id, name }) => {
        return {
          id,
          value: name,
        }
      })

      setDisciplines(disciplinesList)
    }

    getDisciplinesList()
  }, [])

  function handleDisciplineChange(_, option) {
    if (setDisciplineId) setDisciplineId(option?.id || '')
  }

  return (
    <>
      <Text>Disciplina</Text>

      {!!disciplines && (
        <Form.Item name={name} hasFeedback={hasFeedback} rules={rules}>
          <AutoComplete
            placeholder="Escolha a disciplina"
            options={disciplines}
            onSelect={(val, option) => handleDisciplineChange(val, option)}
            onChange={(val, option) => handleDisciplineChange(val, option)}
            value={disciplines.id}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            allowClear
            disabled={isView}
          />
        </Form.Item>
      )}
    </>
  )
}

export default DisciplinesList
