import React, { useEffect, useState } from 'react'

import { Table } from 'antd'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import getDateTimeFormat from '~/utils/date-time-format'
import moment from 'moment'


const StyledTable = styled(Table)`
  .ant-spin-container {
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    overflow: hidden;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ant-table-content {
    min-height: 280px;
  }
  .ant-table-row > .ant-table-cell:nth-child(2) {
    text-align: end;
  }
  .ant-table {
    font-weight: 300;
    font-size: 1.1rem;
  }
  .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  .ant-table-thead > tr > th {
    font-weight: normal;
    background-color: unset;
    font-size: 1.3rem;
  }
`

const FeedbacksManagementTableComponent = ({
  apiMethod,
  apiParams,
  tableTitle,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      simple: true,
    },
  })

  const columns = [
    {
      title: tableTitle,

      dataIndex: 'name',
      width: '70%',
    },
    {
      title: '',
      dataIndex: 'access',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'accessDate',
      width: '30%',
      render: (date) => {
        if (date != undefined) {             
         //Caso seja necessário exibir as datas
         //Utilizar essa area.
         //O banco encontra-se com o timezone norte americano, necessário aplicar alguma estratégia para
          // converter as horas para o timezone do Brasil
        }
      },
    },
  ]

  const fetchData = async () => {
    setLoading(true)
    const params = {
      PageNumber: tableParams.pagination.current,
      PageSize: 5,
    }

    const response = await apiMethod({
      ...apiParams,
      ...params,
    })
    const responseData = response.data

    setData(responseData.content)
    setLoading(false)
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: responseData.totalRecords,
      },
    })
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(tableParams), JSON.stringify(apiParams)])

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...prevParams.pagination,
        ...pagination,
      },
    }))
  }

  return (
    <StyledTable
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      size="small"
    />
  )
}

export default FeedbacksManagementTableComponent
