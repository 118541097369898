import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd'
import FeedbacksManagementComponentList from '../components/feedbacksManagement-list.component'

function FeedbacksManagementListContainer() {
  const [loadingData, setLoadingData] = useState([])

  useEffect(() => {
    setLoadingData(true)

    setLoadingData(false)
  }, [])

  if (loadingData) {
    return <Skeleton active />
  }

  return <FeedbacksManagementComponentList />
}

export default FeedbacksManagementListContainer
