import api from '~/services/api'

const services = {
  includeStudents(data) {
    return api.post('/student/import-excel', data, {
      responseType: 'blob',
    })
  },
  getPartnerSchool() {
    return api.get('/partnerschool/autocomplete', {
      params: { Active: true },
    })
  },
  getSubscriptionPlan() {
    return api.get('/subscriptionplan/autocomplete', {
      params: { Active: true },
    })
  },

  getLogs(){
    return api.get('/student/log-import-students')
  }
}

export default services
