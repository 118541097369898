import qs from 'qs'
import api from '~/services/api'

function returnParams(data) {
  const params = Object.entries(data).reduce((params, [key, value]) => {
    if (value !== undefined) {
      return {
        ...params,
        [key]: value,
      }
    }

    return params
  }, {})

  return params
}

const services = {
  save(data) {
    return api.post('/study-plan', data)
  },
  getById(id) {
    return api.get(`/study-plan/${id}`)
  },
  update(data) {
    return api.put('/study-plan', data)
  },
  updateFree(ids, check) {
    const params = {
      ids: ids,
      check: check,
    }
    return api.put(`/study-plan/update-Free`, params)
  },
  delete(id) {
    return api.delete(`/study-plan/${id}`)
  },
  getStudyPlanType() {
    return api.get(`/study-plan-type`)
  },
  async lessonsActives(data, pageNumber, pageSize) {
    const paramsValid = returnParams(data)

    const response = await api.get('/study-plan/lessons/active', {
      params: { ...paramsValid, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })

    return response
  },
  deleteLesson(params) {
    return api.delete(`/study-plan/lesson/delete`, { params })
  },
  deleteDiscipline(params) {
    return api.delete(`/study-plan/discipline/delete`, { params })
  },
  deleteWeek(params) {
    return api.delete(`/study-plan/week/delete`, { params })
  },
  saveNewWeek(data) {
    return api.post(`/study-plan/week/new`, data)
  },
  getLessons(data) {
    return api.get(`/study-plan/lesson/getLessons?StudyPlanId=${data.StudyPlanId}&DisciplineID=${data.DisciplineID}&StudyPlanStepId=${data.StudyPlanStepId}&SubjectId=${data.SubjectId}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`)
  },
  saveNewLesson(data) {
    return api.post(`/study-plan/lesson/new`, data)
  },
  updateFreeLesson(params) {
    return api.put(`/study-plan/lesson/update-Free/`, params)
  },
  cloneStudyPlan(id) {
    return api.post(`/study-plan/clone/${id}`)
  },
  getStudyPlanStepType() {
    return api.get(`/study-plan-step-type/`)
  },
  saveStudyPlanStepType(data) {
    return api.put(`/study-plan/week/type/`, data)
  },
}

export default services
