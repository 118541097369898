import styled from 'styled-components'

const FormWrapper = styled.div`
  form {
    .row-form {
      width: 100%;
      display: flex;
      align-items: center;

      .filter-form {
        margin-bottom: 5px;
      }
    }
  }
`

const DateFilterMain = styled.div`
  display: flex;

  .margin {
    margin-right: 16px;
  }
`
const DateFilterContent = styled.div``

const ActionWrapper = styled.div`
  button {
    margin: 20px 0 20px 0;
  }

  .ant-alert-info {
    margin-bottom: 10px;
  }

  .reset {
    margin: 10px 25px 10px 0;
    padding: 0;
  }
`

const ModalWrapper = styled.div`
  .text {
    display: block;
  }

  .note {
    height: 200px;
  }
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .send-submit {
    margin-left: 15px;
  }
`

export {
  FormWrapper,
  DateFilterMain,
  DateFilterContent,
  ActionWrapper,
  ModalWrapper,
  FooterWrapper,
}
