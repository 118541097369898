import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Divider,
  Space,
  Button,
  Typography,
} from 'antd'

import moment from 'moment'

import DisciplinesList from '~/shared/Disciplines'
import SubjectsList from '~/shared/Subject'

import getDateTimeFormat from '~/utils/date-time-format'

function LiveClassesFormComponent({
  type,
  loadingSubmit,
  liveClass,
  onSubmit,
}) {
  const [dateLiveClass, setDateLiveClass] = useState('')
  const [disciplineId, setDisciplineId] = useState('')
  const [disciplineSubjectId, setDisciplineSubjectId] = useState('')

  const [form] = Form.useForm()

  const { Text } = Typography

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && liveClass) {
      form.setFieldsValue(liveClass)

      const { dateLiveClass, disciplineId, disciplineSubjectId } = liveClass

      if (dateLiveClass)
        setDateLiveClass(moment(dateLiveClass).format('yyyy-MM-DD HH:mm:ss'))

      setDisciplineId(disciplineId)

      setDisciplineSubjectId(disciplineSubjectId)
    }
  }, [form, isEdit, isView, liveClass])

  async function onFinish({ videoId, inactive }) {
    onSubmit({
      dateLiveClass,
      videoId,
      disciplineId,
      disciplineSubjectId,
      inactive,
    })
  }

  function handleChangeDate(_, dateString) {
    setDateLiveClass(getDateTimeFormat(dateString, true))
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={16} align="middle">
        <Col xs={24} md={12}>
          <Text>Data da aula</Text>

          {(isEdit || isView) && dateLiveClass ? (
            <Form.Item name="dateLiveClassDefault">
              <DatePicker
                onChange={handleChangeDate}
                defaultValue={moment(dateLiveClass)}
                style={{ width: '100%' }}
                showTime
                showSecond={false}
                disabled={isView}
              />
            </Form.Item>
          ) : (
            <Form.Item name="dateLiveClass" rules={requiredRule}>
              <DatePicker
                onChange={handleChangeDate}
                style={{ width: '100%' }}
                showTime
              />
            </Form.Item>
          )}
        </Col>

        <Col xs={24} md={12}>
          <Text>Vídeo</Text>

          <Form.Item name="videoId" hasFeedback={!isView}>
            <Input
              placeholder="Vídeo da Aula"
              maxLength={50}
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <DisciplinesList
            name={isNew ? 'disciplineId' : 'disciplineName'}
            hasFeedback={!isView}
            rules={requiredRule}
            isView={isView}
            setDisciplineId={setDisciplineId}
          />
        </Col>

        <Col xs={24} md={8}>
          <SubjectsList
            name={isNew ? 'disciplineSubjectId' : 'disciplineSubjectName'}
            hasFeedback={!isView}
            rules={requiredRule}
            isView={isView}
            setDisciplineSubjectId={setDisciplineSubjectId}
            disciplineId={disciplineId}
            form={form}
          />
        </Col>

        <Col xs={24} md={8}>
          <Text>Status</Text>

          <Form.Item name="inactive" valuePropName="checked">
            <Checkbox disabled={isView}>Inativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/aula-ao-vivo">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default LiveClassesFormComponent
