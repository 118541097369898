import validatePermision from '~/utils/validate-permission'
import useAuth from '~/hooks/useAuth'

function PermittedAction(props) {
  const { tag, children } = props

  const auth = useAuth()

  return validatePermision(auth.permissions, tag) ? children : null
}

export default PermittedAction
