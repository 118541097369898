import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

function LoginFormComponent(props) {
  const { loading, onSubmit: onFinish } = props

  const rules = [{ required: true }]

  return (
    <Form name="login" size="large" onFinish={onFinish}>
      <Form.Item name="email" rules={rules} hasFeedback>
        <Input
          prefix={<UserOutlined />}
          placeholder="E-mail"
          maxLength={50}
          allowClear
        />
      </Form.Item>

      <Form.Item name="password" rules={rules} hasFeedback>
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Senha"
          maxLength={50}
          allowClear
        />
      </Form.Item>

      <Row align="middle" justify="space-between">
        <Col>
          <Link to="/esqueceu-senha">Esqueceu sua senha?</Link>
        </Col>

        <Col>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
          >
            Entrar
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default LoginFormComponent
