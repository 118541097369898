import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Divider,
  Space,
  Button,
  Upload,
  Select,
  notification,
  Tooltip,
  Tabs,
  Table,
  Modal,
} from 'antd'
import {
  UploadOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import ModalQuestions from './modal-questions.component'

function DisciplineLessonsFormComponent(props) {
  const {
    idLesson,
    getData,
    type,
    loadingSubmit,
    lesson,
    onSubmit,
    subject,
    tags,
    typeLesson,
    displaySequence,
    form,
    disciplineId,
    disciplineSubjectId,
    tagsQuestions,
    deleteQuestionFromLesson,
  } = props

  const { TabPane } = Tabs
  const { Option } = Select
  const requiredRule = [{ required: true }]
  const [pdfUrlAws, setPdfUrlAws] = useState(null)
  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const [formModalQuestion] = Form.useForm()

  const [fileList, setFileList] = useState(null)
  const [modalQuestionsVisible, setModalQuestionsVisible] = useState(false)
  const [
    internalQuestionsDataSource,
    setInternalQuestionsDataSource,
  ] = useState([])
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([])

  const [selectedQuestionIdsModal, setSelectedQuestionIdsModal] = useState([])

  const [modalDataSourceQuestions, setModalDataSourceQuestions] = useState([])

  function closeModalQuestions() {
    setModalQuestionsVisible(false)
  }
  const [lessonSendEssayValue, setLessonSendEssayValue] = useState('')

  const [termsOption, setTermsOption] = useState([])
  const [termsValueDefault, setTermsValueDefault] = useState([])

  useEffect(() => {
    if ((isEdit || isView) && lesson) {
      if (lesson.tags && lesson.tags.length > 0) {
        const { tags } = lesson
        const result = tags.map((result) => {
          return result.tagName
        })

        form.setFieldsValue({ ...lesson, tags: result })
      } else form.setFieldsValue(lesson)
      const fileListEdit = [
        {
          uid: 'uid',
          name: 'Arquivo em PDF',
          status: 'done',
        },
      ]
      setFileList(fileListEdit)
      setPdfUrlAws(lesson.pdfUrlAws)
      setInternalQuestionsDataSource(lesson.questions)

      if (lesson.questions != undefined) {
        const arrayLessons = []
        lesson.questions.forEach((item) => arrayLessons.push(item.questionId))
        setSelectedQuestionIds(arrayLessons)
      }
    }
  }, [isEdit, isView, lesson, form, getData])

  useEffect(() => {
    if (lesson) {
      setLessonSendEssayValue(lesson.sendEssay)
    }
  }, [lesson])

  useEffect(() => {
    async function getTermsValueDefault() {
      const termDefault = await tags.map((itemTermDefault) => {
        return {
          id: itemTermDefault.value,
          value: itemTermDefault.label,
        }
      })

      setTermsOption(termDefault)

      const termValue = await termDefault.map((itemTerm) => {
        return itemTerm.value
      })

      setTermsValueDefault(termValue)
    }

    if (tags) getTermsValueDefault()
  }, [tags])

  async function onChangeFile(info) {
    if (info.file.status === 'done') {
      setFileList([])
      setFileList([info.file.originFileObj])
    } else if (info.file.status === 'removed') {
      setFileList([])
    } else if (info.file.status === 'error') {
      notification.error('Ocorreu um erro ao selecionar o arquivo.')
    }
  }

  async function onPreviewFile(info) {
    if (info.status === 'done') {
      window.open(pdfUrlAws, '_blank')
    }
  }

  async function onBlurName(value) {
    const valueWithoutAccent = value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    const routePeplaced = valueWithoutAccent.split(' ').join('-')
    const routeCompleted = routePeplaced.toLowerCase()

    form.setFieldsValue({ route: routeCompleted })
  }

  async function onFinish(values) {
    const data = {
      name: values.name,
      displaySequence: values.displaySequence,
      active: values.active,
      route: values.route,
      tags: values.tags,
      videoId: values.videoId,
      disciplineLessonTypeId: values.disciplineLessonTypeId,
      disciplineId: subject.disciplineId,
      DisciplineSubjectId: subject.id,
      pdf: fileList != null ? fileList[0] : null,
      sendEssay: values.sendEssay,
      questions: selectedQuestionIds,
    }

    try {
      await onSubmit(data, internalQuestionsDataSource)
    } catch {
      notification.error('Ocorreu um erro durante a execução.')
    }
  }

  const timeoutForPdf = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 5)
  }

  function openModalQuestions() {
    setModalQuestionsVisible(true)
    setSelectedQuestionIds(selectedQuestionIds)
    setModalDataSourceQuestions([])
  }

  function view(id) {
    window.open(`/pergunta/${id}/visualizacao`, '_blank')
  }

  async function removeFromInternal(id, row) {
    const config = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        // remove from table
        const indexFind = internalQuestionsDataSource.findIndex(
          (x) => x.id === id,
        )

        if (indexFind >= 0) {
          internalQuestionsDataSource.splice(indexFind, 1)
          const afterRemoveDataTable = [...internalQuestionsDataSource]
          setInternalQuestionsDataSource(afterRemoveDataTable)
        }

        // remove from selected
        const indexFindSelectds = selectedQuestionIds.findIndex(
          (item) => item === row.questionId,
        )
        selectedQuestionIds.splice(indexFindSelectds, 1)
        const newSelectedQuestionIds = selectedQuestionIds
        setSelectedQuestionIds(newSelectedQuestionIds)

        if (row.questionId > 0) {
          deleteQuestionFromLesson(row.questionId)
        }
      },
      onCancel() { },
    }

    Modal.confirm(config)
  }

  function renderActions(row) {
    const { id, questionId } = row

    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => view(questionId)}
            disabled={isView}
          />
        </Tooltip>
        <Tooltip title="Remover">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeFromInternal(id, row)}
            disabled={isView}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  const columnsQuestions = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Ações',
      align: 'center',
      render: (row) => renderActions(row),
    },
  ]

  return (
    <Form
      form={form}
      initialValues={{ active: true, sendEssay: false }}
      onFinish={onFinish}
    >
      <Row gutter={16} align="middle">
        <Col xs={6} md={11}>
          <Form.Item name="name" rules={requiredRule}>
            <Input
              placeholder="Título Aula"
              maxLength={50}
              disabled={isView}
              onBlur={(event) => onBlurName(event.target.value)}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={4}>
          <Form.Item name="disciplineLessonTypeId">
            <Select
              showSearch
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
              placeholder="Tipo Aula"
              optionFilterProp="children"
              options={typeLesson}
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={9}>
          <Form.Item name="videoId" rules={requiredRule}>
            <Input
              placeholder="Vídeo da Aula"
              maxLength={50}
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={11}>
          {!!termsOption && termsValueDefault && (
            <Form.Item name="tags">
              <Select
                mode="tags"
                placeholder="Termos"
                defaultValue={termsValueDefault}
                onChange={(e) => setTermsValueDefault(e)}
                disabled={isView}
                tokenSeparators={[',']}
              >
                {termsOption.map((itemTerm) => {
                  const value = itemTerm.id
                  const label = itemTerm.value
                  return (
                    <Option key={value} value={label}>
                      {label}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          )}
        </Col>

        <Col xs={6} md={4}>
          <Form.Item name="displaySequence" rules={requiredRule}>
            <Select
              options={displaySequence}
              placeholder="Ordem Exibição"
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={7}>
          <Form.Item name="route" rules={requiredRule}>
            <Input placeholder="Rota" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={6} md={2}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView} defaultChecked>
              Ativo
            </Checkbox>
          </Form.Item>
        </Col>

        <Col
          xs={6}
          md={5}
          style={{
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!isNew && lessonSendEssayValue && (
            <Form.Item
              name="sendEssay"
              valuePropName="checked"
              style={{ marginBottom: '0' }}
            >
              <Checkbox disabled={isView} defaultChecked>
                Enviar Redação
              </Checkbox>
            </Form.Item>
          )}

          {isNew && lessonSendEssayValue === '' && (
            <Form.Item
              name="sendEssay"
              valuePropName="checked"
              style={{ marginBottom: '0' }}
            >
              <Checkbox disabled={isView}>Enviar Redação</Checkbox>
            </Form.Item>
          )}

          {!isNew && !lessonSendEssayValue && (
            <Form.Item
              name="sendEssay"
              valuePropName="checked"
              style={{ marginBottom: '0' }}
            >
              <Checkbox disabled={isView}>Enviar Redação</Checkbox>
            </Form.Item>
          )}

          {!isView && (
            <Tooltip title="Este campo deve ser marcado apenas para aulas de disciplinas de redação, para indicar que ao concluir a aula o aluno deverá enviar uma redação para correção.">
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        {(isNew || isEdit) && (
          <Col xs={6} md={5}>
            <Form.Item name="Pdf">
              <Upload
                name="pdf"
                accept=".pdf"
                onChange={onChangeFile}
                fileList={fileList}
                onPreview={onPreviewFile}
                withCredentials
                customRequest={timeoutForPdf}
              >
                <Button icon={<UploadOutlined />}>
                  Carregar material em PDF
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {isView && (
          <Col xs={6} md={5}>
            {pdfUrlAws && (
              <a href={pdfUrlAws} target="_blank" rel="noreferrer">
                Arquivo em PDF
              </a>
            )}
            {!pdfUrlAws && (
              <Button type="text" danger disabled>
                Não há arquivo em PDF
              </Button>
            )}
          </Col>
        )}
      </Row>

      <Divider />

      <Row justify="end">
        <Space>
          <Button
            type="primary"
            onClick={() => openModalQuestions()}
            disabled={isView}
          >
            Incluir Perguntas
          </Button>
        </Space>
      </Row>
      <Row justify="end">
        <Space>
          <ModalQuestions
            idLesson={idLesson}
            tags={tagsQuestions}
            idDiscipline={disciplineId}
            visibleModalLesson={modalQuestionsVisible}
            closeModalQuestions={closeModalQuestions}
            setInternalQuestionsDataSource={setInternalQuestionsDataSource}
            internalQuestionsDataSource={
              internalQuestionsDataSource === null
                ? []
                : internalQuestionsDataSource
            }
            selectedQuestionIds={selectedQuestionIds}
            setSelectedQuestionIds={setSelectedQuestionIds}
            formModalQuestion={formModalQuestion}
            modalDataSourceQuestions={modalDataSourceQuestions}
            setModalDataSourceQuestions={setModalDataSourceQuestions}
            selectedQuestionIdsModal={selectedQuestionIdsModal}
            setSelectedQuestionIdsModal={setSelectedQuestionIdsModal}
          />
        </Space>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Perguntas" key="1">
              <Table
                columns={columnsQuestions}
                dataSource={internalQuestionsDataSource}
                size="middle"
                pagination={false}
                disabled={isView}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link
              to={`/disciplina/${disciplineId}/materia/${disciplineSubjectId}/aulas`}
            >
              {isEdit ? 'Cancelar' : 'Voltar'}
            </Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default DisciplineLessonsFormComponent
