import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
  Tabs,
  Tooltip,
  Table,
  Modal,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import services from '../tests.services'
import notification from '~/shared/Notification'

function TestFormComponent(props) {
  const [form] = Form.useForm()
  const [formModalStudyPlans] = Form.useForm()
  const [formModalQuestion] = Form.useForm()
  const {
    id,
    type,
    loadingSubmit,
    onSubmit,
    test,
    group,
    disciplines,
    tags,
    tests,
    deleteQuestionFromLesson,
    deleteStudyPlan,
  } = props

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const { TabPane } = Tabs
  const { TextArea } = Input
  const { Option, OptGroup } = Select

  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingTableQuestions, setLoadingTableQuestions] = useState(false)

  const [filterStudyPlans, setFilterStudyPlans] = useState({})
  const [filterQuestions, setFilterQuestions] = useState({})

  const [
    internalStudyPlanDataSource,
    setInternalStudyPlanDataSource,
  ] = useState([])

  const [
    internalQuestionsDataSource,
    setInternalQuestionsDataSource,
  ] = useState([])

  const [paginationStudyPlans, setPaginationStudyPlans] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const [paginationQuestions, setPaginationQuestions] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const [totalRecords1, setTotalRecords1] = useState()
  const [firstLoad1, setFirstLoad1] = useState(true)

  const [totalRecords2, setTotalRecords2] = useState()
  const [firstLoad2, setFirstLoad2] = useState(true)

  const [modalDataSourceStudyPlans, setModalDataSourceStudyPlans] = useState([])
  const [modalDataSourceQuestions, setModalDataSourceQuestions] = useState([])

  const [selectedStudyPlanIds, setSelectedStudyPlanIds] = useState([])
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([])

  const [visibleModalStudyPlans, setVisibleModalStudyPlans] = useState(false)
  const [visibleModalQuestions, setVisibleModalQuestions] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const tagsOptionsInsideModal = tags.map((tag) => (
    <Option id={tag.value} key={tag.value} value={tag.label}>
      {tag.label}
    </Option>
  ))

  function resetFilterQuestions() {
    formModalQuestion.resetFields()
    const initialFilter = {
      TestId: id,
      DisciplineId: null,
      Statement: '',
      Tag: '',
      TestIdSearch: null,
    }
    setFilterQuestions(initialFilter)
    setLoadingTableQuestions(true)
    setModalDataSourceQuestions([])
    setLoadingTableQuestions(false)
  }

  function resetFilterStudyPlan() {
    formModalStudyPlans.resetFields()
    const initialFilter = {
      TestId: id,
      Name: '',
    }
    setFilterStudyPlans(initialFilter)
    setLoadingTable(true)
    setModalDataSourceStudyPlans([])
    setLoadingTable(false)
  }

  useEffect(() => {
    if ((isEdit || isView) && test) {
      form.setFieldsValue(test)
      setInternalQuestionsDataSource(test.questions)
      setInternalStudyPlanDataSource(test.studyPlans)
      // add Questions ja inseridas
      const listIdsQuestions = []
      test.questions.map((question) =>
        listIdsQuestions.push(question.questionId),
      )
      setSelectedQuestionIds(listIdsQuestions)

      // add Study Plans ja inseridas
      const listIdsStudyPlans = []
      test.studyPlans.map((studyPlan) =>
        listIdsStudyPlans.push(studyPlan.studyPlanId),
      )
      setSelectedStudyPlanIds(listIdsStudyPlans)
    }
  }, [isEdit, isView, test, form])

  useEffect(() => {
    const check = internalStudyPlanDataSource.length > 0
    form.setFieldsValue({
      restricted: check,
    })
    setDisabled(check)
  }, [internalStudyPlanDataSource])

  async function onFinish(values) {
    if (values.restricted && internalStudyPlanDataSource.length == 0) {
      notification.error('Inserir pelo menos um plano de estudo')
    } else if (internalQuestionsDataSource.length < 2) {
      notification.error('Deve inserir pelo menos duas perguntas')
    } else onSubmit(values, selectedQuestionIds, selectedStudyPlanIds)
  }

  function viewModal(id, row) {
    window.open(`/pergunta/${id}/visualizacao`, '_blank')
  }

  function viewQuestionInternal(row) {
    window.open(`/pergunta/${row.questionId}/visualizacao`, '_blank')
  }

  function viewModalStudyPlan(id, row) {
    window.open(`/plano-estudo/${id}/visualizacao`, '_blank')
  }

  function viewStudyPlanInternal(row) {
    window.open(`/plano-estudo/${row.studyPlanId}/visualizacao`, '_blank')
  }

  async function removeFromInternalStudyPlan(id, row) {
    const config = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          if (row.id > 0) {
            await deleteStudyPlan(row.studyPlanId)
          }

          // remove from table
          const indexFind = internalStudyPlanDataSource.findIndex(
            (x) => x.studyPlanId === row.studyPlanId,
          )

          if (indexFind >= 0) {
            internalStudyPlanDataSource.splice(indexFind, 1)
            const afterRemoveDataTable = [...internalStudyPlanDataSource]
            setInternalStudyPlanDataSource(afterRemoveDataTable)
          }

          // remove from selected
          const indexFindSelectds = selectedStudyPlanIds.findIndex(
            (item) => item === row.studyPlanId,
          )
          selectedStudyPlanIds.splice(indexFindSelectds, 1)
          const newSelectedStudyPlanIds = selectedStudyPlanIds
          setSelectedStudyPlanIds(newSelectedStudyPlanIds)
        } catch {}
      },
      onCancel() {},
    }

    Modal.confirm(config)
  }

  function renderActionInternalStudyPlan(id, row) {
    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => viewStudyPlanInternal(row)}
            disabled={isView}
          />
        </Tooltip>
        <Tooltip title="Remover">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeFromInternalStudyPlan(id, row)}
            disabled={isView}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  function closeModalStudyPlans() {
    setVisibleModalStudyPlans(false)
  }

  function selectStudyPlanModal(id, row) {
    const alreadyExist = selectedStudyPlanIds.includes(row.key)
    const recordSelected = {
      studyPlanId: row.key,
      name: row.studyPlanName,
      id: null,
    }

    if (alreadyExist) {
      const index = selectedStudyPlanIds.indexOf(row.key)

      selectedStudyPlanIds.splice(index, 1)
      internalStudyPlanDataSource.splice(index, 1)

      const afterRemoveDataTable = [...internalStudyPlanDataSource]
      setInternalStudyPlanDataSource(afterRemoveDataTable)
    } else {
      const newDataTable = [...internalStudyPlanDataSource, recordSelected]

      selectedStudyPlanIds.push(row.key)
      setInternalStudyPlanDataSource(newDataTable)
      setSelectedStudyPlanIds(selectedStudyPlanIds)
    }
  }

  function showInsertOrDeleteStudyPlan(id, row) {
    const alreadyExist = selectedStudyPlanIds.includes(id)
    if (!alreadyExist) {
      return (
        <Tooltip title="Incluir">
          <Button
            type="link"
            icon={<CheckCircleOutlined />}
            onClick={() => selectStudyPlanModal(id, row)}
          />
        </Tooltip>
      )
    }
    return ''
  }

  function renderActionsForModalStudyPlan(id, row) {
    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => viewModalStudyPlan(id, row)}
          />
        </Tooltip>
        {showInsertOrDeleteStudyPlan(id, row)}
      </Button.Group>
    )
  }

  const columsStudyPlanSelected = [
    {
      title: 'Nome',
      dataIndex: 'studyPlanName',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      align: 'center',
      render: (id, row) => renderActionsForModalStudyPlan(id, row),
    },
  ]

  const columnsStudyPlans = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      align: 'center',
      render: (id, row) => renderActionInternalStudyPlan(id, row),
    },
  ]

  async function removeFromInternal(id, row) {
    const config = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          if (row.id > 0) {
            await deleteQuestionFromLesson(row.questionId)
          }

          // remove from table
          const indexFind = internalQuestionsDataSource.findIndex(
            (x) => x.questionId === row.questionId,
          )

          if (indexFind >= 0) {
            internalQuestionsDataSource.splice(indexFind, 1)
            const afterRemoveDataTable = [...internalQuestionsDataSource]
            setInternalQuestionsDataSource(afterRemoveDataTable)
          }

          // remove from selected
          const indexFindSelectds = selectedQuestionIds.findIndex(
            (item) => item === row.questionId,
          )
          selectedQuestionIds.splice(indexFindSelectds, 1)
          const newSelectedQuestionIds = selectedQuestionIds
          setSelectedQuestionIds(newSelectedQuestionIds)
        } catch {}
      },
      onCancel() {},
    }

    Modal.confirm(config)
  }

  function renderActionInternalQuestions(id, row) {
    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => viewQuestionInternal(row)}
            disabled={isView}
          />
        </Tooltip>
        <Tooltip title="Remover">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeFromInternal(id, row)}
            disabled={isView}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  const columnsQuestionsInternal = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Disciplina',
      dataIndex: 'disciplineName',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      align: 'center',
      render: (id, row) => renderActionInternalQuestions(id, row),
    },
  ]

  function closeModalQuestions() {
    setVisibleModalQuestions(false)
  }

  function selectQuestionModal(id, row) {
    const alreadyExist = selectedQuestionIds.includes(row.key)
    const recordSelected = {
      questionId: row.key,
      disciplineName: row.disciplineName,
      code: row.code,
      id: null,
    }

    if (alreadyExist) {
      const index = selectedQuestionIds.indexOf(row.key)

      selectedQuestionIds.splice(index, 1)
      internalQuestionsDataSource.splice(index, 1)

      const afterRemoveDataTable = [...internalQuestionsDataSource]
      setInternalQuestionsDataSource(afterRemoveDataTable)
    } else {
      const newDataTable = [...internalQuestionsDataSource, recordSelected]

      selectedQuestionIds.push(row.key)
      setInternalQuestionsDataSource(newDataTable)
      setSelectedQuestionIds(selectedQuestionIds)
    }

    closeModalQuestions()
  }

  function showInsertOrDelete(id, row) {
    const alreadyExist = selectedQuestionIds.includes(id)
    if (!alreadyExist) {
      return (
        <Tooltip title="Incluir">
          <Button
            type="link"
            icon={<CheckCircleOutlined />}
            onClick={() => selectQuestionModal(id, row)}
          />
        </Tooltip>
      )
    }
    return ''
  }

  function renderActionsForModalQuestions(id, row) {
    return (
      <Button.Group>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => viewModal(id, row)}
          />
        </Tooltip>
        {showInsertOrDelete(id, row)}
      </Button.Group>
    )
  }

  const columnsQuestionsModal = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Disciplina',
      dataIndex: 'disciplineName',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      align: 'center',
      render: (id, row) => renderActionsForModalQuestions(id, row),
    },
  ]

  const tagsOptions = group.map((tag) => (
    <Option id={tag.value} key={tag.value} value={tag.label}>
      {tag.label}
    </Option>
  ))

  async function searchStudyPlan(values) {
    setLoadingTable(true)

    const filter = {
      TestId: id,
      Name: values.name,
    }

    setFilterStudyPlans(filter)

    try {
      const response = await services.getStudyPlan(
        filter,
        paginationStudyPlans.pageNumber,
        paginationStudyPlans.pageSize,
      )
      const dataSource = response.data.content
      const totalRecords = response.data.totalRecords
      if (dataSource.length > 0) {
        const dataSourceWithKey = dataSource.map((studyPlan) => {
          return {
            key: studyPlan.studyPlanId,
            studyPlanName: studyPlan.studyPlanName,
          }
        })

        if (firstLoad1) setFirstLoad1(false)
        setTotalRecords1(totalRecords)

        setTimeout(() => {
          setLoadingTable(false)
          const newSelectedIds = [...selectedStudyPlanIds]
          setSelectedStudyPlanIds(newSelectedIds)
          setModalDataSourceStudyPlans(dataSourceWithKey)
        }, 1500)
      } else {
        setTimeout(() => {
          notification.warning('Não há dados para serem exibidos.')
          setLoadingTable(false)
          setModalDataSourceStudyPlans([])
        }, 1500)
      }
    } catch {
      notification.error('Erro inesperado.')
    }
  }

  async function searchQuestions(values) {
    setLoadingTableQuestions(true)

    const data = {
      TestId: id,
      DisciplineId: values.DisciplineId,
      Statement: values.Statement,
      Tag: values.Tag,
      TestIdSearch: values.TestId,
    }

    setFilterQuestions(data)

    try {
      const response = await services.getQuestions(
        data,
        paginationQuestions.pageNumber,
        paginationQuestions.pageSize,
      )
      const dataSource = response.data.content
      const totalRecords = response.data.totalRecords
      if (dataSource.length > 0) {
        const dataSourceWithKey = dataSource.map((question) => {
          return {
            key: question.id,
            code: question.code,
            disciplineName: question.disciplineName,
            statement: question.statement,
          }
        })

        if (firstLoad2) setFirstLoad2(false)
        setTotalRecords2(totalRecords)

        setTimeout(() => {
          setLoadingTableQuestions(false)
          setModalDataSourceQuestions(dataSourceWithKey)
        }, 1500)
      } else {
        setTimeout(() => {
          notification.warning('Não há dados para serem exibidos.')
          setLoadingTableQuestions(false)
          setModalDataSourceQuestions([])
        }, 1500)
      }
    } catch {
      notification.error('Erro inesperado.')
    }
  }

  function openModalStudyPlans() {
    setVisibleModalStudyPlans(true)
    formModalStudyPlans.setFieldsValue({ name: '' })
    setModalDataSourceStudyPlans([])
  }

  function saveStudyPlansHistory() {
    setVisibleModalStudyPlans(false)
  }

  function openModalQuestions() {
    setVisibleModalQuestions(true)
    formModalQuestion.setFieldsValue({ name: '' })
    setModalDataSourceStudyPlans([])
  }
  function saveModalQuestions() {
    setVisibleModalQuestions(false)
  }

  useEffect(() => {
    async function execute() {
      await searchStudyPlan(formModalStudyPlans.getFieldsValue(true))
    }

    if (firstLoad1) return

    execute()
  }, [paginationStudyPlans])

  useEffect(() => {
    async function execute() {
      await searchQuestions(formModalQuestion.getFieldsValue(true))
    }

    if (firstLoad2) return

    execute()
  }, [paginationQuestions])

  useEffect(() => {
    if (visibleModalStudyPlans) {
      formModalStudyPlans.resetFields()
      setFirstLoad1(true)
      setTotalRecords1(0)
      setPaginationStudyPlans({
        pageNumber: 1,
        pageSize: 10,
      })
      setModalDataSourceStudyPlans([])
    }
  }, [visibleModalStudyPlans])

  useEffect(() => {
    if (visibleModalQuestions) {
      formModalQuestion.resetFields()
      setFirstLoad2(true)
      setTotalRecords2(0)
      setPaginationQuestions({
        pageNumber: 1,
        pageSize: 10,
      })
      setModalDataSourceQuestions([])
    }
  }, [visibleModalQuestions])

  return (
    <Form
      form={form}
      initialValues={{ active: true, freemium: false }}
      onFinish={onFinish}
    >
      <Row gutter={16} align="middle">
        <Col xs={24} md={8}>
          <Form.Item name="name" rules={requiredRule}>
            <Input placeholder="Nome" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name="description" rules={requiredRule}>
            <Input placeholder="Descrição" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name="group">
            <Select placeholder="Grupo" disabled={isView}>
              {tagsOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={4}>
          <Form.Item name="duration">
            <Input
              placeholder="Tempo de Duração"
              maxLength={50}
              disabled={isView}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={2}>
          <Form.Item name="restricted" valuePropName="checked">
            <Checkbox disabled={isView} disabled={disabled}>
              Restrito
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={2}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView}>Ativo</Checkbox>
          </Form.Item>
        </Col>

        <Col xs={24} md={2}>
          <Form.Item name="freemium" valuePropName="checked">
            <Checkbox disabled={isView}>Freemium</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space>
          <Button
            type="primary"
            onClick={() => openModalStudyPlans()}
            disabled={isView}
          >
            Incluir Planos de Estudo
          </Button>
        </Space>
      </Row>

      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Planos de Estudo" key="1">
              <Table
                columns={columnsStudyPlans}
                dataSource={internalStudyPlanDataSource}
                size="middle"
                pagination={false}
                disabled={isView}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space>
          <Button
            type="primary"
            onClick={() => openModalQuestions()}
            disabled={isView}
          >
            Incluir Pergunta
          </Button>
        </Space>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Perguntas" key="1">
              <Table
                columns={columnsQuestionsInternal}
                dataSource={internalQuestionsDataSource}
                size="middle"
                pagination={false}
                disabled={isView}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/simulado">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>

      <Divider />

      {/* MODAL PLANOS */}
      <Modal
        visible={visibleModalStudyPlans}
        title="Inclusão de Planos de Estudo"
        width={1000}
        onCancel={() => closeModalStudyPlans()}
        onOk={() => saveStudyPlansHistory()}
        footer={[
          <Button key="back" onClick={() => closeModalStudyPlans()}>
            Fechar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => closeModalStudyPlans()}
          >
            Confirmar
          </Button>,
        ]}
      >
        <Form form={formModalStudyPlans} onFinish={searchStudyPlan}>
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Form.Item name="name">
                <Input placeholder="Plano de Estudos" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align="end">
            <Col xs={24} md={1}>
              <Tooltip title="Pesquisar">
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SearchOutlined />}
                />
              </Tooltip>
            </Col>
            <Col xs={24} md={1}>
              <Tooltip title="Redefinir Filtros">
                <Button
                  type="primary"
                  icon={<RedoOutlined />}
                  onClick={resetFilterStudyPlan}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Table
                filter={filterStudyPlans}
                dataSource={modalDataSourceStudyPlans}
                columns={columsStudyPlanSelected}
                loading={loadingTable}
                pagination={{
                  current: paginationStudyPlans.pageNumber,
                  pageSizeOptions: [5, 10],
                  onChange: (pageNumber, pageSize) => {
                    setPaginationStudyPlans((pagination) => ({
                      pageNumber:
                        pagination.pageSize !== pageSize ? 1 : pageNumber,
                      pageSize,
                      fromFilter: false,
                    }))
                  },
                  total: totalRecords1,
                  showSizeChanger: true,
                }}
              />
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* MODAL PERGUNTAS */}
      <Modal
        visible={visibleModalQuestions}
        title="Inclusão de Perguntas"
        width={1000}
        onCancel={() => closeModalQuestions()}
        onOk={() => saveModalQuestions()}
        footer={[
          <Button key="back" onClick={() => closeModalQuestions()}>
            Fechar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => closeModalQuestions()}
          >
            Confirmar
          </Button>,
        ]}
      >
        <Form form={formModalQuestion} onFinish={searchQuestions}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item name="DisciplineId">
                <Select
                  showSearch
                  placeholder="Disciplina"
                  options={disciplines}
                  filterOption={(input, option) => {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="TestId">
                <Select
                  showSearch
                  placeholder="Simulado"
                  options={tests}
                  filterOption={(input, option) => {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item name="Statement">
                <TextArea
                  placeholder="Enunciado"
                  autoSize={{ minRows: 2, maxRows: 2 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item name="Tag">
                <Select showSearch placeholder="Termos">
                  {tagsOptionsInsideModal}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16} align="end">
            <Col xs={24} md={1}>
              <Tooltip title="Pesquisar">
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SearchOutlined />}
                />
              </Tooltip>
            </Col>
            <Col xs={24} md={1}>
              <Tooltip title="Redefinir Filtros">
                <Button
                  type="primary"
                  icon={<RedoOutlined />}
                  onClick={resetFilterQuestions}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Table
                filter={filterQuestions}
                dataSource={modalDataSourceQuestions}
                columns={columnsQuestionsModal}
                loading={loadingTableQuestions}
                pagination={{
                  current: paginationQuestions.pageNumber,
                  pageSizeOptions: [5, 10],
                  onChange: (pageNumber, pageSize) => {
                    setPaginationQuestions((pagination) => ({
                      pageNumber:
                        pagination.pageSize !== pageSize ? 1 : pageNumber,
                      pageSize,
                      fromFilter: false,
                    }))
                  },
                  total: totalRecords2,
                  showSizeChanger: true,
                }}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  )
}

export default TestFormComponent
