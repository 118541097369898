import api from '~/services/api'
import removeMask from '~/utils/remove-mask'

const services = {
  getPartnerSchoolById(id) {
    return api.get(`/partnerschool/${id}`)
  },

  partnerSchoolAdd(data) {
    const {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    } = data

    return api.post('/partnerschool', {
      schoolName,
      cnpj: cnpj && removeMask(cnpj),
      cep: cep && removeMask(cep),
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber: phoneNumber && removeMask(phoneNumber),
      generalObservations,
      active,
      studyPlanIds,
    })
  },

  partnerSchoolUpdate(data) {
    const {
      idPartnerSchool,
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    } = data

    return api.put(`/partnerschool/${idPartnerSchool}`, {
      schoolName,
      cnpj: cnpj && removeMask(cnpj),
      cep: cep && removeMask(cep),
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber: phoneNumber && removeMask(phoneNumber),
      generalObservations,
      active,
      studyPlanIds,
    })
  },

  partnerSchoolDelete(id) {
    return api.delete(`/partnerschool/${id}`)
  },

  studyPlan(data) {
    return api.get('/study-plan/control/autocomplete', {
      params: { Active: data },
    })
  },
}

export default services
