import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Collapse, Form, Input, Tooltip, Button, Tag } from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons'

import services from '../group.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function GroupListComponent() {
  const [filter, setFilter] = useState({})

  const history = useHistory()
  const [form] = Form.useForm()

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/grupo-usuario/${id}/visualizacao`)
  }

  function edit(id) {
    history.push(`/grupo-usuario/${id}/edicao`)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    })
  }

  function renderActions(id) {
    return (
      <Button.Group>
        <PermittedAction tag="RoleList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="RoleEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="RoleDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (id) => renderActions(id),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    setFilter(values)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item name="name">
                  <Input placeholder="Nome Completo" maxLength={50} />
                </Form.Item>
              </Col>

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction tag="RoleCreate">
          <Tooltip title="Incluir">
            <Link to="/grupo-usuario/inclusao">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      <Table baseURL="/role" filter={filter} columns={columns} />
    </Space>
  )
}

export default GroupListComponent
