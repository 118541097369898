import React, { useEffect, useState, useMemo } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Table,
  Tooltip,
  Collapse,
  Tabs,
  Modal,
  Checkbox,
} from 'antd'
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons'
import services from '../studyplan.services'
import notification from '~/shared/Notification'
import PermittedAction from '~/shared/PermittedAction'
import ModalLessons from './studyplanlessons.week-modal.component'

function StudyPlanWeek(props) {
  const { Option, OptGroup } = Select
  const { Panel } = Collapse
  const { TabPane } = Tabs
  const {
    studyPlanComplete,
    idStudyPlan,
    disciplines,
    subjects,
    typeLesson,
    getSubjects,
    studyPlanStepType,
    getstudyPlanComplete,
    isView,
  } = props

  const [studyPlanObject, setStudyPlanObject] = useState(studyPlanComplete)
  const [insideVisibleModal, setVisibleModal] = useState(false)
  const [studyPlanStepId, setStudyPlanStepId] = useState(null)
  const [nameWeek, setNameWeek] = useState('')

  useEffect(() => setStudyPlanObject(studyPlanComplete), [studyPlanComplete])

  useEffect(() => {
    if (!insideVisibleModal) {
      getstudyPlanComplete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insideVisibleModal])

  function validateName(day) {
    switch (day) {
      case 0:
        return 'Domingo'
      case 1:
        return 'Segunda'
      case 2:
        return 'Terça'
      case 3:
        return 'Quarta'
      case 4:
        return 'Quinta'
      case 5:
        return 'Sexta'
      case 6:
        return 'Sábado'
      default:
        return ''
    }
  }

  function callModalDelete(params, type) {
    const configModalDelete = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          if (type === 'lesson') {
            services
              .deleteLesson(params)
              .then(() => {
                notification.success('Excluído com sucesso.')
                window.location.reload()
                resolve()
              })
              .catch(reject)
          } else if (type === 'discipline') {
            services
              .deleteDiscipline(params)
              .then(() => {
                notification.success('Excluído com sucesso.')
                window.location.reload()
                resolve()
              })
              .catch(reject)
          } else if (type === 'week') {
            services
              .deleteWeek(params)
              .then(() => {
                notification.success('Excluído com sucesso.')
                window.location.reload()
                resolve()
              })
              .catch(reject)
          }
        })
      },

      onCancel() { },
    }

    Modal.confirm(configModalDelete)
  }

  function onChangeTabs(targetKey, action, stepId) {
    const paramsDelete = {
      StudyPlanId: idStudyPlan,
      StudyPlanStepId: stepId,
      DisciplineId: Number(targetKey),
    }

    if (action === 'remove') {
      callModalDelete(paramsDelete, 'discipline')
    }
  }

  function deleteWeek(week) {
    const params = {
      studyPlanId: week.studyPlanId,
      studyPlanStepId: week.studyPlanStepId,
    }

    callModalDelete(params, 'week')
  }

  function deleteLesson(rowComplete) {
    const paramsDelete = {
      StudyPlanId: rowComplete.studyPlanId,
      StudyPlanStepId: rowComplete.studyPlanStepId,
      DisciplineId: rowComplete.disciplineId,
      DisciplineSubjectId: rowComplete.disciplineSubjectId,
      DisciplineLessonId: rowComplete.disciplineLessonId,
    }

    callModalDelete(paramsDelete, 'lesson')
  }

  function renderActions(row) {
    return (
      <Button.Group>
        <PermittedAction tag="StudyPlanDelete">
          <Tooltip title="Excluir">
            <Button
              id={row.disciplineLessonId}
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => deleteLesson(row)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function handleSendeditFreeCheck(row) {
    
    const paramsFree = {
      StudyPlanID : row.studyPlanId,
      StudyPlanStepID : row.studyPlanStepId,
      DisciplineID : row.disciplineId,
      DisciplineSubjectid : row.disciplineSubjectId,
      DisciplineLessonID : row.disciplineLessonId,
    }
    services.updateFreeLesson(paramsFree)
  }

  //Checkbox customizado para freemium
  class CustCheckbox extends Checkbox {
    constructor(props) {
      super(props);
      this.state = {
        isChecked: props.check,
        row: props.rowEdit
      };
    }

    toggleChange = () => {
      handleSendeditFreeCheck(this.state.row);
      this.setState({
        isChecked: !this.state.isChecked,
      });
    }

    render() {
      return (
        <Checkbox disabled={isView} checked={this.state.isChecked} id={this.state.row.disciplineLessonId} onChange={this.toggleChange}></Checkbox>
      );
    }

  }

  function renderFreemiumCK(row) {
    
    return (<CustCheckbox   check={row.lessonFreemium} rowEdit={row} />)

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps

  let columns = [
    {
      title: "Free",
      align: 'center',
      
      render: (row) => renderFreemiumCK(row),
    },
    {
      title: 'Aula',
      dataIndex: 'disciplineLessonName',
    },
    {
      title: 'Tipo Aula',
      dataIndex: 'disciplineLessonTypeDescription',
    },
  ]

  if (!isView) {
    columns = [
      ...columns,
      {
        title: 'Ações',
        align: 'center',
        render: (row) => renderActions(row),
      },
    ]
  }

  function callModalLesson(state, week) {
    if (week) {
      setStudyPlanStepId(week ? week.studyPlanStepId : null)
      setNameWeek(week.name)
      setVisibleModal(state)
    }
  }

  async function saveStudyPlanStepType(value, Option, idWeek) {
    const data = {
      studyPlanId: idStudyPlan,
      studyPlanStepId: idWeek,
      studyPlanStepTypeId: value,
    }

    const response = await services.saveStudyPlanStepType(data)
  }

  const Weeks = useMemo(() => {
    return studyPlanObject ? (
      <Collapse defaultActiveKey={1} ghost>
        <ModalLessons
          disciplines={disciplines}
          subjects={subjects}
          typeLesson={typeLesson}
          getSubjects={getSubjects}
          idStudyPlan={idStudyPlan}
          isIncluseLesson
          studyPlanStepId={studyPlanStepId}
          nameWeek={nameWeek}
          modalVisible={insideVisibleModal}
          callModal={setVisibleModal}
        />
        {studyPlanObject.studyPlanStep.map((week) =>
          week ? (
            <Panel
              header={
                <Row gutter={16}>
                  <Col xs={24} md={22}>
                    {week.name}
                  </Col>
                  {!isView && (
                    <Col xs={24} md={2}>
                      <Tooltip title="Excluir semana">
                        <Button
                          type="link"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => deleteWeek(week)}
                        />
                      </Tooltip>
                      <Tooltip title="Incluir aulas">
                        <Button
                          type="link"
                          icon={<FileAddOutlined />}
                          onClick={() =>
                            week ? callModalLesson(true, week) : ''
                          }
                        />
                      </Tooltip>
                    </Col>
                  )}
                </Row>
              }
              key={week.studyPlanStepId}
            >
              <Form>
                <Row gutter={16} justify="end">
                  <Col xs={24} md={10}>
                    <Form.Item
                      key={week.studyPlanStepTypeId}
                      name="studyPlanStepTypeId"
                    >
                      <Select
                        defaultValue={week.studyPlanStepTypeId}
                        placeholder="Liberação Semana"
                        allowClear
                        options={studyPlanStepType}
                        onChange={(value, Option) =>
                          saveStudyPlanStepType(
                            value,
                            Option,
                            week.studyPlanStepId,
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Tabs key="1" tabPosition="left">
                {week.studyPlanStepItem.map((stepItem) => (
                  <TabPane
                    tab={validateName(stepItem.dayOfWeek)}
                    key={stepItem.dayOfWeek}
                  >
                    <Tabs
                      defaultActiveKey="1"
                      type="editable-card"
                      hideAdd
                      closable
                      onEdit={(targetKey, action) =>
                        onChangeTabs(targetKey, action, week.studyPlanStepId)
                      }
                    >
                      {stepItem.disciplines != null
                        ? stepItem.disciplines.map((disciplineSelected) => (
                          <TabPane
                            tab={disciplineSelected.disciplineName}
                            key={disciplineSelected.disciplineId}
                          >
                            <Tabs defaultActiveKey="1">
                              {disciplineSelected.subjects.map((subject) => (
                                <TabPane
                                  tab={subject.disciplineSubjectName}
                                  key={subject.disciplineSubjectId}
                                >
                                  <Table
                                    size="small"
                                    columns={columns}
                                    dataSource={subject.lessons}
                                  />
                                </TabPane>
                              ))}
                            </Tabs>
                          </TabPane>
                        ))
                        : ''}
                    </Tabs>
                  </TabPane>
                ))}
              </Tabs>
            </Panel>
          ) : (
            ''
          ),
        )}
      </Collapse>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyPlanObject, columns])

  return <Form>{Weeks}</Form>
}

export default StudyPlanWeek
