import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import FirstAcessFormComponent from '../components/firstaccess-form.component'
import servicesNewPassword from '../../newpassword/newpassword.services'
import notification from '../../../shared/Notification'

function FirstAccessContainer() {
  const [loading, setLoading] = useState(false)

  const parameters = new URLSearchParams(window.location.search)
  const emailUser = parameters.get('email')
  const codeUser = parameters.get('codigo')

  const history = useHistory()

  async function onSubmit(values) {
    const data = {
      resetPasswordCode: values.resetPasswordCode,
      newPassword: values.newPassword,
      isFirstAccess: true,
    }

    setLoading(true)

    try {
      await servicesNewPassword.newPassword(data)
      notification.success('Senha alterada com sucesso.')

      history.push('/login')
    } catch {
      setLoading(false)
    }
  }

  return (
    <FirstAcessFormComponent
      loading={loading}
      onSubmit={onSubmit}
      emailUser={emailUser}
      codeUser={codeUser}
    />
  )
}

export default FirstAccessContainer
