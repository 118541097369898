import styled from 'styled-components'

const PlanWrapper = styled.section`
  @media (max-width: 2000px) {
    &.plan-wrapper .ant-table-thead > tr > th,
    &.plan-wrapper .ant-table-tbody > tr > td {
      padding: 5px;
    }
  }
`

const FormWrapper = styled.div`
  .data-col {
    .ant-picker.ant-picker-range {
      width: 100%;
    }
  }
`

const ButtonIcon = styled.div`
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.color === 'confirmed'
      ? props.theme.success
      : props.color === 'canceled'
      ? props.theme.error
      : props.theme.warning};
`

const GraphicSection = styled.section`
  margin: 40px 0 40px 0;
  width: 100%;
  display: block;

  .bold {
    font-weight: bold;
  }

  .ant-table-tbody .ant-table-row.ant-table-row-level-0 td {
    width: 28%;
  }
`

const GraphicCol = styled.div``

const GraphicSummaryChartCol = styled.div`
  .ant-table-footer {
    padding: 0;
    border: 0;
  }
`

const SummaryFooter = styled.div`
  .ant-table-tbody .ant-table-row.ant-table-row-level-0 td {
    width: 28%;
  }
`

const SummaryTable = styled.table``

const SummaryTbody = styled.tbody``

const SummaryTr = styled.tr``

const SummaryTd = styled.td``

const LegendWrapper = styled.section`
  display: flex;
  align-content: center;
`

const LegendItem = styled.div`
  margin: 10px;
  padding: 5px;
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.color === 'confirmed'
      ? props.theme.success
      : props.color === 'canceled'
      ? props.theme.error
      : props.theme.warning};
  display: flex;
  align-items: center;
`

export {
  PlanWrapper,
  FormWrapper,
  ButtonIcon,
  GraphicSection,
  GraphicCol,
  GraphicSummaryChartCol,
  SummaryFooter,
  SummaryTable,
  SummaryTbody,
  SummaryTr,
  SummaryTd,
  LegendWrapper,
  LegendItem,
}
