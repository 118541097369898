import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Select,
  Tag,
  Button,
  Typography,
  Modal,
} from 'antd'

import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'
import notification from '~/shared/Notification'

import services from '../signature-service'

import cnpjMask from '~/utils/cnpjMask'

function SignatureListComponent({ handleCallbackState }) {
  const [filter, setFilter] = useState()
  const { Text } = Typography
  const { Option } = Select

  useEffect(() => {
    function getFilter() {
      setFilter({})
    }

    // GetAll()

    getFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function GetAll() {
    const all = await services.getAllSignature()
    const { data } = all;
    setFilter(data)
    console.log('!!!!: ', data)

  }


  async function getSignatureDeleteId(id) {
    const configModalDelete = {
      title: 'Deseja deletar este plano?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          services
            .deleteSignature(id)
            .then(() => {
              notification.success('Deletado com sucesso!')
              setFilter({})
              resolve()
            })
            .catch(reject)
        })
      },
      onCancel() { },
    }

    Modal.confirm(configModalDelete)
  }

  async function getValuesEditForm(data) {
    const tipo = 'E'    
    handleCallbackState(data, tipo)   
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => getValuesEditForm(data)}
            />
          </Tooltip>

          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => getSignatureDeleteId(data.id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderTag(active) {
    return active == 2 ? (
      <Tag color="green">Vencimento por Data</Tag>
    ) : (
      <Tag color="blue">Vencimento por Mês</Tag>
    )
  }

  function renderTagStatus(active) {
    return active == false ? (
      <Tag color="red">Inativo</Tag>
    ) : (
      <Tag color="green">Ativo</Tag>
    )
  }

  const columns = [
    {
      title: 'ID Plano',
      dataIndex: 'id',
    },
    {
      title: 'Nome do Plano',
      dataIndex: 'name',
    },
    {
      title: 'Descrição',
      dataIndex: 'shortDescription',
    },
    {
      title: 'Duração do Plano',
      dataIndex: 'numberOfMounts',
    },
    {
      title: 'Valor do plano',
      dataIndex: 'amount',
    },
    {
      title: 'Desconto',
      dataIndex: 'discount',
    },
    {
      title: 'Tipo de Vencimento',
      dataIndex: 'subscriptionExpirationTypeId',
      render: (active) => renderTag(active),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTagStatus(active),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderActions(data),
    },
  ]

  // function resetFilter() {
  //   formPartnerSchool.resetFields()
  //   setFilter({})
  // }

  function onFinish(values) {
    const { partnerSchoolName, partnerSchoolStatus } = values

    setFilter({
      partnerSchoolName,
      partnerSchoolStatus,
    })
  }

  return (
    <Space direction="vertical">
      {filter && (
        <Table
          baseURL="/subscriptionplan/getall"
          filter={filter}
          columns={columns}
        />
      )}
    </Space>
  )
}

export default SignatureListComponent
