import React from 'react'

import { Tag } from 'antd'

function RenderTag(active) {
  return active ? (
    <Tag color="green">Ativo</Tag>
  ) : (
    <Tag color="red">Inativo</Tag>
  )
}

export default RenderTag
