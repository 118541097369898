import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import notification from '~/shared/Notification'

const modalMessages = {
  delete(service, id, action) {
    const config = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          service(id)
            .then(() => {
              notification.success('Excluído com sucesso.')
              if (action) action()

              resolve()
            })
            .catch((error) => {
              Modal.destroyAll()
              reject(error)
            })
        })
      },

      onCancel() {},
    }

    Modal.confirm(config)
  },
}

export default modalMessages
