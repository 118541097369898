import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Tag,
  Select,
  PageHeader,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons'

import services from '../disciplinesubjects.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function DisciplineSubjectListComponent(props) {
  const { idDiscipline, discipline } = props

  const initialFilter = {
    name: '',
    active: null,
    disciplineId: idDiscipline,
  }

  const [filter, setFilter] = useState(initialFilter)
  const { Option, OptGroup } = Select
  const history = useHistory()
  const [form] = Form.useForm()

  function resetFilter() {
    form.resetFields()

    setFilter(initialFilter)
  }

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/disciplina/${idDiscipline}/materia/${id}/visualizacao`)
  }

  function edit(id) {
    history.push(`/disciplina/${idDiscipline}/materia/${id}/edicao`)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      resetFilter()
    })
  }

  function lessons(idSubject) {
    history.push(`/disciplina/${idDiscipline}/materia/${idSubject}/aulas`)
  }

  async function updateSequence(objDisciplineSubject, type) {
    let nextSequence = 0
    if (type === 'up') {
      nextSequence = objDisciplineSubject.displaySequence - 1
    } else if (type === 'down') {
      nextSequence = objDisciplineSubject.displaySequence + 1
    }

    const data = {
      id: objDisciplineSubject.id,
      targetSequence: nextSequence,
    }

    try {
      await services.updateSequence(data)
      resetFilter()
    } catch {
      // doError
    }
  }

  function renderActions(displaySequence, disciplineSubject) {
    return (
      <Button.Group>
        <PermittedAction tag="DisciplineList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(disciplineSubject.id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="DisciplineEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(disciplineSubject.id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="DisciplineDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(disciplineSubject.id)}
            />
          </Tooltip>
        </PermittedAction>

        {disciplineSubject.firstLastFlag !== 'first' && (
          <Tooltip title="Subir">
            <Button
              type="link"
              icon={<UpCircleOutlined />}
              onClick={() => updateSequence(disciplineSubject, 'up')}
            />
          </Tooltip>
        )}

        {disciplineSubject.firstLastFlag !== 'last' && (
          <Tooltip title="Descer">
            <Button
              type="link"
              icon={<DownCircleOutlined />}
              onClick={() => updateSequence(disciplineSubject, 'down')}
            />
          </Tooltip>
        )}

        <Tooltip title="Aulas">
          <Button
            type="link"
            icon={<VideoCameraAddOutlined />}
            onClick={() => lessons(disciplineSubject.id)}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Ordem Exibição',
      dataIndex: 'displaySequence',
    },
    {
      title: 'Matéria',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (displaySequence, disciplineSubject) =>
        renderActions(displaySequence, disciplineSubject),
    },
  ]

  function GetValueFromStatus(selectedStatus) {
    switch (selectedStatus) {
      case '1':
        return true
      case '0':
        return false
      default:
        return null
    }
  }

  function goBack() {
    history.push(`/disciplina`)
  }

  function onFinish(values) {
    const activeResult = GetValueFromStatus(values.status)

    const data = {
      name: values.name,
      active: activeResult,
      disciplineId: idDiscipline,
    }

    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <PageHeader
        ghost={false}
        onBack={goBack}
        title={discipline.name}
        subTitle="Lista de Matérias"
      />
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item name="name">
                  <Input placeholder="Matéria" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={4} md={8}>
                <Form.Item name="status">
                  <Select placeholder="Status" allowClear>
                    <OptGroup label="Status">
                      <Option value="1">Ativo</Option>
                      <Option value="0">Inativo</Option>
                      <Option value="null">Todos</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <Row justify="end">
        <Tooltip title="Incluir">
          <Link to="materia/inclusao">
            <Button type="primary" icon={<PlusOutlined />} />
          </Link>
        </Tooltip>
      </Row>
      <Table baseURL="/subject" filter={filter} columns={columns} />
    </Space>
  )
}

export default DisciplineSubjectListComponent
