import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'
import StudyPlanViewComponent from '../components/studyplan-form.component'
import services from '../studyplan.services'
import servicesDisciplines from '../../disciplines/discipline.services'
import servicesSubjects from '../../disciplinesubjects/disciplinesubjects.services'
import servicesLessons from '../../disciplinelessons/disciplinelesson.services'

function StudyPlanViewContainer() {
  const [form] = Form.useForm()
  const [studyPlanType, setStudyPlanType] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [subjects, setSubjects] = useState([])
  const [lessons, setLessons] = useState([])
  const [typeLesson, setTypeLesson] = useState([])
  const [studyPlanComplete, setStudyPlanComplete] = useState({})
  const [studyPlanStepType, setStudyPlanStepType] = useState([])

  const [loadingData, setLoadingData] = useState(true)

  const params = useParams()
  const { id } = params

  const renderTitle = (title: string) => {
    return <span>{title}</span>
  }

  function renderItemForSubjects(subjectsList) {
    const mapperOptionsSubjects = subjectsList.map((subjectOption) => {
      return {
        value: subjectOption.subjectId,
        label: subjectOption.subjectName,
      }
    })

    return mapperOptionsSubjects
  }

  async function getStudyPlanComplete() {
    const responseStudyPlan = await services.getById(id)

    setStudyPlanComplete(responseStudyPlan.data)
  }

  async function getStudyPlanType() {
    const responsePlanType = await services.getStudyPlanType()

    const mappedStudyPlanType = responsePlanType.data.map((planType) => {
      return {
        label: planType.description,
        value: planType.id,
      }
    })
    setStudyPlanType(mappedStudyPlanType)
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getSubjects(listIdDisciplines) {
    let disciplineFilters = listIdDisciplines

    if (disciplineFilters === undefined) {
      disciplineFilters = {}
    }

    const responseDiscipline = await servicesSubjects.subjectsActive(
      disciplineFilters,
    )

    const mappedSubjects = responseDiscipline.data.map((subject) => {
      return {
        label: renderTitle(subject.disciplineName),
        options: renderItemForSubjects(subject.subjects),
      }
    })

    setSubjects(mappedSubjects)
  }

  async function getLessons(filterLessons) {
    const responseLessons = await services.lessonsActives(filterLessons)

    const mappedLessons = responseLessons.data.map((lesson) => {
      return { label: lesson.disciplineLessonName, value: lesson.disciplineId }
    })

    setLessons(mappedLessons)
  }

  async function getTypeLesson() {
    const responseTypeLesson = await servicesLessons.getTypeLesson()

    const mappedTypes = responseTypeLesson.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setTypeLesson(mappedTypes)
  }

  async function getStudyPlanStepType() {
    const responseStudyPlanStepType = await services.getStudyPlanStepType()

    const mappedStepType = responseStudyPlanStepType.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setStudyPlanStepType(mappedStepType)
  }

  useEffect(() => {
    getStudyPlanComplete()
    getStudyPlanType()
    getDisciplines()
    getSubjects()
    getTypeLesson()
    setLoadingData(false)
    getStudyPlanStepType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loadingData) return <Skeleton active />

  return (
    <StudyPlanViewComponent
      type="V"
      form={form}
      studyPlanComplete={studyPlanComplete}
      studyPlanType={studyPlanType}
      disciplines={disciplines}
      subjects={subjects}
      lessons={lessons}
      typeLesson={typeLesson}
      getSubjects={getSubjects}
      getLessons={getLessons}
      getStudyPlanComplete={getStudyPlanComplete}
      studyPlanStepType={studyPlanStepType}
    />
  )
}

export default StudyPlanViewContainer
