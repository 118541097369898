import api from '~/services/api'

const services = {
  save(data) {
    return api.post('/question', data)
  },
  getById(id) {
    return api.get(`/question/${id}`)
  },
  update(data) {
    return api.put('/question', data)
  },
  delete(id) {
    return api.delete(`/question/${id}`)
  },
  postImage(data) {
    return api.post('/resource/image/upload', data)
  },
  getTags() {
    return api.get(`/question/tag`)
  },
  getTests() {
    return api.get(`/question/test`)
  },
  getFilter(data) {
    return api.get('/question', data)
  },
}

export default services
