import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import LiveClassesFormComponent from '../components/liveClasses-form.component'

import services from '../liveClasses.services'

function LiveClassesViewContainer() {
  const [liveClass, setLiveClass] = useState({})
  const [loadingData, setLoadingData] = useState(true)

  const [form] = Form.useForm()

  const params = useParams()

  const { idLiveClass } = params

  useEffect(() => {
    async function getData() {
      const { data } = await services.getLiveClassesById(idLiveClass)

      setLiveClass(data)

      setLoadingData(false)
    }

    getData()
  }, [idLiveClass])

  if (loadingData) return <Skeleton active />

  return <LiveClassesFormComponent type="V" form={form} liveClass={liveClass} />
}

export default LiveClassesViewContainer
