import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Tag,
  Select,
} from 'antd'
import { SearchOutlined, RedoOutlined, EyeOutlined } from '@ant-design/icons'

import PermittedAction from '~/shared/PermittedAction'

import services from '../forumPost.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'

function ForumPostListComponent() {
  const [filter, setFilter] = useState({})
  const [postStatusList, setPostStatusList] = useState([])
  const [postStatusOptions, setPostStatusOptions] = useState([])
  const [disciplinesList, setDisciplinesList] = useState([])
  const [disciplinesOptions, setDisciplinesOptions] = useState([])
  const { Option } = Select
  const history = useHistory()
  const [form] = Form.useForm()

  async function getPostStatusList() {
    const response = await services.postStatusList()

    const mapPostStatus = response.data.map((status) => {
      return {
        value: status.id,
        label: status.description,
      }
    })

    setPostStatusList(mapPostStatus)

    const statusOptions = mapPostStatus.map((status) => (
      <Option key={status.value} value={status.value}>
        {status.label}
      </Option>
    ))

    setPostStatusOptions(statusOptions)
  }

  async function getPostDisciplineList() {
    const response = await services.disciplineActive()

    const mapDiscipline = response.data.map((status) => {
      return {
        value: status.id,
        label: status.name,
      }
    })

    setDisciplinesList(mapDiscipline)

    const disciplinesOptions = mapDiscipline.map((status) => (
      <Option key={status.value} value={status.value}>
        {status.label}
      </Option>
    ))

    setDisciplinesOptions(disciplinesOptions)
  }

  useEffect(() => {
    getPostStatusList()
    getPostDisciplineList()
  }, [])

  function tagsRender(tags) {
    return tags.map((tag) => (
      <Tag key={tag.forumPostTagId}>{tag.forumPostTagDescription}</Tag>
    ))
  }

  function forumPostView(id) {
    history.push(`/duvida/${id}/visualizacao`)
  }

  function renderActions(id) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => forumPostView(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Título',
      dataIndex: 'subject',
    },
    {
      title: 'Criado por',
      dataIndex: 'createdBy',
    },
    {
      title: 'Data da criação',
      dataIndex: 'formattedCreateDate',
    },
    {
      title: 'Termos',
      dataIndex: 'tags',
      render: (tags) => tagsRender(tags),
    },
    {
      title: 'Respondida por',
      dataIndex: 'repliedBy',
    },
    {
      title: 'Data da resposta',
      dataIndex: 'formattedReplyDate',
    },
    {
      title: 'Ações',
      dataIndex: 'forumPostId',
      align: 'center',
      render: (id) => renderActions(id),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    const data = {
      DisciplineId: values.discipline,
      SearchTerm: values.forumPost,
      ForumPostStatusId: values.status,
    }

    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item name="forumPost">
                  <Input placeholder="Pesquisar" maxLength={50} />
                </Form.Item>
              </Col>

              {!!disciplinesList && (
                <Col xs={4} md={6}>
                  <Form.Item name="discipline">
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      }}
                      placeholder="Disciplina"
                      allowClear
                    >
                      {disciplinesOptions}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {!!postStatusList && (
                <Col xs={4} md={6}>
                  <Form.Item name="status">
                    <Select placeholder="Status" allowClear>
                      {postStatusOptions}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Table baseURL="/forum/post" filter={filter} columns={columns} />
    </Space>
  )
}

export default ForumPostListComponent
