import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DisciplineSubjectListComponent from '../components/disciplinesubjects-list.component'
import services from '../../disciplines/discipline.services'

function DisciplineSubjectListContainer() {
  const params = useParams()
  const { iddiscipline } = params
  const [disciplineData, setDisciplineData] = useState({})

  useEffect(() => {
    async function getData() {
      const response = await services.getById(iddiscipline)
      setDisciplineData(response.data)
    }

    getData()
  }, [iddiscipline])

  return (
    <DisciplineSubjectListComponent
      idDiscipline={iddiscipline}
      discipline={disciplineData}
    />
  )
}

export default DisciplineSubjectListContainer
