import api from '~/services/api'

const services = {
  getPermissions() {
    return api.get('/permission')
  },

  save(data) {
    return api.post('/role', data)
  },
  getById(id) {
    return api.get(`/role/${id}`)
  },
  update(data) {
    return api.put('/role', data)
  },
  delete(id) {
    return api.delete(`/role/${id}`)
  },
  getRolesActive() {
    return api.get('/role/active')
  },
}

export default services
