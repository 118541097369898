import EssayList from './containers/essay-list.container'

const routes = [
  {
    path: '/redacao',
    // tag: 'CanStartMark',
    breadcrumb: [
      {
        breadcrumbName: 'Redações',
      },
    ],
    authenticated: true,
    component: EssayList,
  },
]

export default routes
