import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Divider,
  Space,
  Button,
  AutoComplete,
  Typography,
  Checkbox,
  DatePicker,
} from 'antd'

import { format, parseISO } from 'date-fns'
import moment from 'moment'

import services from '../studentManagement.services'

import StudentInformationComponent from './studentInformation-component'

import { FormWrapper } from '../studentManagement.styles'

function StudentManagementPartnerSchoolFormComponent(props) {
  const [studentId, setStudentId] = useState('')
  const [partnerSchool, setPartnerSchool] = useState([])
  const [partnerSchoolId, setPartnerSchoolId] = useState('')
  // const [studyPlan, setStudyPlan] = useState([])
  // const [studyPlanId, setStudyPlanId] = useState('')
  const [studyPlanExpirationDate, setStudyPlanExpirationDate] = useState('')
  const [form] = Form.useForm()
  const { type, loadingSubmit, school, onSubmit } = props
  const { Text } = Typography
  const { Option } = AutoComplete
  const params = useParams()
  const { idstudent } = params

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && school) {
      // school.studyPlanName = `${school.studyPlanName} (${school.studyPlanShortDescription})`

      form.setFieldsValue(school)

      if (school.studyPlanExpirationDate) {
        setStudyPlanExpirationDate(
          moment(school.studyPlanExpirationDate).utc().format('yyyy-MM-DD'),
        )
      }
    }
  }, [isEdit, isView, school, form])

  useEffect(() => {
    setStudentId(idstudent)
  }, [idstudent])

  useEffect(() => {
    // async function getStudyPlans() {
    //   const response = await services.getStudyPlan()

    //   const studyPlanList = response.data.map((item) => (
    //     <Option
    //       id={item.id}
    //       key={item.id}
    //       value={`${item.name} (${item.shortDescription})`}
    //     >
    //       {item.name} ({item.shortDescription})
    //     </Option>
    //   ))

    //   setStudyPlan(studyPlanList)
    // }

    // getStudyPlans()

    async function getPartnerSchools() {
      const response = await services.getPartnerSchool()

      const partnerSchoolList = response.data.map((item) => (
        <Option id={item.id} key={item.id} value={item.schoolName}>
          {item.schoolName}
        </Option>
      ))

      setPartnerSchool(partnerSchoolList)
    }

    getPartnerSchools()
  }, [])

  useEffect(() => {
    if (isNew) form.setFieldsValue({ studyPlanActive: true })
  }, [isNew, form])

  async function onFinish(values) {
    const data = {
      ...values,
      studentId,
      partnerSchoolId,
      // studyPlanId,
      studyPlanExpirationDate,
    }

    onSubmit(data)
  }

  function disabledDate(current) {
    const minDate = new Date()
    const maxDate = new Date()

    return current < minDate.setDate(maxDate.getDate() - 1)
  }

  function getDateFormat(dateValue) {
    if (dateValue) {
      const date = parseISO(dateValue)

      return format(date, 'yyyy-MM-dd')
    }

    return ''
  }

  function handlePartnerSchoolChange(_, option) {
    setPartnerSchoolId(option.id)
  }

  // function handleStudyPlanChange(_, option) {
  //   setStudyPlanId(option.key)
  // }

  function handleExpirationDate(_, dateString) {
    setStudyPlanExpirationDate(getDateFormat(dateString))
  }

  return (
    <FormWrapper>
      {!isNew && school && (
        <StudentInformationComponent
          studentInformationId={studentId}
          baseURL="study-plan-by-partner-school"
          studentInformationPlanId={school.studentStudyPlanId}
        />
      )}

      <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={9}>
            <Text>Escola parceira</Text>

            <Form.Item name="partnerSchoolName" rules={requiredRule}>
              <AutoComplete
                placeholder="Escola Parceira"
                dataSource={partnerSchool}
                onSelect={(val, option) =>
                  handlePartnerSchoolChange(val, option)
                }
                value={partnerSchool.id}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={isView || isEdit}
                allowClear
              />
            </Form.Item>
          </Col>

          {/* <Col xs={24} md={12}>
            <Text>Planos Estudos</Text>

            <Form.Item name="studyPlanName" rules={requiredRule}>
              <AutoComplete
                placeholder="Planos Estudos"
                dataSource={studyPlan}
                onSelect={(val, option) => handleStudyPlanChange(val, option)}
                value={studyPlan.id}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={isView || isEdit}
                allowClear
              />
            </Form.Item>
          </Col> */}

          <Col xs={24} md={9}>
            <Text>Data Expiração</Text>

            {(isEdit || isView) && studyPlanExpirationDate ? (
              <>
                <Form.Item name="expirationDate">
                  <DatePicker
                    onChange={handleExpirationDate}
                    defaultValue={moment(studyPlanExpirationDate)}
                    disabled={isView}
                    disabledDate={disabledDate}
                    className="row-100"
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item name="expirationDate" rules={requiredRule}>
                <DatePicker
                  onChange={handleExpirationDate}
                  className="row-100"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            )}
          </Col>

          <Col xs={24} md={6}>
            <Text>Status</Text>

            <Form.Item
              name="studyPlanActive"
              valuePropName="checked"
              rules={requiredRule}
            >
              <Checkbox disabled={isView} defaultChecked>
                Ativo
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to={`/aluno/${idstudent}/plano-e-assinatura/`}>
                {isEdit ? 'Cancelar' : 'Voltar'}
              </Link>
            </Button>

            {!isView && (
              <Button htmlType="submit" type="primary" loading={loadingSubmit}>
                {isNew ? 'Confirmar' : 'Atualizar'}
              </Button>
            )}
          </Space>
        </Row>
      </Form>
    </FormWrapper>
  )
}

export default StudentManagementPartnerSchoolFormComponent
