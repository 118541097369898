import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import PartnerSchoolFormComponent from '../components/partnerSchool-form.component'

import services from '../partnerSchool.services'

function PartnerSchoolViewContainer() {
  const [partnerSchool, setPartnerSchool] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [form] = Form.useForm()
  const params = useParams()
  const { idPartnerSchool } = params

  useEffect(() => {
    async function getData() {
      const response = await services.getPartnerSchoolById(idPartnerSchool)

      setPartnerSchool(response.data)
      setLoadingData(false)
    }

    getData()
  }, [idPartnerSchool])

  if (loadingData) return <Skeleton active />

  return (
    <PartnerSchoolFormComponent
      type="V"
      form={form}
      partnerSchool={partnerSchool}
    />
  )
}

export default PartnerSchoolViewContainer
