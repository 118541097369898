import axios from 'axios'
import notification from '~/shared/Notification'

const baseURL = 'https://api.corujasabia.com.br/api/v1/'

const api = axios.create({
  baseURL,
})

api.defaults.headers.common['X-Application-Key'] = 'webkey123'

function signOut() {
  localStorage.clear()

  window.location.href = '/'
  notification.warning('Sesssão expirada.')
}

function resolveErrors(data) {
  if (data.errors) {
    const listErrors = data.errors
    listErrors.map((messageError) => notification.error(messageError.error))
  } else if (data.message) {
    notification.error(data.message)
  }
}

async function refreshToken() {
  const oldRefreshToken = JSON.parse(localStorage.getItem('@CSBO:refresh'))

  const response = await api.post('/user/token/refresh', {
    refresh: oldRefreshToken,
  })

  const { token, refresh } = response.data

  localStorage.setItem('@CSBO:token', JSON.stringify(token))
  localStorage.setItem('@CSBO:refresh', JSON.stringify(refresh))

  return null
}

api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('@CSBO:token')) || null

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { status, data, config } = error.response

    switch (status) {
      case 400:
        resolveErrors(data)
        break
      case 401:
        if (config.url === '/user/token/refresh') {
          signOut()
          return
        }
        await refreshToken()
        // eslint-disable-next-line consistent-return
        return api.request(config)
      case 403:
        notification.error('Usuário não possui acesso a esse recurso.')
        break
      case 404:
        notification.error('Recurso não encontrado.')
        break
      case 500:
        notification.error(data.message)
        break
      default:
        notification.error('')
        break
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error)
  },
)

export default api
