import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import GroupFormComponent from '../components/discipline-form.component'
import services from '../discipline.services'

import notification from '~/shared/Notification'

function DisciplineNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    const { name, shortName, active, route } = values

    const data = {
      name,
      shortName,
      route,
      active,
    }

    setLoadingSubmit(true)

    try {
      await services.save(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/disciplina')
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <GroupFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default DisciplineNewContainer
