import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Tag,
  Select,
  PageHeader,
  Descriptions,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons'

import services from '../disciplinelesson.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function DisciplineLessonsListComponent(props) {
  const { subject } = props

  const initialFilter = {
    name: '',
    active: null,
    disciplineId: subject.disciplineId,
    disciplineSubjectId: subject.id,
  }

  const [filter, setFilter] = useState(initialFilter)

  const { Option, OptGroup } = Select
  const history = useHistory()
  const [form] = Form.useForm()

  function resetFilter() {
    form.resetFields()
    const initialFilter = {
      name: '',
      active: null,
      disciplineId: subject.disciplineId,
      disciplineSubjectId: subject.id,
    }
    setFilter(initialFilter)
  }

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function goBack() {
    history.push(`/disciplina/${subject.disciplineId}/materia/`)
  }

  function view(id) {
    history.push(
      `/disciplina/${subject.disciplineId}/materia/${subject.id}/aulas/${id}/visualizacao`,
    )
  }

  function edit(id) {
    history.push(
      `/disciplina/${subject.disciplineId}/materia/${subject.id}/aulas/${id}/edicao`,
    )
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      resetFilter()
    })
  }

  async function updateSequence(objDisciplineSubject, type) {
    let nextSequence = 0
    if (type === 'up') {
      nextSequence = objDisciplineSubject.displaySequence - 1
    } else if (type === 'down') {
      nextSequence = objDisciplineSubject.displaySequence + 1
    }

    const data = {
      id: objDisciplineSubject.id,
      targetSequence: nextSequence,
    }

    try {
      await services.updateSequence(data)
      resetFilter()
    } catch {
      // doError
    }
  }

  function renderActions(displaySequence, disciplineLesson) {
    return (
      <Button.Group>
        <PermittedAction tag="DisciplineList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(disciplineLesson.id)}
            />
          </Tooltip>
        </PermittedAction>

        {subject.disciplineId && (
          <PermittedAction tag="DisciplineEdit">
            <Tooltip title="Editar">
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => edit(disciplineLesson.id)}
              />
            </Tooltip>
          </PermittedAction>
        )}

        {subject.disciplineId && (
          <PermittedAction tag="DisciplineDelete">
            <Tooltip title="Excluir">
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => remove(disciplineLesson.id)}
              />
            </Tooltip>
          </PermittedAction>
        )}

        {disciplineLesson.firstLastFlag !== 'first' && (
          <Tooltip title="Subir">
            <Button
              type="link"
              icon={<UpCircleOutlined />}
              onClick={() => updateSequence(disciplineLesson, 'up')}
            />
          </Tooltip>
        )}

        {disciplineLesson.firstLastFlag !== 'last' && (
          <Tooltip title="Descer">
            <Button
              type="link"
              icon={<DownCircleOutlined />}
              onClick={() => updateSequence(disciplineLesson, 'down')}
            />
          </Tooltip>
        )}
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Ordem Exibição',
      dataIndex: 'displaySequence',
    },
    {
      title: 'Aula',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (displaySequence, disciplineLesson) =>
        renderActions(displaySequence, disciplineLesson),
    },
  ]

  function GetValueFromStatus(selectedStatus) {
    switch (selectedStatus) {
      case '1':
        return true
      case '0':
        return false
      default:
        return null
    }
  }

  function onFinish(values) {
    const activeResult = GetValueFromStatus(values.status)

    const data = {
      name: values.name,
      active: activeResult,
      disciplineId: subject.disciplineId,
      disciplineSubjectId: subject.id,
    }

    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <PageHeader
        className="site-page-header"
        onBack={goBack}
        title={subject.name}
        subTitle="Lista de Aulas"
        ghost
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Disciplina">
            {subject.disciplineName}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Seção">
            {subject.disciplineAreaName}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item name="name">
                  <Input placeholder="Aula" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={4} md={8}>
                <Form.Item name="status">
                  <Select placeholder="Status" allowClear>
                    <OptGroup label="Status">
                      <Option value="1">Ativo</Option>
                      <Option value="0">Inativo</Option>
                      <Option value="null">Todos</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <Row justify="end">
        <Tooltip title="Incluir">
          <Link to="aulas/inclusao">
            <Button type="primary" icon={<PlusOutlined />} />
          </Link>
        </Tooltip>
      </Row>
      <Table baseURL="/lesson" filter={filter} columns={columns} />
    </Space>
  )
}

export default DisciplineLessonsListComponent
