import api from '~/services/api'

const services = {
  save(data) {
    return api.post('/discipline', data)
  },
  getById(id) {
    return api.get(`/discipline/${id}`)
  },
  update(data) {
    return api.put('/discipline', data)
  },
  delete(id) {
    return api.delete(`/discipline/${id}`)
  },
  disciplineActive() {
    return api.get(`/discipline/active`)
  },
}

export default services
