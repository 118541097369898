import RenderActionsButton from '~/shared/RenderActionsButton'
import RenderTag from '~/shared/RenderTag'

import getDateTimeFormat from '~/utils/date-time-format'

import services from '../liveClasses.services'

function Columns(resetFilter) {
  const columns = [
    {
      title: 'Disciplina',
      dataIndex: 'disciplineName',
    },
    {
      title: 'Matéria',
      dataIndex: 'disciplineSubjectName',
    },
    {
      title: 'Data',
      dataIndex: 'dateLiveClass',
      render: (date) => getDateTimeFormat(date),
    },
    {
      title: 'Status',
      dataIndex: 'inactive',
      render: (inactive) => RenderTag(!inactive),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) =>
        RenderActionsButton(
          data,
          '/aula-ao-vivo',
          services?.liveClassesDelete,
          resetFilter,
        ),
    },
  ]

  return columns
}

export default Columns
