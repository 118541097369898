import UserListContainer from './containers/user-list.container'
import UserNewContainer from './containers/user-new.container'
import UserEditContainer from './containers/user-edit.container'
import UserViewContainer from './containers/user-view.container'

const routes = [
  {
    path: '/usuario/inclusao',
    tag: 'UserCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/usuario',
        breadcrumbName: 'Usuário',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: UserNewContainer,
  },
  {
    path: '/usuario/:id/edicao',
    tag: 'UserEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/usuario',
        breadcrumbName: 'Usuário',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: UserEditContainer,
  },
  {
    path: '/usuario/:id/visualizacao',
    tag: 'UserList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/usuario',
        breadcrumbName: 'Usuário',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: UserViewContainer,
  },
  {
    path: '/usuario',
    tag: 'UserList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        breadcrumbName: 'Usuário',
      },
    ],
    component: UserListContainer,
  },
]

export default routes
