import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import UserFormComponent from '../components/user-form.component'

import userServices from '../user.services'
import groupServices from '../../groups/group.services'

function UserViewContainer() {
  const [loadingData, setLoadingData] = useState(true)

  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState([])

  const params = useParams()

  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseUser = await userServices.getById(id)
      const responseRoles = await groupServices.getRolesActive()

      setLoadingData(false)

      setUser(responseUser.data)
      setRoles(responseRoles.data)
    }

    getData()
  }, [id])

  if (loadingData) return <Skeleton active />

  return <UserFormComponent type="V" user={user} roles={roles} />
}

export default UserViewContainer
