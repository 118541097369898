/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { Table } from 'antd'
import qs from 'qs'

import api from '~/services/api'

function SharedTable(props) {
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const [dataSource, setDataSource] = useState({ content: [] })

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const { baseURL, filter, columns } = props

  const getDataSource = useCallback(async (filter, pageNumber, pageSize) => {
    setLoading(true)

    const params = Object.entries(filter).reduce((params, [key, value]) => {
      if (value !== undefined) {
        return {
          ...params,
          [key]: value,
        }
      }

      return params
    }, {})

    const response = await api.get(baseURL, {
      params: { ...params, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })

    setLoading(false)

    const { data } = response
    if (data.data) {
      setDataSource(data.data)
    } else {
      setDataSource(data)
    }
   
  }, [])

  useEffect(() => {
    const { pageSize } = pagination

    getDataSource(filter, 1, pageSize)

    if (firstLoad) setFirstLoad(false)

    setPagination((pagination) => ({
      pageNumber: 1,
      pageSize: pagination.pageSize,
      fromFilter: true,
    }))
  }, [filter])

  useEffect(() => {
    const { pageNumber, pageSize, fromFilter } = pagination

    if (firstLoad || fromFilter) return

    getDataSource(filter, pageNumber, pageSize)
  }, [pagination])

  return (
    <Table
      rowKey={(row) => row.id}
      loading={loading}
      dataSource={dataSource.content || dataSource}
      columns={columns}
      pagination={{
        current: pagination.pageNumber,
        pageSizeOptions: [5, 10, 20, 50, 100],
        onChange: (pageNumber, pageSize) => {
          setPagination((pagination) => ({
            pageNumber: pagination.pageSize !== pageSize ? 1 : pageNumber,
            pageSize,
            fromFilter: false,
          }))
        },
        total: dataSource.totalRecords,
        showSizeChanger: true,
      }}
    />
  )
}

export default SharedTable
