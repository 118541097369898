import Dashboard from './containers/dashboard.container'

const routes = [
  {
    path: '/',
    component: Dashboard,
    authenticated: true,
  },
]

export default routes
