import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'
import notification from '~/shared/Notification'
import DisciplineFormComponent from '../components/disciplinelesson-form.component'
import services from '../disciplinelesson.services'
import servicesSubjects from '../../disciplinesubjects/disciplinesubjects.services'
import servicesQuestion from '../../questions/question.services'

function DisciplineLessonEditContainer() {
  const [form] = Form.useForm()
  const params = useParams()
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { iddiscipline, idsubject, id } = params
  const history = useHistory()
  const [lesson, setLesson] = useState({})
  const [subject, setSubject] = useState({})
  const [tags, setTags] = useState([])
  const [tagsQuestions, setTagsQuestion] = useState([])
  const [typeLesson, setTypeLesson] = useState([])
  const [sequence, setSequence] = useState([])

  const [disciplineId, setDisciplineId] = useState(null)
  const [disciplineSubjectId, setDisciplineSubjectId] = useState(null)

  async function getData() {
    const responseSubject = await services.getById(id)
    setLesson(responseSubject.data)
  }

  async function getSubject() {
    const responseSubject = await servicesSubjects.getById(idsubject)
    setSubject(responseSubject.data)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      }
    })
    setTags(mappedTags)
  }

  async function getTagsForQuestion() {
    const responseTags = await servicesQuestion.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      }
    })
    setTagsQuestion(mappedTags)
  }

  async function getTypeLesson() {
    const responseTypeLesson = await services.getTypeLesson()

    const mappedTypes = responseTypeLesson.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setTypeLesson(mappedTypes)
  }

  async function getSequence() {
    const responseSequence = await services.getSequence(
      idsubject,
      iddiscipline,
      true,
    )

    const mappedSequence = responseSequence.data.map((sequence) => {
      return {
        label: sequence,
        value: sequence,
      }
    })
    setSequence(mappedSequence)
  }

  async function deleteQuestionFromLesson(idQuestion) {
    const responseDelete = await services.deleteQuestionOnLesson(id, idQuestion)
    notification.success('Excluído com sucesso.')
  }

  useEffect(() => {
    getData()
    getSubject()
    getTags()
    getTagsForQuestion()
    getTypeLesson()
    getSequence()
    setLoadingData(false)
    setDisciplineId(iddiscipline)
    setDisciplineSubjectId(idsubject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iddiscipline, idsubject, id])

  async function onSubmit(values) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('pdf', values.pdf)
    formData.append('name', values.name)
    formData.append('displaySequence', values.displaySequence)
    formData.append('active', values.active)
    formData.append('route', values.route)

    values.tags.forEach((item) => {
      formData.append('tags', item)
    })

    formData.append('videoId', values.videoId)
    formData.append('disciplineLessonTypeId', values.disciplineLessonTypeId)
    formData.append('disciplineId', values.disciplineId)
    formData.append('DisciplineSubjectId', values.DisciplineSubjectId)
    formData.append('sendEssay', values.sendEssay)

    const questionsArray = values.questions

    questionsArray.forEach((item) => {
      formData.append('Questions', item)
    })

    setLoadingSubmit(true)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      await services.update(formData, config)
      notification.success('Atualizado com sucesso.')
      setLoadingSubmit(false)
      history.push(
        `/disciplina/${disciplineId}/materia/${disciplineSubjectId}/aulas`,
      )
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <DisciplineFormComponent
      idLesson={id}
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      lesson={lesson}
      subject={subject}
      tags={tags}
      tagsQuestions={tagsQuestions}
      typeLesson={typeLesson}
      displaySequence={sequence}
      loadTags={getTags}
      loadTypes={getTypeLesson}
      loadSequence={getSequence}
      disciplineId={disciplineId}
      disciplineSubjectId={disciplineSubjectId}
      deleteQuestionFromLesson={deleteQuestionFromLesson}
    />
  )
}

export default DisciplineLessonEditContainer
