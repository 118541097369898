import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Space, Form, Input, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'

function ForgetPasswordFormComponent(props) {
  const { loading, onSubmit: onFinish } = props

  const rules = [{ type: 'email', required: true }]

  return (
    <Form name="forget-password" size="large" onFinish={onFinish}>
      <Form.Item name="email" rules={rules} hasFeedback>
        <Input
          prefix={<UserOutlined />}
          placeholder="E-mail para recuperação"
          maxLength={50}
          allowClear
        />
      </Form.Item>

      <Row justify="end">
        <Space>
          <Link to="/login">
            <Button type="text" size="large">
              Voltar
            </Button>
          </Link>

          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shape="round"
            loading={loading}
          >
            Redefinir Senha
          </Button>
        </Space>
      </Row>
    </Form>
  )
}

export default ForgetPasswordFormComponent
