import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'

import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
  Typography,
} from 'antd'

import ErrorIconComponent from './errorIcon-component'

import Box from '~/shared/Box'
import PasswordStrenght from '~/shared/PasswordStrenght'
import notification from '~/shared/Notification'

import brazilStates from '~/utils/brazil-states'

import * as S from '../studentManagement.styles'

function StudentManagementFormComponent(props) {
  const [password, setPassword] = useState('')
  const [plansAdd, setPlansAdd] = useState(false)
  const [billingAddressCheck, setBillingAddressCheck] = useState(false)
  const [errorMobile, setErrorMobile] = useState(false)
  const [errorCpf, setErrorCpf] = useState(false)
  const [errorCep, setErrorCep] = useState(false)
  const [form] = Form.useForm()
  const { type, loadingSubmit, studentInformation, onSubmit } = props
  const { Text } = Typography

  const requiredRule = [{ required: true }]
  const minLengthRule = [{ min: 6 }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && studentInformation) {
      form.setFieldsValue(studentInformation)
      if (studentInformation.cep) setBillingAddressCheck(true)
    }
  }, [isEdit, isView, studentInformation, form])

  async function onFinish(values) {
    const getFeildValue = await form.getFieldsValue()

    const cpf = await getFeildValue.cpf
    const mobile = await getFeildValue.mobile
    const cep = await getFeildValue.cep

    const cpfReplace = cpf?.replace(/\D/g, '')
    const mobileReplace = mobile?.replace(/\D/g, '')
    const cepReplace = cep?.replace(/\D/g, '')

    const cpfLength = cpfReplace?.length
    const mobileLength = mobileReplace?.length
    const cepLength = cepReplace?.length

    if (cpfLength > 0 && !cpfValidator.isValid(cpfReplace)) {
      setErrorCpf(true)
      return
    }

    if (mobileLength > 0 && mobileLength < 10) {
      setErrorMobile(true)
      return
    }

    if (billingAddressCheck && cepLength < 8) {
      setErrorCep(true)
      return
    }

    if (!billingAddressCheck) {
      if (
        cep ||
        getFeildValue.address ||
        getFeildValue.addressNumber ||
        getFeildValue.addressComplement ||
        getFeildValue.addressDistrict ||
        getFeildValue.addressCity ||
        getFeildValue.addressState
      ) {
        setBillingAddressCheck(true)
        notification.warning(
          'Você não pode enviar o endereço de cobrança sem marcar a opção.',
        )
        return
      }
    }

    onSubmit(values, plansAdd)
  }

  function beforeMaskedValueChange(state) {
    setErrorMobile(false)

    let { value } = state

    const newValue = value.replace(/\D/g, '')

    if (newValue.length > 0 && newValue.length < 10) setErrorMobile(true)

    if (newValue.length === 11)
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')

    return {
      ...state,
      value,
    }
  }

  const matchPasswordRule = [
    (instance) => ({
      validator(_, value) {
        if (!value || password === value) return Promise.resolve()

        return Promise.reject(new Error('As senhas não coincidem.'))
      },
    }),
  ]

  function handleSubmitForm(value) {
    setPlansAdd(value)
  }

  function handleCpf(value) {
    setErrorCpf(false)

    const cpf = value.replace(/\D/g, '')

    if (!cpfValidator.isValid(cpf) && cpf > 0) setErrorCpf(true)
  }

  function handleCep(value) {
    setErrorCep(false)

    const cep = value.replace(/\D/g, '')
    const cepIsNotValid = /^[0-9]{5}-[0-9]{3}$/.test(value)

    if (cep.length > 0 && cep.length < 8 && !cepIsNotValid) setErrorCep(true)
  }

  return (
    <S.FormWrapper>
      <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={24}>
            <Text style={{ marginBottom: '10px', display: 'block' }}>
              Dados cadastrais
            </Text>
          </Col>

          <Col xs={24} md={12}>
            <Text>Nome</Text>

            <Form.Item name="firstName" rules={requiredRule} hasFeedback>
              <Input placeholder="Nome" maxLength={50} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Sobrenome</Text>

            <Form.Item name="lastName" rules={requiredRule} hasFeedback>
              <Input placeholder="Sobrenome" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8} className={errorCpf && 'alert-error-col'}>
            <Text>CPF</Text>

            <Form.Item
              name="cpf"
              hasFeedback={!errorCpf && true}
              className={
                errorCpf &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="CPF"
                mask="999.999.999-99"
                className="ant-input"
                onChange={(e) => handleCpf(e.target.value)}
              />
            </Form.Item>

            {!!errorCpf && (
              <>
                <ErrorIconComponent />

                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">CPF inválido.</div>
                </div>
              </>
            )}
          </Col>

          <Col xs={24} md={8} className={errorMobile && 'alert-error-col'}>
            <Text>Telefone/Celular</Text>

            <Form.Item
              name="mobile"
              hasFeedback={!errorMobile && true}
              className={
                errorMobile &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="Telefone/Celular"
                mask="(99) 9999-99999"
                className="ant-input"
                beforeMaskedValueChange={beforeMaskedValueChange}
              />
            </Form.Item>

            {!!errorMobile && (
              <>
                <ErrorIconComponent />

                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">
                    Telefone/Celular deve ter no mínimo 10 números.
                  </div>
                </div>
              </>
            )}
          </Col>

          <Col xs={24} md={8}>
            <Text>E-mail</Text>

            <Form.Item
              name="email"
              rules={[{ type: 'email' }, ...requiredRule]}
              hasFeedback
            >
              <Input placeholder="E-mail" allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <S.PasswordAdd>
              <Text>Senha</Text>

              <Form.Item
                name="password"
                rules={
                  isEdit
                    ? [...minLengthRule]
                    : [...requiredRule, ...minLengthRule]
                }
                hasFeedback
              >
                <Input.Password
                  placeholder="Senha"
                  maxLength={16}
                  onChange={(e) => setPassword(e.target.value)}
                  allowClear
                />
              </Form.Item>

              {password && (
                <Box>
                  <PasswordStrenght password={password} />
                </Box>
              )}

              <Text className="alert-text">
                A senha deve ter pelo menos 6 caracteres. Para torná-la mais
                forte, use letras maiúsculas e minúsculas, números e símbolos
                como ! " ? $ % ^ & ).
              </Text>
            </S.PasswordAdd>
          </Col>

          <Col xs={24} md={8}>
            <Text>Confirmar senha</Text>

            <Form.Item
              name="passwordConfirmItem"
              rules={password && [...requiredRule, ...matchPasswordRule]}
              hasFeedback
            >
              <Input.Password
                placeholder="Confirmar senha"
                maxLength={16}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Text>Status</Text>

            <Form.Item name="active" valuePropName="checked" defaultChecked>
              <Checkbox>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Text style={{ marginBottom: '10px', display: 'block' }}>
              <Checkbox
                onChange={(e) => setBillingAddressCheck(e.target.checked)}
                checked={billingAddressCheck}
              >
                Endereço de cobrança
              </Checkbox>
            </Text>
          </Col>

          <Col xs={24} md={12} className={errorCep && 'alert-error-col'}>
            <Text>CEP</Text>

            <Form.Item
              name="cep"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck && !errorCep && true}
              className={
                errorCep &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="CEP"
                mask="99999-999"
                className="ant-input"
                onChange={(e) => handleCep(e.target.value)}
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>

            {!!errorCep && (
              <>
                <ErrorIconComponent />

                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">Preencha o CEP corretamente.</div>
                </div>
              </>
            )}
          </Col>

          <Col xs={24} md={12}>
            <Text>Endereço</Text>

            <Form.Item
              name="address"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck}
            >
              <Input
                placeholder="Endereço"
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Número</Text>

            <Form.Item
              name="addressNumber"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck}
            >
              <InputNumber
                placeholder="Número"
                min={0}
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Complemento</Text>

            <Form.Item
              name="addressComplement"
              hasFeedback={billingAddressCheck}
            >
              <Input
                placeholder="Complemento"
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Bairro</Text>

            <Form.Item
              name="addressDistrict"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck}
            >
              <Input
                placeholder="Bairro"
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Cidade</Text>

            <Form.Item
              name="addressCity"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck}
            >
              <Input
                placeholder="Cidade"
                disabled={!billingAddressCheck && true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Estado</Text>

            <Form.Item
              name="addressState"
              rules={billingAddressCheck && requiredRule}
              hasFeedback={billingAddressCheck}
            >
              <Select
                options={brazilStates}
                placeholder="Estado"
                disabled={!billingAddressCheck && true}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to="/aluno">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
            </Button>

            {!isView && (
              <Button
                htmlType="submit"
                type="primary"
                loading={loadingSubmit}
                onClick={() => handleSubmitForm(false)}
              >
                {isNew ? 'Confirmar' : 'Atualizar'}
              </Button>
            )}

            {isNew && (
              <Button
                htmlType="submit"
                type="primary"
                loading={plansAdd && loadingSubmit}
                onClick={() => handleSubmitForm(true)}
              >
                Confirmar e incluir plano
              </Button>
            )}
          </Space>
        </Row>
      </Form>
    </S.FormWrapper>
  )
}

export default StudentManagementFormComponent
