import DisciplineSubjectListContainer from './containers/disciplinesubjects-list.container'
import DisciplineSubjectNewContainer from './containers/disciplinesubjects-new.container'
import DisciplineSubjectEditContainer from './containers/disciplinesubjects-edit.container'
import DisciplineSubjectViewContainer from './containers/disciplinesubjects-view.container'

const routes = [
  {
    path: '/disciplina/:iddiscipline/materia/inclusao',
    tag: 'DisciplineSubjectCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Matéria',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: DisciplineSubjectNewContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/:id/edicao',
    tag: 'DisciplineSubjectEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Matéria',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: DisciplineSubjectEditContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/:id/visualizacao',
    tag: 'DisciplineSubjectList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Matéria',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: DisciplineSubjectViewContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/',
    tag: 'DisciplineSubjectList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Matéria',
      },
    ],
    component: DisciplineSubjectListContainer,
  },
]

export default routes
