import { createGlobalStyle } from 'styled-components'
import 'katex/dist/katex.min.css'

import FuturaPTMedium from '~/assets/fonts/FuturaPTMedium.ttf'
import FuturaPTBold from '~/assets/fonts/FuturaPTBold.ttf'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Futura PT';
    font-weight: 400;
    font-style: normal;
    src: url(${FuturaPTMedium});
  }

  @font-face {
    font-family: 'Futura PT';
    font-weight: 500;
    font-style: normal;
    src: url(${FuturaPTBold});
  }
`

export default GlobalStyles
