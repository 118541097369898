import FeedbacksManagementListContainer from './containers/feedbacksManagement-list.container'
import FeedbacksManagementViewContainer from './containers/feedbacksManagement-view.container'

const routes = [
  {
    path: '/gestao-feedback/:id/visualizacao/:liveClass',
    tag: 'ManagementFeedbackList',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/gestao-feedback',
        breadcrumbName: 'Gestão de Feedbacks',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: FeedbacksManagementViewContainer,
  },
  {
    path: '/gestao-feedback',
    tag: 'ManagementFeedbackList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/gestao-feedback',
        breadcrumbName: 'Gestão de Feedbacks',
      },
    ],
    component: FeedbacksManagementListContainer,
  },
]

export default routes
