import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import DiscountCouponFormComponent from '../components/discountCoupon-form.component'
import services from '../discountCoupon.services'

import notification from '~/shared/Notification'

function DiscountCouponNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    const {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    } = values

    const data = {
      code,
      description,
      type,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds,
      active,
      applyTrialPeriod,
    }

    setLoadingSubmit(true)

    try {
      await services.discountCouponAdd(data)

      notification.success('Cupom cadastrado com sucesso.')
      history.push('/cupom-de-desconto')
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <DiscountCouponFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default DiscountCouponNewContainer
