import React, { useState, useEffect } from 'react'

import { Row, Col, Skeleton, Typography, Divider } from 'antd'

import services from '../studentManagement.services'

import maskMobile from '~/utils/mobileMask'
import cpfMask from '~/utils/cpfMask'

import RenderTag from '~/shared/RenderTag'

export default function StudentInformationComponent(props) {
  const [student, setStudent] = useState({})
  const [loadingData, setLoadingData] = useState(true)

  const { studentInformationId, baseURL, studentInformationPlanId } = props
  const { Text } = Typography

  useEffect(() => {
    async function getStudent() {
      const response = await services.getStudyPlanById(
        studentInformationId,
        baseURL,
        studentInformationPlanId,
      )

      setStudent(response.data)
      setLoadingData(false)
    }

    if (studentInformationId && studentInformationPlanId) getStudent()
  }, [studentInformationId, baseURL, studentInformationPlanId])

  if (loadingData) return <Skeleton active />

  return (
    <Row gutter={16} align="middle">
      {!!student.studentName && (
        <Col xs={24} md={24}>
          <Text>Aluno: {student.studentName}</Text>
        </Col>
      )}

      {!!student.studentCPF && (
        <Col xs={24} md={24}>
          <Text>CPF: {cpfMask(student.studentCPF)}</Text>
        </Col>
      )}

      {!!student.studentMobile && (
        <Col xs={24} md={24}>
          <Text>Telefone/Celular: {maskMobile(student.studentMobile)}</Text>
        </Col>
      )}

      {!!student.studentEmail && (
        <Col xs={24} md={24}>
          <Text>E-mail: {student.studentEmail}</Text>
        </Col>
      )}

      {student.studentActive && (
        <Col xs={24} md={24}>
          <Text>Status: {RenderTag(student.studentActive)}</Text>
        </Col>
      )}

      <Divider />
    </Row>
  )
}
