import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Table, Typography } from 'antd'
import qs from 'qs'

import api from '~/services/api'

import {
  GraphicSection,
  GraphicCol,
  GraphicSummaryChartCol,
  SummaryFooter,
  SummaryTable,
  SummaryTbody,
  SummaryTr,
  SummaryTd,
} from '../subscriptionPlan.styles'

import GraphicChartPie from './subscriptionPlan-graphic-pie.component'

import priceMask from '~/utils/price-mask'
import numberMask from '~/utils/number-price-mask'

export default function SubscriptionPlanGraphicComponent(props) {
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [
    summaryBySubscriptionPlanChart,
    setSummaryBySubscriptionPlanChart,
  ] = useState([])
  const [
    summaryByPaymentMethodChart,
    setSummaryByPaymentMethodChart,
  ] = useState([])

  const { Title, Text } = Typography

  const { baseURL, filter } = props

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const getDataSource = useCallback(async (filter, pageNumber, pageSize) => {
    setLoading(true)

    const params = Object.entries(filter).reduce((params, [key, value]) => {
      if (value !== undefined) {
        return {
          ...params,
          [key]: value,
        }
      }

      return params
    }, {})

    const response = await api.get(baseURL, {
      params: { ...params, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })

    setSummaryBySubscriptionPlanChart(
      response.data.summaryBySubscriptionPlanChart,
    )
    setSummaryByPaymentMethodChart(response.data.summaryByPaymentMethodChart)

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { pageSize } = pagination

    if (filter) getDataSource(filter, 1, pageSize)

    if (firstLoad) setFirstLoad(false)

    setPagination((pagination) => ({
      pageNumber: 1,
      pageSize: pagination.pageSize,
      fromFilter: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    const { pageNumber, pageSize, fromFilter } = pagination

    if (firstLoad || fromFilter) return

    getDataSource(filter, pageNumber, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  function renderPriceMask(val) {
    return <Text>{priceMask(val)}</Text>
  }

  function renderPercentMask(val) {
    return <Text>{numberMask(val)}</Text>
  }

  const columnsSubscriptionPlanChart = [
    {
      title: 'Plano Assinatura',
      dataIndex: 'subscriptionPlanName',
    },
    {
      title: 'Nº Assinaturas',
      dataIndex: 'subscriptionCount',
    },
    {
      title: 'Assinaturas',
      dataIndex: 'subscriptionAmount',
      render: (data) => renderPriceMask(data),
    },
    {
      title: '%',
      dataIndex: 'percent',
      render: (data) => renderPercentMask(data),
    },
  ]

  const columnsPaymentMethodChart = [
    {
      title: 'Forma Pagamento',
      dataIndex: 'paymentMethodDescription',
    },
    {
      title: 'Nº Assinaturas',
      dataIndex: 'paymentMethodCount',
    },
    {
      title: 'Assinaturas',
      dataIndex: 'paymentMethodAmount',
      render: (data) => renderPriceMask(data),
    },
    {
      title: '%',
      dataIndex: 'percent',
      render: (data) => renderPercentMask(data),
    },
  ]

  function summaryFooter(data) {
    const { subscriptionTotalCount, subscriptionTotalAmount } = data

    return (
      subscriptionTotalCount > 0 &&
      subscriptionTotalAmount > 0 && (
        <SummaryFooter>
          <SummaryTable>
            <SummaryTbody className="ant-table-tbody">
              <SummaryTr className="ant-table-row ant-table-row-level-0">
                <SummaryTd className="bold">Total</SummaryTd>

                <SummaryTd>
                  {subscriptionTotalCount > 0 && subscriptionTotalCount}
                </SummaryTd>

                <SummaryTd>
                  {subscriptionTotalAmount > 0 &&
                    renderPriceMask(subscriptionTotalAmount)}
                </SummaryTd>

                <SummaryTd>100,00%</SummaryTd>
              </SummaryTr>
            </SummaryTbody>
          </SummaryTable>
        </SummaryFooter>
      )
    )
  }

  return (
    <GraphicSection>
      <Row gutter={16}>
        <Col xs={12} md={12}>
          <GraphicCol>
            <Title level={3} className="bold">
              Resumo por Planos de Assinatura
            </Title>

            {summaryBySubscriptionPlanChart.details?.length > 0 && (
              <GraphicChartPie dataSource={summaryBySubscriptionPlanChart} />
            )}

            {!!summaryBySubscriptionPlanChart && (
              <GraphicSummaryChartCol>
                <Table
                  loading={loading}
                  columns={columnsSubscriptionPlanChart}
                  dataSource={summaryBySubscriptionPlanChart.details}
                  footer={() => summaryFooter(summaryBySubscriptionPlanChart)}
                  pagination={false}
                />
              </GraphicSummaryChartCol>
            )}
          </GraphicCol>
        </Col>

        <Col xs={12} md={12}>
          <GraphicCol>
            <Title level={3} className="bold">
              Resumo por Formas de Pagamento
            </Title>

            {summaryByPaymentMethodChart.details?.length > 0 && (
              <GraphicChartPie
                dataSource={summaryByPaymentMethodChart}
                type="paymentMethod"
              />
            )}

            {!!summaryByPaymentMethodChart && (
              <GraphicSummaryChartCol>
                <Table
                  loading={loading}
                  columns={columnsPaymentMethodChart}
                  dataSource={summaryByPaymentMethodChart.details}
                  footer={() => summaryFooter(summaryBySubscriptionPlanChart)}
                  pagination={false}
                />
              </GraphicSummaryChartCol>
            )}
          </GraphicCol>
        </Col>
      </Row>
    </GraphicSection>
  )
}
