import api from '~/services/api'

const services = {
  getSubjects({ disciplineId }) {
    return api.get('/subject/active', {
      params: {
        disciplineId,
      },
    })
  },
}

export default services
