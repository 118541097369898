import DisciplineLessonListContainer from './containers/disciplinelesson-list.container'
import DisciplineLessonNewContainer from './containers/disciplinelesson-new.container'
import DisciplineLessonEditContainer from './containers/disciplinelesson-edit.container'
import DisciplineLessonViewContainer from './containers/disciplinelesson-view.container'

const routes = [
  {
    path: '/disciplina/:iddiscipline/materia/:idsubject/aulas/inclusao',
    tag: 'DisciplineLessonCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        path: '/materia',
        breadcrumbName: 'Matéria',
      },
      {
        path: '/aulas',
        breadcrumbName: 'Aula',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: DisciplineLessonNewContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/:idsubject/aulas/:id/edicao',
    tag: 'DisciplineLessonEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        path: '/materia',
        breadcrumbName: 'Matéria',
      },
      {
        path: '/aulas',
        breadcrumbName: 'Aula',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: DisciplineLessonEditContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/:idsubject/aulas/:id/visualizacao',
    tag: 'DisciplineLessonList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        path: '/materia',
        breadcrumbName: 'Matéria',
      },
      {
        path: '/aulas',
        breadcrumbName: 'Aula',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: DisciplineLessonViewContainer,
  },
  {
    path: '/disciplina/:iddiscipline/materia/:idsubject/aulas',
    tag: 'DisciplineLessonList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Matéria',
      },
      {
        breadcrumbName: 'Aula',
      },
    ],
    component: DisciplineLessonListContainer,
  },
]

export default routes
