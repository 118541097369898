//import PartnerSchoolList from '../partnerSchool/containers/partnerSchool-list.container'
import SignatureForm from './components/signature-form'

// import PartnerSchoolNew from '../partnerSchool/containers/partnerSchool-new.container'
// import PartnerSchoolView from '../partnerSchool/containers/partnerSchool-view.container'
// import PartnerSchoolEdit from '../partnerSchool/containers/partnerSchool-edit.container'

const routes = [
  {
    path: '/signature',
    tag: '',
    authenticated: true,
    component: SignatureForm,
  },
  // {
  //   path: '/escola-parceira/inclusao',
  //   tag: '',
  //   breadcrumb: [
  //     {
  //       path: '/escola-parceira',
  //       breadcrumbName: 'Escola Parceira',
  //     },
  //     {
  //       breadcrumbName: 'Inclusão',
  //     },
  //   ],
  //   authenticated: true,
  //   component: PartnerSchoolNew,
  // },
  // {
  //   path: '/escola-parceira/:idPartnerSchool/visualizacao',
  //   tag: '',
  //   breadcrumb: [
  //     {
  //       path: '/escola-parceira',
  //       breadcrumbName: 'Escola Parceira',
  //     },
  //     {
  //       breadcrumbName: 'Visualização',
  //     },
  //   ],
  //   authenticated: true,
  //   component: PartnerSchoolView,
  // },
  // {
  //   path: '/escola-parceira/:idPartnerSchool/edicao',
  //   tag: '',
  //   breadcrumb: [
  //     {
  //       path: '/escola-parceira',
  //       breadcrumbName: 'Escola Parceira',
  //     },
  //     {
  //       breadcrumbName: 'Edição',
  //     },
  //   ],
  //   authenticated: true,
  //   component: PartnerSchoolEdit,
  // },
]

export default routes
