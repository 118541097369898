import { notification } from 'antd'

const notificationMessages = {
  success(description) {
    const config = {
      message: 'Sucesso',
      description,
    }

    notification.success(config)
  },

  info(description) {
    const config = {
      message: 'Informação',
      description,
    }

    notification.info(config)
  },

  warning(description) {
    const config = {
      message: 'Atenção',
      description,
    }
    notification.warning(config)
  },

  error(description) {
    const config = {
      message: 'Erro',
      description,
    }

    notification.error(config)
  },
}

export default notificationMessages
