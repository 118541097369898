import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import GroupFormComponent from '../components/discipline-form.component'
import services from '../discipline.services'

import notification from '~/shared/Notification'

function DisciplineEditContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [discipline, setDiscipline] = useState(null)

  const history = useHistory()
  const params = useParams()

  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseDiscipline = await services.getById(id)

      setLoadingData(false)

      setDiscipline(responseDiscipline.data)
    }

    getData()
  }, [id])

  async function onSubmit(values) {
    const { name, shortName, active, route } = values

    const data = {
      id,
      name,
      shortName,
      active,
      route,
    }

    setLoadingSubmit(true)

    try {
      await services.update(data)
      notification.success('Atualizado com sucesso.')

      history.push('/disciplina')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <GroupFormComponent
      type="E"
      loading={loadingSubmit}
      discipline={discipline}
      onSubmit={onSubmit}
    />
  )
}

export default DisciplineEditContainer
