import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import notification from '~/shared/Notification'

import StudentManagementPlansFormComponent from '../components/studentManagementPlans-form.component'

import services from '../studentManagement.services'

function StudentManagementPlansEditContainer() {
  const [plan, setPlan] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const { idstudent, idplan } = params

  async function getData() {
    const response = await services.getStudyPlanBySubscriptionById(
      idstudent,
      idplan,
    )

    setPlan(response.data)
  }

  useEffect(() => {
    getData()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values) {
    const { startDate, endDate, studyPlanActive } = values

    const data = {
      studentId: idstudent,
      subscriptionPlanId: plan.subscriptionPlanId,
      studentSubscriptionId: plan.studentSubscriptionId,
      studentStudyPlanId: plan.studentStudyPlanId,
      startDate,
      endDate,
      // studyPlanId: plan.studyPlanId,
      active: studyPlanActive,
    }

    setLoadingSubmit(true)

    try {
      await services.updateStudyPlanBySubscription(data)
      notification.success('Plano atualizado com sucesso.')
      setLoadingSubmit(false)

      history.push(`/aluno/${idstudent}/plano-e-assinatura/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <StudentManagementPlansFormComponent
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      plan={plan}
    />
  )
}

export default StudentManagementPlansEditContainer
