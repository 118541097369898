/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  AutoComplete,
  Typography,
  Tag,
  DatePicker,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  UserAddOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'

import InputMask from 'react-input-mask'
import moment from 'moment'
import { format, parseISO, subDays } from 'date-fns'
import services from '../studentManagement.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'

import maskMobile from '~/utils/mobileMask'
import removeMask from '~/utils/remove-mask'

import { FormWrapper } from '../studentManagement.styles'

function StudentManagementListComponent() {
  const [filter, setFilter] = useState({})
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('')
  const [partnerSchool, setPartnerSchool] = useState([])
  const [partnerSchoolId, setPartnerSchoolId] = useState('')
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [formStudent] = Form.useForm()
  const history = useHistory()
  const { Text } = Typography
  const { RangePicker } = DatePicker

  const UserTypeArr = [
    { id: 1, value: 'Assinante' },
    { id: 2, value: 'Upgrade' },
    { id: 3, value: 'Freemium' },
    { id: 4, value: 'Escola' },
  ]

  const SignatureStatusArr = [
    { id: 1, value: 'Ativa' },
    { id: 2, value: 'Expirada' },
    { id: 3, value: 'Cancelada' },
  ]

  useEffect(() => {
    async function getSubscriptionPlans() {
      const response = await services.getSubscriptionPlan()

      const subscriptPlan = response.data.map((item) => {
        return {
          id: item.id,
          value: item.name,
        }
      })

      setSubscriptionPlan(subscriptPlan)
    }

    getSubscriptionPlans()

    async function getPartnerSchools() {
      const response = await services.getPartnerSchool()

      const partnerSchoolList = response.data.map((item) => {
        return {
          id: item.id,
          value: item.schoolName,
        }
      })

      setPartnerSchool(partnerSchoolList)
    }

    getPartnerSchools()
  }, [])

  function getDateFormat(dateValue, type) {
    if (dateValue) {
      const date = parseISO(dateValue)
      let newFormatDate = 'dd/MM/yyyy'

      if (type) {
        newFormatDate = 'yyyy-MM-dd'
      }

      // const dateInitial = dateString[0].replace(/\//g, '-')
      // const dateEnd = dateString[1].replace(/\//g, '-')

      return format(date, newFormatDate)
    }

    return ''
  }

  function handleStudentLink(data, type) {
    let url = '/aluno/'

    if (type === 'editStudent')
      url = `/aluno/dados-cadastrais/${data.id}/edicao/`

    if (type === 'addPlan')
      url = `/aluno/${data.id}/plano-e-assinatura/inclusao/`

    if (type === 'viewPlan') url = `/aluno/${data.id}/plano-e-assinatura/`

    if (type === 'addSchool')
      url = `/aluno/${data.id}/escola-parceira/inclusao/`

    history.push(url)
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar Planos e Escolas">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => handleStudentLink(data, 'viewPlan')}
            />
          </Tooltip>

          <Tooltip title="Editar Informações do Aluno">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => handleStudentLink(data, 'editStudent')}
            />
          </Tooltip>

          <Tooltip title="Adicionar Planos e Assinaturas">
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => handleStudentLink(data, 'addPlan')}
            />
          </Tooltip>

          <Tooltip title="Adicionar Escola Parceira">
            <Button
              type="link"
              icon={<ReadOutlined />}
              onClick={() => handleStudentLink(data, 'addSchool')}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderTagUserType(data) {
    const type = data.userType === '' ? data.userTypeWhenEmpty : data.userType

    const color =
      type === 'Assinante'
        ? 'green'
        : type === 'Upgrade'
        ? 'blue'
        : type === 'Escola'
        ? 'yellow'
        : 'purple'

    return <>{type && <Tag color={color}>{type}</Tag>}</>
  }

  const columns = [
    {
      title: 'Nome Aluno',
      dataIndex: 'name',
    },
    {
      title: 'Tipo de Usuário',
      align: 'center',
      render: (data) => renderTagUserType(data),
    },
    {
      title: 'E-mail Aluno',
      dataIndex: 'email',
    },
    {
      title: 'Telefone Aluno',
      dataIndex: 'mobile',
      render: (data) => data && maskMobile(data),
    },
    {
      title: 'Status da Assinatura',
      align: 'center',
      render: (data) =>
        data.signatureStatus === ''
          ? data.signatureStatusWhenEmpty
          : data.signatureStatus,
    },
    {
      title: 'Ações',
      align: 'center',
      render: (row) => renderActions(row),
    },
  ]

  function handleSubscriptionPlanChange(_, option) {
    setSubscriptionPlanId(option.id)
  }

  function handlePartnerSchoolChange(_, option) {
    setPartnerSchoolId(option.id)
  }

  function handleChangeDate(_, dateString) {
    setBeginDate(getDateFormat(dateString[0], true))
    setEndDate(getDateFormat(dateString[1], true))
  }

  function resetFilter() {
    formStudent.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    const { name, email, cpf, userType, signatureStatus } = values

    const data = {
      name,
      email,
      cpf: cpf ? removeMask(cpf) : '',
      SubscriptionPlanId: subscriptionPlanId,
      PartnerSchoolId: partnerSchoolId,
      userType,
      signatureStatus,
      BeginDate: beginDate,
      EndDate: endDate,
    }
    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <FormWrapper>
            <Form form={formStudent} onFinish={onFinish}>
              <Row gutter={16}>
                <Col xs={24} md={6}>
                  <Text>Nome Aluno</Text>

                  <Form.Item name="name">
                    <Input placeholder="Nome aluno" allowClear />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Text>E-mail Aluno</Text>
                  <Form.Item name="email">
                    <Input placeholder="Digite o e-mail do aluno" allowClear />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Text>CPF Aluno</Text>

                  <Form.Item name="cpf">
                    <InputMask
                      placeholder="Digite o CPF do aluno"
                      mask="999.999.999-99"
                      className="ant-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} className="data-col">
                  <Text>Período</Text>

                  <Form.Item name="date">
                    <RangePicker onChange={handleChangeDate} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                {!!subscriptionPlan && (
                  <Col xs={24} md={6}>
                    <Text>Plano de Assinatura</Text>

                    <Form.Item name="subscriptionPlan">
                      <AutoComplete
                        placeholder="Plano de Assinatura"
                        options={subscriptionPlan}
                        value={subscriptionPlan.id}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col xs={24} md={6}>
                  <Text>Tipo de Usuário</Text>

                  <Form.Item name="userType">
                    <AutoComplete
                      placeholder="Tipo de Usuário"
                      options={UserTypeArr}
                      value={UserTypeArr.value}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Text>Status da Assinatura</Text>

                  <Form.Item name="signatureStatus">
                    <AutoComplete
                      placeholder="Status da Assinatura"
                      options={SignatureStatusArr}
                      onSelect={(val, option) =>
                        handleSubscriptionPlanChange(val, option)
                      }
                      value={SignatureStatusArr.value}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      allowClear
                    />
                  </Form.Item>
                </Col>

                {!!partnerSchool && (
                  <Col xs={24} md={6}>
                    <Text>Escola Parceira</Text>

                    <Form.Item name="partnerSchool">
                      <AutoComplete
                        placeholder="Escola Parceira"
                        options={partnerSchool}
                        onSelect={(val, option) =>
                          handlePartnerSchoolChange(val, option)
                        }
                        value={subscriptionPlan.id}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row gutter={16} align="end">
                <Col className="filter-form">
                  <Space>
                    <Tooltip title="Pesquisar">
                      <Button
                        htmlType="submit"
                        type="primary"
                        icon={<SearchOutlined />}
                      />
                    </Tooltip>

                    <Tooltip title="Redefinir Filtros">
                      <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={resetFilter}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end" gutter={8}>
        <Col>
          <PermittedAction>
            <Tooltip title="Incluir Novo Aluno">
              <Link to="/aluno/dados-cadastrais/inclusao/">
                <Button type="primary" icon={<UserAddOutlined />} />
              </Link>
            </Tooltip>
          </PermittedAction>
        </Col>
        <Col>
          <PermittedAction>
            <Tooltip title="Incluir Alunos em Massa">
              <Link to="/inclusao-em-massa">
                <Button type="primary" icon={<UsergroupAddOutlined />} />
              </Link>
            </Tooltip>
          </PermittedAction>
        </Col>
      </Row>

      <Table baseURL="/student" filter={filter} columns={columns} />
    </Space>
  )
}

export default StudentManagementListComponent
