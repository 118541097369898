import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import StudentManagementPlansFormComponent from '../components/studentManagementPlans-form.component'

import services from '../studentManagement.services'

function StudentManagementPlansViewContainer() {
  const [plan, setPlan] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [form] = Form.useForm()
  const params = useParams()
  const { idstudent, idplan } = params

  useEffect(() => {
    async function getData() {
      const response = await services.getStudyPlanBySubscriptionById(
        idstudent,
        idplan,
      )

      setPlan(response.data)
      setLoadingData(false)
    }

    getData()
  }, [idstudent, idplan])

  if (loadingData) return <Skeleton active />

  return (
    <StudentManagementPlansFormComponent type="V" form={form} plan={plan} />
  )
}

export default StudentManagementPlansViewContainer
