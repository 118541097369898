import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import PartnerSchoolFormComponent from '../components/partnerSchool-form.component'
import services from '../partnerSchool.services'

import notification from '~/shared/Notification'

function PartnerSchoolNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    const {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    } = values

    const data = {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    }

    setLoadingSubmit(true)

    try {
      await services.partnerSchoolAdd(data)

      notification.success('Escola Parceira cadastrada com sucesso.')
      history.push('/escola-parceira')
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <PartnerSchoolFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default PartnerSchoolNewContainer
