import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Select,
  Tooltip,
  Button,
  Tag,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  UndoOutlined,
} from '@ant-design/icons'

import services from '../user.services'
import notification from '~/shared/Notification'
import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function UserListComponent(props) {
  const [filter, setFilter] = useState({})

  const history = useHistory()
  const [form] = Form.useForm()

  const { partnerSchools } = props

  function setValueDefaultSchoolPartner() {
    if (partnerSchools.length === 1) {
      form.setFieldsValue({ partnerSchoolId: partnerSchools[0].value })
    }
  }

  useEffect(() => {
    setValueDefaultSchoolPartner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSchools])

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/usuario/${id}/visualizacao`)
  }

  function edit(id) {
    history.push(`/usuario/${id}/edicao`)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    })
  }

  async function reset(id) {
    try {
      await services.reset(id)
      notification.success('Usuário desbloqueado com sucesso.')
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    } catch {
      notification.error('Ocorreu um erro ao desbloquear o usuário')
    }
  }

  function renderActions(row) {
    const { id, blockedByLoginAttempts } = row

    return (
      <Button.Group>
        {blockedByLoginAttempts && (
          <PermittedAction tag="UserResetLoginAttempts">
            <Tooltip title="Desbloquear Usuário">
              <Button
                type="link"
                icon={<UndoOutlined />}
                onClick={() => reset(id)}
              />
            </Tooltip>
          </PermittedAction>
        )}

        <PermittedAction tag="UserList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="UserEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="UserDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Nome Completo',
      dataIndex: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Escola Parceira',
      dataIndex: 'partnerSchoolName',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (row) => renderActions(row),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    setFilter(values)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item name="name">
                  <Input placeholder="Nome Completo" maxLength={50} />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item name="email">
                  <Input placeholder="E-mail" maxLength={50} />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item name="partnerSchoolId">
                  <Select
                    placeholder="Escola Parceira"
                    options={partnerSchools}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={2}>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <PermittedAction tag="UserCreate">
        <Row justify="end">
          <Tooltip title="Incluir">
            <Link to="/usuario/inclusao">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </Row>
      </PermittedAction>

      <Table baseURL="/user" filter={filter} columns={columns} />
    </Space>
  )
}

export default UserListComponent
