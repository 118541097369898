import api from '~/services/api'

const services = {
  essayList() {
    return api.get('/essay')
  },
  essayListUpdate(data) {
    const { pageNumber, pageSize } = data

    return api.get('/essay', {
      params: {
        pageNumber,
        pageSize,
      },
    })
  },
  essayPartnerSchool() {
    return api.get('/partnerschool/active')
  },
  essayStartMark(data) {
    const { studentEssayId, studyPlanId, disciplineLessonId, studentId } = data

    return api.post('/essay/start-mark', {
      studentEssayId,
      studyPlanId,
      disciplineLessonId,
      studentId,
    })
  },
  essayMark(data, config) {
    return api.post('/essay/mark', data, config)
  },
  essayDisapproved(data) {
    const { studentEssayId, studyPlanId, disciplineLessonId, studentId } = data

    return api.post('/essay/reprove', {
      studentEssayId,
      studyPlanId,
      disciplineLessonId,
      studentId,
    })
  },
}

export default services
