import FirstAccess from './containers/firstaccess.container'

const routes = [
  {
    path: '/primeiro-acesso',
    title: 'Primeiro Acesso',
    component: FirstAccess,
  },
]

export default routes
