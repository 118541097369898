import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import UserFormComponent from '../components/user-form.component'

import userServices from '../user.services'
import groupServices from '../../groups/group.services'

import notification from '~/shared/Notification'

function UserEditContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState([])

  const history = useHistory()
  const params = useParams()

  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseUser = await userServices.getById(id)
      const responseRoles = await groupServices.getRolesActive()

      setLoadingData(false)

      setUser(responseUser.data)
      setRoles(responseRoles.data)
    }

    getData()
  }, [id])

  async function onSubmit(values) {
    const {
      name,
      email,
      password,
      active,
      partnerSchoolId,
      roles,
      biography,
      discipline,
      calendlySlug,
      rating,
      privateClasses,
      mentorShip,
      recordedClasses,
    } = values

    if (!roles.length) {
      notification.error('É necessário informar ao menos um Grupo de Acesso.')
      return
    }

    const data = {
      id,
      name,
      email,
      password,
      active,
      partnerSchoolId,
      roles,
      biography,
      discipline,
      calendlySlug,
      rating,
      privateClasses,
      mentorShip,
      recordedClasses,
    }

    setLoadingSubmit(true)

    try {
      await userServices.update(data)
      notification.success('Editado com sucesso.')

      history.push('/usuario')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <UserFormComponent
      type="E"
      user={user}
      roles={roles}
      loading={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default UserEditContainer
