import React, { createContext, useState, useCallback } from 'react'

import useLocalStorage from '~/hooks/useLocalStorage'
import services from '~/pages/login/login.services'

const AuthContext = createContext()

function AuthProvider(props) {
  const [storageUser, setStorageUser, removeStorageUser] = useLocalStorage(
    '@CSBO:user',
  )

  const [
    storagePermissions,
    setStoragePermissions,
    removeStoragePermissions,
  ] = useLocalStorage('@CSBO:permissions')

  const [storageMenu, setStorageMenu, removeStorageMenu] = useLocalStorage(
    '@CSBO:menu',
  )

  const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage(
    '@CSBO:token',
  )

  const [, setStorageRefresh, removeStorageRefresh] = useLocalStorage(
    '@CSBO:refresh',
  )

  const [user, setUser] = useState(
    storageUser && storageToken ? storageUser : null,
  )

  const [permissions, setPermissions] = useState(storagePermissions || null)

  const [menu, setMenu] = useState(storageMenu || null)

  const [loading, setLoading] = useState(false)

  const { children } = props

  const signIn = useCallback(
    async (values) => {
      setLoading(true)

      try {
        const response = await services.login(values)

        const { name, permissions, menu, token, refresh } = response.data
        setLoading(false)

        setUser({ name })
        setStorageUser({ name })

        setPermissions(permissions)
        setStoragePermissions(permissions)

        setMenu(menu)
        setStorageMenu(menu)

        setStorageToken(token)

        setStorageRefresh(refresh)
      } catch {
        setLoading(false)
      }
    },
    [
      setStorageUser,
      setStoragePermissions,
      setStorageMenu,
      setStorageToken,
      setStorageRefresh,
    ],
  )

  const signOut = useCallback(() => {
    setUser(null)
    removeStorageUser()

    setPermissions(null)
    removeStoragePermissions()

    setMenu(null)
    removeStorageMenu()

    removeStorageToken()

    removeStorageRefresh()
  }, [
    removeStorageUser,
    removeStoragePermissions,
    removeStorageMenu,
    removeStorageToken,
    removeStorageRefresh,
  ])

  return (
    <AuthContext.Provider
      value={{
        signed: !!user?.name,
        user,
        permissions,
        menu,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext }

export default AuthProvider
