import styled from 'styled-components'

const FormWrapper = styled.div`
  .row-xs {
    width: 100%;
  }

  .ant-alert-error.alert-error {
    border: 0;
  }
`

export { FormWrapper }
