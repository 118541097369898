import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import notification from '~/shared/Notification'

import StudentManagementPartnerSchoolFormComponent from '../components/studentManagementPartnerSchool-form.component'

import services from '../studentManagement.services'

function StudentManagementPartnerSchoolEditContainer() {
  const [partnerSchool, setPartnerSchool] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const { idstudent, studentStudyPlanId } = params

  async function getData() {
    const response = await services.getStudentPlanByPartnerSchoolById(
      idstudent,
      studentStudyPlanId,
    )

    setPartnerSchool(response.data)
  }

  useEffect(() => {
    getData()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values) {
    const { studyPlanExpirationDate, studyPlanActive } = values

    const data = {
      studentId: idstudent,
      studentStudyPlanId: partnerSchool.studentStudyPlanId,
      // studyPlanId: partnerSchool.studyPlanId,
      active: studyPlanActive,
      expirationDate: studyPlanExpirationDate,
    }

    setLoadingSubmit(true)

    try {
      await services.updateStudyPlanPartnerSchool(data)
      notification.success('Escola atualizada com sucesso.')
      setLoadingSubmit(false)

      history.push(`/aluno/${idstudent}/plano-e-assinatura/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <StudentManagementPartnerSchoolFormComponent
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      school={partnerSchool}
    />
  )
}

export default StudentManagementPartnerSchoolEditContainer
