import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import DisciplineFormComponent from '../components/disciplinelesson-form.component'
import services from '../disciplinelesson.services'

function DisciplineLessonViewContainer() {
  const [form] = Form.useForm()
  const [loadingData, setLoadingData] = useState(true)
  const params = useParams()
  const { iddiscipline, idsubject, id } = params

  const [lesson, setLesson] = useState({})
  const [tags, setTags] = useState([])
  const [typeLesson, setTypeLesson] = useState([])
  const [sequence, setSequence] = useState([])

  const [disciplineId, setDisciplineId] = useState(null)
  const [disciplineSubjectId, setDisciplineSubjectId] = useState(null)

  async function getData() {
    const responseSubject = await services.getById(id)
    setLesson(responseSubject.data)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      }
    })
    setTags(mappedTags)
  }

  async function getTypeLesson() {
    const responseTypeLesson = await services.getTypeLesson()

    const mappedTypes = responseTypeLesson.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setTypeLesson(mappedTypes)
  }

  async function getSequence() {
    const responseSequence = await services.getSequence(
      idsubject,
      iddiscipline,
      true,
    )

    const mappedSequence = responseSequence.data.map((sequence) => {
      return {
        label: sequence,
        value: sequence,
      }
    })
    setSequence(mappedSequence)
  }

  useEffect(() => {
    getData()
    getTags()
    getTypeLesson()
    getSequence()
    setLoadingData(false)
    setDisciplineId(iddiscipline)
    setDisciplineSubjectId(idsubject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iddiscipline, idsubject, id])

  if (loadingData) return <Skeleton active />

  return (
    <DisciplineFormComponent
      type="V"
      form={form}
      lesson={lesson}
      tags={tags}
      tagsQuestions={[]}
      typeLesson={typeLesson}
      displaySequence={sequence}
      disciplineId={disciplineId}
      disciplineSubjectId={disciplineSubjectId}
    />
  )
}

export default DisciplineLessonViewContainer
