import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import StudyPlanNewComponent from '../components/studyplan-form.component'
import services from '../studyplan.services'
import servicesDisciplines from '../../disciplines/discipline.services'
import servicesSubjects from '../../disciplinesubjects/disciplinesubjects.services'
import servicesLessons from '../../disciplinelessons/disciplinelesson.services'
import notification from '~/shared/Notification'

function StudyPlanNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [studyPlanType, setStudyPlanType] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [subjects, setSubjects] = useState([])
  const [lessons, setLessons] = useState([])
  const [typeLesson, setTypeLesson] = useState([])
  const [studyPlanComplete, setStudyPlanComplete] = useState({})

  const params = useParams()
  const { id } = params

  const history = useHistory()

  const renderTitle = (title: string) => {
    return <span>{title}</span>
  }

  function renderItemForSubjects(subjectsList) {
    const mapperOptionsSubjects = subjectsList.map((subjectOption) => {
      return {
        value: subjectOption.subjectId,
        label: subjectOption.subjectName,
      }
    })

    return mapperOptionsSubjects
  }

  async function getStudyPlanComplete() {
    if (id) {
      const responseStudyPlan = await services.getById(id)
      setStudyPlanComplete(responseStudyPlan.data)
    } else {
      setStudyPlanComplete(null)
    }
  }

  async function getStudyPlanType() {
    const responsePlanType = await services.getStudyPlanType()

    const mappedStudyPlanType = responsePlanType.data.map((planType) => {
      return {
        label: planType.description,
        value: planType.id,
      }
    })
    setStudyPlanType(mappedStudyPlanType)
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getSubjects(listIdDisciplines) {
    let disciplineFilters = listIdDisciplines

    if (disciplineFilters === undefined) {
      disciplineFilters = {}
    }

    const responseDiscipline = await servicesSubjects.subjectsActive(
      disciplineFilters,
    )

    const mappedSubjects = responseDiscipline.data.map((subject) => {
      return {
        label: renderTitle(subject.disciplineName),
        options: renderItemForSubjects(subject.subjects),
      }
    })

    setSubjects(mappedSubjects)
  }

  async function getLessons(filterLessons) {
    const responseLessons = await services.lessonsActives(filterLessons)

    const mappedLessons = responseLessons.data.map((lesson) => {
      return { label: lesson.disciplineLessonName, value: lesson.disciplineId }
    })

    setLessons(mappedLessons)
  }

  async function getTypeLesson() {
    const responseTypeLesson = await servicesLessons.getTypeLesson()

    const mappedTypes = responseTypeLesson.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setTypeLesson(mappedTypes)
  }

  async function onSubmit(values) {
    const data = {
      name: values.name,
      shortDescription: values.shortDescription,
      notes: values.notes,
      studyPlanTypeId: values.studyPlanTypeId,
      active: values.active,
      lessonsCredit: values.lessonsCredit,
      customerServiceCredit: values.customerServiceCredit,
      displayTheme: 'Blue',
      freemium: values.freemium,
    }

    setLoadingSubmit(true)

    try {
      await services.save(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/plano-estudo')
    } catch {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    getStudyPlanComplete()
    getStudyPlanType()
    getDisciplines()
    getSubjects()
    getTypeLesson()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StudyPlanNewComponent
      type="N"
      studyPlanType={studyPlanType}
      disciplines={disciplines}
      subjects={subjects}
      lessons={lessons}
      typeLesson={typeLesson}
      getSubjects={getSubjects}
      getLessons={getLessons}
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
      studyPlanComplete={studyPlanComplete}
      getStudyPlanComplete={getStudyPlanComplete}
    />
  )
}

export default StudyPlanNewContainer
