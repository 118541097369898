import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'
import QuestionFormComponent from '../components/question-form.component'
import notification from '~/shared/Notification'

import services from '../question.services'
import servicesDisciplines from '../../disciplines/discipline.services'

function QuestionNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loading, setLoading] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [tags, setTags] = useState([])
  const [typeResolution, setTypeResolution] = useState([])

  const history = useHistory()

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return { label: tag.name, value: tag.id }
    })

    setTags(mappedTags)
  }

  async function getTypeResolutionId() {
    const typeResolution = [
      {
        value: 1,
        label: 'Sem Resolução',
      },
      {
        value: 2,
        label: 'Conteúdo Texto',
      },
      {
        value: 3,
        label: 'Vídeo',
      },
    ]

    const mappedTypeResolution = typeResolution.map((type) => {
      return { label: type.label, value: type.value }
    })

    setTypeResolution(mappedTypeResolution)
  }

  useEffect(() => {
    setLoading(true)
    getDisciplines()
    getTags()
    getTypeResolutionId()

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values, statement, resolution, listTable) {
    const data = {
      code: values.code,
      disciplineId: values.disciplineId,
      statement,
      allowedInLesson:
        values.allowedInLesson === undefined ? false : values.allowedInLesson,
      allowedInTest:
        values.allowedInTest === undefined ? false : values.allowedInTest,
      allowedInExam:
        values.allowedInExam === undefined ? false : values.allowedInExam,
      active: values.active === undefined ? false : values.active,
      questionSolutionTypeId: values.questionSolutionTypeId,
      solutionContent: resolution,
      solutionVideoId:
        values.solutionVideoId === undefined ? null : values.solutionVideoId,
      answers: listTable,
      tags: values.tags === undefined ? [] : values.tags,
    }

    setLoadingSubmit(true)

    try {
      await services.save(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/pergunta')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loading) {
    return <Skeleton active />
  }

  return (
    <QuestionFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
      disciplines={disciplines}
      tags={tags}
      typeResolutionData={typeResolution}
    />
  )
}

export default QuestionNewContainer
