function maskMobile(number) {
  if (number?.length === 11) {
    const part1 = number.slice(0, 2)
    const part2 = number.slice(2, 7)
    const part3 = number.slice(7, 11)

    return `(${part1}) ${part2}-${part3}`
  }

  const part1 = number?.slice(0, 2)
  const part2 = number?.slice(2, 6)
  const part3 = number?.slice(6, 10)

  return `(${part1}) ${part2}-${part3}`
}

export default maskMobile
