import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Divider,
  Space,
  Button,
  Tabs,
  Table,
  Input,
  Tag,
} from 'antd'
import getDateTimeFormat from '~/utils/date-time-format'

function FeedbacksFormComponent(props) {
  const { feedback, type, loadingSubmit, isLiveClass } = props
  const { TabPane } = Tabs
  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const [form] = Form.useForm()
  const [listTable, setListTable] = useState([])

  const [loadingTableAlternatives, setLoadingTableAlternatives] = useState(
    false,
  )

  function renderTagUserType(type) {
    /* const type = data.userType === '' ? data.userTypeWhenEmpty : data.userType
     */
    const color =
      type === 'Assinante'
        ? 'green'
        : type === 'Upgrade'
          ? 'blue'
          : type === 'Escola'
            ? 'yellow'
            : 'purple'

    return <>{type && <Tag color={color}>{type}</Tag>}</>
  }
  useEffect(() => {
    if ((isEdit || isView) && feedback) {
      form.setFieldsValue({
        className: feedback.disciplineLessonName,
        lastDateFeedback: feedback.lastedDateCreated,
        disciplineName: feedback.disciplineName,
        disciplineSubjectName: feedback.disciplineSubjectName,
      })
      /* setStatment(question.statement) */
      setListTable(feedback.feedbackList)
      setLoadingTableAlternatives(false)
    }
  }, [isEdit, isView, feedback, form])

  const columns = [
    {
      title: 'Nome do Aluno',
      dataIndex: 'studentName',
      width: '20%',
    },
    {
      title: 'Nota da Aula',
      dataIndex: 'score',
      width: '10%',
    },
    {
      title: 'Tipo de Usuário',
      dataIndex: 'userType',
      width: '10%',
      align: 'center',
      render: (type) => renderTagUserType(type),
    },
    {
      title: 'Comentário',
      dataIndex: 'message',
      width: '50%',
    },
    {
      title: 'Data de inclusão Feedback',
      dataIndex: 'createDate',
      width: '20%',
      render: (date) => getDateTimeFormat(date),
    },
  ]
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ active: true, questionSolutionTypeId: 1 }}
    >
      <Row gutter={16} align="middle">
        <Col xs={24} md={12}>
          <Form.Item name="disciplineName" label="Nome da Disciplina">
            <Input placeholder="Nome da Disciplina" disabled={isView} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name="disciplineSubjectName" label="Nome da Matéria">
            <Input placeholder="Nome da Matéria" disabled={isView} />
          </Form.Item>
        </Col>
        {!isLiveClass && (
          <Col xs={24} md={12}>
            <Form.Item name="className" label="Nome da Aula">
              <Input placeholder="Nome da Aula" disabled={isView} />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12}>
          <Form.Item
            name="lastDateFeedback"
            label="Data de Inclusão do último feedback"
          >
            <Input placeholder="Data" disabled={isView} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Row gutter={16} align="end">
        <Col xs={24} md={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Feedbacks dos Alunos" key="1">
              <Table
                loading={loadingTableAlternatives}
                columns={columns}
                dataSource={listTable}
                size="middle"
                pagination={false}
                disabled={isView}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/gestao-feedback">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default FeedbacksFormComponent
