import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Tag,
  Select,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons'

import MathJax from 'react-mathjax-preview'
import services from '../question.services'
import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function QuestionListComponent(props) {
  const [filter, setFilter] = useState({})
  const { Option, OptGroup } = Select
  const history = useHistory()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const { disciplines, tags, tests } = props

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/pergunta/${id}/visualizacao`)
  }

  function edit(id) {
    history.push(`/pergunta/${id}/edicao`)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    })
  }

  function renderActions(id) {
    return (
      <Button.Group>
        <PermittedAction tag="QuestionList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="QuestionEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="QuestionDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Disciplina',
      dataIndex: 'disciplineName',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (id) => renderActions(id),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function GetValueFromStatus(selectedStatus) {
    switch (selectedStatus) {
      case '1':
        return true
      case '0':
        return false
      default:
        return null
    }
  }

  function onFinish(values) {
    const activeResult = GetValueFromStatus(values.status)

    const data = {
      DisciplineId: values.DisciplineId,
      Statement: values.Statement,
      Tag: values.Tag,
      TestId: values.TestId,
      lessonName: values.lessonName,
      status: activeResult,
    }

    setFilter(data)
  }

  const tagsOptions = tags.map((tag) => (
    <Option id={tag.value} key={tag.value} value={tag.label}>
      {tag.label}
    </Option>
  ))

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item name="DisciplineId">
                  <Select
                    showSearch
                    placeholder="Disciplina"
                    options={disciplines}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item name="lessonName">
                  <Input placeholder="Aula" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item name="TestId">
                  <Select
                    showSearch
                    placeholder="Simulado"
                    options={tests}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item name="Statement">
                  <TextArea
                    placeholder="Enunciado"
                    autoSize={{ minRows: 2, maxRows: 2 }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={16}>
                <Form.Item name="Tag">
                  <Select showSearch placeholder="Termos">
                    {tagsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={4} md={4}>
                <Form.Item name="status">
                  <Select placeholder="Status" allowClear>
                    <OptGroup label="Status">
                      <Option value="1">Ativo</Option>
                      <Option value="0">Inativo</Option>
                      <Option value="null">Todos</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>

              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction tag="QuestionCreate">
          <Tooltip title="Incluir">
            <Link to="/pergunta/inclusao">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      <Table baseURL="/question" filter={filter} columns={columns} />
    </Space>
  )
}

export default QuestionListComponent
