import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Dropdown } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import Logo from '~/shared/Logo'
import Box from '~/shared/Box'
import Footer from '~/shared/Footer'

import useAuth from '~/hooks/useAuth'

import {
  StyledLayout,
  StyledHeader,
  StyledMenu,
  StyledSubMenu,
  StyledAvatar,
  StyledContent,
  StyledBreadcrumb,
  StyledContentWrapper,
} from '../default.styles'

function DefaultContainer(props) {
  const { breadcrumb, children } = props
  const { useBreakpoint } = Grid

  const auth = useAuth()
  const breakpoint = useBreakpoint()
  const history = useHistory()

  const { menu } = auth

  const userMenu = (
    <StyledMenu>
      <StyledMenu.ItemGroup title={`Olá, ${auth.user.name}`}>
        <StyledMenu.Item onClick={() => history.push('/trocar-senha')}>
          Trocar Senha
        </StyledMenu.Item>
        <StyledMenu.Item onClick={() => auth.signOut()}>Sair</StyledMenu.Item>
      </StyledMenu.ItemGroup>
    </StyledMenu>
  )

  const subMenu = ({ children, title }) => {
    return (
      <StyledSubMenu title={title}>
        {children.map((menu, index) =>
          menu.hasChildren ? (
            <Fragment key={index}>{subMenu(menu)}</Fragment>
          ) : (
            <StyledMenu.Item
              onClick={() => {
                if (menu.path) onClick(menu.path)
              }}
              key={index}
            >
              {menu.title}
            </StyledMenu.Item>
          ),
        )}
      </StyledSubMenu>
    )
  }

  const renderMenu = (menus) => {
    return menus.map((menu, index) =>
      menu.hasChildren ? (
        <Fragment key={index}>{subMenu(menu)}</Fragment>
      ) : (
        <StyledMenu.Item
          onClick={() => {
            if (menu.path) onClick(menu.path)
          }}
          key={index}
        >
          {menu.title}
        </StyledMenu.Item>
      ),
    )
  }

  const onClick = (route) => {
    history.push(route)
  }

  return (
    <StyledLayout>
      <StyledHeader>
        {!breakpoint.xs && (
          <Box mr="16px">
            <Link to="/dashboard">
              <Logo width={40} />
            </Link>
          </Box>
        )}

        <StyledMenu theme="dark" mode="horizontal">
          {menu && renderMenu(menu)}
        </StyledMenu>

        <Dropdown overlay={userMenu}>
          <StyledAvatar icon={<UserOutlined />} />
        </Dropdown>
      </StyledHeader>

      <StyledContent>
        <StyledBreadcrumb>
          {breadcrumb?.map((breadcrumbItem) => {
            return breadcrumbItem.path ? (
              <Link
                key={breadcrumbItem.breadcrumbName}
                to={breadcrumbItem.path}
              >
                <StyledBreadcrumb.Item>
                  {breadcrumbItem.breadcrumbName}
                </StyledBreadcrumb.Item>
              </Link>
            ) : (
              <StyledBreadcrumb.Item key={breadcrumbItem.breadcrumbName}>
                {breadcrumbItem.breadcrumbName}
              </StyledBreadcrumb.Item>
            )
          })}
        </StyledBreadcrumb>

        <StyledContentWrapper>{children}</StyledContentWrapper>
      </StyledContent>

      <Footer />
    </StyledLayout>
  )
}

export default DefaultContainer
