import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import NotFoundContainer from '~/pages/notfound/containers/notfound.container'

import routes from '~/utils/merge-routes'

function Routes() {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          tag={route.tag}
          title={route.title}
          exact
          breadcrumb={route.breadcrumb}
          component={route.component}
          authenticated={route.authenticated}
        />
      ))}

      <Route component={NotFoundContainer} authenticated />
    </Switch>
  )
}

export default Routes
