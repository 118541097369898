import styled from 'styled-components'

const Info = styled.div`
  margin-bottom: 40px;
  width: 100%;
  display: block;

  .title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
  }
  .margin {
    margin-bottom: 20px;
  }
  .cursor-not-allowed {
    cursor: not-allowed;
    &:hover {
      border-color: #d9d9d9;
    }
  }
  .ant-space.ant-space-horizontal.ant-space-align-center {
    max-width: 25%;
    width: 100%;
  }
  .ant-space.ant-space-horizontal.ant-space-align-center .ant-space-item,
  .ant-space.ant-space-horizontal.ant-space-align-center
    .ant-space-item
    button {
    width: 100%;
  }
  .ant-select-selection-item {
    font-weight: normal;
  }

  @media (max-width: 1200px) {
    #newPasswordForm .ant-row {
      display: block;
    }
    #newPasswordForm .ant-col.ant-col-xs-6.ant-col-md-6 {
      max-width: 100%;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .ant-col-xs-12,
    .ant-space.ant-space-horizontal.ant-space-align-center {
      max-width: 100%;
    }
    .ant-col-xs-12 {
      flex: auto;
    }
    .cursor-not-allowed {
      margin-bottom: 24px;
      display: block;
    }
    .ant-typography {
      margin-bottom: 20px;
      display: block;
    }
  }
`

export { Info }
