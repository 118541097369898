import StudyPlanListContainer from './containers/studyplan-list.container'
import StudyPlanNewContainer from './containers/studyplan-new.container'
import StudyPlanEditContainer from './containers/studyplan-edit.container'
import StudyPlanViewContainer from './containers/studyplan-view.container'

const routes = [
  {
    path: '/plano-estudo/inclusao',
    tag: 'StudyPlanCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/plano-estudo',
        breadcrumbName: 'Plano de Estudo',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: StudyPlanNewContainer,
  },
  {
    path: '/plano-estudo/:id/edicao',
    tag: 'StudyPlanEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/plano-estudo',
        breadcrumbName: 'Plano de Estudo',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: StudyPlanEditContainer,
  },
  {
    path: '/plano-estudo/:id/visualizacao',
    tag: 'StudyPlanList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/plano-estudo',
        breadcrumbName: 'Plano de Estudo',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: StudyPlanViewContainer,
  },
  {
    path: '/plano-estudo',
    tag: 'StudyPlanList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        breadcrumbName: 'Planos de Estudo',
      },
    ],
    component: StudyPlanListContainer,
  },
]

export default routes
