import React, { useEffect, useState, useMemo } from 'react'
import {
  Row,
  Col,
  Form,
  Checkbox,
  Button,
  Table,
  Tooltip,
  Collapse,
  Tabs,
  Modal,
} from 'antd'
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons'
import ModalLessonsDiscipline from './studyplanlessons.discipline-modal.component'
import services from '../studyplan.services'
import notification from '~/shared/Notification'
import PermittedAction from '~/shared/PermittedAction'

function StudyPlanDiscipline(props) {
  const { Panel } = Collapse
  const { TabPane } = Tabs
  const {
    studyPlanComplete,
    idStudyPlan,
    disciplines,
    subjects,
    typeLesson,
    getSubjects,
    isView,
  } = props
  const [studyPlanObject, setStudyPlanObject] = useState(studyPlanComplete)



  const [insideVisibleModal, setVisibleModal] = useState(false);
  const [studyPlanStepId, setStudyPlanStepId] = useState(null);

  const [disciplineId, setDisciplineId] = useState()
  const [disciplineSubjectId, setDisciplineSubjectId] = useState()
  const [studyPlanStepId2, setStudyPlanStepId2] = useState()

  const [lessons, setLessons] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  useEffect(() => {
    setStudyPlanObject(studyPlanComplete);
    setStudyPlanStepId2(studyPlanComplete.studyPlanStep[0].studyPlanStepId)
    setDisciplineSubjectId(studyPlanComplete.studyPlanStep[0].studyPlanStepItem[0].subjects[0].disciplineSubjectId);
    setDisciplineId(studyPlanComplete.studyPlanStep[0].studyPlanStepItem[0].disciplineId);
    setPagination({pageNumber: 1, pageSize: 10,})
  }, [studyPlanComplete]);

  function callModalDelete(params, type) {
    const configModalDelete = {
      title: 'Deseja excluir esse registro?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          if (type === 'lesson') {
            services
              .deleteLesson(params)
              .then(() => {
                notification.success('Excluído com sucesso.')

                resolve()
              })
              .catch(reject)
          } else if (type === 'discipline') {
            services
              .deleteDiscipline(params)
              .then(() => {
                notification.success('Excluído com sucesso.')

                resolve()
              })
              .catch(reject)
          } else if (type === 'week') {
            services
              .deleteWeek(params)
              .then(() => {
                notification.success('Excluído com sucesso.')

                resolve()
              })
              .catch(reject)
          }
        })
      },

      onCancel() { },
    }

    Modal.confirm(configModalDelete)
  }

  function onChangeTabs(targetKey, action, stepId) {
    const paramsDelete = {
      StudyPlanId: idStudyPlan,
      StudyPlanStepId: stepId,
      DisciplineId: Number(targetKey),
    }

    if (action === 'remove') {
      callModalDelete(paramsDelete, 'discipline')
    }
  }

  function deleteWeek(week) {
    const params = {
      studyPlanId: week.studyPlanId,
      studyPlanStepId: week.studyPlanStepId,
    }

    callModalDelete(params, 'week')
  }

  function deleteLesson(rowComplete) {
    const paramsDelete = {
      StudyPlanId: rowComplete.studyPlanId,
      StudyPlanStepId: rowComplete.studyPlanStepId,
      DisciplineId: rowComplete.disciplineId,
      DisciplineSubjectId: rowComplete.disciplineSubjectId,
      DisciplineLessonId: rowComplete.disciplineLessonId,
    }

    callModalDelete(paramsDelete, 'lesson')
  }

  function renderActions(row) {
    return (
      <Button.Group>
        <PermittedAction tag="StudyPlanDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => deleteLesson(row)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function handleSendeditFreeCheck(row) {

    const paramsFree = {
      StudyPlanID: row.studyPlanId,
      StudyPlanStepID: row.studyPlanStepId,
      DisciplineID: row.disciplineId,
      DisciplineSubjectid: row.disciplineSubjectId,
      DisciplineLessonID: row.disciplineLessonId,
    }

    services.updateFreeLesson(paramsFree)
  }

  function CustCheckbox(props) {
    const [isChecked, setIsChecked] = useState(props.check);

    const toggleChange = () => {
      handleSendeditFreeCheck(props.rowEdit);
      setIsChecked(!isChecked);

    }
    useEffect(() => {
      setIsChecked(props.check);
      
    }, [props.check]);
    

    return (
     
      <Checkbox checked={isChecked} id={props.rowEdit.disciplineLessonId} onChange={toggleChange}></Checkbox>
    );
  }

  function renderFreemiumCK(row, index) {

    return (<CustCheckbox check={row.lessonFreemium} rowEdit={row} index={index} />)

  }


  let columns = [
    {

      title: "Free",
      align: 'center',

      render: (row, index) => renderFreemiumCK(row, index),
      //render: (row) => <Checkbox checked={row.lessonFreemium} onChange={handleChecked(row)}></Checkbox>,
    },
    {
      title: 'Aula',
      dataIndex: 'disciplineLessonName',
    },
    {
      title: 'Tipo Aula',
      dataIndex: 'disciplineLessonTypeDescription',
    },
  ]

  if (!isView) {
    columns = [
      ...columns,
      {
        title: 'Ações',
        align: 'center',
        render: (row) => renderActions(row),
      },
    ]
  }

  function callModalLesson(state, discipline) {
    if (discipline) {
      setStudyPlanStepId(discipline ? discipline.studyPlanStepId : null)
      setVisibleModal(state)
    }
  }


  async function _getLessons(studyPlanId, studyPlanStepId, disciplineId, disciplineSubjectId, pageNumber, pageSize ) {
    if (studyPlanId && studyPlanStepId && disciplineId && disciplineSubjectId) {
      const params = {
        StudyPlanId: studyPlanId,
        StudyPlanStepId: studyPlanStepId,
        DisciplineID: disciplineId,
        SubjectId: parseInt(disciplineSubjectId),
        PageNumber: pageNumber,
        PageSize: pageSize
      }
      setLoading(true);

      await services.getLessons(params).then((responseLessons) => {
        setLessons(responseLessons.data)
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      })
    }
  }


  useEffect(() => {

    _getLessons(idStudyPlan, studyPlanStepId2, disciplineId, disciplineSubjectId,pagination.pageNumber,pagination.pageSize)
  }, [disciplineId, disciplineSubjectId, studyPlanStepId2, pagination]);

  

  function CustTable() {
    //_getLessons(idStudyPlan, props.stepId, props.disciplineId, props.subjectSelected)

    return (
      <Table
        size="small"
        columns={columns}
        rowKey={(row) => row.disciplineLessonId}
        dataSource={lessons.content}
        loading={loading}
        pagination={{
          current: pagination.pageNumber,
          pageSizeOptions: [5, 10, 20, 50, 100],
          onChange: (pageNumber, pageSize) => {
            setPagination((pagination) => ({
              pageNumber: pagination.pageSize !== pageSize ? 1 : pageNumber,
              pageSize,
              fromFilter: false,
            }))
          },
          total: lessons.totalRecords,
          showSizeChanger: false,
        }}
        
      />

    )
  };

  const Weeks = useMemo(() => {

    return studyPlanObject ? (
      <Collapse defaultActiveKey={1} ghost>
        <ModalLessonsDiscipline
          disciplines={disciplines}
          subjects={subjects}
          typeLesson={typeLesson}
          getSubjects={getSubjects}
          modalVisible={insideVisibleModal}
          callModal={setVisibleModal}
          idStudyPlan={idStudyPlan}
          isIncluseLesson
          studyPlanStepId={studyPlanStepId}
        />
        {studyPlanObject.studyPlanStep.map((week) =>
          week ? (
            <Panel
              header={
                <Row gutter={16}>
                  <Col xs={24} md={22}>
                    {week.name}
                  </Col>
                  <Col xs={24} md={2}>
                    <Tooltip title="Excluir semana">
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => deleteWeek(week)}
                      />
                    </Tooltip>
                    <Tooltip title="Incluir aulas">
                      <Button
                        type="link"
                        icon={<FileAddOutlined />}
                        onClick={() =>
                          week ? callModalLesson(true, week) : ''
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              }
              key={week.studyPlanStepId}
            >
              <Tabs key="1" tabPosition="left">
                {week.studyPlanStepItem.map((stepItem) => (
                  <TabPane
                    tab={stepItem.disciplineName}
                    key={stepItem.disciplineId}

                  >
                    <Tabs
                      defaultActiveKey="1"
                      type="editable-card"
                      hideAdd
                      closable
                      onChange={(e) => { setDisciplineSubjectId(e); 
                            setDisciplineId(stepItem.disciplineId); 
                            setStudyPlanStepId2(week.studyPlanStepId);
                            setPagination({pageNumber: 1, pageSize: 10,}) }}

                      onEdit={(targetKey, action) =>
                        onChangeTabs(targetKey, action, week.studyPlanStepId)
                      }
                    >
                      {stepItem.subjects.length > 0
                        ? stepItem.subjects.map((subjectSelected) => (

                          <TabPane
                            tab={subjectSelected.disciplineSubjectName}
                            key={subjectSelected.disciplineSubjectId}

                          >

                            <CustTable subjectSelected={subjectSelected.disciplineSubjectId} stepId={week.studyPlanStepId} disciplineId={stepItem.disciplineId} />

                          </TabPane>
                        ))
                        : ''}
                    </Tabs>
                  </TabPane>
                ))}
              </Tabs>
            </Panel>
          ) : (
            ''
          ),
        )}
      </Collapse>
    ) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyPlanObject, columns])

  return <Form>{Weeks}</Form>
}

export default StudyPlanDiscipline
