import { format, parseISO } from 'date-fns'

function getDateFormat(dateValue, type) {
  if (dateValue) {
    const date = parseISO(dateValue)

    let newFormatDate = 'dd/MM/yyyy'

    if (type) newFormatDate = 'yyyy-MM-dd'

    return format(date, newFormatDate)
  }

  return ''
}

export default getDateFormat
