import qs from 'qs'
import api from '~/services/api'

function getParams() {}

const services = {
  save(data) {
    return api.post('/test', data)
  },
  getById(id) {
    return api.get(`/test/${id}`)
  },
  update(data) {
    return api.put('/test', data)
  },
  updateFree(id) {
    return api.put(`/test/${id}`)
  },
  delete(id) {
    return api.delete(`/test/${id}`)
  },
  getGroups() {
    return api.get(`/test/group`)
  },
  getQuestions(filter, pageNumber, pageSize) {
    // Questions
    const params = Object.entries(filter).reduce((params, [key, value]) => {
      if (value !== undefined) {
        return {
          ...params,
          [key]: value,
        }
      }

      return params
    }, {})

    return api.get(`/test/question`, {
      params: { ...params, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // Study Plans
  getStudyPlan(filter, pageNumber, pageSize) {
    const params = Object.entries(filter).reduce((params, [key, value]) => {
      if (value !== undefined) {
        return {
          ...params,
          [key]: value,
        }
      }

      return params
    }, {})

    return api.get(`/test/study-plan`, {
      params: { ...params, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  deleteQuestionOnTest(id, idQuestion) {
    return api.delete(`/test/${id}/question/${idQuestion}`)
  },
  deleteStudyPlanOnTest(id, idStudyPlan) {
    return api.delete(`/test/${id}/study-plan/${idStudyPlan}`)
  },
}

export default services
