import NotAuthorized from './containers/notauthorized.container'

const routes = [
  {
    path: '/nao-autorizado',
    authenticated: true,
    component: NotAuthorized,
  },
]

export default routes
