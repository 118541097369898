import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Collapse,
  Form,
  Tooltip,
  Button,
  Typography,
  DatePicker,
} from 'antd'

import {
  SearchOutlined,
  RedoOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons'

import DisciplinesList from '~/shared/Disciplines'
import PermittedAction from '~/shared/PermittedAction'
import Space from '~/shared/Space'
import Status from '~/shared/Status'
import Table from '~/shared/Table'

import getDateFormat from '~/utils/date-format'

import Columns from '../models/columns'

function LiveClassesListComponent() {
  const [filter, setFilter] = useState({})
  const [dateLiveClass, setDateLiveClass] = useState('')
  const [disciplineId, setDisciplineId] = useState('')

  const { Text } = Typography

  const [formLiveClasses] = Form.useForm()

  function resetFilter() {
    formLiveClasses.resetFields()

    setFilter({})
  }

  const columns = Columns(resetFilter)

  function handleChangeDate(_, dateString) {
    setDateLiveClass(getDateFormat(dateString, true))
  }

  function onFinish(values) {
    const { status } = values

    const inactive = [2, '2'].includes(status)
      ? true
      : [undefined, 0, '0'].includes(status) && undefined

    setFilter({ disciplineId, dateLiveClass, inactive })
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={formLiveClasses} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <DisciplinesList
                  name="disciplineId"
                  setDisciplineId={setDisciplineId}
                />
              </Col>

              <Col xs={24} md={10}>
                <Text>Data</Text>

                <Form.Item name="date" className="margin">
                  <DatePicker
                    onChange={handleChangeDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={4}>
                <Status
                  name="status"
                  placeholder="Escolha o status da aula ao vivo"
                />
              </Col>
            </Row>

            <Row gutter={16} align="end">
              <Col className="filter-form">
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction>
          <Tooltip title="Incluir nova Aula ao Vivo">
            <Link to="/aula-ao-vivo/inclusao/">
              <Button type="primary" icon={<VideoCameraAddOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      {filter && (
        <Table baseURL="/liveclass/list" filter={filter} columns={columns} />
      )}
    </Space>
  )
}

export default LiveClassesListComponent
