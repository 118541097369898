import React from 'react'
import { ThemeProvider as ThemeProviderSC } from 'styled-components'

import theme from '~/styles/theme'

function ThemeProvider(props) {
  const { children } = props

  return <ThemeProviderSC theme={theme}>{children}</ThemeProviderSC>
}

export default ThemeProvider
