import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Tooltip } from 'antd'

import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

import PermittedAction from '~/shared/PermittedAction'

import Modal from '~/shared/Modal'

function RenderActionsButton(data, urlBase, deleteService, resetFilter) {
  const { id } = data

  const history = useHistory()

  return (
    <Button.Group>
      <PermittedAction>
        <Tooltip title="Visualizar">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => history.push(`${urlBase}/${id}/visualizacao`)}
          />
        </Tooltip>

        <Tooltip title="Editar">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => history.push(`${urlBase}/${id}/edicao`)}
          />
        </Tooltip>

        <Tooltip title="Excluir">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              Modal.delete(deleteService, id, () => resetFilter({}))
            }
          />
        </Tooltip>
      </PermittedAction>
    </Button.Group>
  )
}

export default RenderActionsButton
