import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Divider,
  Space,
  Button,
  Typography,
  AutoComplete,
} from 'antd'

import { InfoCircleOutlined } from '@ant-design/icons'

import services from '../discountCoupon.services'

import * as S from '../discountCoupon.styles'

function DiscountCouponFormComponent(props) {
  const [discountCouponType, setDiscountCouponType] = useState('')
  const [partnerSchool, setPartnerSchool] = useState([])
  const [partnerSchoolId, setPartnerSchoolId] = useState('')

  const [
    subscriptionPlanValueDefaultCheck,
    setSubscriptionPlanValueDefaultCheck,
  ] = useState(false)
  const [subscriptionPlanOption, setSubscriptionPlanOption] = useState([])
  const [
    subscriptionPlanValueDefault,
    setSubscriptionPlanValueDefault,
  ] = useState([])

  const [canUpdate, setCanUpdate] = useState(false)

  const [formDiscountCoupon] = Form.useForm()
  const { type, loadingSubmit, discountCoupon, onSubmit } = props
  const { Text } = Typography
  const { Option } = Select

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && discountCoupon) {
      formDiscountCoupon.setFieldsValue(discountCoupon)

      setPartnerSchoolId(discountCoupon.partnerSchoolId)
      setDiscountCouponType(`${discountCoupon.typeId}`)
      setCanUpdate(discountCoupon.canUpdate)

      if (discountCoupon.partnerSchoolId === null)
        formDiscountCoupon.setFieldsValue({ partnerSchoolName: '' })
    }
  }, [isEdit, isView, discountCoupon, formDiscountCoupon])

  useEffect(() => {
    async function getPartnerSchool() {
      const response = await services.partnerSchool()

      const partnerSchoolList = response.data.map((item) => {
        return {
          id: item.id,
          value: item.schoolName,
        }
      })

      setPartnerSchool(partnerSchoolList)
    }

    getPartnerSchool()
  }, [])

  useEffect(() => {
    async function getSubscriptionPlanList() {
      const response = await services.subscriptionPlan(true)

      setSubscriptionPlanOption(response.data)
    }

    if (discountCoupon || isNew) getSubscriptionPlanList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCoupon])

  useEffect(() => {
    setSubscriptionPlanValueDefaultCheck(false)

    async function getSubscriptionPlanValueDefault() {
      const subscriptionPlanDefault = await discountCoupon?.subscriptionPlans?.map(
        (item) => {
          return {
            id: item.id,
            value: item.name,
          }
        },
      )

      const subscriptionPlanValue = await subscriptionPlanDefault?.map(
        (item) => {
          return item.id
        },
      )

      setSubscriptionPlanValueDefaultCheck(subscriptionPlanValue || false)
      setSubscriptionPlanValueDefault(subscriptionPlanValue)
      if (isNew) setSubscriptionPlanValueDefaultCheck(true)
    }

    if (subscriptionPlanOption) getSubscriptionPlanValueDefault()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlanOption, discountCoupon?.subscriptionPlans])

  async function onFinish(values) {
    const {
      code,
      description,
      amountPercent,
      active,
      applyTrialPeriod,
    } = values

    const data = {
      code,
      description,
      type: discountCouponType,
      amountPercent,
      partnerSchoolId,
      subscriptionPlanIds: subscriptionPlanValueDefault,
      active,
      applyTrialPeriod,
    }

    onSubmit(data)
  }

  function handlePartnerSchoolChange(_, option) {
    setPartnerSchoolId(option?.id || null)
  }

  function messageAmountPercent() {
    return (
      <Text className="alert-text">
        <InfoCircleOutlined /> O valor da transação precisa ser maior que R$
        1,00 ou ter o valor igual a R$ 0,00
      </Text>
    )
  }

  return (
    <S.CouponWrapper>
      <Form form={formDiscountCoupon} onFinish={onFinish}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={8}>
            <Text>Cupom de Desconto</Text>

            <Form.Item name="code" rules={requiredRule} hasFeedback={!isView}>
              <Input
                placeholder="Digite o código do cupom"
                maxLength={30}
                disabled={isView || (!canUpdate && !isNew)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={16}>
            <Text>Descrição</Text>

            <Form.Item
              name="description"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Input
                placeholder="Descrição do cupom"
                maxLength={50}
                disabled={isView || (!canUpdate && !isNew)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} align="middle">
          <Col xs={24} md={8}>
            <Text>Tipo do Desconto</Text>

            <Form.Item
              name="typeDescription"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Select
                placeholder="Escolha o tipo do desconto"
                onChange={(e) => setDiscountCouponType(e)}
                disabled={isView || (!canUpdate && !isNew)}
              >
                <Option value="1">Valor</Option>
                <Option value="2">Percentual</Option>
              </Select>
            </Form.Item>
          </Col>

          {discountCouponType === '1' && (
            <Col xs={24} md={8} className="margin-empty">
              <Text>Valor do Desconto</Text>

              <Form.Item
                name="amountPercent"
                rules={requiredRule}
                hasFeedback={!isView}
              >
                <InputNumber
                  placeholder="Valor do Desconto"
                  min={0}
                  max={999999999}
                  className="row-xs"
                  decimalSeparator=","
                  stringMode
                  disabled={isView || (!canUpdate && !isNew)}
                />
              </Form.Item>

              {messageAmountPercent()}
            </Col>
          )}

          {discountCouponType === '2' && (
            <Col xs={24} md={8} className="margin-empty">
              <Text>Porcentagem do Desconto</Text>

              <Form.Item
                name="amountPercent"
                rules={requiredRule}
                hasFeedback={!isView}
              >
                <InputNumber
                  placeholder="Porcentagem do Desconto"
                  min={0}
                  max={100}
                  className="row-xs"
                  decimalSeparator=","
                  stringMode
                  disabled={isView || (!canUpdate && !isNew)}
                />
              </Form.Item>

              {messageAmountPercent()}
            </Col>
          )}

          <Col xs={24} md={8}>
            <Text>Escola Parceira</Text>

            {!!partnerSchool && (
              <Form.Item name="partnerSchoolName" hasFeedback={!isView}>
                <AutoComplete
                  placeholder="Escolha a escola paceira"
                  options={partnerSchool}
                  onSelect={(val, option) =>
                    handlePartnerSchoolChange(val, option)
                  }
                  onChange={(val, option) =>
                    handlePartnerSchoolChange(val, option)
                  }
                  value={partnerSchool.id}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  allowClear
                  disabled={isView || (!canUpdate && !isNew)}
                />
              </Form.Item>
            )}
          </Col>

          {subscriptionPlanValueDefaultCheck && (
            <Col xs={24} md={8}>
              <Text>Planos de Assinaturas</Text>

              <Form.Item
                name="subscriptionPlan"
                hasFeedback={!isView}
                disabled={isView || (!canUpdate && !isNew)}
              >
                <Select
                  mode="multiple"
                  placeholder="Escolha o plano de assinatura"
                  defaultValue={subscriptionPlanValueDefault}
                  onChange={(e) => setSubscriptionPlanValueDefault(e)}
                  disabled={isView || (!canUpdate && !isNew)}
                >
                  {subscriptionPlanOption.map((item) => {
                    const { id, name, shortDescription } = item

                    return (
                      <Option key={id} value={id}>
                        {name} ({shortDescription})
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={8}>
            <Text>Status</Text>

            <Form.Item name="active" valuePropName="checked">
              <Checkbox disabled={isView}>Ativo</Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Aplicar Período Degustação</Text>

            <Form.Item name="applyTrialPeriod" valuePropName="checked">
              <Checkbox disabled={isView}>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to="/cupom-de-desconto">
                {isEdit ? 'Cancelar' : 'Voltar'}
              </Link>
            </Button>

            {!isView && (
              <Button htmlType="submit" type="primary" loading={loadingSubmit}>
                {isNew ? 'Confirmar' : 'Atualizar'}
              </Button>
            )}
          </Space>
        </Row>
      </Form>
    </S.CouponWrapper>
  )
}

export default DiscountCouponFormComponent
