import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import TestFormComponent from '../components/test-form.component'
import notification from '~/shared/Notification'
import services from '../tests.services'
import servicesDisciplines from '../../disciplines/discipline.services'
import servicesTags from '../../questions/question.services'

function TestEditContainer() {
  const params = useParams()
  const { id } = params

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loading, setLoading] = useState([])
  const [group, setGroup] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [tags, setTags] = useState([])
  const [tests, setTests] = useState([])
  const history = useHistory()

  const [dataTest, setDataTest] = useState(null)

  async function getData() {
    const responseTest = await services.getById(id)
    setDataTest(responseTest.data)
  }

  async function getGroup() {
    const responseTags = await services.getGroups()

    const mappedTags = responseTags.data.map((group) => {
      return { label: group.description, value: group.id }
    })

    setGroup(mappedTags)
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getTags() {
    const responseTags = await servicesTags.getTags()

    const mappedTags = responseTags.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setTags(mappedTags)
  }

  async function getTests() {
    const responseTests = await servicesTags.getTests()

    const mappedTags = responseTests.data.map((test) => {
      return { label: test.testName, value: test.testId }
    })

    setTests(mappedTags)
  }

  async function deleteQuestionFromLesson(idQuestion) {
    await services.deleteQuestionOnTest(id, idQuestion)
    notification.success('Excluído com sucesso.')
  }

  async function deleteStudyPlan(idStudyPlan) {
    await services.deleteStudyPlanOnTest(id, idStudyPlan)
    notification.success('Excluído com sucesso.')
  }

  useEffect(() => {
    setLoading(true)
    getData()
    getGroup()
    getDisciplines()
    getTags()
    getTests()
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values, selectedQuestionIds, selectedStudyPlanIds) {
    const data = {
      id,
      name: values.name,
      description: values.description,
      duration: Number(values.duration),
      active: values.active === undefined ? false : values.active,
      restricted: values.restricted === undefined ? false : values.restricted,
      group: values.group,
      questions: selectedQuestionIds,
      studyPlans: selectedStudyPlanIds,
      freemium: values.freemium,
    }

    setLoadingSubmit(true)

    try {
      await services.update(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/simulado')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loading) {
    return <Skeleton active />
  }

  return (
    <TestFormComponent
      id={id}
      type="E"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
      group={group}
      disciplines={disciplines}
      tags={tags}
      tests={tests}
      test={dataTest}
      deleteQuestionFromLesson={deleteQuestionFromLesson}
      deleteStudyPlan={deleteStudyPlan}
    />
  )
}

export default TestEditContainer
