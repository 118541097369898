import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Checkbox,
  Input,
  Button,
  Select,
  Modal,
  Table,
  Tooltip,
} from 'antd'
import { SearchOutlined, RedoOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import notification from '~/shared/Notification'
import services from '../studyplan.services'

function ModalLessonsDisciplineComponent(props) {
  const history = useHistory()
  const [disciplineId, setDisciplineIds] = useState([])
  const [subjectId, setSubjectIds] = useState([])
  const [filterLessons, setFilterLessons] = useState({})
  const [dataSourceTable, setDataSourceTable] = useState()
  const [existDiscipline, setExistDiscipline] = useState(true)
  const [selectedDisciplineIds, setSelectedDisciplineIds] = useState([])
  const [totalRecords, setTotalRecords] = useState()
  const [firstLoad, setFirstLoad] = useState(true)

  const [loadingTable, setLoadingTable] = useState()

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const {
    disciplines,
    subjects,
    typeLesson,
    getSubjects,
    modalVisible,
    callModal,
    idStudyPlan,
    studyPlanStepId,
    valuesForm,
  } = props

  const [form] = Form.useForm()
  const [internalModalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    setModalVisible(modalVisible)
    callModal(modalVisible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible])

  const requiredRule = [{ required: true }]

  function onChangeDiscipline(value) {
    if (value) {
      const disciplineIds = []

      if (value > 0) {
        disciplineIds[0] = value
        const objListDisciplineIds = { disciplineIds }
        getSubjects(objListDisciplineIds)
      } else {
        getSubjects(null)
      }

      setExistDiscipline(false)
    } else {
      setExistDiscipline(true)
    }

    setDisciplineIds(value)
  }

  function onChangeSubject(value) {
    if (value) {
      setSubjectIds(value)
    }
  }

  function resetFilter() {
    form.resetFields()
    setFirstLoad(true)
    setTotalRecords(0)
    setPagination({
      pageNumber: 1,
      pageSize: 10,
    })
    setDataSourceTable([])
  }

  async function onFinishModal(values) {
    setLoadingTable(true)

    const filterLessons = {
      studyplanid: idStudyPlan || 1,
      disciplineid: disciplineId,
      disciplinesubjectid: subjectId,
      DisciplineLessonName: values.DisciplineLessonName,
      LessonType: values.LessonType,
    }

    setFilterLessons(filterLessons)

    try {
      const response = await services.lessonsActives(
        filterLessons,
        pagination.pageNumber,
        pagination.pageSize,
      )
      const dataSource = response.data.content
      const { totalRecords } = response.data

      if (dataSource.length > 0) {
        const dataSourceWithKey = dataSource.map((lesson) => {
          return {
            key: lesson.disciplineLessonId,
            disciplineLessonName: lesson.disciplineLessonName,
            disciplineSubjectName: lesson.disciplineSubjectName,
          }
        })

        if (firstLoad) setFirstLoad(false)
        setTotalRecords(totalRecords)

        setTimeout(() => {
          setLoadingTable(false)
          setDataSourceTable(dataSourceWithKey)
        }, 3000)
      } else {
        setTimeout(() => {
          notification.warning('Não há dados para serem exibidos.')
          setLoadingTable(false)
          setDataSourceTable([])
        }, 3000)
      }
    } catch {
      notification.error('Erro inesperado.')
    }
  }

  const columns = [
    {
      title: 'Aula',
      dataIndex: 'disciplineLessonName',
    },
    {
      title: 'Matéria',
      dataIndex: 'disciplineSubjectName',
    },
  ]

  const rowSelection = {
    onSelect: (record) => {
      const alreadyExist = selectedDisciplineIds.includes(record.key)

      if (alreadyExist) {
        const index = selectedDisciplineIds.indexOf(record.key)
        selectedDisciplineIds.splice(index, 1)
      } else {
        selectedDisciplineIds.push(record.key)
        setSelectedDisciplineIds(selectedDisciplineIds)
      }
    },
    onSelectAll: (selectAll) => {
      if (selectAll) {
        // eslint-disable-next-line array-callback-return
        dataSourceTable.map((lesson) => {
          selectedDisciplineIds.push(lesson.key)
          setSelectedDisciplineIds(selectedDisciplineIds)
        })
      } else {
        setSelectedDisciplineIds([])
      }
    },
  }

  async function saveLessonInStage() {
    if (valuesForm !== undefined && valuesForm != null) {
      const data = {
        name: valuesForm.name,
        shortDescription: valuesForm.shortDescription,
        notes: valuesForm.notes,
        studyPlanTypeId: valuesForm.studyPlanTypeId,
        active: valuesForm.active,
        lessonsCredit: valuesForm.lessonsCredit,
        customerServiceCredit: valuesForm.customerServiceCredit,
        displayTheme: 'Blue',
        disciplineLessonIds: selectedDisciplineIds,
        studyPlanStepTypeId: 2,
        dayOfWeek: null,
        freemium: valuesForm.freemium,
      }

      await services.save(data)
      history.push(`/plano-estudo/`)
    } else {
      const data = {
        studyPlanId: idStudyPlan,
        studyPlanStepId,
        disciplineLessonIds: selectedDisciplineIds,
        studyPlanStepTypeId: 2,
        dayOfWeek: null,
      }

      try {
        await services.saveNewLesson(data)
        notification.success('Cadastrado com sucesso.')
        setModalVisible(false)
        callModal(false)
        window.location.reload()
        history.push(`/plano-estudo/${idStudyPlan}/edicao`)
      } catch {
        notification.error('Erro inesperado.')
      }
    }
  }

  useEffect(() => {
    async function execute() {
      await onFinishModal(form.getFieldsValue(true))
    }

    if (firstLoad) return

    execute()
  }, [pagination])

  useEffect(() => {
    if (internalModalVisible) {
      form.resetFields()
      setFirstLoad(true)
      setTotalRecords(0)
      setPagination({
        pageNumber: 1,
        pageSize: 10,
      })
      setDataSourceTable([])
    }
  }, [internalModalVisible])

  return (
    <Modal
      visible={internalModalVisible}
      title="Inclusão de Aulas"
      width={1000}
      onCancel={() => callModal(false)}
      onOk={() => saveLessonInStage}
      footer={[
        <Button key="back" onClick={() => callModal(false)}>
          Fechar
        </Button>,
        <Button key="submit" type="primary" onClick={saveLessonInStage}>
          Confirmar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinishModal}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={12}>
            <Form.Item name="discipline" rules={requiredRule}>
              <Select
                showSearch
                placeholder="Disciplina"
                options={disciplines}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
                onChange={onChangeDiscipline}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="subject">
              <Select
                showSearch
                placeholder="Matéria"
                options={subjects}
                onChange={onChangeSubject}
                filterOption={(input, option) => {
                  if (typeof option.label === 'object') {
                    return (
                      option?.label?.props?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  )
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col xs={6} md={19}>
            <Form.Item name="DisciplineLessonName">
              <Input placeholder="Nome Aula" maxLength={50} />
            </Form.Item>
          </Col>
          <Col xs={24} md={5}>
            <Form.Item name="LessonType">
              <Select
                showSearch
                placeholder="Tipo"
                options={typeLesson}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="end">
          <Col xs={24} md={1}>
            <Tooltip title="Pesquisar">
              <Button
                htmlType="submit"
                type="primary"
                icon={<SearchOutlined />}
              />
            </Tooltip>
          </Col>

          <Col xs={24} md={1}>
            <Tooltip title="Redefinir Filtros">
              <Button
                type="primary"
                icon={<RedoOutlined />}
                onClick={resetFilter}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col xs={24} md={24}>
            <Table
              rowSelection={{ ...rowSelection }}
              filter={filterLessons}
              dataSource={dataSourceTable}
              columns={columns}
              loading={loadingTable}
              pagination={{
                current: pagination.pageNumber,
                pageSizeOptions: [5, 10, 20, 50, 100],
                onChange: (pageNumber, pageSize) => {
                  setPagination((pagination) => ({
                    pageNumber:
                      pagination.pageSize !== pageSize ? 1 : pageNumber,
                    pageSize,
                    fromFilter: false,
                  }))
                },
                total: totalRecords || 0,
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalLessonsDisciplineComponent
