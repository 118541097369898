import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import TestFormComponent from '../components/test-form.component'
import services from '../tests.services'

function TestViewContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [group, setGroup] = useState([])
  const params = useParams()
  const { id } = params

  const [test, setTest] = useState(null)

  async function getData() {
    const responseTest = await services.getById(id)
    setTest(responseTest.data)
  }

  async function getGroup() {
    const responseTags = await services.getGroups()

    const mappedTags = responseTags.data.map((group) => {
      return { label: group.description, value: group.id }
    })

    setGroup(mappedTags)
  }

  useEffect(() => {
    getData()
    getGroup()
    setLoadingData(false)
  }, [id])

  if (loadingData) return <Skeleton active />

  return <TestFormComponent type="V" test={test} group={group} tags={[]} />
}

export default TestViewContainer
