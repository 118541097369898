import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import GroupFormComponent from '../components/group-form.component'
import services from '../group.services'

function GroupViewContainer() {
  const [loadingData, setLoadingData] = useState(true)

  const [group, setGroup] = useState(null)
  const [permissions, setPermissions] = useState([])

  const params = useParams()

  const { id } = params

  useEffect(() => {
    async function getData() {
      const responseGroup = await services.getById(id)
      const responsePermissions = await services.getPermissions()

      setLoadingData(false)

      setGroup(responseGroup.data)
      setPermissions(responsePermissions.data)
    }

    getData()
  }, [id])

  if (loadingData) return <Skeleton active />

  return <GroupFormComponent type="V" group={group} permissions={permissions} />
}

export default GroupViewContainer
