import React, { useState, useEffect, useCallback } from 'react'

import { Pie } from '@ant-design/charts'

export default function GraphicPieComponent(props) {
  const [percentCheck, setPercentCheck] = useState(false)
  const [config, setConfig] = useState({})

  const { dataSource } = props

  const getPercent = useCallback((value) => {
    const data = value.map((item) => {
      const { percent, subscriptionPlanName, paymentMethodDescription } = item

      return {
        percent,
        type: subscriptionPlanName || paymentMethodDescription,
      }
    })

    setPercentCheck(true)
    setConfig({
      appendPadding: 10,
      data,
      angleField: 'percent',
      colorField: 'type',
      width: 500,
      height: 400,
      autoFit: false,
      radius: 0.8,
      label: {
        type: 'inner',
        autoRotate: false,
        style: {
          fontSize: 15,
          fontWeight: 300,
          textAlign: 'left',
          textBaseline: 'middle',
          shadowColor: 'black',
          shadowBlur: 10,
        },
        formatter: ({ percent }) =>
          `${(percent * 100).toFixed(2).replace('.', ',')}%`,
      },
      tooltip: {
        formatter: (data: Data) => {
          return {
            name: data.type,
            value: `${data.percent.toFixed(2).replace('.', ',')}%`,
          }
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dataSource.details) getPercent(dataSource.details)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource.details])

  return percentCheck && <Pie {...config} />
}
