import React from 'react'

import LoginFormComponent from '../components/login-form.component'

import useAuth from '~/hooks/useAuth'

function LoginContainer() {
  const auth = useAuth()

  function onSubmit(values) {
    auth.signIn(values)
  }

  return <LoginFormComponent loading={auth.loading} onSubmit={onSubmit} />
}

export default LoginContainer
