import qs from 'qs'
import api from '~/services/api'

const services = {
  save(data, config) {
    return api.post('/lesson', data, config)
  },
  getById(id) {
    return api.get(`/lesson/${id}`)
  },
  update(data) {
    return api.put('/lesson', data)
  },
  delete(id) {
    return api.delete(`/lesson/${id}`)
  },
  updateSequence(data) {
    return api.post('lesson/update-sequence', data)
  },
  getTags() {
    return api.get(`/tag/`)
  },
  getTypeLesson() {
    return api.get(`/lesson-type/`)
  },
  getSequence(DisciplineSubjectId, DisciplineId, IsNewDisciplineLesson) {
    return api.get(
      `/lesson/sequence?DisciplineSubjectId=${DisciplineSubjectId}&DisciplineId=${DisciplineId}&IsNewDisciplineLesson=${IsNewDisciplineLesson}`,
    )
  },
  getQuestions(filter, pageNumber, pageSize) {
    const params = Object.entries(filter).reduce((params, [key, value]) => {
      if (value !== undefined) {
        return {
          ...params,
          [key]: value,
        }
      }

      return params
    }, {})

    return api.get(`/lesson/question`, {
      params: { ...params, pageNumber, pageSize },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  deleteQuestionOnLesson(id, idQuestion) {
    return api.delete(`/lesson/${id}/question/${idQuestion}`)
  },
}

export default services
