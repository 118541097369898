import TestListContainer from './containers/test-list.container'
import TestNewContainer from './containers/test-new.container'
import TestEditContainer from './containers/test-edit.container'
import TestViewContainer from './containers/test-view.container'

const routes = [
  {
    path: '/simulado/inclusao',
    tag: 'TestCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/simulado',
        breadcrumbName: 'Simulado',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: TestNewContainer,
  },
  {
    path: '/simulado/:id/edicao',
    tag: 'TestEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/simulado',
        breadcrumbName: 'Simulado',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: TestEditContainer,
  },
  {
    path: '/simulado/:id/visualizacao',
    tag: 'TestList',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/simulado',
        breadcrumbName: 'Simulado',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: TestViewContainer,
  },
  {
    path: '/simulado',
    tag: 'TestList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        breadcrumbName: 'Simulado',
      },
    ],
    component: TestListContainer,
  },
]

export default routes
