import styled from 'styled-components'

const FormWrapper = styled.div`
  .row-100 {
    width: 100%;
  }

  .ant-alert-error.alert-error {
    margin-bottom: 0;
    border: 0;
    background: transparent;
  }

  .ant-alert-error.alert-error .ant-input-number,
  .ant-alert-error.alert-error .ant-form-item-control-input input {
    border-color: #ff4d4f;
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
  }

  .alert-error-col {
    position: relative;
  }

  .alert-error-col .error-icon {
    position: absolute;
    top: 27px;
    right: 17px;
    color: #ff4d4f;
  }

  .ant-input-number {
    width: 100%;
  }

  .data-col {
    .ant-picker.ant-picker-range {
      width: 100%;
    }
  }
`

const PasswordAdd = styled.div`
  display: block;

  .alert-text {
    color: rgba(0, 0, 0, 0.45);
  }
`

const SubscriptionPlan = styled.section`
  .site-page-header {
    padding-top: 0;
  }
`

const PartnerSchool = styled.section``

const CardInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-typography {
    margin-left: 10px;
  }
`

export { FormWrapper, PasswordAdd, SubscriptionPlan, PartnerSchool, CardInfo }
