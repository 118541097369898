import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import StudentManagementPlansFormComponent from '../components/studentManagementPlans-form.component'
import services from '../studentManagement.services'

import notification from '~/shared/Notification'

function StudentManagementPlansNewContainer() {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  async function onSubmit(values) {
    const {
      amount,
      discount,
      amountPaid,
      paymentMethodDescription,
      installments,
      studyPlanActive,
      subscriptionPlanId,
      studentId,
      // studyPlanId,
      startDate,
      endDate,
    } = values

    const paymentMethodId = paymentMethodDescription

    const data = {
      studentId,
      subscriptionPlanId,
      startDate,
      endDate,
      amount,
      discount,
      amountPaid: amountPaid.replace(',', '.'),
      paymentMethodId,
      installments,
      // studyPlanId,
      active: studyPlanActive,
    }

    setLoadingSubmit(true)

    try {
      await services.studentPlanBySubscription(data)
      notification.success('Plano cadastrado com sucesso.')

      history.push(`/aluno/${studentId}/plano-e-assinatura/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  return (
    <StudentManagementPlansFormComponent
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default StudentManagementPlansNewContainer
