import styled from 'styled-components'

const CouponWrapper = styled.div`
  .row-xs {
    width: 100%;
  }

  .margin-empty .ant-row.ant-form-item {
    margin: 0;
  }

  .alert-text {
    margin: 5px 0 10px;
    color: rgba(0, 0, 0, 0.45);
    display: block;
  }

  .ant-row-middle {
    align-items: initial;
  }
`

export { CouponWrapper }
