import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
} from 'antd'

import ModalWeekLessons from './studyplanlessons.week-modal.component'
import ModalWeekStages from './studyplanlessons.discipline-modal.component'
import Weeks from './studyplan-weeks.component'
import Disciplines from './studyplan-disciplines.component'

function StudyPlanFormComponent(props) {
  const { TextArea } = Input

  const [visibleModalWeeks, setVisibleModalWeeks] = useState(false)
  const [visibleModalStages, setVisibleModalStages] = useState(false)

  const [valuesForm, setValuesForm] = useState({})

  const {
    form,
    type,
    loadingSubmit,
    onSubmit,
    studyPlanType,
    disciplines,
    subjects,
    typeLesson,
    getSubjects,
    studyPlanComplete,
    studyPlanStepType,
    getStudyPlanComplete,
  } = props

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  async function callModalWeeks(state) {
    setVisibleModalWeeks(state)
  }

  async function callModalStages(state) {
    setVisibleModalStages(state)
  }

  useEffect(() => {
    if (!visibleModalWeeks) {
      getStudyPlanComplete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleModalWeeks])

  const isWeek =
    isEdit || isView ? studyPlanComplete.studyPlanTypeId === 1 : false
  const isDiscipline =
    isEdit || isView ? studyPlanComplete.studyPlanTypeId === 2 : false

  useEffect(() => {
    if ((isEdit || isView) && studyPlanComplete) {
      form.setFieldsValue(studyPlanComplete)
    }
  }, [isEdit, isView, form, studyPlanComplete])

  const requiredRule = [{ required: true }]

  async function onFinish(values) {
    try {
      if (isNew) {
        setValuesForm(values)
        if (values.studyPlanTypeId === 1) {
          callModalWeeks(true)
        } else if (values.studyPlanTypeId === 2) {
          callModalStages(true)
        }
      } else {
        onSubmit(values)
      }
    } catch {
      // console.log
    }
  }

  return (
    <Form
      form={form}
      initialValues={{ active: true, freemium: false }}
      onFinish={onFinish}
    >
      <ModalWeekLessons
        disciplines={disciplines}
        subjects={subjects}
        typeLesson={typeLesson}
        getSubjects={getSubjects}
        idStudyPlan={isEdit || isView ? studyPlanComplete.id : null}
        valuesForm={isEdit ? null : valuesForm}
        modalVisible={visibleModalWeeks}
        callModal={callModalWeeks}
      />

      <ModalWeekStages
        disciplines={disciplines}
        subjects={subjects}
        typeLesson={typeLesson}
        getSubjects={getSubjects}
        modalVisible={visibleModalStages}
        callModal={callModalStages}
        idStudyPlan={isEdit || isView ? studyPlanComplete.id : null}
        valuesForm={valuesForm}
      />

      <Row gutter={16} align="middle">
        <Col xs={24} md={9}>
          <Form.Item name="name" rules={requiredRule}>
            <Input placeholder="Nome" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name="shortDescription" rules={requiredRule}>
            <Input
              placeholder="Descrição Curta"
              maxLength={60}
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={4}>
          <Form.Item name="studyPlanTypeId" rules={requiredRule}>
            <Select
              showSearch
              placeholder="Tipo"
              options={studyPlanType}
              disabled={isView || isEdit}
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={3}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView}>Ativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} align="middle">
        <Col xs={24} md={5}>
          <Form.Item name="lessonsCredit">
            <InputNumber
              placeholder="Crédito de Aulas por Mês"
              maxLength={5}
              min={1}
              disabled={isView}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={5}>
          <Form.Item name="customerServiceCredit">
            <InputNumber
              placeholder="Crédito de Plantão ao Vivo por Mês"
              maxLength={5}
              min={1}
              disabled={isView}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Form.Item name="notes">
            <TextArea rows={4} placeholder="Notas Gerais" disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={24} md={3}>
          <Form.Item name="freemium" valuePropName="checked">
            <Checkbox disabled={isView}>Freemium</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {studyPlanComplete
        ? studyPlanComplete.studyPlanTypeId === 1 && (
            <Row justify="end">
              <Space>
                <Button
                  type="primary"
                  onClick={() => callModalWeeks(true)}
                  disabled={isView}
                >
                  Incluir semana
                </Button>
              </Space>
            </Row>
          )
        : ''}

      {studyPlanComplete
        ? studyPlanComplete.studyPlanTypeId === 2 &&
          isNew && (
            <Row justify="end">
              <Space>
                <Button
                  type="primary"
                  onClick={() => callModalStages(true)}
                  disabled={isView}
                >
                  Incluir Etapa
                </Button>
              </Space>
            </Row>
          )
        : ''}

      <Divider />

      {(isEdit || isView) && isWeek && (
        <Weeks
          studyPlanComplete={studyPlanComplete}
          idStudyPlan={studyPlanComplete.id}
          disciplines={disciplines}
          subjects={subjects}
          typeLesson={typeLesson}
          getSubjects={getSubjects}
          isWeek
          isDiscipline={false}
          isIncluseLesson={false}
          studyPlanStepId={studyPlanComplete.studyPlanStepId}
          nameWeek=""
          isView={isView}
          studyPlanStepType={studyPlanStepType}
          getstudyPlanComplete={getStudyPlanComplete}
        />
      )}

      {(isEdit || isView) && isDiscipline && (
        <Disciplines
          studyPlanComplete={studyPlanComplete}
          idStudyPlan={studyPlanComplete.id}
          disciplines={disciplines}
          subjects={subjects}
          typeLesson={typeLesson}
          getSubjects={getSubjects}
          isWeek={false}
          isDiscipline
          isIncluseLesson={false}
          isView={isView}
          studyPlanStepId={studyPlanComplete.studyPlanStepId}
        />
      )}

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/plano-estudo">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default StudyPlanFormComponent
