import React, { useEffect, useState } from 'react'

import {
  AutoComplete,
  Row,
  Col,
  Form,
  Tooltip,
  Button,
  Typography,
  DatePicker,
  Input,
  InputNumber,
  Table,
  List,
  Tag,
} from 'antd'

import { FileAddOutlined } from '@ant-design/icons'
import notification from '~/shared/Notification'
import Space from '~/shared/Space'
import services from '../massInclusionStudents.services'
import getDateFormat from '~/utils/date-format'
import getDateTimeFormat from '~/utils/date-time-format'
import moment from 'moment'

function MassInclusionStudentsListComponent() {
  const [
    partnerSchoolExpirationDate,
    setPartnerSchoolExpirationDate,
  ] = useState('')
  const [partnerSchoolId, setPartnerSchoolId] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [partnerSchool, setPartnerSchool] = useState([])
  const [file, setFile] = useState({})

  const { Text, Title } = Typography

  const [formMassInclusionStudents] = Form.useForm()

  const initialValues = {
    creditQuantity: 0,
    creditMentorships: 0,
  }

  const requiredRule = {
    required: true,
    message: 'Campo Obrigatório',
  }

  function handleChangeDate(_, dateString) {
    setPartnerSchoolExpirationDate(getDateFormat(dateString, true))
  }
  async function getSubscriptionPlans() {
    const response = await services.getSubscriptionPlan()

    const subscriptPlan = response.data.map((item) => {
      return {
        id: item.id,
        value: item.name,
      }
    })

    setSubscriptionPlan(subscriptPlan)
  }
  async function getPartnerSchools() {
    const response = await services.getPartnerSchool()

    const partnerSchoolList = response.data.map((item) => {
      return {
        id: item.id,
        value: item.schoolName,
      }
    })

    setPartnerSchool(partnerSchoolList)
  }
  function handlePartnerSchoolChange(_, option) {
    setPartnerSchoolId(option.id)
  }

  function onFinish(values) {
    const data = new FormData()
    setLoading(true)
    data.append('PartnerSchoolId', partnerSchoolId)
    data.append('PartnerSchoolExpirationDate', partnerSchoolExpirationDate)
    data.append('QtdPrivateClass', values.creditQuantity)
    data.append('QtdMentorShip', values.creditMentorships)
    data.append('File', file)

    services
      .includeStudents(data)
      .then((resp) => {
        const dateFormatOptions = {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }
        const todayDate = new Date().toLocaleDateString(
          'pt-BR',
          dateFormatOptions,
        )

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([resp.data]))
        link.download = `Relatório Importação Estudantes ${todayDate}.xlsx`
        document.body.appendChild(link)
        link.click()
        notification.success('Usúarios inseridos com sucesso!')
      })
      .catch((error) => {
        notification.error(
          'Erro ao processar a planilha, favor verificar as informações.',
        )
      }).finally(()=>{
        setLoading(true)
        getLogs()
        formMassInclusionStudents.resetFields()       
      })
  }

  const [logsInsert, setLogInsert] = useState([])
  const [loading, setLoading] = useState(false)

  function getLogs(){
    setLoading(true)
    const arrayLogsParseErr = []
    
    services.getLogs().then((resp)=>{

      resp.data.forEach((e)=>{
        arrayLogsParseErr.push({...e, errors:JSON.parse(e.description)})
      })      
     
      setLogInsert(arrayLogsParseErr)
      setLoading(false)
    })
  }
  

  function formatErros(err){
    let errjsxFormated = null    
   
    if(err){   
    
      errjsxFormated = err.map(e => {
        return <List size='small'> <List.Item><Text><b>Linha: </b>{e.Line} -<b>Erro: </b>{e.Description} </Text> </List.Item></List>
     });
    }
    
    return errjsxFormated
  }

  function renderTag(status) {
    return status === 'Success' ? (
      <Tag color="green">Sucesso</Tag>
    ) : (
      <Tag color="red">Erro</Tag>
    )
  }


  const columns = [
    {
      title: 'Data Criação',
      dataIndex: 'createdDate',     
      render: (value) => getDateTimeFormat(value)
    },
    {
      title: 'Usuário',
      dataIndex: 'user',
    },
    {
      title: "Status",
      dataIndex: 'status',
      render: (sts) => renderTag(sts)
    },
    {
      title: "Observação",
      dataIndex: 'errors',
      width: '50%',
      render: (err) => formatErros(err)
    }      
  ]

  function disabledDate(current) {
    const weekStart = moment().startOf('week');
    const weekEnd = moment().endOf('week');
    return !(
      weekStart.isSameOrBefore(current) && weekEnd.isSameOrBefore(current)
    );
  }

  useEffect(() => {
    getSubscriptionPlans()
    getPartnerSchools()
    getLogs()
  }, [])

  return (
    <Space direction="vertical">
      <Form
        form={formMassInclusionStudents}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          {!!partnerSchool && (
            <Col xs={24} lg={12} xl={6} xxl={6}>
              <Text>Escola Parceira</Text>

              <Form.Item name="partnerSchool" rules={[requiredRule]}>
                <AutoComplete
                  placeholder="Escola Parceira"
                  options={partnerSchool}
                  onSelect={(val, option) =>
                    handlePartnerSchoolChange(val, option)
                  }
                  value={subscriptionPlan.id}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} lg={12} xl={4} xxl={4}>
            <Text>Data Expiração</Text>

            <Form.Item name="date" className="margin" rules={[requiredRule]}>
              <DatePicker
                 disabledDate={disabledDate}
                onChange={handleChangeDate}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={6} xxl={6}>
            <Text>Enviar arquivo</Text>
            <Form.Item name="file" className="margin" rules={[requiredRule]}>
              <Input
                type="file"
                disabled={false}
                onChange={(e) => setFile(e.target.files[0])}
                multiple={false}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={5} xl={4} xxl={3}>
            <Text>Créditos Aulas Particulares</Text>
            <Form.Item name="creditQuantity" className="margin">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={7} xl={4} xxl={3}>
            <Text>Créditos Mentorias</Text>
            <Form.Item name="creditMentorships" className="margin">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} align="end">
          <Col className="filter-form">
            <Space>
              <Tooltip title="Enviar">
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<FileAddOutlined />}
                  disabled={loading}
                >
                  Enviar
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
      </Form>
      <Title level={3}>{`Últimos ${logsInsert.length} logs de inserção`}</Title>
      <Table      
      rowKey={1}
      loading={loading}
      dataSource={logsInsert}
      columns={columns}    
      pagination={false} 
    />

    </Space>
  )
}

export default MassInclusionStudentsListComponent
