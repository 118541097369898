import React, { useEffect } from 'react'

// import './config'
import './plugin'

function getImages(img) {
  console.log(img)
}

function Tinymce() {
  useEffect(() => {
    window.tinymce.init({
      selector: '.tinymce',
      language: 'pt_BR',
      height: 300,
      plugins: ['lists, link, code image'],

      external_plugins: {
        mathjax:
          'https://cdn.jsdelivr.net/npm/@dimakorotkov/tinymce-mathjax@1.0.4/plugin.min.js',
      },
      mathjax: {
        lib: 'https://cdn.jsdelivr.net/npm/mathjax@3.0.5/es5/tex-mml-chtml.js', // required path to mathjax
        // symbols: { start: '%', end: '%' },
        // className: 'math-tex', // optional: mathjax element class
        // configUrl: '/config.js',
      },
      toolbar:
        'undo redo | styleselect | ' +
        'bold italic backcolor  | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'mathjax | removeformat | image code',
      images_upload_url: 'upload.php',
      images_upload_handler(blobInfo, success, failure) {},
    })
  }, [])

  return (
    <>
      <textarea className="tinymce" />
    </>
  )
}

export default Tinymce
