import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Row,
  DatePicker,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Button,
  Checkbox,
  Typography,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  FileExcelOutlined,
} from '@ant-design/icons'
import getDateTimeFormat from '~/utils/date-time-format'
import DisciplinesList from '~/shared/Disciplines'
import SubjectsList from '~/shared/Subject'
import FeedbacksManagementGraphicComponent from './feedbacksManagement-graphic.component'
import FeedbacksManagementTableComponent from './feedbacksManagement-table.component'
import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'
import getDateFormat from '~/utils/date-format'
import services from '../feedbacksManagement.services'

function FeedbacksManagementComponentList() {
  const [filter, setFilter] = useState({})
  const [feedbackDateFormatted, setFeedbackDateFormatted] = useState('')
  const [disciplineId, setDisciplineId] = useState('')
  const [disciplineSubjectId, setDisciplineSubjectId] = useState('')
  const [isLiveClassChecked, setIsLiveClassChecked] = useState(false)
  const [dateFiltersTable, setDateFiltersTable] = useState({
    BeginDate: null,
    EndDate: null,
  })

  const history = useHistory()
  const [form] = Form.useForm()
  const [formFeedback] = Form.useForm()

  const { Text } = Typography

  const filterForm = {
    marginTop: '20px',
  }

  function view(id) {
    const isLiveClass = isLiveClassChecked ? 'aulaAoVivo' : 'aula'
    history.push(`/gestao-feedback/${id}/visualizacao/${isLiveClass}`)
  }

  function renderActions(id) {
    return (
      <Button.Group>
        <PermittedAction tag="ManagementFeedbackList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Nome da disciplina',
      dataIndex: 'disciplineName',
    },
    {
      title: 'Nome da matéria',
      dataIndex: 'disciplineSubjectName',
    },
    {
      title: isLiveClassChecked ? 'Data da aula' : 'Nome da aula',
      dataIndex: isLiveClassChecked ? 'dataLiveClass' : 'disciplineLessonName',
      // eslint-disable-next-line react/display-name
      render: (value) =>
        isLiveClassChecked && value ? getDateTimeFormat(value) : value,
    },
    {
      title: 'Ações',
      dataIndex: isLiveClassChecked
        ? 'idDisciplineLiveClass'
        : 'idDisciplineLesson',
      align: 'center',
      render: (id) => renderActions(id),
    },
  ]

  function resetFeedbackFilter() {
    formFeedback.resetFields()

    setDateFiltersTable({
      BeginDate: '',
      EndDate: '',
    })
  }

  function resetFilter() {
    form.resetFields()
    setIsLiveClassChecked(false)
    setDisciplineId('')
    setDisciplineSubjectId('')
    setFeedbackDateFormatted('')
    setFilter({
      liveClass: false,
    })
  }
  function handleChangeDate(_, dateString) {
    setFeedbackDateFormatted(getDateFormat(dateString, true))
  }

  async function handleExportExcel() {
    const { className } = form.getFieldsValue()

    let data = {}

    if (isLiveClassChecked) {
      data = {
        createdDateForLiveClass: feedbackDateFormatted,
        idDisciplineLiveClass: disciplineId,
        idDisciplineSubjectLiveClass: disciplineSubjectId,
      }
    } else {
      data = {
        createdDate: feedbackDateFormatted,
        idDiscipline: disciplineId,
        idDisciplineSubject: disciplineSubjectId,
        disciplineLessonName: className,
      }
    }

    const response = await services.getFeedbacksExportExcel(data)

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([response.data]))
    link.download = `Feedback_${new Date().getTime()}.xlsx`
    document.body.appendChild(link)
    link.click()
  }

  function handleChangeDateFeedback(_, dateString) {
    console.log(_, dateString)
  }

  function onChangeLiveClass(event) {
    if (event.target.checked) {
      setIsLiveClassChecked(true)
      setFilter({
        ...filter,
        liveClass: true,
      })
    } else {
      setIsLiveClassChecked(false)
      setFilter({
        ...filter,
        liveClass: false,
      })
    }
  }
  function onFinish(values) {
    const data = {
      IdDiscipline: disciplineId,
      IdDisciplineSubject: disciplineSubjectId,
      disciplineLessonName: values.className,
      createdDate: feedbackDateFormatted,
      liveClass: isLiveClassChecked,
    }

    setFilter(data)
  }

  const onFinishTableFeedBack = (values) => {
    const { beginDate, endDate } = values

    const formattedBeginDate = beginDate?.format('YYYY-MM-DD')
    const formattedEndDate = endDate?.format('YYYY-MM-DD')

    setDateFiltersTable({
      BeginDate: formattedBeginDate,
      EndDate: formattedEndDate,
    })
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={formFeedback} onFinish={onFinishTableFeedBack}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Text>Data Inicio</Text>
                <Form.Item name="beginDate">
                  <DatePicker
                    placeholder="Escolha a data"
                    onChange={handleChangeDateFeedback}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Text>Data Fim</Text>
                <Form.Item name="endDate">
                  <DatePicker
                    placeholder="Escolha a data"
                    onChange={handleChangeDateFeedback}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col style={filterForm}>
                <Space>
                  {/* <Tooltip title="Exportar no Excel">
                    <Button
                      type="primary"
                      icon={<FileExcelOutlined />}
                      onClick={handleExportExcel}
                    />
                  </Tooltip> */}
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFeedbackFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <FeedbacksManagementTableComponent
            apiMethod={services.getLastAccessedSubscriptionPlans}
            apiParams={{
              PageSize: 5,
              ...dateFiltersTable,
            }}
            tableTitle="Últimos Planos de Assinatura Acessados"
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <FeedbacksManagementTableComponent
            apiMethod={services.getMostAccessedSubscriptionPlans}
            apiParams={{
              PageSize: 5,
              ...dateFiltersTable,
            }}
            tableTitle="Planos de Assinatura mais Acessados"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <FeedbacksManagementTableComponent
            apiMethod={services.getMostAccessedDisciplineSubject}
            apiParams={{
              PageSize: 5,
              ...dateFiltersTable,
            }}
            tableTitle="Matérias mais Acessadas"
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <FeedbacksManagementTableComponent
            apiMethod={services.getMostAccessedDisciplineLesson}
            apiParams={{
              PageSize: 5,
              ...dateFiltersTable,
            }}
            tableTitle="Aulas mais acessadas"
          />
        </Col>
      </Row>

      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <DisciplinesList
                  name="disciplineName"
                  setDisciplineId={setDisciplineId}
                />
              </Col>

              <Col xs={24} md={8}>
                <SubjectsList
                  name="disciplineSubjectName"
                  isView={!disciplineId}
                  setDisciplineSubjectId={setDisciplineSubjectId}
                  disciplineId={disciplineId}
                  form={form}
                />
              </Col>
              <Col xs={24} md={8}>
                <Text>Data do Feedback</Text>
                <Form.Item name="feedbackDate">
                  <DatePicker
                    placeholder="Escolha a data"
                    onChange={handleChangeDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              {!isLiveClassChecked && (
                <Col xs={24} md={8}>
                  <Text>Nome da Aula</Text>
                  <Form.Item name="className">
                    <Input placeholder="Nome da Aula" disabled={false} />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={2} style={filterForm}>
                <Text> Aula ao vivo ? </Text>
                <Checkbox
                  onChange={onChangeLiveClass}
                  checked={isLiveClassChecked}
                />
              </Col>

              <Col style={filterForm}>
                <Space>
                  <Tooltip title="Exportar no Excel">
                    <Button
                      type="primary"
                      icon={<FileExcelOutlined />}
                      onClick={handleExportExcel}
                    />
                  </Tooltip>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <FeedbacksManagementGraphicComponent filter={filter} />
      <Table baseURL="/feedback" filter={filter} columns={columns} />
    </Space>
  )
}

export default FeedbacksManagementComponentList
