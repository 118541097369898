import React, { useCallback, useEffect, useState } from 'react'

import { Form, AutoComplete, Typography } from 'antd'

import services from './subject.services'

function SubjectsList({
  name,
  hasFeedback,
  rules,
  isView,
  setDisciplineSubjectId,
  disciplineId,
  form,
}) {
  const [subjects, setSubjects] = useState([])

  const { Text } = Typography

  useEffect(() => {
    async function getSubjectsList() {
      if (disciplineId === '') form.resetFields([name])
      const { data } = await services.getSubjects({
        disciplineId,
      })

      const subjectsList = data?.map(({ id, name }) => {
        return {
          id,
          value: name,
        }
      })

      setSubjects(subjectsList)
    }
    getSubjectsList()
  }, [disciplineId, form, name])

  function handleSubjectChange(_, option) {
    if (setDisciplineSubjectId) setDisciplineSubjectId(option?.id || '')
  }

  return (
    <>
      <Text>Matéria</Text>

      {!!subjects && (
        <Form.Item name={name} hasFeedback={hasFeedback} rules={rules}>
          <AutoComplete
            placeholder="Escolha a matéria"
            options={subjects}
            onSelect={(val, option) => handleSubjectChange(val, option)}
            onChange={(val, option) => handleSubjectChange(val, option)}
            value={subjects.id}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            allowClear
            disabled={isView}
          />
        </Form.Item>
      )}
    </>
  )
}

export default SubjectsList
