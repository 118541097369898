function validatePermission(permissions, tag) {
  const findedPermission =
    permissions && tag
      ? permissions.find((permission) => {
          return permission === tag
        })
      : true

  return !!findedPermission
}

export default validatePermission
