import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import FeedbacksFormComponent from '../components/feedbacksManagement-form.component'
import services from '../feedbacksManagement.services'

function FeedbacksManagementViewContainer() {
  const [loadingData, setLoadingData] = useState(true)

  const params = useParams()
  const { id, liveClass } = params
  const isLiveClass = liveClass === 'aulaAoVivo'
  const [feedback, setFeedback] = useState(null)

  async function getData() {
    const responseFeedback = await services.getById(id, isLiveClass)

    setLoadingData(false)

    setFeedback(responseFeedback.data)
  }

  useEffect(() => {
    getData()

    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loadingData) return <Skeleton active />

  return (
    <>
      <FeedbacksFormComponent
        type="V"
        feedback={feedback}
        isLiveClass={isLiveClass}
      />
    </>
  )
}

export default FeedbacksManagementViewContainer
