/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  string: {
    min: 'Campo deve possuir no mínimo ${min} caracteres.',
  },
  types: {
    email: 'E-mail inválido.',
  },
  required: 'Campo obrigatório.',
}

export default validateMessages
