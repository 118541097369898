import SubscriptionPlanList from './containers/subscriptionPlan-list.container'

const routes = [
  {
    path: '/dashboard-assinatura',
    tag: 'SubscriptionPlanDashboard',
    breadcrumb: [
      {
        breadcrumbName: 'Assinatura',
      },
    ],
    authenticated: true,
    component: SubscriptionPlanList,
  },
]

export default routes
