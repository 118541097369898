import api from '~/services/api'

const services = {
  getById(id, liveClass = false) {
    return api.get(`/feedback/${id}?liveClass=${liveClass}`)
  },
  getFeedbacksGraphicData(filter) {
    const { IdDiscipline, liveClass } = filter
    return api.get(`/feedback/graphic`, {
      params: {
        IdDiscipline,
        liveClass,
      },
    })
  },
  getFeedbacksExportExcel(filters) {
    return api.get('/feedback/export/excel', {
      params: filters,
      responseType: 'blob',
    })
  },
  getLastAccessedSubscriptionPlans(params = {}) {
    return api.get('/dashboard/last-accessed-subscription-plans', {
      params,
    })
  },

  getMostAccessedSubscriptionPlans(params = {}) {
    return api.get('/dashboard/most-accessed-subscription-plans', {
      params,
    })
  },

  getMostAccessedDisciplineSubject(params = {}) {
    return api.get('/dashboard/most-accessed-discipline-subject', {
      params,
    })
  },

  getMostAccessedDisciplineLesson(params = {}) {
    return api.get('/dashboard/most-accessed-discipline-lesson', {
      params,
    })
  },
}

export default services
