import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'

import GroupFormComponent from '../components/group-form.component'
import services from '../group.services'

import notification from '~/shared/Notification'

function GroupNewContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [permissions, setPermissions] = useState([])

  const history = useHistory()

  useEffect(() => {
    async function getData() {
      const response = await services.getPermissions()

      setLoadingData(false)
      setPermissions(response.data)
    }

    getData()
  }, [])

  async function onSubmit(values) {
    const { name, active, permissions } = values

    if (!permissions.length) {
      notification.error('É necessário informar ao menos uma Permissão.')
      return
    }

    const data = {
      name,
      active,
      permissions,
    }

    setLoadingSubmit(true)

    try {
      await services.save(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/grupo-usuario')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <GroupFormComponent
      type="N"
      permissions={permissions}
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default GroupNewContainer
