import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Skeleton, Form } from 'antd'
import DisciplineSubjectFormComponent from '../components/disciplinelesson-form.component'
import services from '../disciplinelesson.services'
import notification from '~/shared/Notification'
import servicesSubjects from '../../disciplinesubjects/disciplinesubjects.services'
import servicesQuestion from '../../questions/question.services'

function DisciplineLessonNewContainer() {
  const history = useHistory()
  const [form] = Form.useForm()
  const params = useParams()
  const { iddiscipline, idsubject } = params
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [subject, setSubject] = useState({})
  const [tags, setTags] = useState([])
  const [tagsQuestions, setTagsQuestion] = useState([])
  const [typeLesson, setTypeLesson] = useState([])
  const [sequence, setSequence] = useState([])

  const [disciplineId, setDisciplineId] = useState(null)
  const [disciplineSubjectId, setDisciplineSubjectId] = useState(null)

  async function getData() {
    const responseSubject = await servicesSubjects.getById(idsubject)
    setSubject(responseSubject.data)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      }
    })
    setTags(mappedTags)
  }

  async function getTagsForQuestion() {
    const responseTags = await servicesQuestion.getTags()

    const mappedTags = responseTags.data.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      }
    })
    setTagsQuestion(mappedTags)
  }

  async function getTypeLesson() {
    const responseTypeLesson = await services.getTypeLesson()

    const mappedTypes = responseTypeLesson.data.map((type) => {
      return {
        label: type.description,
        value: type.id,
      }
    })
    setTypeLesson(mappedTypes)
  }

  async function getSequence() {
    const responseSequence = await services.getSequence(
      idsubject,
      iddiscipline,
      true,
    )

    const mappedSequence = responseSequence.data.map((sequence) => {
      return {
        label: sequence,
        value: sequence,
      }
    })
    setSequence(mappedSequence)
  }

  useEffect(() => {
    getData()
    getTags()
    getTagsForQuestion()
    getTypeLesson()
    getSequence()
    setLoadingData(false)
    setDisciplineId(iddiscipline)
    setDisciplineSubjectId(idsubject)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsubject])

  async function onSubmit(values) {
    const formData = new FormData()
    formData.append('pdf', values.pdf)
    formData.append('name', values.name)
    formData.append('displaySequence', values.displaySequence)
    formData.append('active', values.active)
    formData.append('route', values.route)

    if (values.tags !== '' && values.tags !== undefined) {
      values.tags.forEach((item) => {
        formData.append('tags', item)
      })
    }

    formData.append('videoId', values.videoId)
    formData.append('disciplineLessonTypeId', values.disciplineLessonTypeId)
    formData.append('disciplineId', values.disciplineId)
    formData.append('DisciplineSubjectId', values.DisciplineSubjectId)
    formData.append('sendEssay', values.sendEssay)
    const questionsArray = values.questions

    questionsArray.forEach((item) => {
      formData.append('Questions', item)
    })
    setLoadingSubmit(true)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    try {
      await services.save(formData, config)
      notification.success('Cadastrado com sucesso.')
      history.push(
        `/disciplina/${disciplineId}/materia/${disciplineSubjectId}/aulas`,
      )
      getTags()
      getTypeLesson()
      getSequence()
      setLoadingSubmit(false)
      setLoadingSubmit(true)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <DisciplineSubjectFormComponent
      id={null}
      type="N"
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
      subject={subject}
      form={form}
      tags={tags}
      tagsQuestions={tagsQuestions}
      typeLesson={typeLesson}
      displaySequence={sequence}
      loadTags={getTags}
      loadTypes={getTypeLesson}
      loadSequence={getSequence}
      disciplineId={disciplineId}
      disciplineSubjectId={disciplineSubjectId}
    />
  )
}

export default DisciplineLessonNewContainer
