import React, { useState, useEffect } from 'react'
import { Row, Form, Input, Button } from 'antd'
import { CodeOutlined, LockOutlined } from '@ant-design/icons'
import Box from '~/shared/Box'
import PasswordStrenght from '~/shared/PasswordStrenght'

function FirstAccessFormComponent(props) {
  const { codeUser } = props

  const [newPassword, setNewPassword] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ resetPasswordCode: codeUser })
  }, [codeUser, form])

  const { loading, onSubmit: onFinish } = props

  const requiredRule = [{ required: true }]
  const minLengthRule = [{ min: 6 }]

  const matchPasswordRule = [
    (instance) => ({
      validator(_, value) {
        if (!value || instance.getFieldValue('newPassword') === value)
          return Promise.resolve()

        return Promise.reject(new Error('As senhas não coincidem.'))
      },
    }),
  ]

  return (
    <Form name="new-password" size="large" form={form} onFinish={onFinish}>
      <Form.Item name="resetPasswordCode" rules={requiredRule} hasFeedback>
        <Input
          prefix={<CodeOutlined />}
          placeholder="Código"
          maxLength={50}
          allowClear
          disabled
        />
      </Form.Item>

      <Form.Item
        name="newPassword"
        rules={[...requiredRule, ...minLengthRule]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Senha"
          maxLength={16}
          onChange={(event) => setNewPassword(event.target.value)}
          allowClear
        />
      </Form.Item>

      {newPassword && (
        <Box mb="24px">
          <PasswordStrenght password={newPassword} />
        </Box>
      )}

      <Form.Item
        name="newPasswordConfirm"
        rules={[...requiredRule, ...matchPasswordRule]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Confirmar Senha"
          maxLength={16}
          allowClear
        />
      </Form.Item>

      <Row justify="end">
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          shape="round"
          loading={loading}
        >
          Confirmar
        </Button>
      </Row>
    </Form>
  )
}

export default FirstAccessFormComponent
