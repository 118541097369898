const theme = {
  fontFamily: 'Futura PT',
  primaryColor: '#46d83f',
  secondaryColor: '#403edc',
  success: '#46d83f',
  error: '#ff4d4f',
  warning: '#faad14',
  purple: '#88398e',
  blue: '#5ca2d3',
}

module.exports = theme
