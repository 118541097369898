import ForgetPassword from './containers/forgetpassword.container'

const routes = [
  {
    path: '/esqueceu-senha',
    title: 'Esqueceu sua senha',
    component: ForgetPassword,
  },
]

export default routes
