import React from 'react'

import { Form, Typography, Select } from 'antd'

function Status({ name, placeholder, defaultValue }) {
  const { Text } = Typography
  const { Option } = Select

  return (
    <>
      <Text>Status</Text>

      <Form.Item name={name}>
        <Select placeholder={placeholder} defaultValue={defaultValue || '0'}>
          <Option value="0">Todos</Option>
          <Option value="1">Ativos</Option>
          <Option value="2">Inativos</Option>
        </Select>
      </Form.Item>
    </>
  )
}

export default Status
