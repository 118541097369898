import DisciplineListContainer from './containers/discipline-list.container'
import DisciplineNewContainer from './containers/discipline-new.container'
import DisciplineEditContainer from './containers/discipline-edit.container'
import DisciplineViewContainer from './containers/discipline-view.container'

const routes = [
  {
    path: '/disciplina/inclusao',
    tag: 'DisciplineCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: DisciplineNewContainer,
  },
  {
    path: '/disciplina/:id/edicao',
    tag: 'DisciplineEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: DisciplineEditContainer,
  },
  {
    path: '/disciplina/:id/visualizacao',
    tag: 'DisciplineList',
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        path: '/disciplina',
        breadcrumbName: 'Disciplina',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: DisciplineViewContainer,
  },
  {
    path: '/disciplina',
    tag: 'DisciplineList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Configurações',
      },
      {
        breadcrumbName: 'Disciplina',
      },
    ],
    component: DisciplineListContainer,
  },
]

export default routes
