import PartnerSchoolList from './containers/partnerSchool-list.container'
import PartnerSchoolNew from './containers/partnerSchool-new.container'
import PartnerSchoolView from './containers/partnerSchool-view.container'
import PartnerSchoolEdit from './containers/partnerSchool-edit.container'

const routes = [
  {
    path: '/escola-parceira',
    tag: '',
    breadcrumb: [
      {
        breadcrumbName: 'Escolas Parceiras',
      },
    ],
    authenticated: true,
    component: PartnerSchoolList,
  },
  {
    path: '/escola-parceira/inclusao',
    tag: '',
    breadcrumb: [
      {
        path: '/escola-parceira',
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: PartnerSchoolNew,
  },
  {
    path: '/escola-parceira/:idPartnerSchool/visualizacao',
    tag: '',
    breadcrumb: [
      {
        path: '/escola-parceira',
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: PartnerSchoolView,
  },
  {
    path: '/escola-parceira/:idPartnerSchool/edicao',
    tag: '',
    breadcrumb: [
      {
        path: '/escola-parceira',
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: PartnerSchoolEdit,
  },
]

export default routes
