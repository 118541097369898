import React from 'react'
import { Route as ReactRoute, Redirect } from 'react-router-dom'

import { DefaultLayout, AuthLayout } from '~/pages/_layouts'

import useAuth from '~/hooks/useAuth'

import validatePermission from '~/utils/validate-permission'

function Route(props) {
  const {
    path,
    title,
    tag,
    breadcrumb,
    component: Component,
    authenticated: authenticatedRoute,
  } = props

  const auth = useAuth()

  const { signed, permissions } = auth

  const isSigned = signed

  if (!isSigned && authenticatedRoute) return <Redirect to="/login" />
  if (isSigned && !authenticatedRoute) return <Redirect to="/" />
  if (isSigned && !validatePermission(permissions, tag))
    return <Redirect to="/nao-autorizado" />

  const Layout = isSigned ? DefaultLayout : AuthLayout

  return (
    <ReactRoute
      path={path}
      render={() => (
        <Layout breadcrumb={breadcrumb} title={title}>
          <Component />
        </Layout>
      )}
    />
  )
}

export default Route
