import api from '~/services/api'

const services = {
  subscriptionPlan() {
    return api.get('/subscriptionplan/autocomplete')
  },
  partnerSchool() {
    return api.get('/partnerschool/active')
  },
  getDashboardExportExcel(filters) {
    return api.get('/subscriptionplan/dashboard/excel', {
      params: filters,
      responseType: 'blob',
    })
  },
}

export default services
