import React from 'react'

import ConfigProvider from './config'
import ThemeProvider from './theme'
import AuthProvider from './auth'

function Providers(props) {
  const { children } = props

  return (
    <ConfigProvider>
      <ThemeProvider>
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default Providers
