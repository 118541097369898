import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import QuestionFormComponent from '../components/question-form.component'
import services from '../question.services'
import servicesDisciplines from '../../disciplines/discipline.services'

function QuestionViewContainer() {
  const [loadingData, setLoadingData] = useState(true)

  const params = useParams()
  const { id } = params

  const [question, setQuestion] = useState(null)
  const [disciplines, setDisciplines] = useState([])
  const [tags, setTags] = useState([])
  const [typeResolution, setTypeResolution] = useState([])

  async function getData() {
    const responseQuestion = await services.getById(id)

    setLoadingData(false)

    setQuestion(responseQuestion.data)
  }

  async function getDisciplines() {
    const responseDiscipline = await servicesDisciplines.disciplineActive()

    const mappedDisciplines = responseDiscipline.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setDisciplines(mappedDisciplines)
  }

  async function getTags() {
    const responseTags = await services.getTags()

    const mappedTags = responseTags.data.map((discipline) => {
      return { label: discipline.name, value: discipline.id }
    })

    setTags(mappedTags)
  }

  async function getTypeResolutionId() {
    const typeResolution = [
      {
        value: 1,
        label: 'Sem Resolução',
      },
      {
        value: 2,
        label: 'Conteúdo Texto',
      },
      {
        value: 3,
        label: 'Vídeo',
      },
    ]

    const mappedTypeResolution = typeResolution.map((type) => {
      return { label: type.label, value: type.value }
    })

    setTypeResolution(mappedTypeResolution)
  }

  useEffect(() => {
    getData()
    getDisciplines()
    getTags()
    getTypeResolutionId()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (loadingData) return <Skeleton active />

  return (
    <QuestionFormComponent
      type="V"
      question={question}
      disciplines={disciplines}
      tags={tags}
      typeResolutionData={typeResolution}
    />
  )
}

export default QuestionViewContainer
