import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Modal,
  Table,
  Tooltip,
} from 'antd'
import { SearchOutlined, RedoOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import notification from '~/shared/Notification'
import services from '../studyplan.services'

function ModalLessonsComponent(props) {
  const history = useHistory()
  const { Option } = Select
  const [disciplineId, setDisciplineIds] = useState([])
  const [subjectId, setSubjectIds] = useState([])
  const [filterLessons, setFilterLessons] = useState({})
  const [dataSourceTable, setDataSourceTable] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [existDiscipline, setExistDiscipline] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [dayOfWeekSelect, setDayOfWeek] = useState(null)
  const [selectedDisciplineIds, setSelectedDisciplineIds] = useState([])

  const [loadingTable, setLoadingTable] = useState()

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const {
    disciplines,
    subjects,
    typeLesson,
    getSubjects,
    idStudyPlan,
    isIncluseLesson,
    studyPlanStepId,
    nameWeek,
    valuesForm,
    modalVisible,
    callModal,
  } = props

  const [form] = Form.useForm()
  const [internalModalVisible, setInternalModalVisible] = useState(false)
  const [visibleWeek, setVisibleWeek] = useState(false)

  useEffect(() => {
    if (nameWeek !== '' && nameWeek !== undefined) {
      form.setFieldsValue({ nameWeek })
      setVisibleWeek(true)
    }
  }, [nameWeek, form])

  useEffect(() => {
    setInternalModalVisible(modalVisible)
  }, [modalVisible])

  const requiredRule = [{ required: true }]

  async function cleanModal() {
    form.setFieldsValue({
      dayOfWeek: null,
      discipline: null,
      DisciplineLessonName: null,
      LessonType: null,
      subject: null,
    })
    setSelectedDisciplineIds([])
    setDataSourceTable([])
  }

  function onChangeDiscipline(value) {
    if (value) {
      const disciplineIds = []

      if (value > 0) {
        disciplineIds[0] = value
        const objListDisciplineIds = { disciplineIds }
        getSubjects(objListDisciplineIds)
      } else {
        getSubjects(null)
      }

      setExistDiscipline(false)
    } else {
      setExistDiscipline(true)
    }

    setDisciplineIds(value)
  }

  function onChangeSubject(value) {
    if (value) {
      setSubjectIds(value)
    }
  }

  function resetFilter() {
    form.resetFields()
    setFirstLoad(true)
    setTotalRecords(0)
    setPagination({
      pageNumber: 1,
      pageSize: 10,
    })
    setDataSourceTable([])
  }

  async function onFinishModal(values) {
    setLoadingTable(true)
    const filterLessons = {
      studyplanid: idStudyPlan,
      disciplineid: disciplineId,
      disciplinesubjectid: subjectId,
      DisciplineLessonName: values.DisciplineLessonName,
      LessonType: values.LessonType,
    }
    setFilterLessons(filterLessons)

    try {
      const response = await services.lessonsActives(
        filterLessons,
        pagination.pageNumber,
        pagination.pageSize,
      )
      const dataSource = response.data.content
      const { totalRecords } = response.data
      if (dataSource.length > 0) {
        const dataSourceWithKey = dataSource.map((lesson) => {
          return {
            key: lesson.disciplineLessonId,
            disciplineLessonName: lesson.disciplineLessonName,
            disciplineSubjectName: lesson.disciplineSubjectName,
          }
        })

        if (firstLoad) setFirstLoad(false)
        setTotalRecords(totalRecords)

        setTimeout(() => {
          setLoadingTable(false)
          setDataSourceTable(dataSourceWithKey)
        }, 3000)
      } else {
        setTimeout(() => {
          notification.warning('Não há dados para serem exibidos.')
          setLoadingTable(false)
          setDataSourceTable([])
          setTotalRecords(null)
        }, 3000)
      }
    } catch {
      // console.log
    }
  }

  const columns = [
    {
      title: 'Aula',
      dataIndex: 'disciplineLessonName',
    },
    {
      title: 'Matéria',
      dataIndex: 'disciplineSubjectName',
    },
  ]

  const rowSelection = {
    onSelect: (record) => {
      const alreadyExist = selectedDisciplineIds.includes(record.key)

      if (alreadyExist) {
        const index = selectedDisciplineIds.indexOf(record.key)
        selectedDisciplineIds.splice(index, 1)
      } else {
        selectedDisciplineIds.push(record.key)
        setSelectedDisciplineIds(selectedDisciplineIds)
      }
    },
    onSelectAll: (selectAll) => {
      if (selectAll) {
        // eslint-disable-next-line array-callback-return
        dataSourceTable.map((lesson) => {
          selectedDisciplineIds.push(lesson.key)
          setSelectedDisciplineIds(selectedDisciplineIds)
        })
      } else {
        setSelectedDisciplineIds([])
      }
    },
  }

  async function saveWeek() {
    if (valuesForm !== undefined && valuesForm != null) {
      const data = {
        name: valuesForm.name,
        shortDescription: valuesForm.shortDescription,
        notes: valuesForm.notes,
        studyPlanTypeId: valuesForm.studyPlanTypeId,
        active: valuesForm.active,
        lessonsCredit: valuesForm.lessonsCredit,
        customerServiceCredit: valuesForm.customerServiceCredit,
        displayTheme: 'Blue',
        disciplineLessonIds: selectedDisciplineIds,
        studyPlanStepTypeId: 1,
        dayOfWeek: Number(dayOfWeekSelect),
        freemium: valuesForm.freemium,
      }

      const response = await services.save(data)
      const idNewStudyPlan = response.data
      history.push(`/plano-estudo/${idNewStudyPlan}/edicao`)
    } else {
      const data = {
        studyPlanId: idStudyPlan,
        studyPlanStepTypeId: 1,
        disciplineLessonIds: selectedDisciplineIds,
        dayOfWeek: Number(dayOfWeekSelect),
      }

      try {
        await services.saveNewWeek(data)
        notification.success('Cadastrado com sucesso.')
        setInternalModalVisible(false)
        cleanModal()
        callModal(false)
      } catch {
        // console.log('teste')
      }
    }
  }

  async function saveLessonInWeek() {
    const data = {
      studyPlanId: idStudyPlan,
      studyPlanStepId,
      disciplineLessonIds: selectedDisciplineIds,
      dayOfWeek: Number(dayOfWeekSelect),
      studyPlanStepTypeId: 1,
    }

    try {
      await services.saveNewLesson(data)
      notification.success('Cadastrado com sucesso.')
      setInternalModalVisible(false)
      cleanModal()
      callModal(false)
    } catch {
      // console.log('teste')
    }
  }

  function saveDayWeek(value, option) {
    setDayOfWeek(value)
  }

  function closeModalCleanFields(closeModal) {
    cleanModal()
    callModal(closeModal)
  }

  useEffect(() => {
    async function execute() {
      await onFinishModal(form.getFieldsValue(true))
    }

    if (firstLoad) return

    execute()
  }, [pagination])

  useEffect(() => {
    if (internalModalVisible) {
      form.resetFields()
      setFirstLoad(true)
      setTotalRecords(0)
      setPagination({
        pageNumber: 1,
        pageSize: 10,
      })
      setDataSourceTable([])
    }
  }, [internalModalVisible])

  return (
    <Modal
      visible={internalModalVisible}
      title="Inclusão de Aulas"
      width={1000}
      onCancel={() => callModal(false)}
      onOk={() => (!isIncluseLesson ? saveLessonInWeek : saveWeek)}
      footer={[
        <Button key="back" onClick={() => closeModalCleanFields(false)}>
          Fechar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={isIncluseLesson ? saveLessonInWeek : saveWeek}
        >
          Confirmar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinishModal}>
        {visibleWeek && (
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Form.Item name="nameWeek">
                <Input
                  placeholder="Nome Aula"
                  maxLength={50}
                  visible={visibleWeek}
                  disabled
                  defaultValue={nameWeek}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16} align="middle">
          <Col xs={24} md={4}>
            <Form.Item name="dayOfWeek">
              <Select
                placeholder="Dia da Semana"
                allowClear
                onChange={(value, Option) => saveDayWeek(value, Option)}
              >
                <Option value="1">Segunda</Option>
                <Option value="2">Terça</Option>
                <Option value="3">Quarta</Option>
                <Option value="4">Quinta</Option>
                <Option value="5">Sexta</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item name="discipline" rules={requiredRule}>
              <Select
                showSearch
                placeholder="Disciplina"
                options={disciplines}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
                onChange={onChangeDiscipline}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item name="subject">
              <Select
                showSearch
                placeholder="Matéria"
                options={subjects}
                onChange={onChangeSubject}
                filterOption={(input, option) => {
                  if (typeof option.label === 'object') {
                    return (
                      option?.label?.props?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  )
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col xs={6} md={19}>
            <Form.Item name="DisciplineLessonName">
              <Input placeholder="Nome Aula" maxLength={50} />
            </Form.Item>
          </Col>
          <Col xs={24} md={5}>
            <Form.Item name="LessonType">
              <Select
                showSearch
                placeholder="Tipo"
                options={typeLesson}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} align="end">
          <Col xs={24} md={1}>
            <Tooltip title="Pesquisar">
              <Button
                htmlType="submit"
                type="primary"
                icon={<SearchOutlined />}
              />
            </Tooltip>
          </Col>

          <Col xs={24} md={1}>
            <Tooltip title="Redefinir Filtros">
              <Button
                type="primary"
                icon={<RedoOutlined />}
                onClick={resetFilter}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col xs={24} md={24}>
            <Table
              rowSelection={{ ...rowSelection }}
              filter={filterLessons}
              dataSource={dataSourceTable}
              columns={columns}
              loading={loadingTable}
              pagination={{
                current: pagination.pageNumber,
                pageSizeOptions: [5, 10, 20, 50, 100],
                onChange: (pageNumber, pageSize) => {
                  setPagination((pagination) => ({
                    pageNumber:
                      pagination.pageSize !== pageSize ? 1 : pageNumber,
                    pageSize,
                    fromFilter: false,
                  }))
                },
                total: totalRecords || 0,
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalLessonsComponent
