import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Checkbox,
  Divider,
  Space,
  Button,
  Select,
  Tooltip,
  Modal,
  Tabs,
  Table,
  Input,
  Tag,
} from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'
import MathJax from 'react-mathjax-preview'

import EditorTinyStatement from './editor-tinyStatement'
import EditorTinyResolution from './editor-tinyResolution'
import EditorTinyAlternatives from './editor-tinyAlternatives'

function QuestionFormComponent(props) {
  const {
    disciplines,
    tags,
    question,
    type,
    loadingSubmit,
    onSubmit,
    typeResolutionData,
  } = props
  const { TabPane } = Tabs

  const { Option, OptGroup } = Select
  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const [form] = Form.useForm()
  const [formModal] = Form.useForm()
  const [formModalResolution] = Form.useForm()
  const requiredRule = [{ required: true }]
  const [listTable, setListTable] = useState([])
  const [listTableResolution, setListTableResolution] = useState([])
  const [internalModalVisible, setInternalModalVisible] = useState(false)
  const [
    internalModalVisibleResolution,
    setInternalModalVisibleResolution,
  ] = useState(false)
  const [typeResolution, setTypeResolution] = useState(null)
  const [valueStatment, setStatment] = useState('')
  const [valueStatmentAlternatives, setStatmentAlternatives] = useState('')

  const [valueResolution, setValueResolution] = useState('')
  const [disableVideoResolution, setDisableVideoResolution] = useState(true)
  const [disableTextResolution, setDisableTextResolution] = useState(1)
  const [
    disableSelectTypeResolution,
    setDisableSelectTypeResolution,
  ] = useState(false)
  const [typeOff, setTypeOffModal] = useState('')
  const [typeOffResolution, setTypeOffModalResolution] = useState('')

  const [oldObjectAlternative, setOldObjectiveAlternative] = useState({})
  const [oldObjectResolution, setOldObjectiveResolution] = useState({})

  const [loadingTableAlternatives, setLoadingTableAlternatives] = useState(
    false,
  )

  useEffect(() => {
    const statement = window.tinymce.get('myTextarea')
    if (statement !== null) {
      window.location.reload()
    }
  }, [isNew])

  useEffect(() => {
    if ((isEdit || isView) && question) {
      form.setFieldsValue(question)
      setStatment(question.statement)
      setTypeResolution(question.questionSolutionTypeId)
      setValueResolution(question.solutionContent)
      setLoadingTableAlternatives(true)
      setListTable(question.answers)
      setListTableResolution(
        question.solutionContent !== ''
          ? [{ answer: question.solutionContent }]
          : [],
      )
      setLoadingTableAlternatives(false)
    }
  }, [isEdit, isView, question, form])

  async function onFinish(values) {
    const statement = valueStatment

    let resolution = ''
    if (typeResolution === 2) {
      resolution = valueResolution
    }

    onSubmit(values, statement, resolution, listTable)
  }

  async function openModalAlternative() {
    setTypeOffModal('N')
    setInternalModalVisible(true)
  }

  async function closeModalAlternative() {
    setTypeOffModal('N')
    setStatmentAlternatives('')
    setInternalModalVisible(false)
    formModal.setFieldsValue({ isCorrect: false })
  }

  async function openModalResolutionText() {
    setTypeOffModalResolution('N')
    setInternalModalVisibleResolution(true)
  }

  async function closeModalResolution() {
    setTypeOffModalResolution('N')
    setInternalModalVisibleResolution(false)
  }

  function renderAnswer(answer) {
    return <MathJax math={answer} />
  }

  function renderResolution(resolution) {
    return <MathJax math={resolution} />
  }

  function renderTag(isCorrect) {
    return isCorrect ? (
      <Tag color="green">Correta</Tag>
    ) : (
      <Tag color="red">Incorreta</Tag>
    )
  }

  function editResolution(objectSelected, record) {
    setValueResolution(objectSelected.answer)

    const oldObjectResolution = {
      answer: objectSelected.answer,
    }

    setOldObjectiveResolution(oldObjectResolution)

    setTypeOffModalResolution('E')
    setInternalModalVisibleResolution(true)
  }

  function editAlternative(objectSelected, record) {
    setStatmentAlternatives(objectSelected.answer)

    const oldObject = {
      id: objectSelected.id,
      answer: objectSelected.answer,
      isCorrect: objectSelected.isCorrect,
    }

    setOldObjectiveAlternative(oldObject)

    formModal.setFieldsValue({ isCorrect: objectSelected.isCorrect })
    setTypeOffModal('E')
    setInternalModalVisible(true)
  }

  function removeResolution(objectSelected, record) {
    const exists = listTableResolution.indexOf(objectSelected)
    if (exists !== -1) {
      const config = {
        title: 'Deseja excluir esse registro?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk() {
          listTableResolution.splice(exists, 1)
          const newListTableResolution = listTableResolution
          setListTableResolution([...newListTableResolution])
        },

        onCancel() {},
      }

      Modal.confirm(config)
    }
  }

  function removeAlternative(objectSelected, record) {
    const exists = listTable.indexOf(objectSelected)
    if (exists !== -1) {
      const config = {
        title: 'Deseja excluir esse registro?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk() {
          setLoadingTableAlternatives(true)
          listTable.splice(exists, 1)
          const newListTable = listTable
          setListTable([...newListTable])

          setLoadingTableAlternatives(false)
        },

        onCancel() {},
      }

      Modal.confirm(config)
    }
  }
  function renderActions(id) {
    return (
      <Button.Group>
        <Tooltip title="Editar">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => editAlternative(id)}
            disabled={isView}
          />
        </Tooltip>
        <Tooltip title="Excluir">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeAlternative(id)}
            disabled={isView}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  function renderActionsResolution(id) {
    return (
      <Button.Group>
        <Tooltip title="Editar">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => editResolution(id)}
            disabled={isView}
          />
        </Tooltip>
        <Tooltip title="Excluir">
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeResolution(id)}
            disabled={isView}
          />
        </Tooltip>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'answer',
      width: '80%',
      render: (answer) => renderAnswer(answer),
    },
    {
      title: 'Correta',
      dataIndex: 'isCorrect',
      width: '20%',
      // eslint-disable-next-line react/display-name
      render: (isCorrect) => renderTag(isCorrect),
    },
    {
      title: 'Ações',
      align: 'center',
      key: 'action',
      render: (text, record) => renderActions(text, record),
    },
  ]

  const columnsForResolution = [
    {
      title: 'Resolução por Texto',
      dataIndex: 'answer',
      render: (resolution) => renderResolution(resolution),
    },
    {
      title: 'Ações',
      align: 'center',
      key: 'action',
      render: (text, record) => renderActionsResolution(text, record),
    },
  ]

  function SetResolution() {
    if (typeOffResolution === 'N') {
      const resolutionSet = {
        answer: valueResolution,
      }
      setListTableResolution([...listTableResolution, resolutionSet])
      form.setFieldsValue({ listTableResolution: resolutionSet })
      setInternalModalVisibleResolution(false)
    }
    if (typeOffResolution === 'E') {
      const indexEdit = listTableResolution.indexOf(oldObjectResolution)

      const editObject = {
        answer: valueResolution,
      }

      listTableResolution.splice(indexEdit, 1, editObject)
      const newListTable = listTableResolution
      setListTableResolution([...newListTable])
      setInternalModalVisibleResolution(false)
    }
  }

  function SetAnswers(values) {
    if (typeOff === 'N') {
      const valueAnwser = valueStatmentAlternatives
      const alternativeInserted = {
        id: null,
        answer: valueAnwser,
        isCorrect: values.isCorrect === undefined ? false : values.isCorrect,
      }

      setListTable([...listTable, alternativeInserted])
      // form.setFieldsValue({ listTable: alternativeInserted })

      setStatmentAlternatives('')
      formModal.setFieldsValue({ isCorrect: false })
    } else if (typeOff === 'E') {
      const indexEdit = listTable.findIndex(
        (item) =>
          item.answer === oldObjectAlternative.answer &&
          item.isCorrect === oldObjectAlternative.isCorrect,
      )

      const valueAnwser = valueStatmentAlternatives
      const editObject = {
        id: oldObjectAlternative.id,
        answer: valueAnwser,
        isCorrect: values.isCorrect === undefined ? false : values.isCorrect,
      }

      listTable.splice(indexEdit, 1, editObject)
      const newListTable = listTable
      setListTable([...newListTable])
      setStatmentAlternatives('')
      formModal.setFieldsValue({ isCorrect: false })
      setLoadingTableAlternatives(false)
      setInternalModalVisible(false)
    }
  }

  const tagsOptions = tags.map((tag) => (
    <Option id={tag.value} key={tag.value} value={tag.label}>
      {tag.label}
    </Option>
  ))

  function changeTypeResolution(value, option) {
    const valueSelected = Number(value)
    setTypeResolution(valueSelected)

    if (valueSelected === 1) {
      setDisableVideoResolution(true)
      setDisableTextResolution(true)
    } else if (valueSelected === 2) {
      setDisableVideoResolution(true)
      setDisableTextResolution(false)
    } else if (valueSelected === 3) {
      setDisableVideoResolution(false)
      setDisableTextResolution(true)
    }
  }

  function disableButtonResolutionText() {
    if (
      typeResolution === null ||
      typeResolution === 1 ||
      typeResolution === 3
    ) {
      return true
    }

    if (isView === true) {
      return true
    }
    if (listTableResolution.length > 0) {
      return true
    }

    if (typeResolution === 2) {
      if (listTableResolution.length > 0) {
        return true
      }

      return false
    }

    return false
  }

  function verifyVideo(value) {
    if (value === '') {
      setDisableSelectTypeResolution(false)
    } else if (value !== '') {
      setDisableSelectTypeResolution(true)
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ active: true, questionSolutionTypeId: 1 }}
      onFinish={onFinish}
    >
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Form.Item name="code" rules={requiredRule}>
            <Input placeholder="Código" maxLength={128} disabled={isView} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={12}>
          <Form.Item name="disciplineId" rules={requiredRule}>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Disciplina"
              options={disciplines}
              disabled={isView}
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={12}>
          <Form.Item name="tags">
            <Select mode="multiple" placeholder="Termos" disabled={isView}>
              {tagsOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={4}>
          <Form.Item name="allowedInExam" valuePropName="checked">
            <Checkbox disabled={isView}>Permitir inclusão em provas</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item name="allowedInLesson" valuePropName="checked">
            <Checkbox disabled={isView}>Permitir inclusão em aulas</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={4}>
          <Form.Item name="allowedInTest" valuePropName="checked">
            <Checkbox disabled={isView}>
              Permitir inclusão em simulados
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={2}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView}>Ativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Form.Item label="Enunciado" name="statement">
            <EditorTinyStatement
              valueEditView={valueStatment}
              readonly={isView ? 1 : 0}
              onEditorChange={(content) => {
                setStatment(content)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} align="end">
        <Col xs={24} md={3}>
          <Tooltip title="Inserir Alternativa">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={openModalAlternative}
              disabled={isView}
            >
              Inserir Alternativa
            </Button>
          </Tooltip>
        </Col>

        <Col xs={24} md={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Alternativas" key="1">
              <Table
                loading={loadingTableAlternatives}
                columns={columns}
                dataSource={listTable}
                size="middle"
                pagination={false}
                disabled={isView}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} align="middle">
        <Col xs={24} md={5}>
          <Form.Item name="questionSolutionTypeId" rules={requiredRule}>
            <Select
              showSearch
              placeholder="Tipo Resolução"
              disabled={isView === true ? true : disableSelectTypeResolution}
              options={typeResolutionData}
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
              onChange={(value, Option) => changeTypeResolution(value, Option)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={3}>
          <Form.Item name="questionSolutionTypeId" rules={requiredRule}>
            <Tooltip title="Inserir Texto">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={openModalResolutionText}
                disabled={disableButtonResolutionText()}
              >
                Inserir Resolução por Texto
              </Button>
            </Tooltip>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Table
            columns={columnsForResolution}
            dataSource={listTableResolution}
            size="middle"
            pagination={false}
            disabled={isView}
          />
        </Col>
      </Row>{' '}
      <Divider />
      <Row gutter={16} align="middle">
        <Col xs={24} md={24}>
          <Form.Item label="Resolução por Vídeo" name="solutionVideoId">
            <Input
              placeholder="Link Vídeo"
              maxLength={50}
              disabled={isView === true ? true : disableVideoResolution}
              onBlur={(event) => verifyVideo(event.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/pergunta">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
      {/* modais */}
      <Modal
        visible={internalModalVisible}
        title="Inclusão de Alternativa"
        width={1000}
        onCancel={() => closeModalAlternative()}
        onOk={() => closeModalAlternative()}
        footer={null}
        typeOff={typeOff}
      >
        <Form form={formModal} onFinish={SetAnswers}>
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Form.Item name="answer">
                <EditorTinyAlternatives
                  valueEditView={valueStatmentAlternatives}
                  readonly={isView ? 1 : 0}
                  onEditorChange={(content) => {
                    setStatmentAlternatives(content)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align="middle">
            <Col xs={24} md={12}>
              <Form.Item name="isCorrect" valuePropName="checked">
                <Checkbox>Correta</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align="end">
            <Col xs={24} md={3}>
              <Tooltip title="Pesquisar">
                <Button onClick={() => closeModalAlternative(false)}>
                  Cancelar
                </Button>
              </Tooltip>
            </Col>
            <Col xs={24} md={3}>
              <Tooltip title="Pesquisar">
                <Button htmlType="submit" type="primary">
                  Confirmar
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={internalModalVisibleResolution}
        title="Inclusão de Resolução por Texto"
        width={1000}
        onCancel={() => closeModalResolution()}
        onOk={() => closeModalResolution()}
        footer={null}
        typeOffResolution={typeOffResolution}
      >
        <Form
          form={formModalResolution}
          layout="vertical"
          onFinish={SetResolution}
        >
          <Row gutter={16} align="middle">
            <Col xs={24} md={24}>
              <Form.Item label="Resolução Por Texto" name="resolutionText">
                <EditorTinyResolution
                  valueEditView={valueResolution}
                  readonly={isView ? 1 : 0}
                  onEditorChange={(content) => {
                    setValueResolution(content)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align="end">
            <Col xs={24} md={3}>
              <Tooltip title="Pesquisar">
                <Button onClick={() => closeModalResolution()}>Cancelar</Button>
              </Tooltip>
            </Col>
            <Col xs={24} md={3}>
              <Tooltip title="Pesquisar">
                <Button htmlType="submit" type="primary">
                  Confirmar
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form>
  )
}

export default QuestionFormComponent
