import LiveClassesList from './containers/massInclusionStudents-list.container'

const urlBase = '/inclusao-em-massa'

const routes = [
  {
    path: urlBase,
    tag: '',
    breadcrumb: [
      {
        breadcrumbName: 'Cadastro Alunos em Massa',
      },
    ],
    authenticated: true,
    component: LiveClassesList,
  },
]

export default routes
