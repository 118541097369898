import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Divider,
  Space,
  Button,
  AutoComplete,
  Select,
  notification,
} from 'antd'
import {} from '@ant-design/icons'

function DisciplineSubjectsFormComponent(props) {
  const [valueArea, setValueArea] = useState(null)
  const { Option } = AutoComplete

  const {
    type,
    loadingSubmit,
    disciplineSubjects,
    onSubmit,
    areas,
    disciplineId,
    displaySequence,
    formContainer,
    loadAreas,
    loadSequence,
  } = props

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  const areaOptions = areas.map((area) => (
    <Option id={area.value} key={area.value} value={area.label}>
      {area.label}
    </Option>
  ))

  useEffect(() => {
    if ((isEdit || isView) && disciplineSubjects)
      formContainer.setFieldsValue(disciplineSubjects)
  }, [isEdit, isView, disciplineSubjects, formContainer])

  useEffect(() => {
    if ((isEdit || isView) && disciplineSubjects) {
      setValueArea(disciplineSubjects.disciplineAreaName)
    }
  }, [isEdit, isView, disciplineSubjects])

  async function onFinish(values) {
    const data = {
      name: values.name,
      displaySequence: values.displaySequence,
      areaName: valueArea || '',
      disciplineId,
      active: values.active,
    }

    try {
      await onSubmit(data)
      loadAreas()
      loadSequence()
    } catch {
      notification.error('Ocorreu um erro')
    }
  }

  function onBlur(event) {
    setValueArea(event.target.value)
  }

  function onSelect(valor, option) {
    setValueArea(option.value)
  }

  return (
    <Form
      form={formContainer}
      initialValues={{ active: true }}
      onFinish={onFinish}
    >
      <Row gutter={16} align="middle">
        <Col xs={6} md={10}>
          <Form.Item name="name" rules={requiredRule}>
            <Input
              placeholder="Nome Matéria"
              maxLength={50}
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={8}>
          <Form.Item name="disciplineAreaName">
            <AutoComplete
              dataSource={areaOptions}
              onBlur={(val, option) => onBlur(val, option)}
              onSelect={(val, option) => onSelect(val, option)}
              placeholder="Seção Matéria"
              disabled={isView}
              value={isEdit || isView ? { valueArea } : null}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={3}>
          <Form.Item name="displaySequence" rules={requiredRule}>
            <Select
              options={displaySequence}
              placeholder="Ordem Exibição"
              disabled={isView}
            />
          </Form.Item>
        </Col>

        <Col xs={6} md={3}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView} defaultChecked>
              Ativo
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to={`/disciplina/${disciplineId}/materia`}>
              {isEdit ? 'Cancelar' : 'Voltar'}
            </Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default DisciplineSubjectsFormComponent
