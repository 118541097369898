import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Checkbox,
  Divider,
  Space,
  Button,
} from 'antd'

function GroupFormComponent(props) {
  const [groupPermissionsIds, setGroupPermissionsIds] = useState([])

  const [form] = Form.useForm()

  const { type, loadingSubmit, group, permissions, onSubmit } = props

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && group) form.setFieldsValue(group)
  }, [isEdit, isView, group, form])

  useEffect(() => {
    if ((isEdit || isView) && group) {
      const reducedPermissionsIds = group.permissionsGroups.reduce(
        (acc, permissionsGroups) => {
          const permissionssIds = permissionsGroups.permissions.map((x) => x.id)
          return [...acc, ...permissionssIds]
        },
        [],
      )

      setGroupPermissionsIds(reducedPermissionsIds)
    }
  }, [isEdit, isView, group])

  function addOrRemoveIfContainsSelectedPermissions(id) {
    if (!groupPermissionsIds.includes(id))
      setGroupPermissionsIds((groupPermissionsIds) => [
        ...groupPermissionsIds,
        id,
      ])
    else
      setGroupPermissionsIds((groupPermissionsIds) =>
        groupPermissionsIds.filter(
          (groupPermissionId) => groupPermissionId !== id,
        ),
      )
  }

  async function onFinish(values) {
    const data = {
      ...values,
      permissions: groupPermissionsIds,
    }

    onSubmit(data)
  }

  return (
    <Form form={form} initialValues={{ active: true }} onFinish={onFinish}>
      <Row gutter={16} align="middle">
        <Col xs={18} md={10}>
          <Form.Item name="name" rules={requiredRule}>
            <Input placeholder="Nome" maxLength={50} disabled={isView} />
          </Form.Item>
        </Col>

        <Col xs={6} md={3}>
          <Form.Item name="active" valuePropName="checked">
            <Checkbox disabled={isView}>Ativo</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {permissions.map((permission) => (
          <Col key={permission.displayGroup} xs={24} md={12} lg={6}>
            <Card size="small" title={permission.displayGroup} bordered={false}>
              {permission.permissions.map((permission) => {
                const defaultChecked = groupPermissionsIds.includes(
                  permission.id,
                )

                return (
                  <Row key={permission.id}>
                    <Checkbox
                      defaultChecked={defaultChecked}
                      onChange={() =>
                        addOrRemoveIfContainsSelectedPermissions(permission.id)
                      }
                      disabled={isView}
                    >
                      {permission.displayName}
                    </Checkbox>
                  </Row>
                )
              })}
            </Card>
          </Col>
        ))}
      </Row>

      <Divider />

      <Row justify="end">
        <Space>
          <Button type="text">
            <Link to="/grupo-usuario">{isEdit ? 'Cancelar' : 'Voltar'}</Link>
          </Button>

          {!isView && (
            <Button htmlType="submit" type="primary" loading={loadingSubmit}>
              {isNew ? 'Confirmar' : 'Atualizar'}
            </Button>
          )}
        </Space>
      </Row>
    </Form>
  )
}

export default GroupFormComponent
