import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import notification from '~/shared/Notification'

import PartnerSchoolFormComponent from '../components/partnerSchool-form.component'

import services from '../partnerSchool.services'

function PartnerSchoolEditContainer() {
  const [partnerSchool, setPartnerSchool] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const { idPartnerSchool } = params

  async function getData() {
    const response = await services.getPartnerSchoolById(idPartnerSchool)

    setPartnerSchool(response.data)
  }

  useEffect(() => {
    getData()
    setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(values) {
    const {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    } = values

    const data = {
      idPartnerSchool,
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
      studyPlanIds,
    }

    setLoadingSubmit(true)

    try {
      await services.partnerSchoolUpdate(data)
      notification.success('Escola Parceira atualizada com sucesso.')
      setLoadingSubmit(false)

      history.push(`/escola-parceira/`)
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <PartnerSchoolFormComponent
      getData={getData}
      type="E"
      onSubmit={onSubmit}
      loadingSubmit={loadingSubmit}
      form={form}
      partnerSchool={partnerSchool}
    />
  )
}

export default PartnerSchoolEditContainer
