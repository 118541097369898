import ForumPostList from './containers/forumPost-list.container'
import ForumPostViewContainer from './containers/forumPost-view.container'

const routes = [
  {
    path: '/duvida',
    tag: 'ForumPostList',
    breadcrumb: [
      {
        breadcrumbName: 'Dúvida',
      },
    ],
    authenticated: true,
    component: ForumPostList,
  },
  {
    path: '/duvida/:id/visualizacao',
    tag: 'ForumPostReply',
    breadcrumb: [
      {
        path: '/duvida',
        breadcrumbName: 'Dúvida',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: ForumPostViewContainer,
  },
]

export default routes
