import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import DisciplineLessonsListComponent from '../components/disciplinelesson-list.component'

import servicesSubject from '../../disciplinesubjects/disciplinesubjects.services'

function DisciplineLessonListContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const params = useParams()
  const { idsubject } = params
  const [subjectData, setSubjectsData] = useState({})

  useEffect(() => {
    async function getData() {
      setLoadingData(true)
      const responseSubject = await servicesSubject.getById(idsubject)
      await setSubjectsData(responseSubject.data)
      setLoadingData(false)
    }
    getData()
  }, [idsubject])

  if (loadingData) {
    return <Skeleton active />
  }

  return <DisciplineLessonsListComponent subject={subjectData} />
}

export default DisciplineLessonListContainer
