import StudentManagementList from './containers/studentManagement-list.container'
import StudentManagementNew from './containers/studentManagement-new.container'
import StudentManagementEdit from './containers/studentManagement-edit.container'
import StudentManagementPlansList from './containers/studentManagementPlans-list.container'
import StudentManagementPlansNew from './containers/studentManagementPlans-new.container'
import StudentManagementPlansEdit from './containers/studentManagementPlans-edit.container'
import StudentManagementPlansView from './containers/studentManagementPlans-view.container'
import StudentManagementPartnerSchoolNew from './containers/studentManagementPartnerSchool-new.container'
import StudentManagementPartnerSchoolEdit from './containers/studentManagementPartnerSchool-edit.container'
import StudentManagementPartnerSchoolView from './containers/studentManagementPartnerSchool-view.container'

const routes = [
  {
    path: '/aluno',
    tag: '',
    authenticated: true,
    component: StudentManagementList,
  },
  {
    path: '/aluno/dados-cadastrais/inclusao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: StudentManagementNew,
  },
  {
    path: '/aluno/dados-cadastrais/:idstudent/edicao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: StudentManagementEdit,
  },
  {
    path: '/aluno/:idstudent/plano-e-assinatura',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Planos e Assinaturas',
      },
    ],
    authenticated: true,
    component: StudentManagementPlansList,
  },
  {
    path: '/aluno/:idstudent/plano-e-assinatura/inclusao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Planos e Assinaturas',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: StudentManagementPlansNew,
  },
  {
    path: '/aluno/:idstudent/plano-e-assinatura/:idplan/edicao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Planos e Assinaturas',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: StudentManagementPlansEdit,
  },
  {
    path: '/aluno/:idstudent/plano-e-assinatura/:idplan/visualizacao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Planos e Assinaturas',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: StudentManagementPlansView,
  },
  {
    path: '/aluno/:idstudent/escola-parceira/inclusao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: StudentManagementPartnerSchoolNew,
  },
  {
    path: '/aluno/:idstudent/escola-parceira/:studentStudyPlanId/edicao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: StudentManagementPartnerSchoolEdit,
  },
  {
    path: '/aluno/:idstudent/escola-parceira/:studentStudyPlanId/visualizacao',
    tag: '',
    breadcrumb: [
      {
        path: '/aluno',
        breadcrumbName: 'Aluno',
      },
      {
        breadcrumbName: 'Escola Parceira',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: StudentManagementPartnerSchoolView,
  },
]

export default routes
