import QuestionListContainer from './containers/question-list.container'
import QuestionNewContainer from './containers/question-new.container'
import QuestionEditContainer from './containers/question-edit.container'
import QuestionViewContainer from './containers/question-view.container'

const routes = [
  {
    path: '/pergunta/inclusao',
    tag: 'QuestionCreate',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/pergunta',
        breadcrumbName: 'Pergunta',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: QuestionNewContainer,
  },
  {
    path: '/pergunta/:id/edicao',
    tag: 'QuestionEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/pergunta',
        breadcrumbName: 'Pergunta',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: QuestionEditContainer,
  },
  {
    path: '/pergunta/:id/visualizacao',
    tag: 'QuestionList',
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/pergunta',
        breadcrumbName: 'Pergunta',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: QuestionViewContainer,
  },
  {
    path: '/pergunta',
    tag: 'QuestionList',
    authenticated: true,
    breadcrumb: [
      {
        breadcrumbName: 'Conteúdo Didático',
      },
      {
        path: '/pergunta',
        breadcrumbName: 'Pergunta',
      },
    ],
    component: QuestionListContainer,
  },
]

export default routes
