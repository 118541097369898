import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import DiscountCouponFormComponent from '../components/discountCoupon-form.component'

import services from '../discountCoupon.services'

function DiscountCouponViewContainer() {
  const [discountCoupon, setDiscountCoupon] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [form] = Form.useForm()
  const params = useParams()
  const { idDiscountCoupon } = params

  useEffect(() => {
    async function getData() {
      const response = await services.getDiscountCouponById(idDiscountCoupon)

      setDiscountCoupon(response.data)
      setLoadingData(false)
    }

    getData()
  }, [idDiscountCoupon])

  if (loadingData) return <Skeleton active />

  return (
    <DiscountCouponFormComponent
      type="V"
      form={form}
      discountCoupon={discountCoupon}
    />
  )
}

export default DiscountCouponViewContainer
