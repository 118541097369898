import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'

import UserFormComponent from '../components/user-form.component'

import userServices from '../user.services'
import groupServices from '../../groups/group.services'

import notification from '~/shared/Notification'

function UserNewContainer() {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const [partnerSchools, setPartnerSchools] = useState([])
  const [roles, setRoles] = useState([])

  const history = useHistory()

  useEffect(() => {
    async function getData() {
      const responsePartnerSchools = await userServices.getPartnerSchools()
      const responseRoles = await groupServices.getRolesActive()

      const mappedPartnerSchools = responsePartnerSchools.data.map(
        (partnerSchool) => {
          return { label: partnerSchool.schoolName, value: partnerSchool.id }
        },
      )

      setLoadingData(false)

      setPartnerSchools(mappedPartnerSchools)
      setRoles(responseRoles.data)
    }

    getData()
  }, [])

  async function onSubmit(values) {
    const {
      name,
      email,
      password,
      active,
      partnerSchoolId,
      roles,
      biography,
      discipline,
      calendlySlug,
      rating,
      privateClasses,
      mentorShip,
      recordedClasses,
    } = values

    if (!roles.length) {
      notification.error('É necessário informar ao menos um Grupo de Acesso.')
      return
    }

    const data = {
      name,
      email,
      password,
      active,
      partnerSchoolId,
      roles,
      biography,
      discipline,
      calendlySlug,
      rating,
      privateClasses,
      mentorShip,
      recordedClasses,
    }

    setLoadingSubmit(true)

    try {
      await userServices.save(data)
      notification.success('Cadastrado com sucesso.')

      history.push('/usuario')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <UserFormComponent
      type="N"
      partnerSchools={partnerSchools}
      roles={roles}
      loadingSubmit={loadingSubmit}
      onSubmit={onSubmit}
    />
  )
}

export default UserNewContainer
