import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Row, Tooltip, Typography, Button, PageHeader } from 'antd'
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'

import { format, parseISO } from 'date-fns'

import services from '../studentManagement.services'

import StudentInformationComponent from './studentInformation-component'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import RenderTag from '~/shared/RenderTag'
import PermittedAction from '~/shared/PermittedAction'

import priceMask from '~/utils/price-mask'

import * as S from '../studentManagement.styles'

function StudentManagementPlansListComponent() {
  const [studentId, setStudentId] = useState('')
  const [studyPlans, setStudyPlans] = useState([])

  const params = useParams()
  const history = useHistory()

  const { idstudent } = params
  const { Title, Text } = Typography

  async function getStudyPlanBySubscriptionList() {
    const response = await services.getStudyPlanBySubscription(idstudent)

    setStudyPlans(response.data.content)
  }

  useEffect(() => {
    setStudentId(idstudent)

    if (idstudent) {
      getStudyPlanBySubscriptionList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idstudent])

  function getDateFormat(dateValue) {
    if (dateValue) {
      const date = parseISO(dateValue)

      return format(date, 'dd/MM/yyyy')
    }

    return ''
  }

  function goBack() {
    history.push('/aluno/')
  }

  function planLink(data, type) {
    history.push(
      `/aluno/${idstudent}/plano-e-assinatura/${data.studentStudyPlanId}/${type}`,
    )
  }

  function renderPlansActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => planLink(data, 'visualizacao')}
            />
          </Tooltip>

          <Tooltip title="Alterar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => planLink(data, 'edicao')}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function schoolLink(data, type) {
    history.push(
      `/aluno/${idstudent}/escola-parceira/${data.studentStudyPlanId}/${type}`,
    )
  }

  function renderSchoolActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => schoolLink(data, 'visualizacao')}
            />
          </Tooltip>

          <Tooltip title="Alterar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => schoolLink(data, 'edicao')}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderSubscriptionPlanName(data) {
    return (
      <Text>
        {data.subscriptionName} ({data.subscriptionShortDescription})
      </Text>
    )
  }

  function renderStudyPlanName(data) {
    return (
      <Text>
        {data.studyPlanName} ({data.studyPlanShortDescription})
      </Text>
    )
  }

  const columnsPlans = [
    {
      title: 'Número Assinatura',
      dataIndex: 'subscriptionNumber',
    },
    {
      title: 'Plano Assinatura',
      render: (data) => renderSubscriptionPlanName(data),
    },
    {
      title: 'Planos Estudos',
      render: (data) => renderStudyPlanName(data),
    },
    {
      title: 'Data Início',
      dataIndex: 'subscriptionDate',
      render: (subscriptionDate) => getDateFormat(subscriptionDate),
    },
    {
      title: 'Data Expiração',
      dataIndex: 'studyPlanExpirationDate',
      render: (studyPlanExpirationDate) =>
        getDateFormat(studyPlanExpirationDate),
    },
    {
      title: 'Valor Assinatura',
      dataIndex: 'amount',
      render: (data) => priceMask(data),
    },
    {
      title: 'Valor Desconto',
      dataIndex: 'discount',
      render: (data) => priceMask(data),
    },
    {
      title: 'Forma Pagamento',
      dataIndex: 'paymentMethodDescription',
    },
    {
      title: 'Status Pagamento',
      dataIndex: 'paymentStatusDescription',
    },

    {
      title: 'Status',
      dataIndex: 'studyPlanActive',
      render: (data) => RenderTag(data),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderPlansActions(data),
    },
  ]

  const columnsSchools = [
    {
      title: 'Escola Parceira',
      dataIndex: 'partnerSchoolName',
    },
    {
      title: 'Planos Estudos',
      render: (data) => renderStudyPlanName(data),
    },
    {
      title: 'Data Expiração',
      dataIndex: 'studyPlanExpirationDate',
      render: (subscriptionDate) => getDateFormat(subscriptionDate),
    },
    {
      title: 'Status',
      dataIndex: 'studyPlanActive',
      render: (data) => RenderTag(data),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderSchoolActions(data),
    },
  ]

  return (
    <>
      {studentId && studyPlans[0] && (
        <StudentInformationComponent
          studentInformationId={studentId}
          baseURL="study-plan-by-subscription"
          studentInformationPlanId={studyPlans[0].studentStudyPlanId}
        />
      )}

      <S.SubscriptionPlan>
        <PageHeader
          className="site-page-header"
          onBack={goBack}
          title="Voltar"
        />

        <Row justify="end">
          <PermittedAction>
            <Tooltip title="Incluir Plano e Assinatura">
              <Link
                to={`/aluno/${studentId}/plano-e-assinatura/inclusao/`}
                style={{ marginRight: '20px' }}
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Incluir Plano e Assinatura
                </Button>
              </Link>
            </Tooltip>

            <Tooltip title="Incluir Escola Parceira">
              <Link to={`/aluno/${studentId}/escola-parceira/inclusao/`}>
                <Button type="primary" icon={<PlusOutlined />}>
                  Incluir Escola Parceira
                </Button>
              </Link>
            </Tooltip>
          </PermittedAction>
        </Row>
      </S.SubscriptionPlan>

      <S.SubscriptionPlan>
        <Title level={2}>Planos de Assinatura</Title>

        <Space direction="vertical">
          {!!idstudent && (
            <Table
              baseURL={`/student/${idstudent}/study-plan-by-subscription`}
              filter=""
              columns={columnsPlans}
            />
          )}
        </Space>
      </S.SubscriptionPlan>

      <S.PartnerSchool>
        <Title level={2}>Escola Parceira</Title>

        <Space direction="vertical">
          {!!idstudent && (
            <Table
              baseURL={`/student/${idstudent}/study-plan-by-partner-school`}
              filter=""
              columns={columnsSchools}
            />
          )}
        </Space>
      </S.PartnerSchool>
    </>
  )
}

export default StudentManagementPlansListComponent
