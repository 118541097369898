import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'

import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Divider,
  Space,
  Button,
  Typography,
} from 'antd'

import services from '../partnerSchool.services'

import brazilStates from '~/utils/brazil-states'

import * as S from '../partnerSchool.styles'
import removeMask from '~/utils/remove-mask'

function PartnerSchoolFormComponent(props) {
  const [errorCep, setErrorCep] = useState(false)
  const [errorCnpj, setErrorCnpj] = useState(false)
  const [errorMobile, setErrorMobile] = useState(false)
  const [studyPlanValueDefaultCheck, setStudyPlanValueDefaultCheck] = useState(
    false,
  )
  const [studyPlanOption, setStudyPlanOption] = useState([])
  const [studyPlanValueDefault, setStudyPlanValueDefault] = useState([])

  const [formPartnerSchool] = Form.useForm()
  const { type, loadingSubmit, partnerSchool, onSubmit } = props
  const { Text } = Typography
  const { Option } = Select
  const { TextArea } = Input

  const requiredRule = [{ required: true }]

  const isNew = type === 'N'
  const isEdit = type === 'E'
  const isView = type === 'V'

  useEffect(() => {
    if ((isEdit || isView) && partnerSchool) {
      formPartnerSchool.setFieldsValue(partnerSchool)
    }
  }, [isEdit, isView, partnerSchool, formPartnerSchool])

  useEffect(() => {
    async function getStudyPlan() {
      const response = await services.studyPlan(true)

      setStudyPlanOption(response.data)
    }

    if (partnerSchool || isNew) getStudyPlan()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSchool])

  useEffect(() => {
    setStudyPlanValueDefaultCheck(false)

    async function getstudyPlanValueDefault() {
      const studyPlanDefault = await partnerSchool?.studyPlans?.map((item) => {
        return {
          id: item.id,
          value: item.name,
        }
      })

      const studyPlanValue = await studyPlanDefault?.map((item) => {
        return item.id
      })

      setStudyPlanValueDefaultCheck(studyPlanValue || false)
      setStudyPlanValueDefault(studyPlanValue)
      if (isNew) setStudyPlanValueDefaultCheck(true)
    }

    if (studyPlanOption) getstudyPlanValueDefault()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyPlanOption, partnerSchool?.studyPlans])

  async function onFinish(values) {
    const {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active,
    } = values

    const data = {
      schoolName,
      cnpj,
      cep,
      address,
      addressNumber,
      addressComplement,
      neighborhood,
      city,
      stateCode,
      email,
      phoneNumber,
      generalObservations,
      active: active || false,
      studyPlanIds: studyPlanValueDefault,
    }

    onSubmit(data)
  }

  function beforeMaskedValueChange(state) {
    setErrorMobile(false)

    let { value } = state

    const newValue = value.replace(/\D/g, '')

    if (newValue.length > 0 && newValue.length < 10) setErrorMobile(true)

    if (newValue.length === 11)
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')

    return {
      ...state,
      value,
    }
  }

  function handleCep(value) {
    setErrorCep(false)

    const cep = value.replace(/\D/g, '')
    const cepIsNotValid = /^[0-9]{5}-[0-9]{3}$/.test(value)

    if (cep.length > 0 && cep.length < 8 && !cepIsNotValid) setErrorCep(true)
  }

  function handleCnpjCheck(value) {
    setErrorCnpj(false)

    const cnpjRemoveMask = removeMask(value)
    const cnpjCheck = cnpjValidator.isValid(cnpjRemoveMask)

    if (!cnpjCheck && cnpjRemoveMask > 0) setErrorCnpj(true)
  }

  return (
    <S.FormWrapper>
      <Form form={formPartnerSchool} onFinish={onFinish}>
        <Row gutter={16} align="middle">
          <Col xs={24} md={12}>
            <Text>Escola Parceira</Text>

            <Form.Item
              name="schoolName"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Input
                placeholder="Digite o nome da Escola Parceira"
                disabled={isView}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>CNPJ</Text>

            <Form.Item
              name="cnpj"
              rules={requiredRule}
              hasFeedback={!isView}
              className={
                errorCnpj &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="Digite o CNPJ da Escola Parceira"
                mask="99.999.999/9999-99"
                className="ant-input"
                disabled={isView}
                onChange={(e) => handleCnpjCheck(e.target.value)}
              />
            </Form.Item>

            {!!errorCnpj && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">CNPJ inválido.</div>
              </div>
            )}
          </Col>

          <Col xs={24} md={4}>
            <Text>CEP</Text>

            <Form.Item
              name="cep"
              rules={requiredRule}
              hasFeedback={!isView}
              className={
                errorCep &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="CEP"
                mask="99999-999"
                className="ant-input"
                onChange={(e) => handleCep(e.target.value)}
                disabled={isView}
              />
            </Form.Item>

            {!!errorCep && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">Preencha o CEP corretamente.</div>
              </div>
            )}
          </Col>

          <Col xs={24} md={8}>
            <Text>Endereço</Text>

            <Form.Item
              name="address"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Input placeholder="Endereço" disabled={isView} />
            </Form.Item>
          </Col>

          <Col xs={24} md={4}>
            <Text>Número</Text>

            <Form.Item
              name="addressNumber"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <InputNumber
                placeholder="Número"
                className="row-xs"
                min={0}
                disabled={isView}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Complemento</Text>

            <Form.Item name="addressComplement" hasFeedback={!isView}>
              <Input placeholder="Complemento" disabled={isView} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Bairro</Text>

            <Form.Item
              name="neighborhood"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Input placeholder="Bairro" disabled={isView} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Cidade</Text>

            <Form.Item name="city" rules={requiredRule} hasFeedback={!isView}>
              <Input placeholder="Cidade" disabled={isView} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text>Estado</Text>

            <Form.Item
              name="stateCode"
              rules={requiredRule}
              hasFeedback={!isView}
            >
              <Select
                options={brazilStates}
                placeholder="Estado"
                disabled={isView}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>E-mail de Contato</Text>

            <Form.Item
              name="email"
              rules={[{ type: 'email' }]}
              hasFeedback={!isView}
            >
              <Input placeholder="E-mail de Contato" disabled={isView} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Text>Telefone/Celular</Text>

            <Form.Item
              name="phoneNumber"
              hasFeedback={!errorMobile && true && !isView}
              className={
                errorMobile &&
                'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
              }
            >
              <InputMask
                placeholder="Telefone de Contato"
                mask="(99) 9999-99999"
                className="ant-input"
                beforeMaskedValueChange={beforeMaskedValueChange}
                disabled={isView}
              />
            </Form.Item>

            {!!errorMobile && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">
                  Telefone/Celular deve ter no mínimo 10 números.
                </div>
              </div>
            )}
          </Col>

          <Col xs={24} md={12}>
            <Text>Observações Gerais</Text>

            <Form.Item name="generalObservations" hasFeedback={!isView}>
              <TextArea
                placeholder="Observações Gerais"
                disabled={isView}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
          </Col>

          {studyPlanValueDefaultCheck && (
            <Col xs={24} md={6}>
              <Text>Planos de Estudos</Text>

              <Form.Item
                name="studyPlanIds"
                hasFeedback={!isView}
                disabled={isView}
              >
                <Select
                  mode="multiple"
                  placeholder="Escolha os Planos de Estudos"
                  defaultValue={studyPlanValueDefault}
                  onChange={(e) => setStudyPlanValueDefault(e)}
                  disabled={isView}
                >
                  {studyPlanOption.map((item) => {
                    const { id, name, shortDescription } = item

                    return (
                      <Option key={id} value={id}>
                        {name} ({shortDescription})
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={6}>
            <Text>Status</Text>

            <Form.Item name="active" valuePropName="checked">
              <Checkbox disabled={isView}>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to="/escola-parceira">
                {isEdit ? 'Cancelar' : 'Voltar'}
              </Link>
            </Button>

            {!isView && (
              <Button htmlType="submit" type="primary" loading={loadingSubmit}>
                {isNew ? 'Confirmar' : 'Atualizar'}
              </Button>
            )}
          </Space>
        </Row>
      </Form>
    </S.FormWrapper>
  )
}

export default PartnerSchoolFormComponent
