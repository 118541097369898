import LiveClassesList from './containers/liveClasses-list.container'
import LiveClassesNew from './containers/liveClasses-new.container'
import LiveClassesView from './containers/liveClasses-view.container'
import LiveClassesEdit from './containers/liveClasses-edit.container'

const urlBase = '/aula-ao-vivo'

const routes = [
  {
    path: urlBase,
    tag: '',
    breadcrumb: [
      {
        breadcrumbName: 'Aulas ao Vivo',
      },
    ],
    authenticated: true,
    component: LiveClassesList,
  },
  {
    path: `${urlBase}/inclusao`,
    tag: '',
    breadcrumb: [
      {
        path: urlBase,
        breadcrumbName: 'Aulas ao Vivo',
      },
      {
        breadcrumbName: 'Inclusão',
      },
    ],
    authenticated: true,
    component: LiveClassesNew,
  },
  {
    path: `${urlBase}/:idLiveClass/visualizacao`,
    tag: '',
    breadcrumb: [
      {
        path: urlBase,
        breadcrumbName: 'Aulas ao Vivo',
      },
      {
        breadcrumbName: 'Aula ao Vivo',
      },
      {
        breadcrumbName: 'Visualização',
      },
    ],
    authenticated: true,
    component: LiveClassesView,
  },
  {
    path: `${urlBase}/:idLiveClass/edicao`,
    tag: '',
    breadcrumb: [
      {
        path: urlBase,
        breadcrumbName: 'Aulas ao Vivo',
      },
      {
        breadcrumbName: 'Aula ao Vivo',
      },
      {
        breadcrumbName: 'Edição',
      },
    ],
    authenticated: true,
    component: LiveClassesEdit,
  },
]

export default routes
