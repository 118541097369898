import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Collapse,
  Form,
  Tooltip,
  Select,
  InputNumber,
  Button,
  Modal,
  Typography,
  DatePicker,
  Upload,
  AutoComplete,
  Alert,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  RightCircleOutlined,
} from '@ant-design/icons'

import { format, parseISO } from 'date-fns'
import PermittedAction from '~/shared/PermittedAction'

import services from '../essay.services'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import notification from '~/shared/Notification'

import dotToComma from '~/utils/dotToComma-replace'

import {
  FormWrapper,
  DateFilterMain,
  DateFilterContent,
  ActionWrapper,
  ModalWrapper,
  FooterWrapper,
} from '../essay.styles'

function EssayListComponent() {
  const [filter, setFilter] = useState({})
  const [dateSendInitial, setDateSendInitial] = useState('')
  const [dateSendEnd, setDateSendEnd] = useState('')
  const [dateCorrectionInitial, setDateCorrectionInitial] = useState('')
  const [dateCorrectionEnd, setDateCorrectionEnd] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [essay, setEssay] = useState([])
  const [essayPageCurrent, setEssayPageCurrent] = useState('')
  const [essayPagesTotal, setEssayPagesTotal] = useState('')
  const [essayStartMark, setEssayStartMark] = useState(false)
  const [partnerSchool, setPartnerSchool] = useState([])
  const [partnerSchoolId, setPartnerSchoolId] = useState('')
  const [markedFinish, setMarkedFinish] = useState(false)
  const [markComplete, setMarkComplete] = useState(false)
  const [fileList, setFileList] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { Text } = Typography
  const { Option } = Select
  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const [essayFinishForm] = Form.useForm()
  const requiredRule = [{ required: true }]

  useEffect(() => {
    async function getEssayPagesTotal() {
      const response = await services.essayList()
      const { data } = response

      setEssayPageCurrent(data.pageNumber)
      setEssayPagesTotal(data.totalRecords)
    }

    getEssayPagesTotal()
  }, [])

  useEffect(() => {
    async function getEssayPartnerSchool() {
      const response = await services.essayPartnerSchool()

      const partnerSchoolList = response.data.map((item) => {
        return {
          id: item.id,
          value: item.schoolName,
        }
      })

      setPartnerSchool(partnerSchoolList)
    }

    getEssayPartnerSchool()
  }, [])

  function handleEssayModal(data) {
    setEssayStartMark(false)
    setEssay(data)
    setIsModalVisible(true)
  }

  async function handleEssayListUpdate() {
    const response = await services.essayListUpdate({
      pageSize: essayPagesTotal,
    })
    const essayUpdate = response.data.content.find(
      (essayList) => essayList.studentEssayId === essay.studentEssayId,
    )

    setEssay(essayUpdate)
  }

  async function getApprovedEssay(essay, type) {
    if (type === 'start') {
      await services.essayStartMark(essay)

      handleEssayListUpdate()
      setFilter({})
    }

    setEssayStartMark(true)
    setMarkComplete(true)
    setFilter({})
  }

  async function getDisapprovedId(essay) {
    const configModalDelete = {
      title: 'Deseja reprovar essa redação?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          const {
            studentEssayId,
            studyPlanId,
            disciplineLessonId,
            studentId,
          } = essay

          const data = {
            studentEssayId,
            studyPlanId,
            disciplineLessonId,
            studentId,
          }

          services
            .essayDisapproved(data)
            .then(() => {
              notification.success('Redação reprovada com sucesso.')
              setEssayStartMark(false)
              setMarkComplete(false)
              setMarkedFinish(true)
              setFilter({})
              resolve()
            })
            .catch(reject)
        })
      },
      onCancel() {},
    }

    Modal.confirm(configModalDelete)
  }

  function getDateFormat(dateValue, type) {
    if (dateValue) {
      const date = parseISO(dateValue)
      let newFormatDate = 'dd/MM/yyyy'

      if (type) {
        newFormatDate = 'yyyy-MM-dd'
      }

      // const dateInitial = dateString[0].replace(/\//g, '-')
      // const dateEnd = dateString[1].replace(/\//g, '-')

      return format(date, newFormatDate)
    }

    return ''
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => handleEssayModal(data)}
            />
          </Tooltip>

          {data.marked && (
            <>
              <Tooltip title="Download redação do aluno">
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  href={data.studentEssayUrl}
                  target="_blank"
                />
              </Tooltip>

              {data.markedUrl && (
                <Tooltip title="Download da correção da redação">
                  <Button
                    type="link"
                    icon={<FileDoneOutlined />}
                    href={data.markedUrl}
                    target="_blank"
                  />
                </Tooltip>
              )}
            </>
          )}
        </PermittedAction>
      </Button.Group>
    )
  }

  const columns = [
    {
      title: 'Aluno',
      dataIndex: 'studentName',
    },
    {
      title: 'Escola Parceira',
      dataIndex: 'partnerSchoolName',
    },
    {
      title: 'Planos de Estudos',
      dataIndex: 'studyPlanName',
    },
    {
      title: 'Título da Aula',
      dataIndex: 'disciplineLessonName',
    },
    {
      title: 'Data de Envio',
      dataIndex: 'studentUploadDate',
      render: (studentUploadDate) => getDateFormat(studentUploadDate),
    },
    {
      title: 'Status da Correção',
      dataIndex: 'studentEssayStatusDescription',
    },
    {
      title: 'Data da Correção',
      dataIndex: 'markedDate',
      render: (markedDate) => getDateFormat(markedDate),
    },
    {
      title: 'Nota da Redação',
      dataIndex: 'score',
      render: (score) => (score > 0 ? dotToComma(score) : ''),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderActions(data),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function handleChangeDateSend(_, dateString) {
    setDateSendInitial(getDateFormat(dateString[0], true))
    setDateSendEnd(getDateFormat(dateString[1], true))
  }

  function handleChangeDateCorrection(_, dateString) {
    setDateCorrectionInitial(getDateFormat(dateString[0], true))
    setDateCorrectionEnd(getDateFormat(dateString[1], true))
  }

  function handlePartnerSchoolChange(value, option) {
    setPartnerSchoolId(option.id)
  }

  function onFinish(values) {
    const data = {
      StudentEssayStatusId: values.status,
      SendDateBegin: dateSendInitial,
      SendDateEnd: dateSendEnd,
      MarkDateBegin: dateCorrectionInitial,
      MarkDateEnd: dateCorrectionEnd,
      PartnerSchoolId: partnerSchoolId,
      StudentType: values.typeStudent,
    }

    setFilter(data)
  }

  useEffect(() => {
    const fileListEdit = [
      {
        uid: 'uid',
        name: 'Arquivo em PDF, DOC, DOCX',
        status: 'done',
      },
    ]

    setFileList(fileListEdit)
  }, [isModalVisible])

  async function onChangeFile(info) {
    if (info.file.status === 'done') {
      setFileList([])
      setFileList([info.file.originFileObj])
    } else if (info.file.status === 'removed') {
      setFileList([])
    } else if (info.file.status === 'error') {
      notification.error('Ocorreu um erro ao selecionar o arquivo.')
    }
  }

  async function onEssaySubmit(data) {
    setLoadingSubmit(true)

    const essayFormData = new FormData()
    essayFormData.append('MarkedEssay', data.MarkedEssay)
    essayFormData.append('StudentEssayId', data.StudentEssayId)
    essayFormData.append('StudyPlanId', data.StudyPlanId)
    essayFormData.append('disciplineLessonId', data.disciplineLessonId)
    essayFormData.append('StudentId', data.StudentId)
    essayFormData.append('Score', data.Score)

    try {
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }

      await services.essayMark(essayFormData, config)
      setFilter({ pageNumber: essayPageCurrent })
      setFileList([])
      setLoadingSubmit(false)
      setEssayStartMark(false)
      setMarkComplete(false)
      setMarkedFinish(false)
      handleEssayListUpdate()
      notification.success('Correção da redação enviada com sucesso.')
      essayFinishForm.resetFields()
    } catch {
      setLoadingSubmit(false)
      notification.error('Ocorreu um erro durante a execução.')
    }
  }

  async function onFinishEssay(values) {
    const { studentEssayId, studyPlanId, disciplineLessonId, studentId } = essay

    const data = {
      MarkedEssay: fileList !== null ? fileList[0] : null,
      StudentEssayId: studentEssayId,
      StudyPlanId: studyPlanId,
      disciplineLessonId,
      StudentId: studentId,
      Score: values.essayNote,
    }

    onEssaySubmit(data)
  }

  const timeoutForFile = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 5)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    setEssayStartMark(false)
    setMarkComplete(false)
    setMarkedFinish(false)
    essayFinishForm.resetFields()
    setFilter({})
  }

  function ModalFooter() {
    return (
      <FooterWrapper>
        <Tooltip title={!markComplete && !markedFinish ? 'Fechar' : 'Cancelar'}>
          <Button
            type="primary"
            icon={<CloseCircleOutlined />}
            onClick={handleModalCancel}
            danger
          >
            {!markComplete && !markedFinish ? 'Fechar' : 'Cancelar'}
          </Button>
        </Tooltip>

        {essay.canStartMark !== '' &&
          markComplete !== false &&
          markedFinish === false && (
            <Tooltip title="Enviar">
              <Button
                htmlType="submit"
                type="primary"
                icon={<RightCircleOutlined />}
                loading={loadingSubmit}
                className="send-submit"
              >
                Enviar
              </Button>
            </Tooltip>
          )}
      </FooterWrapper>
    )
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <FormWrapper>
            <Form form={form} onFinish={onFinish}>
              <Row gutter={16} className="row-form">
                <Col xs={4} md={6}>
                  <Text>Status da Correção</Text>
                  <Form.Item name="status">
                    <Select
                      placeholder="Escolha o status da correção"
                      allowClear
                    >
                      <Option value="">Todos</Option>
                      <Option value="1">Aguardando</Option>
                      <Option value="2">Em Correção</Option>
                      <Option value="3">Reprovada</Option>
                      <Option value="4">Corrigida</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={4} md={6}>
                  <DateFilterMain>
                    <DateFilterContent>
                      <Text>Data de Envio</Text>
                      <Form.Item name="dateSend" className="margin">
                        <RangePicker showtime onChange={handleChangeDateSend} />
                      </Form.Item>
                    </DateFilterContent>

                    <DateFilterContent>
                      <Text>Data da Correção</Text>
                      <Form.Item name="dateCorrection">
                        <RangePicker
                          showtime
                          onChange={handleChangeDateCorrection}
                        />
                      </Form.Item>
                    </DateFilterContent>
                  </DateFilterMain>
                </Col>

                {!!partnerSchool && (
                  <Col xs={4} md={4}>
                    <Text>Escola Parceira</Text>
                    <Form.Item name="partnerSchool">
                      <AutoComplete
                        placeholder="Escolha a escola"
                        options={partnerSchool}
                        onSelect={(val, option) =>
                          handlePartnerSchoolChange(val, option)
                        }
                        value={partnerSchool.id}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col xs={4} md={4}>
                  <Text>Tipo de Aluno</Text>
                  <Form.Item name="typeStudent">
                    <Select placeholder="Escolha o tipo do aluno" allowClear>
                      <Option value="">Todos</Option>
                      <Option value="1">Aluno Escola Parceira</Option>
                      <Option value="2">Alunos Assinantes</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="filter-form">
                  <Space>
                    <Tooltip title="Pesquisar">
                      <Button
                        htmlType="submit"
                        type="primary"
                        icon={<SearchOutlined />}
                      />
                    </Tooltip>

                    <Tooltip title="Redefinir Filtros">
                      <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={resetFilter}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        </Collapse.Panel>
      </Collapse>

      <Table baseURL="/essay" filter={filter} columns={columns} />

      {!!essay && (
        <Modal
          title={essay.disciplineLessonName}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer=""
        >
          <ModalWrapper>
            <Text className="text">Aluno: {essay.studentName}</Text>
            <Text className="text">
              Data de Envio: {getDateFormat(essay.studentUploadDate)}
            </Text>

            {!!essay.disapproved && (
              <Text className="text">Status da Correção: Reprovada</Text>
            )}

            {!!essay.marked && (
              <>
                <Text className="text">
                  Data da Correção: {getDateFormat(essay.markedDate)}
                </Text>

                <Text className="text">
                  Nota: {essay.score && dotToComma(essay.score)}
                </Text>
              </>
            )}

            <Form form={essayFinishForm} onFinish={onFinishEssay}>
              <ActionWrapper>
                {essay.canStartMark && !markComplete && (
                  <>
                    <Tooltip title="Iniciar correção">
                      <Button
                        type="link"
                        icon={<CheckCircleOutlined />}
                        onClick={() => getApprovedEssay(essay, 'start')}
                        className="reset"
                      >
                        Iniciar correção
                      </Button>
                    </Tooltip>

                    {essay.markStartDate === null && (
                      <Alert
                        type="info"
                        message="Tenha certeza que deseja iniciar a correção da redação."
                      />
                    )}
                  </>
                )}

                {essay.markStartDate &&
                  !markComplete &&
                  !markedFinish &&
                  !essay.marked &&
                  !essay.disapproved &&
                  essay.canUploadMarkedEssay && (
                    <Tooltip title="Concluir correção">
                      <Button
                        type="link"
                        icon={<CheckCircleOutlined />}
                        onClick={() => getApprovedEssay(essay)}
                        className="reset"
                      >
                        Concluir correção
                      </Button>
                    </Tooltip>
                  )}

                {essay.markStartDate !== null && !essay.disapproved && (
                  <Tooltip title="Download redação do aluno">
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      href={essay.studentEssayUrl}
                      target="_blank"
                      className="reset"
                    >
                      Redação do aluno
                    </Button>
                  </Tooltip>
                )}

                {essay.markedUrl && !essay.disapproved && (
                  <Tooltip title="Download da correção da redação">
                    <Button
                      type="link"
                      icon={<FileDoneOutlined />}
                      href={essay.markedUrl}
                      target="_blank"
                      className="reset"
                    >
                      Redação do professor
                    </Button>
                  </Tooltip>
                )}

                {essay.canReprove &&
                  essay.markStartDate !== null &&
                  !essay.marked &&
                  !essay.disapproved &&
                  !markedFinish && (
                    <Tooltip title="Reprovar">
                      <Button
                        type="link"
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() => getDisapprovedId(essay)}
                        className="reset"
                      >
                        Reprovar redação
                      </Button>
                    </Tooltip>
                  )}
              </ActionWrapper>

              {!!essayStartMark && (
                <Col span={24}>
                  <Form.Item name="essayNote" rules={requiredRule}>
                    <InputNumber
                      placeholder="Nota da redação"
                      decimalSeparator=","
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  <Form.Item name="essayUpload" rules={requiredRule}>
                    <Upload
                      name="upload"
                      accept={['.pdf', '.doc', '.docx']}
                      onChange={onChangeFile}
                      fileList={fileList}
                      withCredentials
                      customRequest={timeoutForFile}
                    >
                      <Button icon={<UploadOutlined />}>
                        Carregar correção
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              )}
              <ModalFooter />
            </Form>
          </ModalWrapper>
        </Modal>
      )}
    </Space>
  )
}

export default EssayListComponent
