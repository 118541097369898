import React from 'react'

import { Editor } from '@tinymce/tinymce-react'
import services from '../question.services'

function Tinymce(props) {
  const { valueEditView, readonly, onEditorChange } = props

  return (
    <Editor
      key={1}
      apiKey="ieb59hbzg8i2husrzf2w667b49ykjg955zehe0a36yffty17"
      value={valueEditView}
      content="1"
      disabled={readonly}
      init={{
        selector: '.alternative',
        external_plugins: {
          mathjax:
            'https://cdn.jsdelivr.net/npm/@dimakorotkov/tinymce-mathjax@1.0.4/plugin.min.js',
        },
        mathjax: {
          lib:
            'https://cdn.jsdelivr.net/npm/mathjax@3.0.5/es5/tex-mml-chtml.js',
        },
        language: 'pt_BR',
        height: 300,
        plugins: ['lists, link, code image'],

        toolbar:
          'undo redo | styleselect | ' +
          'bold italic backcolor  | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'mathjax | removeformat | image code',

        images_upload_url: 'upload.php',
        images_upload_handler(blobInfo, success, failure) {
          async function saveImage() {
            try {
              const formData = new FormData()
              formData.append('file', blobInfo.blob(), blobInfo.filename())
              const saveImage = await services.postImage(formData)
              const imgUrl = saveImage.data.imageUrl
              success(imgUrl)
            } catch {
              failure('Ocorreu um erro.')
            }
          }

          saveImage()
        },
      }}
      onEditorChange={onEditorChange}
    />
  )
}

export default Tinymce
