import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Tooltip,
  Select,
  Divider,
  Space,
  Button,
  Tag,
  Typography,
  Modal,
} from 'antd'
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

import MathJax from 'react-mathjax-preview'
import { Editor } from '@tinymce/tinymce-react'
import services from '../forumPost.services'

import notification from '~/shared/Notification'

function ForumPostComponent(props) {
  const [formForumPost] = Form.useForm()
  const [termsTag, setTermsTag] = useState([])
  const [termsOption, setTermsOption] = useState([])
  const [termsValueDefault, setTermsValueDefault] = useState([])
  const [replyMessage, setReplyMessage] = useState('')
  const [answerActive, setAnswerActive] = useState(false)
  const [anserEdit, setAnserEdit] = useState(false)
  const [anserSuccess, setAnserSuccess] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { forumPost } = props
  const { Title, Text } = Typography
  const { Option } = Select
  const history = useHistory()

  useEffect(() => {
    formForumPost.setFieldsValue(forumPost)
  }, [formForumPost, forumPost])

  useEffect(() => {
    async function getData() {
      const termTag = await forumPost.tags.map((itemTermTag) => {
        return (
          <Tag key={itemTermTag.forumPostTagId}>
            {itemTermTag.forumPostTagDescription}
          </Tag>
        )
      })

      setTermsTag(termTag)

      setReplyMessage(forumPost.replyMessage)
    }

    if (forumPost) {
      getData()
    }
  }, [forumPost])

  useEffect(() => {
    async function getTags() {
      const response = await services.tagList()

      setTermsOption(response.data)
    }

    getTags()

    async function getTermsValueDefault() {
      const termDefault = await forumPost.tags.map((itemTermDefault) => {
        return {
          id: itemTermDefault.forumPostTagId,
          value: itemTermDefault.forumPostTagDescription,
        }
      })

      const termValue = await termDefault.map((itemTerm) => {
        return itemTerm.value
      })

      setTermsValueDefault(termValue)
    }

    if (forumPost) {
      getTermsValueDefault()
    }
  }, [forumPost])

  function handleEditorChange(content) {
    setReplyMessage(content)
  }

  async function onFinish() {
    setLoadingSubmit(true)

    const data = {
      id: forumPost.id,
      terms: termsValueDefault,
      message: replyMessage,
    }

    try {
      await services.forumPostAnswer(data)

      notification.success(
        `Resposta ${anserEdit ? 'alterada' : 'cadastrada'} com sucesso.`,
      )
      setAnserSuccess(true)
      setAnswerActive(false)
      setLoadingSubmit(false)
      setTimeout(() => {
        history.go(0)
      }, 3000)
    } catch (e) {
      setLoadingSubmit(false)
      notification.error(e)
    }
  }

  async function getDisapprovedId(id) {
    const configModalDelete = {
      title: 'Deseja reprovar essa dúvida?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          services
            .forumPostDisapproved(id)
            .then(() => {
              notification.success('Resposta reprovada com sucesso.')
              resolve()
              history.push('/duvida')
            })
            .catch(reject)
        })
      },
      onCancel() {},
    }

    Modal.confirm(configModalDelete)
  }

  function handleAnswer(type, edit) {
    setAnswerActive(type)
    setAnserEdit(edit)
  }

  return (
    !!forumPost && (
      <Form
        form={formForumPost}
        initialValues={{ active: true }}
        onFinish={onFinish}
      >
        <Row gutter={16} align="middle">
          <Col span={24}>
            <Title>{forumPost.subject}</Title>
          </Col>

          <Col span={24} style={{ marginBottom: '10px' }}>
            {termsTag}
          </Col>

          <Col span={24}>
            <Text>Disciplina: {forumPost.disciplineName}</Text>
          </Col>

          <Col span={24}>
            <Text>
              Criado por: {forumPost.createdBy} à{' '}
              {forumPost.formattedCreateDate}
            </Text>
          </Col>

          <Col span={24}>
            <Text>Status: {forumPost.forumPostStatusDescription}</Text>
          </Col>

          <Divider />

          <Col span={24}>
            <MathJax math={forumPost.message} />
          </Col>

          {!!forumPost.repliedBy && (
            <>
              <Divider />

              <Col span={24}>
                <Title>Resposta</Title>
              </Col>

              <Col span={24}>
                <Text>
                  Respondida por: {forumPost.repliedBy} à{' '}
                  {forumPost.formattedReplyDate}
                </Text>
              </Col>

              <Divider />

              {!!replyMessage && (
                <Col span={24} style={{ paddingBottom: '15px' }}>
                  <MathJax math={replyMessage} />
                </Col>
              )}
            </>
          )}

          {!!forumPost.editable && (
            <Col span={24}>
              <Space>
                <Tooltip title="Editar">
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => handleAnswer(true, true)}
                    style={{ padding: 0 }}
                  >
                    Editar
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          )}

          {forumPost.repliedBy === null &&
            forumPost.forumPostStatusId !== 3 &&
            answerActive === false &&
            anserSuccess === false && (
              <>
                <Divider />

                <Row>
                  <Space>
                    <Tooltip title="Responder">
                      <Button
                        type="link"
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleAnswer(true)}
                      >
                        Responder
                      </Button>
                    </Tooltip>

                    <Tooltip title="Reprovar">
                      <Button
                        type="link"
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() => getDisapprovedId(forumPost.id)}
                      >
                        Reprovar
                      </Button>
                    </Tooltip>
                  </Space>
                </Row>
              </>
            )}

          {!!answerActive && (
            <>
              <Divider />

              <Row style={{ width: '100%' }}>
                <Col span={24}>
                  <Title>Responder dúvida:</Title>

                  <Form.Item name="answer">
                    <Editor
                      key={1}
                      initialValue={replyMessage || ''}
                      value={replyMessage || ''}
                      init={{
                        height: 500,
                        menubar: false,
                        readonly: 0,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        external_plugins: {
                          mathjax:
                            'https://cdn.jsdelivr.net/npm/@dimakorotkov/tinymce-mathjax@1.0.4/plugin.min.js',
                        },
                        mathjax: {
                          lib:
                            'https://cdn.jsdelivr.net/npm/mathjax@3.0.5/es5/tex-mml-chtml.js',
                        },
                        language: 'pt_BR',
                        toolbar:
                          'undo redo | styleselect | ' +
                          'bold italic backcolor  | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'mathjax | removeformat | image code',

                        images_upload_handler(blobInfo, success, failure) {
                          async function saveImage() {
                            try {
                              const formData = new FormData()
                              formData.append(
                                'file',
                                blobInfo.blob(),
                                blobInfo.filename(),
                              )

                              const saveImage = await services.postImage(
                                formData,
                              )

                              const imgUrl = saveImage.data.imageUrl
                              success(imgUrl)
                            } catch {
                              failure('Ocorreu um erro.')
                            }
                          }

                          saveImage()
                        },
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  </Form.Item>

                  {!!termsOption && termsValueDefault && (
                    <Form.Item name="terms">
                      <Select
                        mode="tags"
                        placeholder="Preencha os termos"
                        defaultValue={termsValueDefault}
                        onChange={(e) => setTermsValueDefault(e)}
                        tokenSeparators={[',']}
                      >
                        {termsOption.map((itemTerm) => {
                          const value = itemTerm.id
                          const label = itemTerm.name
                          return (
                            <Option key={value} value={label}>
                              {label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>

                <Col span={24}>
                  <Space>
                    <Tooltip title="Enviar">
                      <Button
                        htmlType="submit"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        loading={loadingSubmit}
                      >
                        Confirmar
                      </Button>
                    </Tooltip>

                    <Tooltip title="Cancelar">
                      <Button
                        type="link"
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() => handleAnswer(false, false)}
                      >
                        Cancelar
                      </Button>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Row>

        <Row justify="end">
          <Space>
            <Button type="text">
              <Link to="/duvida">Voltar</Link>
            </Button>
          </Space>
        </Row>
      </Form>
    )
  )
}

export default ForumPostComponent
