import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Checkbox,
  Form,
  Input,
  Select,
  Tooltip,
  Button,
  Tag,
} from 'antd'
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons'

import services from '../studyplan.services'
import notification from '~/shared/Notification'
import Space from '~/shared/Space'
import Table from '~/shared/Table'
import Modal from '~/shared/Modal'
import PermittedAction from '~/shared/PermittedAction'

function StudyPlanListComponent(props) {
  const { disciplines, subjects, getSubjects } = props
  const { Option, OptGroup } = Select
  
  const [listStudyPlanIds, setStudyPlanIds] = useState([])
  
  const [filter, setFilter] = useState({})
  const [listDisciplineIds, setDisciplineIds] = useState([])
  const [listSubjectIds, setSubjectIds] = useState([])

  const [disableSubject, setDisableSubject] = useState(true)

  const history = useHistory()
  const [form] = Form.useForm()

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  function view(id) {
    history.push(`/plano-estudo/${id}/visualizacao`)
  }

  async function clone(id) {
    const response = await services.cloneStudyPlan(id)
    const newId = response.data
    history.push(`/plano-estudo/${newId}/edicao`)
  }

  function edit(id) {
    history.push(`/plano-estudo/${id}/edicao`)
  }

  function remove(id) {
    Modal.delete(services.delete, id, () => {
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    })
  }

  function handleSendeditFreeCheck(ids = [], check) {
    services.updateFree(ids, check);
  }

  async function reset(id) {
    try {
      await services.reset(id)
      notification.success('Usuário desbloqueado com sucesso.')
      const formFielsdValue = form.getFieldsValue()
      setFilter(formFielsdValue)
    } catch {
      notification.error('Ocorreu um erro ao desbloquear o usuário')
    }
  }

  function renderActions(row) {
    const { id } = row

    return (
      <Button.Group>
        <PermittedAction tag="StudyPlanList">
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => view(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="StudyPlanEdit">
          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => edit(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="StudyPlanDelete">
          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => remove(id)}
            />
          </Tooltip>
        </PermittedAction>

        <PermittedAction tag="StudyPlanDelete">
          <Tooltip title="Clonar">
            <Button
              type="link"
              icon={<MergeCellsOutlined />}
              onClick={() => clone(id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }
  // Classe customizada do checkbox
  function CustCheckbox(props) {

    const [isChecked, setIsChecked] = useState(props.check);
    
    const toggleChange = () => {
      var ids = [props.id];
      handleSendeditFreeCheck(ids, !isChecked);

      setIsChecked(!isChecked)
    };

    return (
      <Checkbox checked={isChecked} onChange={toggleChange}></Checkbox>
    );

  }

  function renderFreemiumCK(row) {
   
    if(!listStudyPlanIds.includes(row.id) ){
      if(listStudyPlanIds.length < 10){
        setStudyPlanIds(listStudyPlanIds => ([...listStudyPlanIds,  row.id]) );
      } else {
        setStudyPlanIds([row.id]);
      }
    }
    
    return (<CustCheckbox check={row.studyPlanFreemium} id={row.id} />)
  }

  function handleAllFree(e){
        handleSendeditFreeCheck(listStudyPlanIds, e.target.checked);
  }

  const columns = [
    {
       //title: (row) => <Checkbox key={row.id} onChange={(e) => handleAllFree(e)}>Free</Checkbox>,
       title: "Free",
       align: 'center',
       render: (row) => renderFreemiumCK(row),
    },
    {
      title: 'Plano de Estudos',
      dataIndex: 'studyPlanName',
    },
    {
      title: 'Tipo',
      dataIndex: 'studyPlanType',
    },
    {
      title: 'Disciplinas',
      dataIndex: 'disciplinesNumber',
    },
    {
      title: 'Matérias',
      dataIndex: 'disciplineSubjectsNumber',
    },
    {
      title: 'Aulas',
      dataIndex: 'disciplineLessonsNumber',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (row) => renderActions(row),
    },
  ]

  function resetFilter() {
    form.resetFields()
    setFilter({})
  }

  function GetValueFromStatus(selectedStatus) {
    switch (selectedStatus) {
      case '1':
        return true
      case '0':
        return false
      default:
        return null
    }
  }

  function onFinish(values) {
    const activeResult = GetValueFromStatus(values.status)

    const data = {
      studyPlanName: values.studyPlanName,
      active: activeResult,
      disciplineIds: listDisciplineIds,
      disciplineSubjectIds: listSubjectIds,
    }

    setFilter(data)
  }

  function onChangeDiscipline(value) {
    if (value.length > 0) {
      setDisableSubject(false)
    } else {
      setDisableSubject(true)
      form.setFieldsValue({ discipline: [] })
      form.setFieldsValue({ subject: [] })
    }
    setDisciplineIds(value)
  }

  function onChangeSubjects(value) {
    if (value.length === 0) {
      setDisciplineIds(null)
      form.setFieldsValue({ discipline: [] })
      form.setFieldsValue({ subject: [] })
      setDisableSubject(true)
    } else {
      setSubjectIds(value)
    }
  }

  function onFocusSubjects() {
    let disciplineIds = null

    if (listDisciplineIds != null) {
      disciplineIds = [...listDisciplineIds]
      const objListDisciplineIds = { disciplineIds }
      getSubjects(objListDisciplineIds)
    } else {
      getSubjects(disciplineIds)
    }
  }
 

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item name="studyPlanName">
                  <Input placeholder="Plano de Estudos" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={4} md={14}>
                <Form.Item name="discipline">
                  <Select
                    mode="multiple"
                    options={disciplines}
                    placeholder="Disciplina"
                    onChange={onChangeDiscipline}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={18}>
                <Form.Item name="subject">
                  <Select
                    mode="multiple"
                    options={subjects}
                    placeholder="Matéria"
                    disabled={disableSubject}
                    onFocus={onFocusSubjects}
                    onChange={onChangeSubjects}
                    filterOption={(input, option) => {
                      if (typeof option.label === 'object') {
                        return (
                          option?.label?.props?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      } else {
                        return (
                          option?.label
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={4} md={4}>
                <Form.Item name="status">
                  <Select placeholder="Status" allowClear>
                    <OptGroup label="Status">
                      <Option value="1">Ativo</Option>
                      <Option value="0">Inativo</Option>
                      <Option value="null">Todos</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <PermittedAction tag="StudyPlanCreate">
        <Row justify="end">
          <Tooltip title="Incluir">
            <Link to="/plano-estudo/inclusao">
              <Button type="primary" icon={<PlusOutlined />} />
            </Link>
          </Tooltip>
        </Row>
      </PermittedAction>

      <Table baseURL="/study-plan" filter={filter} columns={columns}  />
    </Space>
  )
}

export default StudyPlanListComponent
