import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton, Form } from 'antd'

import DisciplineFormComponent from '../components/disciplinesubjects-form.component'
import services from '../disciplinesubjects.services'

function DisciplineSubjectsViewContainer() {
  const [form] = Form.useForm()
  const [loadingData, setLoadingData] = useState(true)
  const [disciplineSubjects, setDisciplineSubjects] = useState(null)
  const [areas, setAreas] = useState([])
  const [sequence, setSequence] = useState([])
  const params = useParams()
  const { iddiscipline, id } = params

  async function loadAreas() {
    const responseAreas = await services.getAreas(iddiscipline)

    const mappedAreas = responseAreas.data.map((area) => {
      return { label: area.name, value: area.id }
    })

    setAreas(mappedAreas)
  }

  async function loadSequence() {
    const responseSequence = await services.getSequence(iddiscipline, true)

    const mappedSequence = responseSequence.data.map((sequence) => {
      return {
        label: sequence,
        value: sequence,
      }
    })
    setSequence(mappedSequence)
  }

  useEffect(() => {
    async function getData() {
      const responseDisciplineSubjects = await services.getById(id)
      setDisciplineSubjects(responseDisciplineSubjects.data)
      setLoadingData(false)
    }

    getData()
    loadAreas()
    loadSequence()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, iddiscipline])

  if (loadingData) return <Skeleton active />

  return (
    <DisciplineFormComponent
      type="V"
      disciplineSubjects={disciplineSubjects}
      areas={areas}
      displaySequence={sequence}
      disciplineId={disciplineSubjects.disciplineId}
      formContainer={form}
      loadAreas={loadAreas}
      loadSequence={loadSequence}
    />
  )
}

export default DisciplineSubjectsViewContainer
