import api from '~/services/api'

const services = {
  forumPostList() {
    return api.get(`/forum/post`)
  },
  forumPostUpdate(data) {
    return api.put('/forum/post', data)
  },
  forumPostListById(id) {
    return api.get(`/forum/post/${id}`)
  },
  postStatusList() {
    return api.get(`/forum/post-status`)
  },
  tagList() {
    return api.get(`/tag`)
  },
  disciplineActive() {
    return api.get(`/discipline/active`)
  },
  forumPostDisapproved(id) {
    return api.post(`/forum/post-reprove`, {
      forumPostId: id,
    })
  },
  forumPostAnswer(data) {
    return api.post(`/forum/post-reply`, {
      forumPostId: data.id,
      message: data.message || data.answer.level.content,
      tags: data.terms,
    })
  },
  postImage(data) {
    return api.post('/resource/image/upload', data)
  },
}

export default services
