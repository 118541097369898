import api from '~/services/api'

const services = {
  studentAdd(data) {
    return api.post('/student', data)
  },
  updateStudent(data) {
    return api.put('/student', data)
  },
  getStudent(idstudent) {
    return api.get(`/student/${idstudent}`)
  },
  getSubscriptionPlan() {
    return api.get('/subscriptionplan/autocomplete', {
      params: { Active: true },
    })
  },
  getPartnerSchool() {
    return api.get('/partnerschool/autocomplete', {
      params: { Active: true },
    })
  },
  getStudyPlan() {
    return api.get('/study-plan/control/autocomplete', {
      params: { Active: true },
    })
  },
  studentPlanBySubscription(data) {
    return api.post('/student/study-plan-by-subscription', data)
  },
  getStudyPlanBySubscription(idstudent) {
    return api.get(`/student/${idstudent}/study-plan-by-subscription`)
  },
  getStudyPlanBySubscriptionById(idstudent, idplan) {
    return api.get(`/student/${idstudent}/study-plan-by-subscription/${idplan}`)
  },
  getStudyPlanById(idstudent, baseURL, idplan) {
    return api.get(`/student/${idstudent}/${baseURL}/${idplan}`)
  },
  getStudyPlanByPartnerSchool(idstudent) {
    return api.get(`/student/${idstudent}/study-plan-by-partner-school`)
  },
  updateStudyPlanBySubscription(data) {
    return api.put('/student/study-plan-by-subscription', data)
  },
  studentPlanByPartnerSchool(data) {
    return api.post('/student/study-plan-by-partner-school', data)
  },
  getStudentPlanByPartnerSchoolById(idstudent, studentStudyPlanId) {
    return api.get(
      `/student/${idstudent}/study-plan-by-partner-school/${studentStudyPlanId}`,
    )
  },
  updateStudyPlanPartnerSchool(data) {
    return api.put('/student/study-plan-by-partner-school', data)
  },
}

export default services
