import UserPasswordChange from './containers/userPasswordChange-list.container'

const routes = [
  {
    path: '/trocar-senha',
    tag: 'UserEdit',
    breadcrumb: [
      {
        breadcrumbName: 'Trocar senha',
      },
    ],
    authenticated: true,
    component: UserPasswordChange,
  },
]

export default routes
