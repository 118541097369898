import api from '~/services/api'

const services = {
  save(data) {
    return api.post('/subject', data)
  },
  getById(id) {
    return api.get(`/subject/${id}`)
  },
  update(data) {
    return api.put('/subject', data)
  },
  delete(id) {
    return api.delete(`/subject/${id}`)
  },
  updateSequence(data) {
    return api.post('subject/update-sequence', data)
  },
  getAreas(DisciplineId) {
    return api.get(`/area/?DisciplineId=${DisciplineId}`)
  },
  saveArea(data) {
    return api.post('/area', data)
  },
  getSequence(DisciplineId, IsNewDisciplineSubject) {
    return api.get(
      `/subject/sequence?DisciplineId=${DisciplineId}&IsNewDisciplineSubject=${IsNewDisciplineSubject}`,
    )
  },
  subjectsActive(disciplineIds) {
    return api.post('subject/active/grouped-by-discipline', disciplineIds)
  },
}

export default services
