import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Tooltip,
  Select,
  Tag,
  Button,
  Typography,
  Modal,
} from 'antd'

import {
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  TagOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'

import Space from '~/shared/Space'
import Table from '~/shared/Table'
import PermittedAction from '~/shared/PermittedAction'
import notification from '~/shared/Notification'

import services from '../discountCoupon.services'

import priceMask from '~/utils/price-mask'
import numberMask from '~/utils/number-price-mask'

function DiscountCouponListComponent() {
  const [filter, setFilter] = useState()
  const { Text } = Typography
  const { Option } = Select
  const [formDiscountCoupon] = Form.useForm()
  const history = useHistory()

  function getFilter() {
    setFilter({
      discountCouponStatus: 0,
    })
  }

  useEffect(() => {
    getFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderAmountPercent(data) {
    const { typeId, amountPercent } = data

    let values = priceMask(amountPercent)

    if (typeId === 2) values = `${numberMask(amountPercent)}`

    return values
  }

  async function getDiscountCouponDeleteId(id) {
    const configModalDelete = {
      title: 'Deseja excluir esse cupom?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return new Promise((resolve, reject) => {
          services
            .discountCouponDelete(id)
            .then(() => {
              notification.success('Cupom excluído com sucesso.')
              setFilter({})
              resolve()
            })
            .catch(reject)
        })
      },
      onCancel() {},
    }

    Modal.confirm(configModalDelete)
  }

  function renderActions(data) {
    return (
      <Button.Group>
        <PermittedAction>
          <Tooltip title="Visualizar">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() =>
                history.push(`/cupom-de-desconto/${data.id}/visualizacao`)
              }
            />
          </Tooltip>

          <Tooltip title="Editar">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() =>
                history.push(`/cupom-de-desconto/${data.id}/edicao`)
              }
            />
          </Tooltip>

          <Tooltip title="Excluir">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => getDiscountCouponDeleteId(data.id)}
            />
          </Tooltip>
        </PermittedAction>
      </Button.Group>
    )
  }

  function renderTag(active) {
    return active ? (
      <Tag color="green">Ativo</Tag>
    ) : (
      <Tag color="red">Inativo</Tag>
    )
  }

  const columns = [
    {
      title: 'Cupom',
      dataIndex: 'code',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
    },
    {
      title: 'Tipo do Desconto',
      dataIndex: 'typeDescription',
    },
    {
      title: 'Valor ou Porcentagem do Desconto',
      render: (data) =>
        renderAmountPercent({
          amountPercent: data.amountPercent,
          typeId: data.typeId,
        }),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (active) => renderTag(active),
    },
    {
      title: 'Ações',
      align: 'center',
      render: (data) => renderActions(data),
    },
  ]

  function resetFilter() {
    formDiscountCoupon.resetFields()
    setFilter({})
  }

  function onFinish(values) {
    const { discountCouponCode, discountCouponStatus } = values
    const data = { discountCouponCode, discountCouponStatus }

    setFilter(data)
  }

  return (
    <Space direction="vertical">
      <Collapse defaultActiveKey={1} bordered={false}>
        <Collapse.Panel key={1} header="Filtros">
          <Form form={formDiscountCoupon} onFinish={onFinish}>
            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Text>Cupom de Desconto</Text>

                <Form.Item name="discountCouponCode">
                  <Input placeholder="Digite o código do cupom" />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Text>Status</Text>

                <Form.Item name="discountCouponStatus">
                  <Select
                    placeholder="Escolha o status do cupom"
                    defaultValue="0"
                  >
                    <Option value="0">Todos</Option>
                    <Option value="1">Ativos</Option>
                    <Option value="2">Inativos</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} align="end">
              <Col className="filter-form">
                <Space>
                  <Tooltip title="Pesquisar">
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Redefinir Filtros">
                    <Button
                      type="primary"
                      icon={<RedoOutlined />}
                      onClick={resetFilter}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <Row justify="end">
        <PermittedAction>
          <Tooltip title="Incluir Novo Cupom">
            <Link to="/cupom-de-desconto/inclusao/">
              <Button type="primary" icon={<TagOutlined />} />
            </Link>
          </Tooltip>
        </PermittedAction>
      </Row>

      {filter && (
        <Table baseURL="/discount-coupon" filter={filter} columns={columns} />
      )}
    </Space>
  )
}

export default DiscountCouponListComponent
