// import TinyMceContainer from './containers'
import TinyMceContainer2 from './containers/mathjax'

const routes = [
  // {
  //   path: '/tinymce',
  //   component: TinyMceContainer,
  //   authenticated: true,
  // },
  {
    path: '/tinymce2',
    component: TinyMceContainer2,
    authenticated: true,
  },
]

export default routes
