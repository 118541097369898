import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import notification from '~/shared/Notification'

import LiveClassesFormComponent from '../components/liveClasses-form.component'

import services from '../liveClasses.services'

function LiveClassesEditContainer() {
  const [liveClass, setLiveClass] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  const params = useParams()

  const { idLiveClass } = params

  useEffect(() => {
    async function getData() {
      const { data } = await services.getLiveClassesById(idLiveClass)

      setLiveClass(data)
      setLoadingData(false)
    }

    getData()
  }, [idLiveClass])

  async function onSubmit({
    dateLiveClass,
    videoId,
    disciplineId,
    disciplineSubjectId,
    inactive,
  }) {

    setLoadingSubmit(true)

    try {
      await services.liveClassesUpdate({
        idLiveClass,
        dateLiveClass,
        videoId,
        disciplineId,
        disciplineSubjectId,
        inactive: inactive || false,
      })

      notification.success('Aula ao Vivo atualizada com sucesso.')

      setLoadingSubmit(false)

      history.push('/aula-ao-vivo')
    } catch {
      setLoadingSubmit(false)
    }
  }

  if (loadingData) return <Skeleton active />

  return (
    <LiveClassesFormComponent
      type="E"
      loadingSubmit={loadingSubmit}
      liveClass={liveClass}
      onSubmit={onSubmit}
    />
  )
}

export default LiveClassesEditContainer
